<template>
    <div class="component-card-variant1" @click="handleSlideWrapperClick">
        <div class="header" :style="`background-image: url(${projectData.imgSrc})`">
            <div class="header-top">
                <div class="buttons-top-wrapper">
                    <ButtonShare/>

                    <Button
                            v-if="edit"
                            type="button"
                            class="small"
                            variant="round"
                            @click="handleEditButtonClick"
                    >
<!--                        <div class="button-edit-icon">-->
                            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'content-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="d2s6g597ma" maskUnits="userSpaceOnUse" x="4" y="4" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.8 16.202v3h3l8.848-8.848-3-3L4.8 16.202zm14.168-8.168a.797.797 0 000-1.128l-1.872-1.872a.797.797 0 00-1.128 0l-1.464 1.464 3 3 1.464-1.464z" fill="#fff"/></mask><g mask="url(#d2s6g597ma)"><path fill="#fff" d="M1.6 1.6h20.8v20.8H1.6z"/></g></svg>
<!--                        </div>-->

<!--                        <div class="button-edit-label">-->
                            {{ i18n.edit[$lang] }}
<!--                        </div>-->
                    </Button>

                    <ToggleFavourite v-else :projectId="projectData.id" :isFavourite="projectData.userFavourite"/>
                </div>
            </div>

            <div class="header-bottom">
                <div class="header-tags-wrapper">
                    <div v-for="tagCur in projectData.tags" :key="tagCur" class="header-tag">
                        {{ tagCur }}
                    </div>
                </div>
                <div class="header-stats-wrapper">
                    <div class="header-stat">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'header-stat-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="gvny19fb0a" maskUnits="userSpaceOnUse" x="4" y="7" width="22" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 7.5c-5 0-9.27 3.11-11 7.5 1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM15 20c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" fill="#fff"/></mask><g mask="url(#gvny19fb0a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

                        <div class="header-stat-label">
                            {{ projectData.stats.views }}
                        </div>
                    </div>

                    <div class="header-stat">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'header-stat-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="5mrwl80loa" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M24 9h-2v9H9v2c0 .55.45 1 1 1h11l4 4V10c0-.55-.45-1-1-1zm-4 6V6c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z" fill="#fff"/></mask><g mask="url(#5mrwl80loa)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

                        <div class="header-stat-label">
                            {{ projectData.stats.comments }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="title">
                <h3>{{ projectData.title }}</h3>
            </div>

            <div class="text">
                <p>{{ projectData.text }}</p>
            </div>

            <div class="content-footer">
                <div
                        v-if="projectData.stats.progress"
                        class="content-stats-and-progress-bar"
                >
                    <ProgressBar
                            :progress="projectData.stats.rating"
                            class="content-progress-bar"
                    />

                    <div class="content-stats">
                        <div class="content-stat-item">
                            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'content-stat-item-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="8pp1ni93ba" maskUnits="userSpaceOnUse" x="4" y="4" width="22" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 24h4V12H4v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L17.17 4l-6.58 6.59c-.37.36-.59.86-.59 1.41v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L26 13z" fill="#fff"/></mask><g mask="url(#8pp1ni93ba)"><path d="M2 2h26v26H2z"/></g></svg>

                            <div class="content-stat-item-label">
                                <h5>{{ projectData.stats.rating }} %</h5>
                            </div>
                        </div>

                        <div class="content-stat-item">
                            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'content-stat-item-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="25j8xwr7ra" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 5C9.5 5 5 9.5 5 15s4.5 10 10 10 10-4.5 10-10S20.5 5 15 5zm4.2 14.2L14 16v-6h1.5v5.2l4.5 2.7-.8 1.3z" fill="#fff"/></mask><g mask="url(#25j8xwr7ra)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

                            <template v-if="projectData.stats.remaining !== null && projectData.stats.remaining >= 0">
                                <div class="content-stat-item-label">
                                    <h5>{{ projectData.stats.remaining }} {{ i18n.daysRemaining[$lang] }}</h5>
                                </div>
                            </template>
                            <template v-else>
                                <div class="content-stat-item-label">
                                    <h5>{{ i18n.pollFinished[$lang] }}</h5>
                                </div>
                            </template>

                        </div>
                    </div>
                </div>
                <div v-if="status" class="content-status">
                    <img
                            :src="getImgSrcForStatus(status)"
                            alt=""
                            class="content-status-icon"
                    />

                    <div class="content-status-label">
                        {{ status[$lang] }}
                    </div>
                </div>

                <ToggleVoteButton
                        :is-user-voted="projectData.userVote"
                        :project-id="projectData.id"
                        @change="handleVoteStatusChange"
                />

                <!--Button
                            class="content-button"
                            :class="{ 'is-cancel': button === 'cancel' }"
                            @click="handleButtonClick(button)"
                            @mouseover="buttonHover = true"
                            @mouseleave="buttonHover = false"
                        >
                            <img
                                v-if="button === 'promote'"
                                svg-inline
                                :src="getImgSrcForButton(button)"
                                class="content-button-img"
                                alt=""
                            />
        
                            <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="button === 'cancel'" v-bind:svg-inline="''" v-bind:class="'content-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="5iiy9w7mea" maskUnits="userSpaceOnUse" x="3" y="3" width="22" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.733 22.4h3.734V11.2H3.733v11.2zm20.534-10.267c0-1.026-.84-1.866-1.867-1.866h-5.89L17.398 6l.028-.298c0-.383-.158-.738-.41-.99l-.99-.98-6.14 6.151c-.346.336-.552.803-.552 1.316v9.333c0 1.027.84 1.867 1.867 1.867h8.4c.775 0 1.437-.467 1.717-1.139l2.82-6.58c.083-.214.13-.438.13-.68v-1.784l-.01-.009.01-.075z" fill="#fff"/></mask><g mask="url(#5iiy9w7mea)"><path fill="#fff" d="M1.866 1.867h24.267v24.267H1.866z"/></g></svg>
        
                            <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="button === 'view'" v-bind:svg-inline="''" v-bind:class="'content-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="np5dc6mjpa" maskUnits="userSpaceOnUse" x="5" y="5" width="18" height="18"><path fill-rule="evenodd" clip-rule="evenodd" d="M20.533 20.533H7.467V7.467H14V5.6H7.467C6.43 5.6 5.6 6.44 5.6 7.467v13.066c0 1.027.83 1.867 1.867 1.867h13.066c1.027 0 1.867-.84 1.867-1.867V14h-1.867v6.533zM15.867 5.6v1.867h3.35l-9.174 9.174 1.316 1.316 9.174-9.174v3.35H22.4V5.6h-6.533z" fill="#fff"/></mask><g mask="url(#np5dc6mjpa)"><path fill="#D8D8D8" d="M1.867 1.867h24.267v24.267H1.867z"/></g></svg>
        
                            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="button === 'publish'" v-bind:svg-inline="''" v-bind:class="'content-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="su6x66wd9a" maskUnits="userSpaceOnUse" x="3" y="7" width="24" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.35 13.04A7.49 7.49 0 0015 7c-2.89 0-5.4 1.64-6.65 4.04A5.994 5.994 0 003 17c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 16v4h-4v-4h-3l5-5 5 5h-3z" fill="#fff"/></mask><g mask="url(#su6x66wd9a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
        
                            <div class="content-button-label">
                                {{ button }}
                                <div class="tooltip">
                                    Вы можете отменить голос в течение 24 часов
                                </div>
                            </div>
                        </Button-->
            </div>
        </div>
    </div>
</template>

<script>
    import Button from "@/components/Button";
    import ProgressBar from "@/components/ProgressBar";
    import objectValidator from "@/utils/objectValidator.js";
    import ToggleFavourite from "@/components/ToggleFavourite";
    import ToggleVoteButton from "@/components/ToggleVoteButton";
    import ButtonShare from "@/components/ButtonShare";
    import {mapState} from "vuex";

    export default {
        name: "CardVariant1",
        components: {
            ToggleVoteButton,
            ProgressBar,
            Button,
            ToggleFavourite,
            ButtonShare,
        },
        props: {
            project: {
                type: Object,
                required: true,
            },
            status: {
                type: [String, Object],
                default: "",
            },
            edit: {
                default: false,
            }
        },
        data() {
            return {
                projectData: {},
                buttonHover: false,
                i18n: {
                    daysRemaining: {
                        en: "days remaining",
                        ru: "дней осталось",
                    },
                    pollFinished:{
                        en: "Finished",
                        ru: "Завершено"
                    },
                    edit: {
                        en: "Edit",
                        ru: "Редактировать",
                    },
                },
            };
        },
        computed: {
            ...mapState(["$lang"]),
        },
        methods: {
            getImgSrcForStatus(status) {
                if (status.en === "moderation") {
                    return "/img/icons/ic_report.svg";
                }

                if (status.en === "draft") {
                    return "/img/icons/ic_restore.svg";
                }
            },
            getImgSrcForButton(button) {
                if (button === "promote") {
                    if (this.buttonHover) return "/img/icons/ic_thumb_up-white.svg";
                    else return "/img/icons/ic_thumb_up.svg";
                }

                if (button === "view") {
                    return "/img/icons/ic_open_in_new.svg";
                }

                if (button === "publish") {
                    return "/img/icons/ic_cloud_upload.svg";
                }
            },

            handleButtonClick(button) {
                event.stopPropagation();
                if (button === "promote") {
                    this.$router.push("profile-supported");
                }
            },

            handleEditButtonClick() {
                event.stopPropagation();
                this.$store
                    .dispatch("projectCreate/fetchProjectDraft", this.project.id)
                    .then(() => {
                        this.$router.push("/new-project-step");
                    });
            },

            handleSlideWrapperClick() {
                this.$router.push("/project/" + this.project.id);
            },

            handleVoteStatusChange(project) {
                vm.$set(this.projectData, "stats", project.data.stats);
                vm.$set(this.projectData, "userVote", project.data.userVote);
            }
        },
        mounted() {
            this.projectData = this.project;
        }
    };
</script>

<style scoped lang="scss">
    @import "@/sass/mixins.scss";

    .component-card-variant1 {
        // width: 540px;
        width: 100%;
        // max-width: calc(50% - 30px);
        //max-width: calc(50% - 15px);
        outline: none;
        cursor: pointer;
        overflow: hidden;
        border-radius: 6px;
        //margin-bottom: 30px;

        &.slide {
            max-width: calc(50% - 15px);
            @media only screen and (max-width: 767px){
                max-width: 100%;
            }
        }

        @include box-shadow-1;
    }

    .tooltip {
        position: absolute;
        top: 35px;
        left: -77px;
        width: 200px;
        border-radius: 6px;
        height: 60px;
        display: none;
        background: #666666;
        font-size: 11px;
        padding: 10px 0;
        color: #fff;
        line-height: 20px;
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        height: 300px;
        background: no-repeat center;
        background-size: cover;
        border-radius: 6px 6px 0 0;
        @media only screen and (max-width: 767px){
            height: 260px;
        }
        @media only screen and (max-width: 479px){
            height: 160px;
        }
    }

    .header-top {
        padding: 30px 31px;
        width: 100%;
        @media only screen and (max-width: 767px){
            padding: 10px;
        }
    }

    .header-bottom {
        margin-top: auto;
    }

    .buttons-top-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        background: rgba(#000, 0.3);
        border-radius: 50%;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #ffffff;
        @include mark-cards;

        .content-button-img {
            color: #ffffff;
        }

        &.edit {
            width: 180px;
            border-radius: 20px;
            justify-content: space-around;

            .button-edit-icon {
                margin-left: 10px;
            }

            .button-edit-label {
                margin-right: 25px;
            }
        }
    }

    .header-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 20px 30px 20px 34px;
        @media only screen and (max-width: 767px){
            padding: 20px 10px;
        }
    }

    .header-tags-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .header-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: bold;
        font-size: 9px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #ffffff;
        background: rgba(#000, 0.3);
        margin-left: 10px;

        &:nth-child(1) {
            margin-left: 0;
        }
    }

    .header-stats-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        @media only screen and (max-width: 767px){
            display: none;
        }
    }

    .header-stat {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 24px;

        &:nth-child(1) {
            margin-left: 0;
        }
    }

    .header-stat-img {
        width: 26px;
        color: #fff;
    }

    .header-stat-label {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        margin-left: 13px;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .content {
        padding: 30px;
        padding-bottom: 35px;
        background: #fff;
        width: 100%;

        @media only screen and (max-width: 1285px) {
            padding: 15px;
            padding-bottom: 20px;
        }
        @media only screen and (max-width: 767px){
            padding: 20px;
        }
    }

    .title {
        color: #4d4d4d;

    }

    .text {
        color: #3c3c3c;
        margin-top: 10px;
        height: 70px;
        overflow: hidden;
    }

    .content-footer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 25px;
        @media only screen and (max-width: 767px){
            .component-button{
                display: none;
            }
        }
    }

    .content-stats-and-progress-bar {
        margin-right: 30px;
        cursor:default;

        @media only screen and (max-width: 1405px) {
            margin-left: 15px;
        }
        @media only screen and (max-width: 767px){
            width: 100%;
            margin: 0;
        }
    }

    .content-stats {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 17px;
    }

    .content-stat-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 20px;

        &:nth-child(1) {
            margin-left: 0;
        }

        @media only screen and (max-width: 1405px) {
            margin-left: 15px;
        }

        @media only screen and (max-width: 1040px) {
            margin-left: 10px;
        }
        @media only screen and (max-width: 767px){
            margin-left: 12px;
        }
    }

    .content-stat-item-img {
        color: #d8d8d8;
        width: 26px;
        @media only screen and (max-width: 767px){
            width: 30px;
        }
    }

    .content-stat-item-label {
        color: #666666;
        margin-left: 10px;
        min-width: 30px;

        @media only screen and (max-width: 1405px) {
            margin-left: 5px;
        }

        @media only screen and (max-width: 767px){
            margin-left: 4px;
        }
    }

    .content-status {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .content-status-icon {
        width: 26px;
    }

    .content-status-label {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #808080;
        margin-left: 12px;

        &:first-letter {
            text-transform: capitalize;
        }
    }
</style>
