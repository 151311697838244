<template>
    <div class="view-help">
        <Header
            :logo-white="true"
            :invisible-bg="true"
            :login-button="true"
            :burger="true"
            page="support-center"
            class="header"
        />

<!--        <BurgerMenu />-->

        <div class="content">
            <div class="top-block">
                <div class="top-block-title">
                    <h1>{{ i18n.supportCenter[$lang] }}</h1>
                </div>

                <div class="top-block-input-wrapper">
                    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'top-block-input-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="wkpj44zaaa" maskUnits="userSpaceOnUse" x="6" y="6" width="18" height="18"><path fill-rule="evenodd" clip-rule="evenodd" d="M18.5 17h-.79l-.28-.27A6.471 6.471 0 0019 12.5a6.5 6.5 0 10-6.5 6.5c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L23.49 22l-4.99-5zm-6 0C10.01 17 8 14.99 8 12.5S10.01 8 12.5 8s4.5 2.01 4.5 4.5-2.01 4.5-4.5 4.5z" fill="#fff"/></mask><g mask="url(#wkpj44zaaa)"><path fill="#fff" d="M2 2h26v26H2z"/></g></svg>

                    <input :placeholder="i18n.searchAnswer[$lang]" class="top-block-input"/>
                </div>

                <div class="top-block-line"/>
            </div>

            <div class="cards-wrapper">
                <div v-for="(cardCur, index) in cards" :key="index" class="card">
                    <div class="card-left">
                        <img :src="cardCur.imgScr" alt="" class="card-icon"/>
                    </div>

                    <div class="card-right">
                        <div class="card-text">
                            <div class="card-title">
                                <h3>{{ cardCur.title[$lang] }}</h3>
                            </div>

                            <div class="card-desc">
                                <p>{{ cardCur.desc[$lang] }}</p>
                            </div>

                            <div class="card-amount text-micro">
                                {{ cardCur.amount[$lang] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Slide :img-src="require('@/assets/img/view-about/slide.png')" class="big-slide">
                <template #title>
                    <h2 v-html="i18n.FindAnAnswer[$lang]"/>
                </template>

                <template #text>
                    <p v-html="i18n.sendYourQuestion[$lang]"/>
                </template>

                <template #buttons>
                    <Button class="button primary">
                        {{ i18n.askQuestion[$lang] }}
                    </Button>
                </template>
            </Slide>
        </div>

        <Footer class="footer"/>
    </div>
</template>

<script>
    import Button from '@/components/Button';
    import Slide from '@/views/Home/components/Slide';
    import Header from '@/components/Header';
    import BurgerMenu from '@/components/BurgerMenu';
    import Footer from '@/components/Footer';
    import {mapState} from 'vuex';

    export default {
        name: 'Help',
        components: {
            Footer,
            Header,
            BurgerMenu,
            Slide,
            Button,
        },
        data() {
            return {
                cards: [
                    {
                        imgScr: require("@/assets/img/view-help/question-mark.svg"),
                        title: {
                            en: 'What is Grossfort',
                            ru: 'Что такое Grossfort'
                        },
                        desc: {
                            en: 'First time here? Explore how Grossfort can help you; starting from sales and marketing, and ending with customer acquisition and support.',
                            ru: 'Здесь впервые? Посмотрите, как Grossfort может помочь; от продаж и маркетинга до привлечения клиентов и поддержки.'
                        },
                        amount: {
                            en: '6 articles in this collection',
                            ru: '6 статей в этой коллекции'
                        },
                    },
                    {
                        imgScr: require('@/assets/img/view-help/user.svg'),
                        title: {
                            en: 'For authors',
                            ru: 'Авторам'
                        },
                        desc: {
                            en: 'Everything you need yo know to propose your project and start working.',
                            ru: 'Все, что вам нужно знать, чтобы предложить свой проект и начать работу'
                        },
                        amount: {
                            en: '6 articles in this collection',
                            ru: '12 статей в этой коллекции'
                        },
                    },
                    {
                        imgScr: require('@/assets/img/view-help/pie-chart.svg'),
                        title: {
                            en: 'For sponsors',
                            ru: 'Спонсорам'
                        },
                        desc: {
                            en: 'I am trying to find a job and I can be help with the project implementation. How can I contact the founder?',
                            ru: 'Я ищу работу и могу помочь в реализации проекта. Как связаться с владельцем'
                        },
                        amount: {
                            en: '6 articles in this collection',
                            ru: '12 статей в этой коллекции'
                        },
                    },
                    {
                        imgScr: require('@/assets/img/view-help/settings.svg'),
                        title: {
                            en: 'Technocal support',
                            ru: 'Техническая поддержка'
                        },
                        desc: {
                            en: 'We have a big number of projects from a variety of industries.',
                            ru: 'На площадку обращается большое количество компаний из разных сфер'
                        },
                        amount: {
                            en: '6 articles in this collection',
                            ru: '12 статей в этой коллекции'
                        },
                    },
                    {
                        imgScr: require('@/assets/img/view-help/life-saver.svg'),
                        title: {
                            en: 'Help with project preparation',
                            ru: 'Помощь в подготовке проектов'
                        },
                        desc: {
                            en: 'We try to select only investment attractive ones and provide them to accredited investors for analysis and investment decisions.',
                            ru: 'Мы стараемся отобрать только инвестиционно привлекательные, и предоставить их аккредитованным инвесторам для анализа и принятия решений об инвестициях.'
                        },
                        amount: {
                            en: '6 articles in this collection',
                            ru: '12 статей в этой коллекции'
                        },
                    },
                ],
                i18n: {
                    supportCenter: {
                        en: 'Support center',
                        ru: 'Центр поддержки'
                    },
                    FindAnAnswer: {
                        en: `Didn’t find an answer to your question?`,
                        ru: `Не нашли ответа на свой вопрос?`
                    },
                    sendYourQuestion: {
                        en: `Send us your question, and our manager will contact you<br />in 24 hours`,
                        ru: `Напишите что вас интересует и наш оператор свяжется с<br />вами в течении 24 часов с момента подачи заявки`
                    },
                    askQuestion: {
                        en: 'Ask a question',
                        ru: 'Задать вопрос'
                    },
                    searchAnswer: {
                        en: 'Search answer',
                        ru: 'Искать в ответах'
                    },
                },
            }
        },
        computed: {
            ...mapState(["$lang"])
        }
    };
</script>

<style scoped lang="scss">
    .footer {
        margin-top: 0 !important;
    }

    .top-block {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        height: 360px;
        padding: 0 260px 0 260px;
        background: url("~@/assets/img/view-help/placeholder.png") no-repeat center;
        background-size: cover;

        @media only screen and(max-width: 767px){
            padding: 0 25px 0 25px;
        }
    }

    .top-block-title {
        color: #FFFFFF;
        padding: 60px 0 60px;
    }

    .top-block-input-wrapper {
        position: relative;
        // margin-top: 60px;
        width: 100%;
    }

    .top-block-input-icon {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 12px;
        margin: auto;
        color: #999999;
    }

    .top-block-input {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // width: 296px;
        width: 100%;
        height: 60px;
        padding: 0 12px 0 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        color: #999999;
        border-radius: 4px;
        border: 0;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .top-block-line {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        // bottom: 60px;
        bottom: 30px;
        margin: auto;
        width: 70px;
        height: 2px;
        background: #50CAB6;

        // @media only screen and (max-width: 1300px) {
        //   bottom: 30px;
        // }
    }

    .cards-wrapper {
        margin-top: 60px;
        // padding: 0 260px 0 260px;
        padding: 0 260px 40px 260px;
        @media only screen and (max-width: 767px){
            padding: 0 25px 0 25px;
        }
    }

    .card {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: #FFFFFF;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        margin-top: 30px;
        padding: 30px 65px;
        min-height: 210px;

        &:nth-child(1) {
            margin-top: 0;
        }

        @media only screen and (max-width: 767px){
            padding: 20px;
        }
    }

    .card-right {
        margin-left: 64px;

        @media only screen and(max-width: 767px){
            margin-left: 25px;
        }
    }

    .card-text {
        transform: translateY(-10px);

        @media only screen and (max-width: 1250px) {
            transform: translateY(0px);
        }
    }

    .card-title {
        color: #4D4D4D;
    }

    .card-desc {
        color: #666666;
        margin-top: 10px;
    }

    .card-amount {
        color: #B3B3B3;
        margin-top: 15px;
    }

    .big-slide {
        margin-top: 22px;
        padding: 8% 166px 6.5%;

        @media only screen and(max-width: 767px){
            padding: 8% 25px 6.5%;
        }
    }

    .button {
        transform: translateY(-30px);
    }
</style>
