import { Node } from "tiptap";

export default class Div extends Node {
    get name() {
        return "div";
    }

    get schema() {
        return {
            group: "block",
            selectable: false,
            parseDOM: [
                {
                    tag: "div"
                }
            ],
            toDOM: node => [
                "div"
            ]
        };
    }

    /*commands({ type }) {
        return attrs => (state, dispatch) => {
            const { selection } = state;
            const position = selection.$cursor
                ? selection.$cursor.pos
                : selection.$to.pos;
            const node = type.create(attrs);
            const transaction = state.tr.insert(position, node);
            dispatch(transaction);
        };
    }*/
}
