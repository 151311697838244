<template>
  <div class="footer-card">
    <div class="line">
      <div class="user-stats">
        <div class="user-stat-item">
          <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stat-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="fdmu9roaxa" maskUnits="userSpaceOnUse" x="4" y="7" width="22" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 7.5c-5 0-9.27 3.11-11 7.5 1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM15 20c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" fill="#fff"/></mask><g mask="url(#fdmu9roaxa)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

          <div class="stat-count">
            275
          </div>
        </div>

        <div class="user-stat-item">
          <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stat-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="dodrltgjqa" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M24 9h-2v9H9v2c0 .55.45 1 1 1h11l4 4V10c0-.55-.45-1-1-1zm-4 6V6c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z" fill="#fff"/></mask><g mask="url(#dodrltgjqa)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

          <div class="stat-count">
            12
          </div>
        </div>

        <div class="user-stat-item">
          <img src="@/assets/img/icons/ic_account_circle.svg" alt="" class="stat-icon" />

          <div class="stat-count">
            Артур Куракин
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'FooterCard',
    data() {
      return {
        i18n: {
          userDesc: {
            en: 'Author',
            ru: 'Автор'
          },
          goUp: {
            en: 'Go up',
            ru: 'Наверх'
          },
          drones: {
            en: 'Drones',
            ru: 'Дроны'
          },
          jobs: {
            en: 'Jobs',
            ru: 'Роботы'
          },
          culture: {
            en: 'Culture',
            ru: 'Культура'
          },
          share: {
            en: 'Share',
            ru: 'Поделиться:'
          },

        },
      }
    },
    methods: {
      handleGoUpButtonClick() {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    }
  };
</script>

<style scoped lang="scss">
  .footer-card {
    width: 100%;
    // padding: 0 65px;
    border-top: 2px #F5F5F5 solid;
    border-bottom: 2px #F5F5F5 solid;
  }

  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 120px;
    width: 100%;
    padding: 0 65px;
    border-top: 2px #F5F5F5 solid;
    background: #fff;
    display: flex;
    justify-content: center;

    &:nth-child(1) {
      border-top: 0;
    }
  }

  .user-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .user-about {
    margin-left: 25px;
  }

  .user-name {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #666666;
  }

  .user-desc {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #B3B3B3;
    margin-top: 7px;
  }

  .user-stats {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .user-stat-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 45px;

    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .stat-icon {
    width: 26px;
  }

  .stat-count {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #B3B3B3;
    margin-left: 17px;
  }

  .go-up {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }

  .go-up-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 50px;
    text-align: right;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #B3B3B3;
  }

  .go-up-icon {
    width: 24px;
    margin-left: 12px;
  }

  .tags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .tag-item {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #B3B3B3;
    padding: 0 23px;
    background: #F7F7F7;
    border-radius: 4px;
    margin-left: 10px;

    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .share {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .share-items-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 22px;
  }

  .share-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 22px;

    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .share-label {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: #999999;
  }

  .share-icon {
    width: 26px;
  }

  .share-counter {
    position: relative;
  }

  .share-counter {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #B3B3B3;
    margin-left: 12px;
    line-height: 30px;
    border: 2px solid #F5F5F5;
    border-radius: 4px;
    padding: 0 10px;
    background: #fff;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      left: -7px;
      top: 0;
      bottom: 0;
      margin: auto;
      border-left: 2px solid #F5F5F5;
      border-bottom: 2px solid #F5F5F5;
      height: 10px;
      width: 10px;
      transform: rotate(45deg);
      background: #fff;
    }
  }
</style>
