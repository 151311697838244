//! @/store/_noty.js

export default {
  namespaced: true,
  state: {
    message: [],
    success: [],
    warning: [],
    error: [],
  },
  mutations: {
    SET_MESSAGE(state, payload) {
      state.message.push(payload);
    },
    SET_SUCCESS(state, payload) {
      state.success.push(payload);
    },
    SET_WARNING(state, payload) {
      state.warning.push(payload);
    },
    SET_ERROR(state, payload) {
      state.error.push(payload);
    },
  },
};
