<template>
    <div class="view-profile-supported">
        <Header/>
<!--        <BurgerMenu/>-->

        <div class="top-block">
            <div class="title">
                <h2>{{ i18n.projectSponsor[$lang] }}</h2>
            </div>
        </div>

        <div class="content">
            <template v-for="(project, index) in projects">
                <div :key="index" class="slide-wrapper">
                    <CardVariant1 class="slide" :project="project"/>
                </div>
            </template>
        </div>

        <div class="show-more-wrapper">
            <Spinner v-if="isLoading"/>
            <ShowMore v-else-if="hasMorePages" class="show-more" @click="load"/>
        </div>

        <Footer/>
    </div>
</template>

<script>
    import api from "@/api/project";
    import Spinner from '@/components/Spinner';
    import Header from '@/components/Header';
    import BurgerMenu from '@/components/BurgerMenu';
    import ShowMore from '@/components/ShowMore';
    import SlideVariant1 from '@/components/SlideVariant1';
    import Footer from '@/components/Footer';
    import prepareSvgElements from '@/mixins/prepareSvgElements.js';
    import {mapState} from 'vuex';
    import CardVariant1 from "@/components/CardVariant1";

    export default {
        name: 'ProfileSupported',
        components: {
            CardVariant1,
            Header,
            Footer,
            BurgerMenu,
            ShowMore,
            SlideVariant1,
            Spinner,
        },
        mixins: [prepareSvgElements],
        data() {
            return {
                isLoading: false,
                hasMorePages: false,
                page: 1,
                projects: [],
                i18n: {
                    projectSponsor: {
                        en: 'Project Sponsor',
                        ru: 'Спонсор проектов'
                    },
                },
            }
        },
        methods: {
            load() {
                this.isLoading = true;
                this.resize();

                api.getSupportedProjects(this.page)
                    .then(({data}) => {
                        this.projects = [...this.projects, ...data.data];
                        this.page = data.meta.current_page+1;
                        this.hasMorePages = data.meta.current_page < data.meta.last_page;
                    })
                    .catch()
                    .then(()=>{
                        this.isLoading = false;
                        this.resize();
                    })
            },
            resize() {
                window.dispatchEvent(new Event("resize"));
            }
        },
        computed: {
            ...mapState(["$lang"]),
        },
        mounted(){
            this.load();
        }
    };
</script>

<style scoped lang="scss">
    .view-profile-supported {
        padding-top: 90px;
    }

    .top-block {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        height: 148px;
        width: 100%;
        padding: 0 195px;
        border-bottom: 2px #f0f0f0 solid;

        @media only screen and (max-width: 767px){
            padding: 0 20px;
            height: 80px;
        }
    }

    .title {
        margin-bottom: 28px;
        margin-top: 90px;
    }

    .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px 30px;
        padding: 30px 165px 35px 165px;

        @media only screen and (max-width: 1130px) {
            padding: 30px 10px 35px 10px;
        }

        @media only screen and (max-width: 767px){
            grid-template-columns: 1fr;
        }
    }

    .slide-wrapper {
        background: #FFFF;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
    }

    .slide {
        max-width: none !important;
    }

    .show-more-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .footer {
        margin-top: 30px !important;
    }
</style>
