<template>
  <div class="view-home-component-how-it-works">
    <h2 class="title">
      {{ i18n.howItWorks[$lang] }}
    </h2>

    <div class="steps-wrapper">
      <div v-for="(stepCur, index) in steps" :key="index" class="step-item">
        <div class="left">
          <div class="step-number">
            {{ index + 1 }}
          </div>
        </div>

        <div class="right">
          <h3 class="step-name">
            {{ stepCur.name[$lang] }}
          </h3>

          <p class="step-desc">
            {{ stepCur.desc[$lang] }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'HowItWorks',
    data() {
      return {
        steps: [
          {
            name: {
              en: 'Registration and entry fee',
              ru: 'Регистрация и стартовый взнос'
            },
            desc:
            {
              en: 'Only registered participants can propose and vote for projects. This will require an entry fee.',
              ru: 'Предлагать и голосовать за проекты могут только зарегистрированные участники. Для этого потребуется внести стартовый взнос.'
            },
          },
          {
            name: {
              en: 'Stock purchase',
              ru: 'Покупка акций'
            },
            desc:
            {
              en: 'The number of shares determines your share in the projects of the site and the amount of dividends. Balance can be checked and replenished in your account.',
              ru: 'Количество акций определяет вашу долю в проектах площадки и размер дивидендов. Баланс можно проверить и пополнить в личном кабинете.'
            },
          },
          {
            name: {
              en: 'Project proposal',
              ru: 'Предложение проекта'
            },
            desc: {
              en: 'In your account you can describe your business idea in detail. Our experienced moderators will help you design and evaluate it.',
              ru: 'В личном кабинете вы можете подробно описать вашу бизнес-идею. Наши опытные модераторы помогут её оформить и оценить.'
            },
          },
          {
            name: {
              en: 'Voting',
              ru: 'Голосование'
            },
            desc: {
              en: 'After the idea was accepted by moderators, it appears on the main page. All participants of the site can vote for the project.',
              ru: 'После того как идею приняли модераторы, она появляется на главной странице. Голосовать за проект могут все участники площадки.'
            },
          },
          {
            name: {
              en: 'Funding and Launch',
              ru: 'Финансирование и запуск'
            },
            desc: {
              en: 'If the project scored the required number of votes, we sign an agreement with you and transfer funds to its implementation.',
              ru: 'Если проект набрал нужное количество голосов, мы подписываем с вами договор и переводим средства на его реализацию.'
            },
          },
          {
            name: {
              en: 'Dividends',
              ru: 'Дивиденды'
            },
            desc: {
              en: 'Part of the business profit is returned to the site. It is distributed among all participants in the form of dividends and goes to the implementation of new projects.',
              ru: 'Часть прибыли бизнеса возвращается на площадку. Она распределяется между всеми участниками в виде дивидендов и идёт на реализацию новых проектов.'
            },
          }
        ],
        i18n: {
          howItWorks: {
            en: 'How it works',
            ru: 'Как это работает'
          },
        },
      };
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    }
  };
</script>




<style scoped lang="scss">
  .view-home-component-how-it-works {
    // padding: 120px 165px 178px 180px;
    padding: 119px 12.5vw 59px;
    background: #fff;
    @media only screen and (max-width: 767px){
      padding: 60px 20px;
    }
  }

  .title {
    text-align: center;
    color: #4D4D4D;
  }

  .steps-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 60px;
    grid-row-gap: 60px;
    align-items: flex-start;
    margin-top: 90px;
    @media only screen and (max-width: 767px){
      grid-template-columns: 1fr 1fr;
      margin-top: 52px;
    }
    @media only screen and (max-width: 599px){
      grid-template-columns: 1fr;
      grid-row-gap: 40px;
    }
  }

  .step-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .step-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background: #50CAB6;
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #FFFFFF;
    border-radius: 50%;
    transform: translateY(calc(-50% + 15px));
    @media only screen and (max-width: 767px){
      width: 40px;
      height: 40px;
      font-size: 10px;
      transform: translateY(calc(-50% + 10px));
    }
  }

  .right {
    margin-left: 20px;
    @media only screen and (max-width: 767px){
      margin-left: 10px;
    }
    @media only screen and (max-width: 479px){
      padding-right: 30px;
    }
  }

  .step-name {
    color: #4D4D4D;
  }

  .step-desc {
    color: #3c3c3c;
    margin-top: 10px;
  }
</style>
