<template>
  <DiagramWrapper class="wrapper" :header-text="headerText">
    <div class="lines">
      <canvas :id="diagramId" width="800" height="500" />
    </div>

    <div class="legend">
      <div class="legend-item">
        <div class="legend-item-dot" style="background: #5553CE" />
        <div class="legend-item-label">
          {{ i18n.consumption[$lang] }}
        </div>
      </div>

      <div class="legend-item">
        <div class="legend-item-dot" style="background: #50CAB6" />
        <div class="legend-item-label">
          {{ i18n.consumptionAmount[$lang] }}
        </div>
      </div>

      <div class="legend-item">
        <div class="legend-item-dot" style="background: #FFA660" />
        <div class="legend-item-label">
          {{ i18n.income[$lang] }}
        </div>
      </div>

      <div class="legend-item">
        <div class="legend-item-dot" style="background: #CA5074" />
        <div class="legend-item-label">
          {{ i18n.incomeAmount[$lang] }}
        </div>
      </div>
    </div>
  </DiagramWrapper>
</template>

<script>
  import Chart from 'chart.js';
  import DiagramWrapper from '../DiagramWrapper';
  import { mapState } from 'vuex';

  export default {
    name: 'BarDiagram',
    components: { DiagramWrapper },
    props: {
      diagramId: {
        type: String,
        required: true
      },
      headerText: {
        type: [String, Object],
        required: true
      }
    },
    data() {
      return {
        labels: [
          '2017',
          '2018',
          '2019',
          '2020',
          '2021'
        ],
        diagramData1: [
          0,
          25,
          50,
          75,
          107
        ],
        diagramData2: [
          0,
          15,
          10,
          55,
          77
        ],
        diagramData3: [
          0,
          27,
          33,
          2,
          55
        ],
        diagramData4: [
          0,
          77,
          15,
          60,
          110
        ],
        i18n: {
          consumption: {
            en: 'Consumption',
            ru: 'Расход'
          },
          consumptionAmount: {
            en: 'Amount of consumption ',
            ru: 'Сумм. Расход'
          },
          income: {
            en: 'Income',
            ru: 'Доход'
          },
          incomeAmount: {
            en: 'Amount of income',
            ru: 'Сумм. доход'
          }
        }
      };
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang
      }),
    },
    mounted() {
      const ctx = document.getElementById(this.diagramId);

      const myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.labels,
          datasets: [
            this.getDataSet(this.diagramData1, '#FFA660'),
            this.getDataSet(this.diagramData2, '#50CAB6'),
            this.getDataSet(this.diagramData3, '#606EFF'),
            this.getDataSet(this.diagramData4, '#CA5074')
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          },
          elements: {
            point: {
              radius: 0
            }
          }
        }
      });
    },
    methods: {
      getDataSet(data, color) {
        return {
          label: '',
          data,
          backgroundColor: color,
          borderColor: color,
          fill: false
        };
      },
      getColors() {
        const colors = [];

        for (let i = 0; i < this.diagramData.data.length; i++) {
          colors.push('#606EFF');
        }

        return colors;
      }
    }
  };
</script>

<style scoped lang="scss">
  .wrapper {
    padding-bottom: 0;
  }

  .bar {
    height: 500px;
    max-width: 800px;
    width: 100%;
  }

  .legend {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 58px;
    border-top: 2px solid #F5F5F5;
    border-radius: 6px;
    width: 100%;
    margin-top: 15px;
    @media only screen and (max-width: 767px){
      flex-wrap: wrap;
    }
  }

  .legend-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 30px;

    &:nth-child(1) {
      margin-left: 0;
    }
    @media only screen and (max-width: 767px){
      margin-left: 0;
      margin-right: 20px;
      &:last-child{
        margin-right: 0;
      }
    }
  }

  .legend-item-dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  .legend-item-label {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #B3B3B3;
    margin-left: 8px;
    @media only screen and (max-width: 767px){
      font-size: 10px;
      line-height: 14px;
      margin-left: 10px;
    }
  }
</style>
