export default function objectValidator(value, keys) {
  const obj = {};

  keys.forEach((keyCur) => {
    obj[keyCur] = false;
  });

  Object.keys(value).forEach((keyCur) => {
    obj[keyCur] = true;
  });

  const objectKeys = Object.keys(obj);
  const absentKeys = [];

  for (let i = 0; i < objectKeys.length; i++) {
    const keyCur = objectKeys[i];
    const valueCur = obj[keyCur];

    if (valueCur === false) {
      absentKeys.push(keyCur);
    }
  }

  if (absentKeys.length > 0) {
    console.error(`There are absent keys in passed object that are required: ${absentKeys}`);
  }

  return absentKeys.length === 0;
}
