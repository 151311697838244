<template>
    <div class="view-profile-discovery-component-card" @click="handleSlideWrapperClick">
        <div class="header" :style="`background-image: url(${imgSrc})`">
            <div class="header-top">
                <div class="buttons-top-wrapper">
                    <button type="button" class="button share">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'content-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="sfh0frbvja" maskUnits="userSpaceOnUse" x="6" y="7" width="18" height="15"><path fill-rule="evenodd" clip-rule="evenodd" d="M17 11V7l7 7-7 7v-4.1c-5 0-8.5 1.6-11 5.1 1-5 4-10 11-11z" fill="#fff"/></mask><g mask="url(#sfh0frbvja)"><path d="M2 2h26v26H2z"/></g></svg>
                    </button>

                    <ToggleFavourite :projectId="id"/>
                </div>
            </div>

            <div class="header-bottom">
                <div class="header-tags-wrapper">
                    <div v-for="tagCur in tags" :key="tagCur" class="header-tag">
                        {{ tagCur }}
                    </div>
                </div>
                <div class="header-stats-wrapper">
                    <div class="header-stat">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'header-stat-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="v6xk5ygk5a" maskUnits="userSpaceOnUse" x="4" y="7" width="22" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 7.5c-5 0-9.27 3.11-11 7.5 1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM15 20c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" fill="#fff"/></mask><g mask="url(#v6xk5ygk5a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

                        <div class="header-stat-label">
                            {{ stats.views }}
                        </div>
                    </div>

                    <div class="header-stat">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'header-stat-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="9bs9w1ufca" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M24 9h-2v9H9v2c0 .55.45 1 1 1h11l4 4V10c0-.55-.45-1-1-1zm-4 6V6c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z" fill="#fff"/></mask><g mask="url(#9bs9w1ufca)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

                        <div class="header-stat-label">
                            {{ stats.comments }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="title">
                {{ title }}
            </div>

            <div class="text">
                {{ text }}
            </div>

            <div class="content-footer">
                <div class="content-stats-and-progress-bar">
                    <ProgressBar
                        :progress="stats.progress"
                        class="content-progress-bar"
                    />

                    <div class="content-stats">
                        <div class="content-stat-item">
                            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'content-stat-item-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="59ggony98a" maskUnits="userSpaceOnUse" x="4" y="4" width="22" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 24h4V12H4v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L17.17 4l-6.58 6.59c-.37.36-.59.86-.59 1.41v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L26 13z" fill="#fff"/></mask><g mask="url(#59ggony98a)"><path d="M2 2h26v26H2z"/></g></svg>

                            <div class="content-stat-item-label">
                                {{ stats.rating }} %
                            </div>
                        </div>

                        <div class="content-stat-item">
                            <img src="@/assets/img/icons/ic_clock.svg" alt="" class="content-stat-item-img"/>

                            <div class="content-stat-item-label">
                                {{ stats.remaining }} {{ i18n.daysRemaining[$lang] }}
                            </div>
                        </div>
                    </div>
                </div>
                <ToggleVoteButton
                    :is-user-voted="userVote"
                    :project-id="id"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import Button from '@/components/Button';
    import ProgressBar from '@/components/ProgressBar';
    import objectValidator from '@/utils/objectValidator.js';
    import ToggleFavourite from "@/components/ToggleFavourite"
    import ToggleVoteButton from "@/components/ToggleVoteButton";
    import {mapState} from "vuex";

    export default {
        name: 'Card',
        components: {
            ProgressBar,
            Button,
            ToggleVoteButton,
            ToggleFavourite
        },
        props: {
            id: {
                type: [Number],
                required: true
            },
            title: {
                type: [String, Object],
                required: true
            },
            text: {
                type: [String, Object],
                required: true
            },
            imgSrc: {
                type: String,
                required: true
            },
            tags: {
                type: [Array, Object],
                required: true
            },
            edit: {
                type: Boolean,
                default: false
            },


            userVote:{
                type: Boolean,
                default: false
            },
            /**
             * @var status { 'draft' || 'moderation' }
             */
            status: {
                type: [String, Object],
                default: ""
            },
            stats: {
                type: Object,
                required: true,
                validator(value) {
                    const keys = ["comments", "views", "rating", "remaining"];

                    return objectValidator(value, keys);
                }
            },
            // progress: {
            //     type: Object,
            //     default() {
            //         return null;
            //     },
            //     validator(value) {
            //         const keys = ["support", "remaining", "percent"];
            //
            //         return objectValidator(value, keys);
            //     }
            // }
        },
        data() {
            return {
                i18n: {
                    daysRemaining: {
                        en: 'days remaining',
                        ru: 'дней осталось'
                    },
                },
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
        },
        methods: {
            handleSlideWrapperClick() {
                this.$router.push("/project/" + this.id);
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "@/sass/mixins.scss";

    .view-profile-discovery-component-card {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        outline: none;
        margin: 30px 0 30px 2px;
        width: 100%;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
        cursor: pointer;
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 50%;
        height: 300px;
        background: no-repeat center;
        background-size: cover;
        border-radius: 6px 0 0 6px;
    }

    .header-top {
        padding: 30px 31px;
        width: 100%;
    }

    .header-bottom {
        margin-top: auto;
    }

    .buttons-top-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        background: rgba(#000, 0.3);
        border-radius: 50%;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #FFFFFF;

        .content-button-img {
            color: #FFFFFF;
        }

        /*&:hover {*/
        /*    background: #F7F7F7;*/
        /*    border: 2px solid #f5f5f5;*/
        /*    transition: all 0.3s ease;*/

        /*    .content-button-img {*/
        /*        color: #B3B3B3;*/
        /*    }*/
        /*}*/
    }

    .content-button-img {
        width: 21px;
    }

    .header-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 20px 30px 20px 34px;
    }

    .header-tags-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .header-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: bold;
        font-size: 9px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #FFFFFF;
        background: rgba(#000, 0.3);
        margin-left: 10px;

        &:nth-child(1) {
            margin-left: 0;
        }
    }

    .header-stats-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
    }

    .header-stat {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 24px;

        &:nth-child(1) {
            margin-left: 0;
        }
    }

    .header-stat-img {
        width: 26px;
        color: #fff;
    }

    .header-stat-label {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        margin-left: 13px;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 30px;
        padding-bottom: 35px;
        background: #fff;
        width: 50%;

        @media only screen and (max-width: 1285px) {
            padding: 15px;
            padding-bottom: 20px;
        }
    }

    .title {
        font-family: Raleway;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #4D4D4D;

        @media only screen and (max-width: 1030px) {
            font-size: 18px;
        }
    }

    .text {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;
        color: #999999;
        margin-top: 10px;

        @media only screen and (max-width: 1155px) {
            line-height: 25px;
        }

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .content-footer {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin-top: auto;
    }

    .content-stats-and-progress-bar {
        margin-right: 30px;

        @media only screen and (max-width: 1405px) {
            margin-left: 15px;
        }
    }

    .content-button {
        // margin-left: 30px;
        margin-left: auto;
        width: 180px;
    }

    .content-button-img {
        width: 24px;
    }

    .content-button-label {
        font-size: 12px !important;
        margin-left: 22px;

        @media only screen and (max-width: 1100px) {
            margin-left: 8px;
        }
    }

    .content-stats {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 17px;
    }

    .content-stat-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 36px;
        white-space: nowrap;

        &:nth-child(1) {
            margin-left: 0;
        }

        @media only screen and (max-width: 1405px) {
            margin-left: 15px;
        }

        @media only screen and (max-width: 1040px) {
            margin-left: 10px;
        }
    }

    .content-stat-item-img {
        width: 26px;
    }

    .content-stat-item-label {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #666666;
        margin-left: 10px;

        @media only screen and (max-width: 1405px) {
            margin-left: 5px;
            font-size: 12px;
        }
        @media only screen and (max-width: 1075px) {
            font-size: 10px;
        }
    }
</style>
