import ax from "axios";
import store from "@/store";
import router from "@/router";

ax.defaults.withCredentials = true;
ax.defaults.baseURL = process.env.VUE_APP_API;
ax.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const token = localStorage.getItem("token");

if (token) {
  ax.defaults.headers.common["Authorization"] = "Bearer " + token;
}

ax.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (
        error.response.status === 401 &&
        error.config &&
        !error.config.__isRetryRequest &&
        router.currentRoute.path !== "/login"
      ) {
        store.dispatch("logout");

        var redirect = {
          path: "/login",
        };

        if (router.currentRoute.path !== "/") {
          redirect.query = { from: router.currentRoute.path };
        }

        router.push(redirect);
      } else if (error.response.status === 422) {
        for (var field in error.response.data.errors) {
          store.commit(
            "noty/SET_ERROR",
            error.response.data.errors[field].join(", ")
          );
        }
      } else {
        store.commit("noty/SET_ERROR", error.message);
      }
      // } else if (error.request) {
      // } else {
    }

    return Promise.reject(error);
  }
);

export const axios = ax;

export default {
  install(Vue) {
    Vue.prototype.$axios = ax;
  },
};
