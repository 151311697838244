<template>
  <div class="view-home-component-paragraph">
    <div class="header">
      <h2 class="title">
        {{ paragraph.title[$lang] }}
      </h2>

      <div class="buttons-wrapper">
        <Button
          v-bind="sliderButtonConfig"
          class="slider-button is-prev"
          @click="handleSliderPrevBtnClick"
        >
          <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="sbactuwt0a" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M23 14H10.83l5.59-5.59L15 7l-8 8 8 8 1.41-1.41L10.83 16H23v-2z" fill="#fff"/></mask><g mask="url(#sbactuwt0a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
        </Button>

        <Button
          v-bind="sliderButtonConfig"
          class="slider-button is-next"
          @click="handleSliderNextBtnClick"
        >
          <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="2r0op2izia" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M23 14H10.83l5.59-5.59L15 7l-8 8 8 8 1.41-1.41L10.83 16H23v-2z" fill="#fff"/></mask><g mask="url(#2r0op2izia)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
        </Button>
      </div>
    </div>

    <div :class="{ line: line, 'no-line': !line }" />

    <div class="slider-wrapper">
      <div class="slider">
        <VueSlickCarousel
          ref="carousel"
          v-bind="slickCarouselConfig"
          class="slides-wrapper"
        >
          <component
            :is="sliderVariant"
            v-for="(slideCur, index) in paragraph.slides"
            :key="index"
            v-bind="slideCur"
            class="slide"
          />
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import objectValidator from "@/utils/objectValidator.js";
import SlideVariant1 from "@/components/SlideVariant1";
import SlideVariant2 from "@/components/SlideVariant2";
import Roadmap from "./components/Roadmap";
import VueSlickCarousel from "vue-slick-carousel";
import { mapState } from "vuex";

export default {
  name: "Paragraph",
  components: {
    SlideVariant1,
    SlideVariant2,
    Roadmap,
    VueSlickCarousel,
    Button,
  },
  props: {
    paragraph: {
      type: Object,
      required: true,
      validator(value) {
        const keys = ["title", "slides"];

        return objectValidator(value, keys);
      },
    },
    sliderVariant: {
      type: String,
      required: true,
    },
    slickCarouselConfig: {
      type: Object,
      required: true,
    },
    line: {
      default: true,
    },
  },
  data() {
    return {
      sliderButtonConfig: {
        variant: "round",
        withoutBorder: true,
      },
    };
  },
  methods: {
    handleSliderPrevBtnClick() {
      this.$refs.carousel.prev();
    },
    handleSliderNextBtnClick() {
      this.$refs.carousel.next();
    },
  },
  computed: {
    ...mapState({
      $lang: (state) => state.$lang,
    }),
  },
};
</script>

<style scoped lang="scss">
@import "@/sass/mixins.scss";

.view-home-component-paragraph {
  position: relative;
}

.slick-list {
  padding: 0 0 0 5px !important;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 0 185px;
  // padding: 0 9.65vw;
  padding: 0px 12.6vw;
  @media only screen and (max-width: 767px){
    padding: 0px 5vw;
  }
}

.title {
  color: #4d4d4d;
}

.line {
  /*width: 100%;*/
  border-bottom: 2px solid #f0f0f0;
  /*height: 2px;*/
  /*background: #f0f0f0;*/
  margin-top: 26px;
  margin-bottom: 30px;
  @media only screen and (max-width: 767px){
    margin: 20px;
  }
}

.no-line {
  width: 100%;
  height: 0 !important;
  background: none;
  margin-top: 26px;
  margin-bottom: 30px;
}

.slides-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // padding: 0 165px;
  padding: 0 11.5vw;

  @media only screen and (max-width: 1300px) {
    padding: 0 12.5vw;
  }

  @media only screen and (max-width: 1130px) {
    padding: 0px 8.5vw;
  }

  @media only screen and (max-width: 1440px) {
    &:nth-child(1) {
      padding: 0px 8.5vw;
    }
  }

  @media only screen and (max-width: 1170px) {
    &:nth-child(1) {
      padding: 0px 6vw;
    }
  }

  @media only screen and (max-width: 1060px) {
    &:nth-child(1) {
      padding: 0px 5vw;
    }
  }
  @media only screen and (max-width: 767px){
    padding: 0 !important;
  }
}

.roadmap .slide {
  margin-left: 0 !important;
}
.slide {
  margin-left: 30px;

  &:nth-child(1) {
    margin-left: 0;
  }
}

.buttons-wrapper {
  display: flex;
  @media only screen and (max-width: 767px){
    display: none;
  }
}

.slider-button {
  width: 60px;
  height: 60px;

  &.is-next {
    transform: rotate(180deg);
  }
}
</style>
