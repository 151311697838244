<template>
    <div class="view-new-project-step">
        <Header class="header" page="create-a-project"/>

<!--        <BurgerMenu/>-->

        <div class="step-main">
            <div class="step-nav">
                <StepNavigation :active-step="4"/>
            </div>

            <div class="step-content-wrapper">
                <div class="step-content">
                    <div class="view-profile-discovery-component-slide">
                        <div class="head" :style="`background-image: url(${image_link})`">
                            <div class="head-bottom">
                                <div class="head-tags-wrapper">
                                    <div v-for="tagCur in tags" :key="tagCur" class="head-tag">
                                        {{ tagCur }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <div class="title">
                                {{ name }}
                            </div>

                            <div class="text">
                                {{ description }}

                            </div>

                            <div class="content-footer">
                                <div class="content-stats-and-progress-bar">
                                    <div class="content-stats">
                                        <div class="content-stat-item">
                                            <div class="content-stat-item-label">
                                                <h6>{{ stats.reviewPeriod }} {{ i18n.days[$lang] }}</h6>
                                            </div>

                                            <div class="content-stat-item-text text-micro">
                                                {{ i18n.reviewPeriod[$lang] }}
                                            </div>
                                        </div>

                                        <div class="content-stat-item">
                                            <div class="content-stat-item-label">
                                                <h6>{{ funding/1000000 }} {{ i18n.m[$lang] }} ₽</h6>
                                            </div>

                                            <div class="content-stat-item-text text-micro">
                                                {{ i18n.budget[$lang] }}
                                            </div>
                                        </div>
                                        <div class="content-stat-item">
                                            <div class="content-stat-item-label">
                                                <h6>{{ stats.implPeriod }} {{ i18n.months[$lang] }}</h6>
                                            </div>

                                            <div class="content-stat-item-text text-micro">
                                                {{ i18n.implementationPeriod[$lang] }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="step-content-wrapper">
                <div class="step-buttons">
                    <Button class="choose-file-button btn-back" @click="handleButtonBackClick()">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'choose-file-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="o4mty896va" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M23 14H10.83l5.59-5.59L15 7l-8 8 8 8 1.41-1.41L10.83 16H23v-2z" fill="#fff"/></mask><g mask="url(#o4mty896va)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

                        <span class="choose-file-button-label">
                            {{ i18n.financialPart[$lang] }}
                        </span>
                    </Button>

                    <Button class="choose-file-button primary" :loading="sendModerationStatus===1"
                            @click="handleButtonNextClick()">
                        <span class="choose-file-button-label">
                            {{ i18n.sendOnModeration[$lang] }}
                        </span>

                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'choose-file-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="rzavkq9tna" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 7l-1.41 1.41L19.17 14H7v2h12.17l-5.58 5.59L15 23l8-8-8-8z" fill="#fff"/></mask><g mask="url(#rzavkq9tna)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                    </Button>
                </div>
            </div>
        </div>

        <Footer class="footer"/>
    </div>
</template>

<script>
    import Header from '@/components/Header';
    import BurgerMenu from '@/components/BurgerMenu';
    import Footer from '@/components/Footer';
    import Button from '@/components/Button';
    import StepNavigation from '@/components/StepNavigation';
    import {mapState, mapActions} from 'vuex';


    export default {
        name: 'NewProjectStep',
        components: {
            Header,
            BurgerMenu,
            Footer,
            StepNavigation,
            Button,
        },
        data() {
            return {

                title: {
                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    ru: 'Дом, позволяющий выращивать Эко продукты и зарабатывать, проживая на земле'
                },
                text: {
                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                    ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                },
                imgSrc: '/img/view-new-project-step/img.png',
                tags: [
                    'Литература',
                    'Культура'
                ],
                stats: {
                    reviewPeriod: 7,
                    budget: 4.6,
                    implPeriod: 18,
                },
                i18n: {
                    reviewPeriod: {
                        en: 'Review period',
                        ru: 'Срок голосования'
                    },
                    days: {
                        en: 'days',
                        ru: 'дней'
                    },
                    budget: {
                        en: 'Budget',
                        ru: 'Бюджет'
                    },
                    m: {
                        en: 'm',
                        ru: 'Млн'
                    },
                    months: {
                        en: 'months',
                        ru: 'мес'
                    },
                    implementationPeriod: {
                        en: 'Implementation period',
                        ru: 'Срок реализации'
                    },
                    financialPart: {
                        en: 'Financial part',
                        ru: 'Финансовая часть'
                    },
                    sendOnModeration: {
                        en: 'Send on moderation',
                        ru: 'Отправить на модерацию'
                    },

                },
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
            ...mapState('projectCreate', [
                'id',
                'name',
                'description',
                'image_link',
                "sendModerationStatus",
                "funding"
            ])
        },
        methods: {
            ...mapActions('projectCreate', [
                "sendToModeration"
            ]),
            handleButtonNextClick() {
                console.log(this.id);
                this.sendToModeration(this.id)
                    .then(() => {
                        this.$router.push('new-project-step-5');
                    })
            },
            handleButtonBackClick() {
                this.$router.push('new-project-step-3');
            },
        }
    };
</script>

<style scoped lang="scss">

    .step-main {
        padding: 120px 165px 0 165px;

        @media only screen and (max-width: 1180px) {
            padding: 120px 95px 0 95px;
        }
        @media only screen and (max-width: 767px){
            padding: 70px 0 0;
        }
    }

    .step-content-wrapper {
        background: #fff;
        box-shadow: 0 10px 30px -2px #0000000d;
        border-radius: 6px;
        @media only screen and (max-width: 767px){
            margin: 0 10px;
        }
    }

    .step-content {
        padding: 0 0 0px 0;
        margin-top: 30px;
        margin-bottom: 36px;
        @media only screen and (max-width: 767px){
            margin: 10px 0;
        }
    }


    .step-buttons {
        display: flex;
        position: relative;
        justify-content: center;
        padding: 48px 0 48px 0;

        .choose-file-button {
            padding: 8px 25px;

            &:nth-child(1) {
                margin-right: 30px;
                @media only screen and (max-width: 767px){
                    margin-right: 0;
                }
            }
        }
        @media only screen and (max-width: 767px){
            padding: 18px 20px;
            justify-content: space-between;
            .btn-back{
                span{
                    display: none;
                }
            }
            .choose-file-button{
                padding: 5px 6px !important;
                svg{
                    position: relative;
                    width: 24px;
                    height: 24px;
                    top: -3px;
                    left: -3px;
                }
                &.primary{
                    min-width: 183px;
                }
            }
        }
    }

    .view-profile-discovery-component-slide {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        outline: none;
        margin: 0;
        width: 100%;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        @media only screen and (max-width: 767px){
            flex-direction: column;
            border-radius: 6px;
            box-shadow: 0 10px 30px -2px rgba(79, 65, 124, 0.0533217);
            overflow: hidden;
        }
    }

    .head {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 48.88%;
        min-height: 322px;
        background: no-repeat center;
        background-size: cover;
        border-radius: 6px 0 0 6px;
        @media only screen and (max-width: 767px){
            width: 100%;
            border-radius: 0;
            min-height: 222px;
        }
        @media only screen and (max-width: 479px){
            min-height: 160px;
        }
    }

    .head-top {
        padding: 30px 31px;
        width: 100%;
    }

    .head-bottom {
        margin-top: auto;
    }

    .buttons-top-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        background: rgba(#000, 0.3);
        border-radius: 50%;
        // @include mark-default;
    }

    .content-button-img {
        width: 21px;
    }

    .header-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 20px 30px 20px 34px;
    }

    .head-tags-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 0 32px 32px;
        @media only screen and (max-width: 767px){
            padding: 0 0 20px 20px;
        }
    }

    .head-tag {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 80px;
        max-width: 120px;
        height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: bold;
        font-size: 9px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #FFFFFF;
        background: rgba(#000, 0.3);
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
        @media only screen and (max-width: 767px){
            line-height: 20px;
            height: auto;
        }
    }

    .head-stats-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
    }

    .head-stat {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 24px;

        &:nth-child(1) {
            margin-left: 0;
        }
    }

    .head-stat-img {
        width: 26px;
        color: #fff;
    }

    .head-stat-label {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        margin-left: 13px;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 35px 32px;
        padding-bottom: 35px;
        background: #fff;
        width: 50%;

        @media only screen and (max-width: 1285px) {
            padding: 15px;
            padding-bottom: 20px;
        }
        @media only screen and (max-width: 767px){
            width: 100%;
            padding: 20px 25px 40px;
        }
    }

    .title {
        font-family: Raleway;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #4D4D4D;

        @media only screen and (max-width: 1030px) {
            font-size: 18px;
        }
        @media only screen and (max-width: 767px){
            font-size: 15px;
            line-height: 20px;
        }
    }

    .text {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;
        color: #999999;
        margin-top: 10px;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px){
            font-size: 11px;
            line-height: 18px;
        }
    }

    .content-footer {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin-top: auto;
        @media only screen and (max-width: 767px){
            margin-top: 40px;
            &>div{
                width: 100%;
            }
        }
    }


    .content-button {
        // margin-left: 30px;
        margin-left: auto;
        width: 180px;
    }

    .content-button-img {
        width: 24px;
    }

    .content-button-label {
        font-size: 12px !important;
        margin-left: 22px;

        @media only screen and (max-width: 1100px) {
            margin-left: 8px;
        }
    }

    .content-stats {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 17px;
        padding: 0;
        @media only screen and (max-width: 767px){
            margin-top: 0;
            flex-direction: column;
            width: 100%;
        }
    }

    .content-stat-item {
        display: flex;
        /* align-items: center; */
        /* justify-content: flex-start; */
        margin-left: 36px;
        white-space: nowrap;
        flex-direction: column;

        &:nth-child(1) {
            margin-left: 0;
        }

        @media only screen and (max-width: 1405px) {
            margin-left: 15px;
        }

        @media only screen and (max-width: 1040px) {
            margin-left: 10px;
        }
        @media only screen and (max-width: 767px){
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-left: 0;
            &:not(:last-child){
                margin-bottom: 10px;
            }
            &>div{
                max-width: 50%;
                width: 100%;
            }

        }
    }

    .content-stat-item-img {
        width: 26px;
    }

    .content-stat-item-label {
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #808080;
        margin-right: 64px;

        &:last-child {
            margin-right: 0;
        }

        @media only screen and (max-width: 1405px) {
            margin-left: 5px;
        }
        @media only screen and (max-width: 1075px) {
            font-size: 10px;
        }
        @media only screen and (max-width: 767px){
            margin: 0;
            h6{
                font-size: 11px;
                line-height: 11px;
            }
        }
    }

    .content-stat-item-text {
        color: #B3B3B3;
        @media only screen and (max-width: 767px){
            font-size: 10px;
            line-height: 14px;
        }
    }

</style>
