<template>
    <div class="team">
        <div v-for="memberCur in team" :key="memberCur.name" class="team-member">
            <div class="team-member-ava">
                <img :src="memberCur.imgSrc" alt=""/>
            </div>

            <div class="team-member-about">
                <div class="team-member-name">
                    <h5>{{ memberCur.name }}</h5>
                </div>

                <div class="team-member-desc">
                    <div class="text-micro">{{ memberCur.desc }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Team',
        data() {
            return {
                team: [
                    {
                        imgSrc: require('@/assets/img/team-ava.png'),
                        name: 'Denis Rubakin',
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                    },
                    {
                        imgSrc: require('@/assets/img/team-ava2.png'),
                        name: 'Metis Rubakin',
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                    },
                    {
                        imgSrc: require('@/assets/img/team-ava3.png'),
                        name: 'Karniz Rubakin',
                        desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                    }
                ]
            }
        }
    };
</script>

<style scoped lang="scss">
    .team {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 52px;
    }

    .team-member {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 65px;
        margin-top: 10px;

        &:nth-child(1) {
            margin-left: 0;
        }

        @media only screen and (max-width: 1409px) {
            margin-left: 0;
        }
    }

    .team-member-ava {
        height: 50px;
        width: 50px;
        border-radius: 50%;
    }

    .team-member-about {
        margin-left: 20px;
        width: 120px;
    }

    .team-member-name {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #666666;
    }

    .team-member-desc {
        color: #B3B3B3;
        margin-top: 7px;
    }
</style>
