<template>
    <div class="view-profile-favourites">
        <Header has-new-notifications/>
<!--        <BurgerMenu/>-->

        <div class="top-block">
            <div class="title">
                <h2>{{ i18n.favourites[$lang] }}</h2>
            </div>
        </div>

        <div class="content">
            <div
                v-for="(project, index) in projects"
                :key="index"
                class="slide-wrapper"
            >
                <CardVariant1 class="" :project="project" button="promote"/>
            </div>
        </div>

        <div class="show-more-wrapper">
            <Spinner v-if="isLoading"/>
            <ShowMore
                v-if="hasMore && !isLoading"
                class="show-more"
                @click="loadMore"
            />
        </div>

        <Footer/>
    </div>
</template>

<script>
    import api from "@/api/project";
    import Header from "@/components/Header";
    import Footer from "@/components/Footer";
    import BurgerMenu from "@/components/BurgerMenu";
    import CardVariant1 from "@/components/CardVariant1";
    import ShowMore from "@/components/ShowMore";
    import Spinner from "@/components/Spinner";
    import scrollByNewItems from "@/utils/scrollByNewItems.js";
    import {mapState} from "vuex";
    import Card from "../ProfileDiscovery/components/Card/Card";

    export default {
        name: "ProfileFavourites",
        components: {
            Card,
            BurgerMenu,
            Header,
            Footer,
            ShowMore,
            Spinner,
            CardVariant1,
        },
        data() {
            return {
                projects: [],
                page: 1,
                hasMore: false,
                isLoading: false,
                i18n: {
                    favourites: {
                        en: "Favourites",
                        ru: "Избранное",
                    },
                },
            };
        },
        mounted() {
            this.loadMore();
        },
        methods: {
            loadMore() {
                if (this.isLoading) {
                    return;
                }

                this.isLoading = true;

                api.getFavouriteProjects(this.page)
                    .then(({data}) => {
                        this.projects = [...this.projects, ...data.data];
                        this.hasMore = data.meta.current_page < data.meta.last_page;

                        if (this.page > 1) {
                            this.$nextTick(() => {
                                scrollByNewItems(
                                    ".content",
                                    ".slide",
                                    data.data.length
                                );
                            });
                        }

                        this.page++;
                    })
                    .catch(e => {
                        console.log(e);
                    })
                    .then(() => {
                        this.isLoading = false;
                    });
            }
        },
        computed: {
            ...mapState(["$lang"]),
        },
    };
</script>

<style scoped lang="scss">
    .view-profile-favourites {
        padding-top: 90px;
    }

    .top-block {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        height: 148px;
        width: 100%;
        padding: 0 195px;
        border-bottom: 2px #f0f0f0 solid;

        @media only screen and (max-width: 767px){
            padding: 0 20px;
            height: 80px;
        }
    }

    .title {
        margin-bottom: 28px;
        margin-top: 90px;
    }

    /*.content {*/
    /*    display: grid;*/
    /*    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));*/
    /*    grid-gap: 0 0;*/
    /*    padding: 30px 120px;*/
    /*}*/

    .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px 30px;
        padding: 30px 165px 35px 165px;

        @media only screen and (max-width: 1130px) {
            padding: 30px 10px 35px 10px;
        }

        @media only screen and (max-width: 767px){
            grid-template-columns: 1fr;
        }
    }

    .show-more-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
