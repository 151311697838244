<template>
  <div class="view-home-component-stats">
    <div v-for="(itemCur, index) in items" :key="index" class="stat-item">
      <div class="line" />

      <div class="amount">
        {{ itemCur.amount }}
      </div>

      <div class="item">
        {{ itemCur.desc[$lang] }}
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'Stats',
    data() {
      return {
        items: [
          {
            amount: '16 724',
            desc: {
              en: 'Amount of users',
              ru: 'Всего участников'
            },
          },
          {
            amount: '7 641',
            desc: {
              en: 'Published projects',
              ru: 'Размещённых проектов'
            },
          },
          {
            amount: '678 млн',
            desc: {
              en: 'Rubles invested',
              ru: 'рублей инвестировано'
            },
          },
        ]
      }
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    }
  };
</script>

<style scoped lang="scss">
  .view-home-component-stats {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 180px;
    width: 100%;
    background: #fff;
  }

  .stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .line {
    width: 37px;
    height: 2px;
    background: #50CAB6;
  }

  .amount {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #666666;
    margin-top: 15px;

    @media only screen and (max-width: 1030px) {
      font-size: 20px;
    }
  }

  .item {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #B3B3B3;
    margin-top: 5px;

    @media only screen and (max-width: 1030px) {
      font-size: 12px;
    }
  }
</style>
