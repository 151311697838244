<template>
    <div class="component-roadmap">
        <div class="steps-wrapper">
            <div class="step-item">
                <div class="name-line"/>
                <div class="name">
                    <div class="name-label">
                        <h3>{{ name }}</h3>
                    </div>
                </div>
                <div class="item">
                    <p class="text-micro">{{ desc[$lang] }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Roadmap',
        props: {
            name: {
                type: String,
                required: true,
            },
            desc: {
                type: Object,
                required: true,
            }
        },
        computed: {
            ...mapState(["$lang"]),
        }
    };
</script>

<style scoped lang="scss">
    .steps-wrapper {
        display: grid;
        align-items: start;
        grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
        grid-column-gap: 0px;
        grid-row-gap: 30px;

        @media only screen and (max-width: 1210px) {
            grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
        }
    }

    .name {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        margin-top: 10px;
        padding-right: 30px;
    }

    .name-label {
        color: #4D4D4D;
        white-space: nowrap;

        @media only screen and (max-width: 1030px) {
            font-size: 18px;
        }
    }

    .name-line {
        width: 100%;
        height: 2px;
        background: #50CAB6;
        position: relative;
        margin: 7px 0;
    }

    .step-item {
        position: relative;
    }

    .step-item:before {
        content: '';
        position: absolute;
        background: #50cab6;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        z-index: 9999999;
        border-radius: 50px;
    }

    .item {
        color: #666;
        margin-top: 14px;
        padding-right: 30px;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }
</style>
