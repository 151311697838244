<template>
  <div class="view-profile-discovery">
    <Header class="header" page="view-projects" />
<!--    <BurgerMenu />-->

    <div class="top-block">
      <div class="title">
        <h2>{{ i18n.projectsTitle[$lang] }}</h2>
      </div>

      <div class="nav">
        <div
          class="nav-item"
          :class="{ 'is-active': navItemActiveIndex === index }"
          v-for="(navItemCur, index) in navItems[$lang]"
          :key="index"
          @click="handleNavItemClick(index)"
        >
          {{ navItemCur }}
        </div>
      </div>
    </div>

    <div class="content">
      <VueSlickCarousel
        class="slides-wrapper"
        ref="carousel"
        v-bind="slickCarouselConfig"
        @beforeChange="beforeChange"
      >
        <slide
          v-for="slideIndex in slides"
          :key="slideIndex"
          :is-active="navItemActiveIndex === slideIndex"
          :filter="navItemsFilters[slideIndex]"
        />
      </VueSlickCarousel>
    </div>

    <Subscribe class="subscribe" />

    <Footer class="footer" />
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import { mapState } from "vuex";

import Header from "@/components/Header";
import BurgerMenu from "@/components/BurgerMenu";
import Footer from "@/components/Footer";

import Subscribe from "@/views/Home/components/Subscribe";
import Slide from "./components/Slide";

export default {
  name: "ProfileDiscovery",
  components: {
    VueSlickCarousel,
    Header,
    BurgerMenu,
    Footer,
    Subscribe,
    Slide,
  },
  data() {
    return {
      isLoading: false,
      navItemActiveIndex: false,
      navItems: {
        en: ["All Projects", "Ending Soon", "Most Discussed", "New Projects"],
        ru: [
          "Все проекты",
          "Скоро завершатся",
          "Самые обсуждаемые",
          "Новые проекты",
        ],
      },
      navItemsFilters: ["", "end", "most", "new"],
      slickCarouselConfig: {
        arrows: false,
        dots: false,
        slidesPerRow: 1,
        swipe: false,
        draggable: false,
        adaptiveHeight: true,
        infinite: false,
      },
      i18n: {
        projectsTitle: {
          en: "Projects",
          ru: "Проекты",
        },
      },
      slides: [0, 1, 2, 3],
    };
  },
  computed: {
    ...mapState(["$lang"]),
  },
  mounted() {
    this.navItemActiveIndex = 0;
    // this.$store.dispatch("projects/loadProjects");
    // this.$store.dispatch("projects/loadFavouritesId");
  },
  methods: {
    handleNavItemClick(index) {
      this.$refs.carousel.goTo(index);
    },
    beforeChange(oldSlide, newSlide) {
      this.navItemActiveIndex = newSlide;
    },
  },
};
</script>

<style scoped lang="scss">
.view-profile-discovery {
  padding-top: 90px;
  background: #fafafa;
}

.top-block {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 148px;
  width: 100%;
  padding: 0 195px;
  border-bottom: 2px #f0f0f0 solid;
  margin-bottom: 30px;

  @media only screen and (max-width: 1360px) {
    padding: 0 95px;
  }

  @media only screen and (max-width: 767px) {
    padding: 0 15px;
    flex-direction: column;
    align-items: flex-start;
    height: 95px;
  }
}

.title {
  color: #4d4d4d;
  margin-bottom: 28px;

  @media only screen and (max-width: 1030px) {
    font-size: 28px;
  }
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 88px;
  width: 100%;
  padding: 0 25px 0 30%;

  @media only screen and (max-width: 1400px) {
    padding: 0 25px 0 20%;
  }

  @media only screen and (max-width: 767px) {
    padding: 0 0 0 0;
    height: 47px;
    align-items: flex-end;
  }
}

.nav-item {
  position: relative;
  font-family: IBMPlexSans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 90px;
  text-align: center;
  color: #b3b3b3;
  cursor: pointer;

  &:after {
    content: "";
    display: none;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 150%;
    height: 2px;
    background: #50cab6;

    @media only screen and (max-width: 767px){
      width: 100%;
      bottom: -2px;
    }

  }

  &.is-active {
    color: #50cab6;

    &:after {
      display: block;
    }
  }

  @media only screen and (max-width: 1130px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 12px;
    line-height: 18px;
    padding-bottom: 5px;
  }
}

.content {
  padding: 0 140px;

  @media only screen and (max-width: 1360px) {
    padding: 0 95px;
  }

  @media only screen and (max-width: 767px) {
    padding: 0 0px;
  }
}

.subscribe {
  margin-top: 40px;
}

.show-more-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  margin-top: 0 !important;
}
</style>
