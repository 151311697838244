<template>
    <div class="view-main-component-digest">
        <div class="stat supported">


            <div class="stat-percent-and-desc">
                <div class="stat-circle">
                    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="jmhv48cjea" maskUnits="userSpaceOnUse" x="5" y="6" width="20" height="18"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 10V6H5v18h20V10H15zM9 22H7v-2h2v2zm0-4H7v-2h2v2zm0-4H7v-2h2v2zm0-4H7V8h2v2zm4 12h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V8h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2v-2h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z" fill="#fff"/></mask><g mask="url(#jmhv48cjea)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                </div>
                <div class="stat-percent">
                    {{capitalCosts / 1000000}} МЛН ₽
                </div>

                <div class="stat-desc">
                    Капитальные
                </div>
            </div>
        </div>

        <div class="stat remaining">


            <div class="stat-percent-and-desc">
                <div class="stat-circle">
                    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="okya7rjqca" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="19"><path fill-rule="evenodd" clip-rule="evenodd" d="M23 9h-4V7c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H7c-1.11 0-1.99.89-1.99 2L5 22c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V11c0-1.11-.89-2-2-2zm-6 0h-4V7h4v2z" fill="#fff"/></mask><g mask="url(#okya7rjqca)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                </div>
                <div class="stat-percent">
                    {{fixedCosts / 1000000}} МЛН ₽
                </div>

                <div class="stat-desc">
                    Постоянные
                </div>
            </div>
        </div>

        <div class="stat budget">


            <div class="stat-percent-and-desc">
                <div class="stat-circle">
                    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="9n9xw7hc7a" maskUnits="userSpaceOnUse" x="5" y="8" width="20" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M13 8h4v12h-4V8zm-2 7H7v5h4v-5zm-6 9v-3h20v3H5zm18-13h-4v9h4v-9z" fill="#fff"/></mask><g mask="url(#9n9xw7hc7a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                </div>
                <div class="stat-percent">
                    {{variableCosts}} ₽/ед.
                </div>

                <div class="stat-desc">
                    Переменные
                </div>
            </div>
        </div>

        <div class="stat period">


            <div class="stat-percent-and-desc">
                <div class="stat-circle">
                    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="3kat1ga5da" maskUnits="userSpaceOnUse" x="8" y="7" width="15" height="17"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.4 9L17 7H8v17h2v-7h5.6l.4 2h7V9h-5.6z" fill="#fff"/></mask><g mask="url(#3kat1ga5da)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                </div>
                <div class="stat-percent">
                    {{ Math.round(paybackPeriod / 12 * 10)/10}} Года
                </div>

                <div class="stat-desc">
                    Срок окупаемости
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Stats',
        props:{
            "capitalCosts":{
                type: Number,
                required: true,
                default:0
            },
            "fixedCosts":{
                type: Number,
                required: true,
                default:0
            },
            "variableCosts":{
                type: Number,
                required: true,
                default:0
            },
            "paybackPeriod":{
                type: Number,
                required: true,
                default:0
            },

        },
        data() {
            return {
                i18n: {
                    supported: {
                        en: 'Supported',
                        ru: 'Поддержали'
                    },
                    days: {
                        en: 'days',
                        ru: 'дней'
                    },
                    remaining: {
                        en: 'Remaining',
                        ru: 'Осталось'
                    },
                    poll: {
                        en: 'Poll',
                        ru: 'Голосование'
                    },
                    finished: {
                        en: 'Finished',
                        ru: 'Завершено'
                    },
                    budget: {
                        en: 'Budget',
                        ru: 'Бюджет'
                    },
                    m: {
                        en: 'M',
                        ru: 'Млн'
                    },
                    M: {
                        en: 'm',
                        ru: 'мес'
                    },
                    implementationPeriod: {
                        en: 'Implementation period',
                        ru: 'Срок реализации'
                    },

                },
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
                stats: (state) => state.projects.project.stats,
                funding: (state) => {
                    return state.projects.project.funding / 1000000

                }
            }),
        }
    };
</script>

<style scoped lang="scss">
    .view-main-component-digest {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        min-height: 150px;
        background: #fff;
        //padding: 10px 21px 10px 45px;
        margin-top: 30px;
        border-radius: 6px;

        @media only screen and (max-width: 1272px) {
            padding: 10px 15px 10px 15px;
        }
        @media only screen and (max-width: 767px){
            padding: 17px 11px 39px;
            margin-top: 12px;
        }
    }

    .stat {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        margin-left: 15px;

        &:before {
            content: '';
            display: block;
            height: 90px;
            width: 2px;
            background: #F5F5F5;
            margin-right: 30px;
            flex-shrink: 0;

            @media only screen and (max-width: 1272px) {
                margin-right: 15px;
            }
        }

        &:nth-child(1) {
            margin-left: 0;

            &:before {
                display: none;
            }
        }

        @media only screen and (max-width: 1272px) {
            &:nth-last-child(1) {
                // transform: translateX(-47px);

                &:before {
                    // opacity: 0;
                }
            }
        }
        @media only screen and (max-width: 767px){
            max-width: 50%;
            width: 100%;
            margin-left: 0;
            margin-bottom: 20px;
            &:nth-child(2n){
                margin-left: 20px;
                max-width: calc(50% - 20px);
            }
            &:before{
                display: none;
            }

        }
    }

    .stat-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 26px;
        width: 26px;
        background: #FFFFFF;
        //border: 2px solid #F5F5F5;
        //border-radius: 50%;
        flex-shrink: 0;
        color: #d8d8d8;
        margin-bottom: 10px;
        @media only screen and (max-width: 767px){
            height: 30px;
            width: 30px;
        }
    }

    .stat-percent-and-desc {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        //margin-left: 30px;
        flex-shrink: 0;

        @media only screen and (max-width: 1272px) {
            margin-left: 15px;
        }
        @media only screen and (max-width: 767px){
            margin-left: 5px;
            flex-shrink: 1;
        }
    }

    .stat-percent {
        font-family: IBMPlexSans;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0px;
        text-align: left;

        color: #666666;

        @media only screen and (max-width: 1272px) {
            font-size: 20px;
        }

        @media only screen and (max-width: 1030px) {
            font-size: 18px;
        }
        @media only screen and (max-width: 767px){
            font-size: 16px;
        }
    }

    .stat-desc {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #B3B3B3;
        margin-top: 12px;

        @media only screen and (max-width: 1272px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px){
            font-size: 11px;
            margin-top: 0;
        }
    }
</style>
