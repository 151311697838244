<template>
    <div class="component-profile-my-projects">
        <Header class="header"/>

<!--        <BurgerMenu/>-->

        <div class="top-block">
            <div class="title">
                <h2>{{ i18n.projectsTitle[$lang] }}</h2>
            </div>
        </div>

        <div class="content">
            <div class="slide-wrapper">
                <div class="create-project">
                    <div class="create-project-title">
                        {{ i18n.createProjectTitle[$lang] }}
                    </div>

                    <div class="create-project-desc">
                        {{ i18n.createProjectDesc[$lang] }}
                    </div>

                    <Button
                            class="create-project-button"
                            variant="round"
                            @click="handleButtonClick"
                    >
                        <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'create-project-button-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="asz1124g6a" maskUnits="userSpaceOnUse" x="7" y="7" width="14" height="14"><path fill-rule="evenodd" clip-rule="evenodd" d="M20.534 14.934h-5.6v5.6h-1.867v-5.6h-5.6v-1.867h5.6v-5.6h1.867v5.6h5.6v1.867z"/></mask><g mask="url(#asz1124g6a)"><path d="M1.867 1.867h24.267v24.267H1.867z"/></g></svg>

                        <div class="create-project-button-label">
                            {{ i18n.createProject[$lang] }}
                        </div>
                    </Button>
                </div>
            </div>

            <template v-for="(project, index) in projects">
                <div
                        :key="index"
                        class="slide-wrapper"
                >
                    <CardVariant class="slide" :project="project" status="draft" edit="true"/>
                </div>
            </template>

            <div class="show-more-wrapper">
                <Spinner v-if="isLoading"/>
                <ShowMore
                        v-if="hasMorePages && !isLoading"
                        class="show-more"
                        @click="load"
                />
            </div>

        </div>



        <Footer class="footer"/>
    </div>
</template>

<script>
    import api from "@/api/project";
    import Header from "@/components/Header";
    import BurgerMenu from "@/components/BurgerMenu";
    import Spinner from "@/components/Spinner";
    import ShowMore from "@/components/ShowMore";
    import SlideVariant1 from "@/components/SlideVariant1";
    import Button from "@/components/Button";
    import Footer from "@/components/Footer";
    import prepareSvgElements from "@/mixins/prepareSvgElements.js";
    import scrollByNewItems from "@/utils/scrollByNewItems.js";
    import {mapState, mapGetters} from "vuex";
    import CardVariant from "@/components/CardVariant1/";

    export default {
        name: "ProfileMyProjects",
        components: {
            CardVariant,
            Header,
            BurgerMenu,
            Spinner,
            ShowMore,
            Button,
            Footer
        },
        mixins: [prepareSvgElements],
        data() {
            return {
                projects: [],
                page: 1,
                hasMorePages: true,
                isLoading: false,
                slides: [
                    {
                        title: {
                            en:
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                            ru:
                                "Съедобные не токсичные покрытия с энергетиком для спортсменов"
                        },
                        text: {
                            en:
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                            ru:
                                "Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё"
                        },
                        imgSrc: "/img/view-profile-my-projects/img1.png",
                        edit: true,
                        status: {
                            en: "moderation",
                            ru: "На модерации"
                        },
                        button: {
                            en: "view",
                            ru: "Смотреть"
                        },
                        tags: ["Роботы", "Дроны"],
                        stats: {
                            views: 275,
                            comments: 12
                        }
                    },
                ],
                i18n: {
                    projectsTitle: {
                        en: "Projects",
                        ru: "Мои проекты"
                    },
                    createProjectTitle: {
                        en: "Show people an interesting idea or something helpful",
                        ru: "Предложите людям интересную идею или что-то полезное"
                    },
                    createProjectDesc: {
                        en:
                            "Tell everybody about it, test the demand by early preorders. Get the money and change the world right now!",
                        ru:
                            "Расскажите об этом всем, протестируйте спрос на свой продукт через ранние предзаказы. Получите деньги и измените мир прямо сейчас!"
                    },
                    createProject: {
                        en: "Create a project",
                        ru: "Создать проект"
                    }
                }
            };
        },
        mounted() {
            this.load();
        },
        methods: {
            handleButtonClick() {
                this.$router.push("new-project-step");
            },
            load() {
                this.isLoading = true;
                this.resize();

                api.getMyProjects(this.page)
                    .then(({data}) => {
                        this.projects = [...this.projects, ...data.data];
                        this.page = data.meta.current_page + 1;
                        this.hasMorePages = data.meta.current_page < data.meta.last_page;
                    })
                    .catch()
                    .then(() => {
                        this.isLoading = false;
                        this.resize();
                    })


            },
            resize() {
                window.dispatchEvent(new Event("resize"));
            },
        },
        computed: {
            ...mapState(["$lang"]),
            ...mapGetters("projects", ["getProjects"])
        }
    };
</script>

<style scoped lang="scss">
    .component-profile-my-projects {
        padding-top: 90px;
        background: #fafafa;
    }

    .top-block {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        height: 148px;
        width: 100%;
        padding: 0 195px;
        border-bottom: 2px #f0f0f0 solid;

        @media only screen and (max-width: 767px){
            padding: 0 20px;
            height: 80px;
        }
    }

    .title {
        color: #4d4d4d;
        margin-bottom: 28px;
    }

    .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px 30px;
        padding: 30px 165px 35px 165px;

        @media only screen and (max-width: 1130px) {
            padding: 30px 10px 35px 10px;
        }

        @media only screen and (max-width: 767px){
            grid-template-columns: 1fr;
        }
    }

    .slide-wrapper {
        background: #ffff;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
    }

    .slide {
        max-width: none !important;
    }

    .create-project {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 90px 0 90px;
        min-height: 510px;
        height: 100%;

        @media only screen and (max-width: 1330px) {
            padding: 0 90px 0 90px;
        }

        @media only screen and (max-width: 1260px) {
            padding: 0 40px 0 40px;
        }
    }

    .create-project-title {
        font-family: Raleway;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        color: #4d4d4d;
        line-height: 30px;

        @media only screen and (max-width: 1030px) {
            font-size: 18px;
        }
    }

    .create-project-desc {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        text-align: center;
        color: #999999;
        margin-top: 30px;
        line-height: 30px;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .create-project-button {
        width: 225px;
        margin-top: 30px;
    }

    .create-project-button-icon {
        width: 25px !important;
        height: 25px !important;
        margin-top: -2px;
    }

    .create-project-button-label {
        margin-left: 12px;
    }

    .show-more-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .footer {
        margin-top: 30px !important;
    }
</style>
