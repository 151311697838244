export default class LocalStorage {
    /**
     *
     * @param key {string}
     * @param val {*}
     */
    static write(key, val) {
        if (typeof val === 'undefined') throw new Error('value for write is undefined!');

        localStorage.setItem(key, JSON.stringify(val)); //сериализуем объект в строку
    }

    /**
     *
     * @param key {string}
     */
    static read(key) {
        if (!localStorage.getItem(key)) return null;

        return JSON.parse(localStorage.getItem(key)); //получаем значение и десериализируем его в объект
    }

    /**
     *
     * @param key {string}
     */
    static remove(key) {
        localStorage.removeItem(key);
    }

    static removeAll() {
        Object.keys(localStorage).forEach((key) => {
            LocalStorage.remove(key);
        });
    }
};
