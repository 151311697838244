<template>
  <button
    type="button"
    class="component-button"
    :class="{
      'is-round': variant === 'round',
      'without-border': withoutBorder,
      'is-loading': loading,
    }"
    v-on="$listeners"
  >
    <div v-if="loading" class="loader" />
    <slot v-else />
  </button>
</template>

<script>
export default {
  name: "Button",
  data() {
    return {
      hover: false,
    };
  },
  props: {
    /**
     * @param variant {'square' | 'round'}
     */
    variant: {
      type: String,
      default: "square",
    },
    withoutBorder: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/mixins.scss";

.component-button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 4px;

  @include button-default;
  background-color: transparent;
  padding: 0 10px;

  &.is-round {
    border-radius: 30px;
  }

  &.without-border {
    border-color: transparent;
  }

  .content-button-img {
  }

  /* Button Size */
  &.large {

  }
  &.normal {
    @media only screen and (max-width: 767px) {
      letter-spacing: 1.5px;
      height: auto !important;
      font-weight: 700;
      padding: 0 28px !important;
    }
  }
  &.small {
    line-height: var(--line-height-button-small);
    font-size: var(--font-size-button-small);
  }
  &.extra {
  }

  /* Button Color Scheme */
  &.primary {
    @include button-primary;
    @media only screen and (max-width: 767px) {
      letter-spacing: .5px;
      height: auto !important;
    }

    &:after,
    &:before{
      content:"\200d";
    }
  }

  .svg,
  path {
    fill: currentColor !important;
  }
}

.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #50cab6;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;

}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
