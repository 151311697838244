<template>
  <div class="component-progress-bar">
    <div class="active" :style="`width: ${progress}%`" />
  </div>
</template>

<script>
  export default {
    name: 'ProgressBar',
    props: {
      progress: {
        type: Number,
        required: true,
      }
    }
  };
</script>

<style scoped lang="scss">
  .component-progress-bar {
    height: 5px;
    width: 100%;
    background: #F5F5F5;
    border-radius: 3px;
    @media only screen and (max-width: 767px){
      margin-top: 20px;
    }
  }

  .active {
    height: inherit;
    background: #50CAB6;
    border-radius: 5px;
  }
</style>
