<template>
    <div class="view-profile-settings">
        <Header class="header"/>

<!--        <BurgerMenu/>-->

        <div class="top-block">
            <div class="top-block-title">
                <h2>{{ i18n.settings[$lang] }}</h2>
            </div>
        </div>

        <div class="content">
            <div class="nav">
                <div
                        v-for="(navItemCur, index) in navItems[$lang]"
                        :key="index"
                        class="nav-item"
                        :class="{ 'is-active': navItemActiveIndex === index }"
                        @click="handleNavItemClick(index)"
                >
                    {{ navItemCur.label }}
                </div>
            </div>
            <div class="nav-mobile">
                <v-select

                        :options='navItems[$lang]'
                        :clearable="false"
                        :value="navItems[$lang][0].label"
                        @input="handleNavSelectChange"
                        class="select"
                >
                    <template #open-indicator="{ attributes }">
                                          <span v-bind="attributes">
                                            <svg width="9" height="5" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.498 5L9 2.5V.104L4.498 2.62 0 0v2.5L4.498 5z" fill="#D9D9D9"/></svg>
                                          </span>
                    </template>
                </v-select>
            </div>

            <div class="wrapper">
                <div class="menu">
                    <a
                            v-for="(menuItemCur, index) in menuItemsCurrent"
                            :key="index"
                            :href="menuItemCur.href"
                            class="menu-item"
                            :class="{ 'is-active': index === menuItemActiveIndex }"
                            @click="handleMenuItemClick($event, menuItemCur, index)"
                    >
                        <h5>{{ menuItemCur.label }}</h5>
                    </a>
                </div>

                <div class="slider">
                    <VueSlickCarousel
                            ref="carousel"
                            v-bind="slickCarouselConfig"
                            class="slides-wrapper"
                    >
                        <div class="slide-item-wrapper">
                            <div class="slide">
                                <Main/>
                            </div>
                        </div>

                        <div class="slide-item-wrapper">
                            <div class="slide">
                                <Contacts/>
                            </div>
                        </div>

                        <div class="slide-item-wrapper">
                            <div class="slide">
                                <Security/>
                            </div>
                        </div>

                        <div class="slide-item-wrapper">
                            <div class="slide">
                                <Notifications/>
                            </div>
                        </div>

                        <div class="slide-item-wrapper">
                            <div class="slide">
                                <Finance ref="finance"/>
                            </div>
                        </div>
                    </VueSlickCarousel>
                </div>
            </div>
        </div>

        <Footer class="footer"/>
    </div>
</template>

<script>
    import VueSlickCarousel from "vue-slick-carousel";
    import Header from "@/components/Header";
    import BurgerMenu from "@/components/BurgerMenu";
    import Footer from "@/components/Footer";
    import Main from "./components/Main";
    import Contacts from "./components/Contacts";
    import Security from "./components/Security";
    import Notifications from "./components/Notifications";
    import Finance from "./components/Finance";
    import {mapState} from "vuex";

    export default {
        name: "ProfileInfo",
        components: {
            VueSlickCarousel,
            Finance,
            Header,
            BurgerMenu,
            Footer,
            Main,
            Contacts,
            Security,
            Notifications
        },
        data() {
            return {
                navItemActiveIndex: 0,
                navItems: {
                    en: [
                        {"label":"Main","code":"0"},
                        {"label":"Contacts","code":"1"},
                        {"label":"Security","code":"2"},
                        {"label":"Notifications","code":"3"},
                        {"label":"Finance","code":"4"}
                    ],
                    ru: [
                        {"label":"Общие","code":"0"},
                        {"label":"Контакты","code":"1"},
                        {"label":"Безопасность","code":"2"},
                        {"label":"Уведомления","code":"3"},
                        {"label":"Финансы","code":"4"}
                    ]
                },
                menuItems: {
                    en: {
                        Main: [
                            {
                                label: "Personal Information",
                                href: "#personal-info"
                            },
                            {
                                label: "Location",
                                href: "#location"
                            },
                            {
                                label: "About me",
                                href: "#about-me"
                            }
                        ],
                        Contacts: [
                            {
                                label: "Main",
                                href: "#main"
                            },
                            {
                                label: "Social Networks",
                                href: "#social-networks"
                            },
                            {
                                label: "Website",
                                href: "#website"
                            }
                        ],
                        Security: [
                            {
                                label: "Email",
                                href: "#email"
                            },
                            {
                                label: "Password",
                                href: "#password"
                            },
                            {
                                label: "Account",
                                href: "#account"
                            }
                        ],
                        Notifications: [
                            {
                                label: "On the website",
                                href: "#on-the-website"
                            },
                            {
                                label: "Via email",
                                href: "#via-email"
                            },
                            {
                                label: "Other",
                                href: "#other"
                            }
                        ],
                        Finance: [
                            {
                                label: "Balance",
                                href: "#balance"
                            },
                            {
                                label: "Current rate",
                                href: "#current-rate"
                            },
                            {
                                label: "Transaction history",
                                href: "#transaction-history"
                            }
                        ]
                    },
                    ru: {
                        Общие: [
                            {
                                label: "Личные данные",
                                href: "#personal-info"
                            },
                            {
                                label: "Местоположение",
                                href: "#location"
                            },
                            {
                                label: "О себе",
                                href: "#about-me"
                            }
                        ],
                        Контакты: [
                            {
                                label: "Основные",
                                href: "#main"
                            },
                            {
                                label: "Соц. сети",
                                href: "#social-networks"
                            },
                            {
                                label: "Сайт",
                                href: "#website"
                            }
                        ],
                        Безопасность: [
                            {
                                label: "Эл. почта",
                                href: "#email"
                            },
                            {
                                label: "Пароль",
                                href: "#password"
                            },
                            {
                                label: "Аккаунт",
                                href: "#account"
                            }
                        ],
                        Уведомления: [
                            {
                                label: "На сайте",
                                href: "#on-the-website"
                            },
                            {
                                label: "По электронной почте",
                                href: "#via-email"
                            },
                            {
                                label: "Другое",
                                href: "#other"
                            }
                        ],
                        Финансы: [
                            {
                                label: "Баланс",
                                href: "#balance"
                            },
                            {
                                label: "Текущий курс",
                                href: "#current-rate"
                            },
                            {
                                label: "История операций",
                                href: "#transaction-history"
                            }
                        ]
                    }
                },
                menuItemActiveIndex: 0,
                slickCarouselConfig: {
                    arrows: false,
                    dots: false,
                    slidesPerRow: 1,
                    swipe: false,
                    draggable: false,
                    adaptiveHeight: true,
                    infinite: false
                },
                i18n: {
                    settings: {
                        en: "Settings",
                        ru: "Настройки"
                    }
                }
            };
        },
        computed: {
            ...mapState({
                $lang: state => state.$lang
            }),
            menuItemsCurrent() {
                const key = this.navItems[this.$lang][this.navItemActiveIndex].label;

                return this.menuItems[this.$lang][key];
            }
        },
        methods: {
            handleNavSelectChange(e) {
                this.handleNavItemClick(e.code);
            },
            handleNavItemClick(index) {
                this.navItemActiveIndex = index;

                this.$refs.carousel.goTo(index);
            },
            handleMenuItemClick(e, menuItem, index) {
                e.preventDefault();

                const href = menuItem.href
                    .split("")
                    .slice(1)
                    .join("");
                const selector = `[data-id='${href}']`;
                const scrollTop =
                    document.querySelector(selector).getBoundingClientRect().top -
                    100;

                window.scrollBy({
                    top: scrollTop,
                    behavior: "smooth"
                });

                this.menuItemActiveIndex = index;
            }
        }
    };
</script>

<style scoped lang="scss">
    .view-profile-settings {
        padding-top: 90px;

        .slick-slide{
            padding: 0 !important;
        }
    }

    .top-block {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        height: 148px;
        width: 100%;
        padding: 0 195px;

        @media only screen and (max-width: 1200px) {
            padding: 0 120px;
        }

        @media only screen and (max-width: 767px){
            padding: 0 20px;
            height: 80px;
        }
    }

    .top-block-title {
        color: #4d4d4d;
        margin-bottom: 28px;
    }

    .content {
        margin: 0 165px;
        background: #ffffff;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        // min-height: 100vh;

        @media only screen and (max-width: 1295px) {
            margin: 0 130px;
        }

        @media only screen and (max-width: 1210px) {
            margin: 0 100px;
        }

        @media only screen and (max-width: 1130px) {
            margin: 0 70px;
        }

        @media only screen and(max-width: 767px){
            margin: 0 10px;
        }


    }

    .wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        // min-height: 100vh;
    }

    .slide-item-wrapper {
        height: 100%;
    }

    .slider {
        width: calc(100% - 287px);
        border-left: 2px #f0f0f0 solid;
        @media only screen and(max-width: 767px){
            width: 100%;
            border-left: none;
        }
    }

    .nav {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        height: 88px;
        // padding: 0 6vw;
        padding: 0px 17vw 0 250px;
        border-bottom: 2px #f0f0f0 solid;
        @media only screen and(max-width: 767px){
            display: none;
        }
    }

    .nav-mobile{
        display: none;
        @media only screen and(max-width: 767px){
            display: flex;
            padding: 0 20px;
            align-items: center;
            justify-content: flex-start;
            min-height: 90px;
            border-bottom: #f5f5f5 2px solid;
        }
    }

    .nav-item {
        position: relative;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 90px;
        text-align: center;
        color: #b3b3b3;
        cursor: pointer;

        &:after {
            content: "";
            display: none;
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 150%;
            height: 2px;
            background: #50cab6;
        }

        &.is-active {
            color: #50cab6;

            &:after {
                display: block;
            }
        }

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .menu {
        position: sticky;
        top: 90px; // height of a header
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        width: 287px;
        padding-right: 60px;
        padding-top: 72px;
        padding-bottom: 72px;
        flex-shrink: 0;
        @media only screen and (max-width: 767px){
            display: none;
        }
    }

    .menu-item {

        text-align: right;
        color: #b3b3b3;
        cursor: pointer;
        // margin-top: 44px;
        margin-top: 46px;
        width: 100%;
        &:not(.is-active) h5{
            font-weight: 500 !important;
        }

        &:nth-child(1) {
            margin-top: 0;
        }

        &.is-active {
            color: #666666;
            font-weight: 600 !important;
        }


        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }
</style>
