<template>
    <div class="view-main-component-project">
        <modal
            name="gallery-modal"
            :adaptive="true"
            width="90%"
            height="90%"
            class="modal"
        >
            <img :src="fullscreenSrc" alt="" @click="handleModalContentClick"/>
        </modal>

        <div class="main">
            <div class="header">
                <div class="nav">
                    <div class="nav-item is-active">
                        {{ i18n.about[$lang] }}
                    </div>

                    <div class="nav-item">
                        {{ i18n.businessModel[$lang] }}
                    </div>

                    <div class="nav-item">
                        {{ i18n.team[$lang] }}
                    </div>

                    <div class="nav-item">
                        {{ i18n.faq[$lang] }}
                    </div>

                    <div class="nav-item">
                        {{ i18n.discussions[$lang] }}
                    </div>

                    <button type="button" class="promote-button" @click="handleSlidePromoteClick">
                        <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'promote-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="ls2n72m3ia" maskUnits="userSpaceOnUse" x="3" y="3" width="22" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.733 22.4h3.734V11.2H3.733v11.2zm20.534-10.267c0-1.026-.84-1.866-1.867-1.866h-5.89L17.398 6l.028-.298c0-.383-.158-.738-.41-.99l-.99-.98-6.14 6.151c-.346.336-.552.803-.552 1.316v9.333c0 1.027.84 1.867 1.867 1.867h8.4c.775 0 1.437-.467 1.717-1.139l2.82-6.58c.083-.214.13-.438.13-.68v-1.784l-.01-.009.01-.075z" fill="#fff"/></mask><g mask="url(#ls2n72m3ia)"><path fill="#fff" d="M1.866 1.867h24.267v24.267H1.866z"/></g></svg>

                        <span class="promote-button-label">
              {{ i18n.promote[$lang] }}
            </span>
                    </button>
                </div>

                <div class="project-nav_mob">
                    <div class="project-nav_mob__select">
                        <v-select
                                :placeholder="i18n.about[$lang]"
                                :options="[i18n.about[$lang], i18n.businessModel[$lang], i18n.team[$lang], i18n.faq[$lang], i18n.discussions[$lang]]"
                                :clearable="false"
                                :value="i18n.about[$lang]"
                                class="select"
                        >
                            <template #open-indicator="{ attributes }">
                                          <span v-bind="attributes">
                                            <svg width="9" height="5" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.498 5L9 2.5V.104L4.498 2.62 0 0v2.5L4.498 5z" fill="#D9D9D9"/></svg>
                                          </span>
                            </template>
                        </v-select>
                    </div>
                    <div class="project-nav_mob__button">
                        <button type="button" class="big-round-slide-button button-share">
                            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="akjtq86kqa" maskUnits="userSpaceOnUse" x="6" y="7" width="18" height="15"><path fill-rule="evenodd" clip-rule="evenodd" d="M17 11V7l7 7-7 7v-4.1c-5 0-8.5 1.6-11 5.1 1-5 4-10 11-11z" fill="#fff"/></mask><g mask="url(#akjtq86kqa)"><path d="M2 2h26v26H2z"/></g></svg>
                        </button>
                        <button type="button" class="big-round-slide-button button-favorite">
                            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="1v9te738fa" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="19"><path fill-rule="evenodd" clip-rule="evenodd" d="M25 12.24l-7.19-.62L15 5l-2.81 6.63-7.19.61 5.46 4.73L8.82 24 15 20.27 21.18 24l-1.63-7.03L25 12.24zM15 18.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L15 9.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L15 18.4z" fill="#fff"/></mask><g mask="url(#1v9te738fa)"><path d="M2 2h26v26H2z"/></g></svg>
                        </button>
                    </div>
                </div>
            </div>

            <div class="steps">
                <div class="video">
                    <button type="button" class="video-play-button">
            <span class="video-play-button-label">
              {{ i18n.watchPresentation[$lang] }}
            </span>

                        <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'video-play-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="gi3c370p7a" maskUnits="userSpaceOnUse" x="9" y="7" width="11" height="14"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.333 7.467v13.067L19.6 14 9.333 7.467z" fill="#fff"/></mask><g mask="url(#gi3c370p7a)"><path fill="#fff" d="M1.866 1.867h24.267v24.267H1.866z"/></g></svg>
                    </button>
                </div>

                <div class="content">
                    <div class="paragraph">
                        <div class="paragraph-name">
                            {{ i18n.problem[$lang] }}
                        </div>

                        <div class="paragraph-title">
                            {{ i18n.paragraphTitle[$lang] }}
                        </div>

                        <div class="paragraph-text">
                            {{ i18n.paragraphText[$lang] }}
                        </div>

                        <div class="paragraph-text-highlight">
                            {{ i18n.paragraphTextHighlight[$lang] }}
                        </div>
                    </div>
                    <div class="paragraph">
                        <div class="paragraph-name">
                            {{ i18n.product[$lang] }}
                        </div>

                        <div class="paragraph-title">
                            {{ i18n.paragraphTitleSecond[$lang] }}
                        </div>

                        <div class="paragraph-text">
                            {{ i18n.paragraphTextSecond[$lang] }}
                        </div>

                        <div class="slider">
                            <div class="slider-body">
                                <button type="button" class="slider-button is-prev" @click="handleSliderPrevBtnClick">
                                    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="nx6ggspjga" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M23 14H10.83l5.59-5.59L15 7l-8 8 8 8 1.41-1.41L10.83 16H23v-2z" fill="#fff"/></mask><g mask="url(#nx6ggspjga)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                                </button>

                                <button type="button" class="slider-button is-next" @click="handleSliderNextBtnClick">
                                    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="rwqvz8a08a" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M23 14H10.83l5.59-5.59L15 7l-8 8 8 8 1.41-1.41L10.83 16H23v-2z" fill="#fff"/></mask><g mask="url(#rwqvz8a08a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                                </button>

                                <div ref="sliderSlidesWrapper" class="slider-slides-wrapper js-slider">
                                    <div class="slider-slide">
                                        <img src="@/assets/img/slider-placeholder.png" alt=""/>
                                    </div>

                                    <div class="slider-slide">
                                        <img src="@/assets/img/slider-placeholder.png" alt=""/>
                                    </div>

                                    <div class="slider-slide">
                                        <img src="@/assets/img/slider-placeholder.png" alt=""/>
                                    </div>

                                    <div class="slider-slide">
                                        <img src="@/assets/img/slider-placeholder.png" alt=""/>
                                    </div>

                                    <div class="slider-slide">
                                        <img src="@/assets/img/slider-placeholder.png" alt=""/>
                                    </div>
                                </div>
                            </div>

                            <div class="slider-footer">
                                <div class="slider-counter">
                                    {{ activeSlideNumber }} / {{ slidesAmount }}
                                </div>

                                <div class="slider-desc">
                                    {{ i18n.sliderDesc[$lang] }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="paragraph">
                        <div class="paragraph-name">
                            {{ i18n.solution[$lang] }}
                        </div>

                        <div class="paragraph-title">
                            {{ i18n.paragraphTitleThird[$lang] }}
                        </div>

                        <div class="paragraph-text">
                            {{ i18n.paragraphTextThird[$lang] }}
                        </div>

                        <div class="gallery">
                            <div class="gallery-item" @click="handleGalleryItemClick">
                                <div class="gallery-item-content">
                                    <img
                                        src="@/assets/img/gallery-placeholder-1.png"
                                        data-fullscreen-src="@/assets/img/gallery-placeholder-1.png"
                                        alt=""
                                    />
                                </div>

                                <div class="gallery-item-desc">
                                    Look Up In The Sky
                                </div>
                            </div>

                            <div class="gallery-item" @click="handleGalleryItemClick">
                                <div class="gallery-item-content">
                                    <img
                                        src="@/assets/img/gallery-placeholder-2.png"
                                        data-fullscreen-src="@/assets/img/gallery-placeholder-2.png"
                                        alt=""
                                    />
                                </div>

                                <div class="gallery-item-desc">
                                    Dude You Re Getting A Telescope
                                </div>
                            </div>

                            <div class="gallery-item" @click="handleGalleryItemClick">
                                <div class="gallery-item-content">
                                    <img
                                        src="@/assets/img/gallery-placeholder-3.png"
                                        data-fullscreen-src="@/assets/img/gallery-placeholder-3.png"
                                        alt=""
                                    />
                                </div>

                                <div class="gallery-item-desc">
                                    The Kansas City Chiefs
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="paragraph">
                        <div class="paragraph-name">
                            {{ i18n.market[$lang] }}
                        </div>

                        <div class="paragraph-title">
                            {{ i18n.paragraphTitleFourth[$lang] }}
                        </div>

                        <div class="paragraph-text">
                            {{ i18n.paragraphTextFourth[$lang] }}
                        </div>


                    </div>
                    <div class="paragraph" style="margin-top: 75px;">
                        <div class="paragraph-name">
                            {{ i18n.market[$lang] }}
                        </div>

                        <div class="paragraph-title">
                            {{ i18n.paragraphTitleFifth[$lang] }}
                        </div>

                        <div class="paragraph-text-with-subtitles">
                            <div class="paragraph-text-subtitle">
                                {{ i18n.paragraphTextSubtitleFirst[$lang] }}
                            </div>

                            <div class="paragraph-text">
                                {{ i18n.paragraphTextFifth[$lang] }}
                            </div>
                        </div>

                        <div class="paragraph-text-with-subtitles">
                            <div class="paragraph-text-subtitle">
                                {{ i18n.paragraphTextSubtitleSecond[$lang] }}
                            </div>

                            <div class="paragraph-text">
                                {{ i18n.paragraphTextSixth[$lang] }}
                            </div>
                        </div>

                        <div class="paragraph-text-with-subtitles">
                            <div class="paragraph-text-subtitle">
                                {{ i18n.paragraphTextSubtitleThird[$lang] }}
                            </div>

                            <div class="paragraph-text">
                                {{ i18n.paragraphTextSeventh[$lang] }}
                            </div>
                        </div>

                        <div class="paragraph-text-with-subtitles">
                            <div class="paragraph-text-subtitle">
                                {{ i18n.paragraphTextSubtitleFourth[$lang] }}
                            </div>

                            <div class="paragraph-text">
                                {{ i18n.paragraphTextEighth[$lang] }}
                            </div>
                        </div>
                    </div>
                    <div class="paragraph">
                        <div class="paragraph-name">
                            {{ i18n.monetisation[$lang] }}
                        </div>

                        <div class="paragraph-title" style="margin-top: 40px;">
                            {{ i18n.paragraphTitleSixth[$lang] }}
                        </div>

                        <div class="paragraph-text">
                            {{ i18n.paragraphTextNinth[$lang] }}
                        </div>

                        <!--                        <Table/>-->
                    </div>
                    <div class="paragraph">
                        <div class="paragraph-name">
                            {{ i18n.competition[$lang] }}
                        </div>

                        <div class="paragraph-title">
                            {{ i18n.technologySoar[$lang] }}
                        </div>

                        <div class="paragraph-text">
                            {{ i18n.competitorsText[$lang] }}
                        </div>

                        <div class="paragraph-img">
                            <img src="@/assets/img/paragraph-competition-placeholder.png" alt=""/>
                        </div>
                    </div>
                    <div class="paragraph">
                        <div class="paragraph-name">
                            {{ i18n.financialPart[$lang] }}
                        </div>

                        <div class="paragraph-title">
                            {{ i18n.costs[$lang] }}
                        </div>

                        <!--                        <div class="paragraph-text">-->
                        <!--                            {{ i18n.paragraphTextTenth[$lang] }}-->
                        <!--                        </div>-->

                        <CircleDiagram diagram-id="circle1" :header-text="i18n.circleOneHeaderText[$lang]"/>
                        <CircleDiagram diagram-id="circle2" :header-text="i18n.circleTwoHeaderText[$lang]"/>
                        <CircleDiagram diagram-id="circle3" :header-text="i18n.circleThreeHeaderText[$lang]"/>

                    </div>
                    <div class="paragraph">
                        <div class="paragraph-name">
                            {{ i18n.salesForecast[$lang] }}
                        </div>

                        <div class="paragraph-title">
                            {{ i18n.paragraphTitleEighth[$lang] }}
                        </div>

                        <div class="paragraph-text">
                            {{ i18n.paragraphTextEleventh[$lang] }}
                        </div>

<!--                        <BarDiagram diagram-id="bar1" :header-text="i18n.barHeaderText[$lang]"/>-->
                        <LineDiagram diagram-id="line1" :header-text="i18n.lineHeaderText[$lang]"/>
                    </div>

                    <div class="paragraph">
                        <div class="paragraph-name">
                            {{ i18n.financialPart[$lang] }}
                        </div>

                        <div class="paragraph-title">
                            {{ i18n.implementationPlan[$lang] }}
                        </div>

                        <ImplementationPlanHorizontal/>
                    </div>

                    <div class="paragraph">
                        <div class="paragraph-name">
                            {{ i18n.executors[$lang] }}
                        </div>

                        <div class="paragraph-title">
                            {{ i18n.team[$lang] }}
                        </div>

                        <Team/>
                    </div>
                    <div class="paragraph">
                        <div class="paragraph-title">
                            {{ i18n.faq[$lang] }}
                        </div>

                        <FAQ/>
                    </div>
                </div>
            </div>

            <FooterCard/>
        </div>

        <div class="footer">
            <Comments/>
        </div>

        <div class="project-nav_mob-fixed">
            <button type="button" class="component-button primary" @click="handleSlidePromoteClick">
                <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'promote-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="9mh18h3tpa" maskUnits="userSpaceOnUse" x="3" y="3" width="22" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.733 22.4h3.734V11.2H3.733v11.2zm20.534-10.267c0-1.026-.84-1.866-1.867-1.866h-5.89L17.398 6l.028-.298c0-.383-.158-.738-.41-.99l-.99-.98-6.14 6.151c-.346.336-.552.803-.552 1.316v9.333c0 1.027.84 1.867 1.867 1.867h8.4c.775 0 1.437-.467 1.717-1.139l2.82-6.58c.083-.214.13-.438.13-.68v-1.784l-.01-.009.01-.075z" fill="#fff"/></mask><g mask="url(#9mh18h3tpa)"><path fill="#fff" d="M1.866 1.867h24.267v24.267H1.866z"/></g></svg>
                <span class="promote-button-label">{{ i18n.promote[$lang] }}</span>
            </button>
        </div>
    </div>

</template>

<script>
    import CircleDiagram from './components/CircleDiagram';
    import Table from './components/Table';
    import ImplementationPlanHorizontal from './components/ImpementationPlanHorizontal';
    import BarDiagram from './components/BarDiagram';
    import LineDiagram from './components/LineDiagram';
    import Team from './components/Team';
    import FAQ from './components/FAQ';
    import FooterCard from './components/FooterCard';
    import Comments from './components/Comments';
    import {tns} from 'tiny-slider/src/tiny-slider';
    import {mapState} from 'vuex';

    export default {
        name: 'Project',
        components: {
            Comments,
            FooterCard,
            FAQ,
            Team,
            BarDiagram,
            ImplementationPlanHorizontal,
            CircleDiagram,
            Table,
            LineDiagram,
        },
        data() {
            return {
                slidesAmount: 0,
                activeSlideNumber: 1,
                fullscreenSrc: '',
                i18n: {
                    circleOneHeaderText: {
                        ru: 'Капитальные затраты',
                        en: 'Capital costs'
                    },
                    circleTwoHeaderText: {
                        ru: 'Постоянные затраты в месяц',
                        en: 'Average fixed costs.'
                    },
                    circleThreeHeaderText: {
                        ru: 'Переменные затраты на еденицу продукции',
                        en: 'Variable costs per unit'
                    },
                    barHeaderText: {
                        ru: 'Динамика продаж дронов за последние 3 года',
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                    },
                    lineHeaderText: {
                        ru: 'Рост продаж',
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
                    },
                    about: {
                        en: 'About',
                        ru: 'Описание'
                    },
                    businessModel: {
                        en: 'Business model',
                        ru: 'Бизнес-модель'
                    },
                    team: {
                        en: 'Team',
                        ru: 'Команда проекта'
                    },
                    faq: {
                        en: 'FAQ 23',
                        ru: 'Частые вопросы 23'
                    },
                    discussions: {
                        en: 'Discussions 12',
                        ru: 'Обсуждения 12'
                    },
                    promote: {
                        en: 'Promote',
                        ru: 'Поддержать'
                    },
                    watchPresentation: {
                        en: 'Watch presentation',
                        ru: 'Смотреть презентацию'
                    },
                    problem: {
                        en: 'Problem',
                        ru: 'Проблема'
                    },
                    paragraphTitle: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                        ru: 'Дронам не хватает достаточной автономии и интеллекта'
                    },
                    paragraphText: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et nunc eget ante pellentesque malesuada placerat quis augue. Aenean at tristique mi. Vestibulum at egestas justo. Vivamus iaculis eros non dapibus suscipit. Vivamus quam tellus, iaculis ac nulla nec, luctus cursus augue. Phasellus eget placerat nisi, ac sagittis mi. Donec commodo dolor quis lectus aliquet, condimentum blandit felis aliquam. Duis ac diam quis nisi egestas aliquam sed nec ex. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce et purus at nulla maximus sollicitudin. Ut commodo lorem sem, sed mattis velit venenatis at. Nulla dolor mi, blandit non orci nec, congue auctor nisl. Nam accumsan nulla sapien, vel vehicula nisl consectetur at. Proin congue venenatis eros, vel imperdiet nunc dictum vel. Proin pulvinar nibh eget lacus molestie lobortis. Pellentesque vitae consectetur arcu, eget vestibulum dolor.',
                        ru: 'Отрасли промышленности не могут эффективно, надежно и быстро использовать беспилотники и летательные аппараты для промышленного применения. В результате мы не можем повысить эффективность человеческого труда. Демонстрируются неэффективные и примитивные инструменты в руках человека-оператора с точки зрения обработки данных как в полете, так и после операции. Это экономическое и экономическое развитие, безопасность и многое другое.'
                    },
                    paragraphTextHighlight: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et nunc eget ante pellentesque malesuada placerat quis augue. Aenean at tristique mi. Vestibulum at egestas justo. Vivamus iaculis eros non dapibus suscipit. Vivamus quam tellus, iaculis ac nulla nec, luctus cursus augue. Phasellus eget placerat nisi, ac sagittis mi. Donec commodo dolor quis lectus aliquet, c',
                        ru: 'Современная парадигма робототехники неэффективна и ограничивает рост многих отраслей. Кроме того, эти роботы имеют очень ограниченный доступ к интеллектуальным и долговременным интеллектуальным роботам в режиме реального времени, которые они могут использовать, чтобы стать умнее и более автономными.'
                    },
                    product: {
                        en: 'Product',
                        ru: 'Продукт'
                    },
                    paragraphTitleSecond: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                        ru: 'Soar Robotics разрабатывает облачный роботизированный интеллект для летательных аппаратов'
                    },
                    paragraphTextSecond: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et nunc eget ante pellentesque malesuada placerat quis augue. Aenean at tristique mi. Vestibulum at egestas justo. Vivamus iaculis eros non dapibus suscipit. Vivamus quam tellus, iaculis ac nulla nec, luctus cursus augue. Phasellus eget placerat nisi, ac sagittis mi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et nunc eget ante pellentesque malesuada placerat quis augue. Aenean at tristique mi. Vestibulum at egestas justo. Vivamus iaculis eros non dapibus suscipit. Vivamus quam tellus, iaculis ac nulla nec, luctus cursus augue. Phasellus eget placerat nisi, ac sagittis mi.',
                        ru: 'Наша система состоит из автономного беспилотного летательного аппарата, док-станции для подзарядки, технологии передачи данных, функций защиты от атмосферных воздействий и нашей платформы облачного интеллекта. Система может быть развернута менее чем за 2 часа и способна обслуживать практически любую отрасль, какую только можно себе представить, благодаря своей модульной конструкции системы. Мы разрабатываем полностью автономные промышленные беспилотники и наземные базы, которые работают без человека-пилота. Полет, сбор данных, перезарядка аккумулятора и обработка данных - эти этапы работы были частью непрерывного цикла, который требовал человеческого контроля. Благодаря нашему решению Drone-in-a-box этот цикл теперь автоматизирован и создает гораздо большую ценность.'
                    },
                    sliderDesc: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                        ru: 'Мы производим полностью автономные летательные аппараты'
                    },
                    solution: {
                        en: 'Solution',
                        ru: 'Решение'
                    },
                    paragraphTitleThird: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                        ru: 'Мы превращаем дронов в роботов, выполняющих миссии'
                    },
                    paragraphTextThird: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                        ru: 'Облачные продукты искусственного интеллекта Soar Robotics делают дроны и любые летательные аппараты интеллектуальными и подключенными. Мы производим полностью автономные летательные аппараты, которые автоматизируют работу людей в промышленных условиях.'
                    },
                    market: {
                        en: 'Market',
                        ru: 'Рынок'
                    },
                    paragraphTitleFourth: {
                        en: '300 Lorem ipsum dolor sit amet,',
                        ru: '300 миллиардов долларов'
                    },
                    paragraphTextFourth: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                        ru: 'Согласно рыночным прогнозам PWC, глобальная рыночная стоимость коммерческих решений с поддержкой дронов оценивается в 127 миллиардов долларов. Мы ожидаем, что с широким распространением рейсов BVLOS и созданием правительствами нормативно-правовой базы вокруг него коммерческие беспилотники сделают шаг ближе к своему полному потенциалу и охватят рынок с адресом + 300 млрд долларов до 2025 года.'
                    },
                    paragraphTitleFifth: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                        ru: 'Работа с клиентами в четырех отраслях'
                    },
                    paragraphTextSubtitleFirst: {
                        en: 'Lorem',
                        ru: 'Энергия'
                    },
                    paragraphTextFifth: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                        ru: 'Наши беспилотники будут проверять и обеспечивать безопасность инфраструктуры для выработки 450 МВт электроэнергии.'
                    },
                    paragraphTextSubtitleSecond: {
                        en: 'Lorem',
                        ru: 'Безопасность'
                    },
                    paragraphTextSixth: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                        ru: 'Мы внедряем 20 дроновых подразделений в охранную компанию, которая предоставляет услуги мониторинга для городов и крупных инфраструктурных подразделений. Беспилотные летательные аппараты выполняют обычные, полностью автономные миссии наблюдения.'
                    },
                    paragraphTextSubtitleThird: {
                        en: 'Lorem',
                        ru: 'Эргономика'
                    },
                    paragraphTextSeventh: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing',
                        ru: 'Мы тестировали нашу систему через компанию, предоставляющую сельскохозяйственные данные, в базе данных которой содержится более 20 000 фермеров, чтобы предоставить точные и действенные данные об их полях.'
                    },
                    paragraphTextSubtitleFourth: {
                        en: 'Lorem',
                        ru: 'Инфраструктура'
                    },
                    paragraphTextEighth: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                        ru: 'Наши беспилотники будут проверять и обеспечивать безопасность инфраструктуры для выработки 450 МВт электроэнергии.'
                    },
                    monetisation: {
                        en: 'Monetisation',
                        ru: 'Монетизация'
                    },
                    paragraphTitleSixth: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet,',
                        ru: 'Получение постоянного дохода с помощью модели Robotics-as-a-Service (RaaS)'
                    },
                    paragraphTextNinth: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                        ru: 'Клиенты могут пользоваться только софтом, или заказать себе сразу готового и прошитого дрона на нашей платформе. Модель оплаты — месячная подписка.'
                    },
                    competition: {
                        en: 'Competition',
                        ru: 'Конкуренты'
                    },
                    technologySoar: {
                        en: 'Technology Soar Robotics lorem tefwefwf Technology Soar Robotics lorem tefwefwfTechnology Soar Robotics lorem tefwefwf',
                        ru: 'Технология Soar Robotics не зависит от аппаратного обеспечения и полностью автономна'
                    },
                    competitorsText: {
                        en: 'Зависимые от человека рабочие процессы приводят к неэффективности и, следовательно, к потере доходов. Полная автономия революционизирует способ ведения бизнеса предприятия путем устранения рисков и затрат , связанных с человеческими операторами. Зависимость от оборудования означает меньшую гибкость и доступность. Аппаратно-зависимые системы ограничены в приложениях и поэтому масштабируются намного медленнее, чем аппаратно-независимые системы. Наша система реализует лучшее из обоих миров, обеспечивая гибкость и доступность для масштабирования промышленных приложений и роста по мере роста каждого производителя в своей сети. ',
                        ru: 'Зависимые от человека рабочие процессы приводят к неэффективности и, следовательно, к потере доходов. Полная автономия революционизирует способ ведения бизнеса предприятия путем устранения рисков и затрат , связанных с человеческими операторами. Зависимость от оборудования означает меньшую гибкость и доступность. Аппаратно-зависимые системы ограничены в приложениях и поэтому масштабируются намного медленнее, чем аппаратно-независимые системы. Наша система реализует лучшее из обоих миров, обеспечивая гибкость и доступность для масштабирования промышленных приложений и роста по мере роста каждого производителя в своей сети. '
                    },
                    costStructure: {
                        en: 'Cost structure',
                        ru: 'Структура затрат'
                    },
                    financialPart: {
                        en: 'Financial part',
                        ru: 'Финансовая часть',
                    },
                    costs: {
                        en: 'Costs',
                        ru: 'Затраты'
                    },
                    paragraphTitleSeventh: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                        ru: 'Основная масса средств уйдёт на разработку и продвижение софта'
                    },
                    paragraphTextTenth: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                        ru: 'Согласно рыночным прогнозам PWC, глобальная рыночная стоимость коммерческих решений с поддержкой дронов оценивается в 127 миллиардов долларов. Мы ожидаем, что с широким распространением рейсов BVLOS и созданием правительствами нормативно-правовой базы вокруг него коммерческие беспилотники сделают шаг ближе к своему полному потенциалу и охватят рынок с адресом + 300 млрд долларов до 2025 года.'
                    },
                    implementationPlan: {
                        en: 'Implementation plan',
                        ru: 'План реализации'
                    },
                    salesForecast: {
                        en: 'Sales forecast',
                        ru: 'Прогноз продаж'
                    },
                    paragraphTitleEighth: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                        ru: 'Для прогноза продаж мы взяли динамику продаж дронов на b2b-рынке за последние 3 года'
                    },
                    paragraphTextEleventh: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                        ru: 'Мы планируем постепенно увеличивать маркетинговые бюджеты и привлекать новую аудиторию. В первый год важно будет собрать первый фидбек и доработать продукт, чтобы затем выходить на более массовый рынок.'
                    },
                    executors: {
                        en: 'Executors',
                        ru: 'Исполнители'
                    },


                },
            }
        },
        mounted() {
            this.slider = tns({
                container: '.js-slider',
                items: 1,
                slideBy: 'page',
                autoplay: false,
                controls: false
            });

            const info = this.slider.getInfo();
            this.slidesAmount = info.slideCount;
        },
        methods: {
            updateActiveSlideNumber() {
                const info = this.slider.getInfo();
                const {navCurrentIndex} = info;

                this.activeSlideNumber = navCurrentIndex + 1;
            },

            handleGalleryItemClick(e) {
                const parentElem = e.currentTarget;
                const img = parentElem.querySelector('img');
                this.fullscreenSrc = img.dataset.fullscreenSrc;

                this.$modal.show('gallery-modal');
            },
            handleModalContentClick() {
                this.$modal.hide('gallery-modal');
            },
            handleSliderPrevBtnClick() {
                this.slider.goTo('prev');
                this.updateActiveSlideNumber();
            },
            handleSliderNextBtnClick() {
                this.slider.goTo('next');
                this.updateActiveSlideNumber();
            },
            handleSlidePromoteClick() {
                this.$router.push('profile-supported');
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
        },
    };
</script>


<style scoped lang="scss">
    @import "@/sass/mixins.scss";

    .main {
        background: #fff;
        margin-top: 30px;
        border-radius: 6px;
        @media only screen and (max-width: 767px){
            margin-top: 12px;
        }
    }

    .footer {
        background: #fff;
        margin-top: 30px;
        border-radius: 6px;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        min-height: 90px;
        padding: 0 60px 0 82px;

        @media only screen and (max-width: 1200px) {
            flex-wrap: wrap;
        }
        @media only screen and (max-width: 767px){
            padding: 0 20px;
        }
    }

    .steps {
        // padding: 0 190px;
        padding: 0 7.5vw;
    }

    .nav {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 90px;
        width: 100%;
        @media only screen and (max-width: 767px){
            display: none;
        }
    }

    .nav-item {
        position: relative;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #B3B3B3;
        margin-left: 55px;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        cursor: pointer;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
            display: none;
            width: 150%;
            height: 2px;
            background: #50CAB6;
        }

        &:nth-child(1) {
            margin-left: 0;
        }

        &:nth-child(4) {
            margin-right: 55px;

            @media only screen and (max-width: 1150px) {
                margin-right: 20px;
            }
        }

        &:nth-child(5) {
            //margin-left: auto;
            margin-right: auto;
        }

        &.is-active {
            font-weight: bold;
            color: #50CAB6;

            &:after {
                display: block;
            }
        }

        @media only screen and (max-width: 1150px) {
            margin-left: 40px;
        }

        @media only screen and (max-width: 1080px) {
            font-size: 12px;
        }
    }

    .promote-button {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 190px;
        height: 50px;
        margin-left: 10px;
        flex-shrink: 0;
        border-radius: 6px;
        @include button-default;
    }

    .promote-button-img {
        margin-left: 22px;
        @media only screen and (max-width: 767px){
            margin-left: 0;
        }
    }

    .promote-button-label {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: inherit;
        margin-left: 30px;
        @media only screen and (max-width: 767px){
            margin-left: 6px;
            font-size: 9px;
            line-height: 18px;
            letter-spacing: .5px;
        }
    }

    .steps {
        margin-top: 90px;
        @media only screen and (max-width: 767px){
            margin-top: 37px;
            padding: 0 20px;
        }
    }

    .video {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 360px;
        background: url("~@/assets/img/video-placeholder.png") no-repeat center;
        background-size: cover;
        @media only screen and (max-width: 767px){
            border-radius: 6px;
            height: 260px;
        }
        @media only screen and (max-width: 479px){
            height: 160px;
        }
    }

    .video-play-button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 272px;
        height: 50px;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        text-align: center;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        border-radius: 23px;
        @include button-default;
        @media only screen and (max-width: 767px){
            background-color: #50CAB6;
            border-color: #50CAB6;
            color: #fff !important;
            width: 50px;
        }
    }
    .video-play-button-label{
        @media only screen and (max-width: 767px){
            display: none;
        }
    }

    .video-play-button-img {
        position: relative;
        left: 24px;
        height: 28px;
        width: 28px;
        // margin-left: 24px;
        @media only screen and (max-width: 767px){
            left: 0;
        }
    }

    .content {
        margin-top: 60px;
        @media only screen and (max-width: 767px){
            margin-top: 0;
        }
    }

    .paragraph-name {
        display: inline-block;
        background: #F7F7F7;
        border-radius: 4px;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #B3B3B3;
        padding: 0 12px;
        @media only screen and (max-width: 767px){
            margin-top: 0 !important;
        }
    }

    .paragraph-title {
        font-family: Raleway;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        color: #4D4D4D;
        margin-top: 30px;

        @media only screen and (max-width: 1030px) {
            font-size: 28px;
        }
    }

    .paragraph-text {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;
        color: #666666;
        margin-top: 30px;

        .paragraph-text-with-subtitles & {
            color: #999999;
            margin-top: 10px;
        }

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .paragraph-text-highlight {
        position: relative;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 30px;
        color: #666666;
        padding: 0 65px;
        margin-top: 60px;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto 0;
            content: '';
            display: block;
            height: 125%;
            width: 2px;
            background: #50CAB6;
        }

        @media only screen and (max-width: 1030px) {
            font-size: 14px;
        }
    }

    .paragraph {
        margin-top: 60px;
    }

    .slider {
        position: relative;
        width: 100%;
        //height: 300px;
        max-width: 730px;
        margin: 60px auto 90px auto;
        @media only screen and (max-width: 767px){
            margin: 24px 0 60px !important;
        }
    }

    .slider-body {
        position: relative;
    }

    .slider-slide {
        img {
            object-fit: cover;
            width: 100%;
        }
    }

    .slider-button {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        margin: auto 0;
        background: #FFFFFF;
        border: 2px solid #F5F5F5;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        @include button-default;

        &.is-prev {
            left: -95px;
        }

        &.is-next {
            right: -95px;
            transform: rotate(180deg);
        }

        @media only screen and (max-width: 1120px) {
            &.is-prev {
                left: -70px;
            }

            &.is-next {
                right: -70px;
                transform: rotate(180deg);
            }
        }
        @media only screen and (max-width: 767px){
            width: 30px !important;
            min-height: 30px !important;
            height: 30px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                width: 24px;
                height: 24px;
                position: relative;
                right: 3px;
                bottom: 3px;
            }
            &.is-prev {
                left: -16px !important;
            }
            &.is-next {
                right: -16px !important;
            }
        }
    }

    .slider-footer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 30px;
        @media only screen and (max-width: 767px){
            margin-top: 10px !important;
        }
    }

    .slider-counter {
        width: 57px;
        background: #F7F7F7;
        border-radius: 4px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 900;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #B3B3B3;
        @media only screen and (max-width: 767px){
            max-width: 38px;
            width: 100% !important;
            font-size: 9px !important;
            line-height: 20px !important;
            letter-spacing: 1.125px !important;
        }
    }

    .slider-desc {
        margin-left: 13px;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #666666;
        @media only screen and (max-width: 767px){
            margin-left: 9px;
            font-size: 11px;
            line-height: 11px;
        }
    }

    .gallery {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;
        width: 100%;
        max-width: 730px;
        margin: 30px auto 0 auto;
        @media only screen and (max-width: 767px){
            grid-gap: 20px !important;
            margin-top: 24px !important;
        }
    }

    .gallery-item {
        position: relative;
        cursor: pointer;

        &:before {
            content: '';
            display: none;
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            mix-blend-mode: normal;
            width: 60px;
            height: 60px;
            background: rgba(#000, 0.3) url("~@/assets/img/icons/ic_search.svg") no-repeat center;
            background-size: 26px;
            border-radius: 50%;
        }

        &:nth-child(3n) {
            grid-column: span 2;
        }

        &:hover {
            &:before {
                display: block;
            }
        }

        img {
            width: 100%;
        }
    }

    .gallery-item-desc {
        position: absolute;
        left: 30px;
        bottom: 30px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        color: #FFFFFF;
        @media only screen and (max-width: 767px){
            display: none;
        }
    }

    .paragraph-text-subtitle {
        display: inline-block;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
        color: #666666;
        padding-bottom: 8px;
        border-bottom: 1px solid #50CAB6;
        @media only screen and (max-width: 767px){
            margin-top: 0 !important;
            padding-bottom: 10px !important;
            line-height: 24px !important;
        }
    }

    .paragraph-text-with-subtitles {
        margin-top: 30px;
        @media only screen and (max-width: 767px){
            margin-top: 20px;
            .paragraph-text{
                font-size: 11px;
                line-height: 18px;
                margin-top: 10px !important;
            }
        }
    }

    .paragraph-img {
        margin-top: 30px;
        text-align: center;
        @media only screen and (max-width: 767px){
            margin-top: 20px !important;
            img{
                width: 100%;
            }
        }
    }

    .modal {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .select {
        width: 100%;
        cursor: pointer;

        /deep/ .vs__open-indicator {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        /deep/ .vs__dropdown-toggle {
            cursor: pointer;
        }


        /deep/ .vs__selected-options {
            padding: 10px 2px;
        }

        /deep/ .vs__actions {
            padding: 0 15px 0 3px;
        }
        @media only screen and (max-width: 767px){
            font-size: 11px;
            line-height: 18px;
            width: 100%;
        }
    }
    .project-nav_mob{
        display: none;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        &__select{
            max-width: 175px;
            width: 100%;
        }
        &__button{

            .big-round-slide-button{
                width: 40px;
                height: 40px;
                color: #B3B3B3;
            }
        }
        &-fixed{
            display: none;
            position: fixed;
            left: 0;
            bottom: 0;
            padding: 10px;
            text-align: center;
            background-color: #fff;
            width: 100%;
            box-shadow: 0 10px 30px -2px rgba(0, 0, 0, 0.05);
            border-top: 2px solid #F5F5F5;
            .component-button{
                margin: 0 auto;
                display: flex;
                justify-content: center;
                &.primary{
                    height: 40px !important;
                }
            }
        }
        @media only screen and (max-width: 767px){
            display: flex;
            &-fixed{
                display: block;
            }
        }
    }
</style>
