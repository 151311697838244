<template>
    <div class="view-profile">
        <Header />
<!--        <BurgerMenu />-->

        <div class="steps">
            <Nav :items="navItems" />
            <template v-if="projectLoadStatus !== 1">
                <Slide />
                <Stats />
                <Project />
            </template>
            <Spinner v-else />
        </div>

        <Footer class="project-footer" />
    </div>
</template>

<script>
    import Header from "@/components/Header";
    import Nav from "@/components/Nav";
    import Footer from "@/components/Footer";
    import Stats from "./components/Stats";
    import Slide from "./components/Slide";
    import Project from "./components/Project";
    import BurgerMenu from "@/components/BurgerMenu";
    import Spinner from "@/components/Spinner";
    import { mapState } from "vuex";
    export default {
        name: "Profile",
        components: {
            BurgerMenu,
            Header,
            Nav,
            Stats,
            Slide,
            Project,
            Footer,
            Spinner,
        },
        data() {
            return {};
        },
        methods: {},
        computed: {
            ...mapState("projects", ["project", "projectLoadStatus"]),
            navItems: function () {
                return {
                    en: [
                        {
                            label: "Home",
                            link: "/",
                        },
                        {
                            label: "Projects",
                            link: "/profile-discovery",
                        },
                        {
                            label: this.project.title,
                            link: "#",
                        },
                    ],
                    ru: [
                        {
                            label: "Главная",
                            link: "/",
                        },
                        {
                            label: "Проекты",
                            link: "/profile-discovery",
                        },
                        {
                            label: this.project.title,
                            link: "#",
                        },
                    ],
                };
            },
        },
        mounted() {
            this.$store.dispatch("projects/loadProject", {
                id: this.$route.params.id,
            });
        },
    };
</script>

<style scoped lang="scss">
    .view-profile {
        min-height: 100vh;
        width: 100%;
        background: #fafafa;
    }
    .steps {
        width: 100%;
        // max-width: 1100px;
        // padding: 0 165px;
        padding: 0 8.5vw;
        margin: 0 auto;
        margin-top: 30px;
        @media only screen and (max-width: 767px){
            margin-top: 0;
            padding: 70px 0 0;
        }
    }
    .project-footer{
        margin-top: 0;
    }
</style>
