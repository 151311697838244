<template>
  <div class="nav">
    <router-link
            to="/profile-discovery"
            class="nav-item"
            :class="{ 'is-active': page === 'view-projects' }"
    >
      {{ i18n.nav.viewProjects[$lang] }}
    </router-link>

    <router-link
            to="/new-project-step"
            class="nav-item"
            :class="{ 'is-active': page === 'create-a-project' }"
    >
      {{ i18n.nav.createAProject[$lang] }}
    </router-link>

    <router-link
            to="/help"
            class="nav-item"
            :class="{ 'is-active': page === 'support-center' }"
    >
      {{ i18n.nav.supportCenter[$lang] }}
    </router-link>
  </div>
</template>

<script>
  import prepareSvgElements from "@/mixins/prepareSvgElements.js";
  import { mapState } from 'vuex';

  export default {
    name: 'MaimMenu',
    mixins: [prepareSvgElements],
    props: {
      page: {
        type: String,
        default: "",
      },
    },
    //   title: {
    //     type: Object,
    //     required: true
    //   },
    //   text: {
    //     type: Object,
    //     required: true
    //   },
    //   projectName: {
    //     type: Object,
    //     default() {
    //       return {
    //         en: '',
    //         ru: '',
    //       }
    //     }
    //   },
    //   imgSrc: {
    //     type: String,
    //     required: true
    //   },
    //   date: {
    //     type: Object,
    //     required: true
    //   },
    //   iconColor: {
    //     type: String,
    //     default: '#50CAB6'
    //   },
    //   icon: {
    //     type: String,
    //     required: true
    //   },
    // },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    },
    data() {
      return {
        languageVariants: ["English", "Russian"],
        languageChosen: "English",
        languageChangerActive: false,
        ifScroll: false,
        notificationsIsActive: false,

        i18n: {
          nav: {
            viewProjects: {
              en: "View Projects",
              ru: "Смотреть проекты",
            },
            createAProject: {
              en: "Create a Project",
              ru: "Создать проект",
            },
            supportCenter: {
              en: "Support Center",
              ru: "Центр поддержки",
            },
          },
          logIn: {
            en: "Log in",
            ru: "Войти",
          },
          notifications: {
            en: "Notifications",
            ru: "Уведомления",
          },
        },
      };
    },
  };
</script>

<style scoped lang="scss">
  .nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 30px;
    @media only screen and (max-width: 767px){
      order: 2;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 0;
      width: 100%;
      padding: 0 20px;
    }
  }

  .nav-item {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    //font-size: 14px;
    //font-size: 0.97vw;
    color: #808080;
    mix-blend-mode: normal;
    opacity: 1;
    margin-left: 30px;
    cursor: pointer;

    &:nth-child(1) {
      margin-left: 0;
    }

    &.is-active,
    &.router-link-active,
    &:hover {
      opacity: 1;
      color: #50cab6;
    }

    @media only screen and (max-width: 1030px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 767px){
      margin-left: 0;
      font-size: 16px;
      line-height: 30px;
      margin-bottom: 24px;
      color: #3c3c3c;
    }
  }
  .logo-white {
    .nav-item {
      @media only screen and (min-width: 768px){
        color: #fff;
      }

    }
  }
</style>
