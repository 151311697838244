import {axios} from "@/plugins/axios.js";
import Vue from "vue";
import {getField, updateField} from 'vuex-map-fields';

export default {
    namespaced: true,
    state: {
        status: "",
        token: localStorage.getItem("token") || "",
        id: null,
        "email": null,
        "is_verified": false,
        "firstname": null,
        "lastname": null,
        "fullname": null,
        "phone": null,
        "about": null,
        "image": null,
        "image_link": null,
        "telegram": null,
        "linkedin": null,
        "facebook": null,
        "twitter": null,
        "website": null,
        "city_id": null,
        "country_id": null

    },

    actions: {
        login({commit, dispatch}, user) {
            return new Promise((resolve, reject) => {
                commit("SET_AUTH_REQUEST");
                axios({
                    url: "/api/login",
                    data: user,
                    method: "POST",
                })
                    .then((resp) => {
                        const token = resp.data.token;
                        const user = resp.data.user;
                        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                        commit("SET_AUTH_SUCCESS", {token, user});
                        dispatch("fetchUserData");
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit("SET_AUTH_ERROR");

                        reject(err);
                    });
            });
        },

        register({commit, dispatch, context}, user) {
            return new Promise((resolve, reject) => {
                commit("SET_AUTH_REQUEST");
                axios({
                    url: "/api/register",
                    data: user,
                    method: "POST",
                })
                    .then((resp) => {
                        const token = resp.data.token;
                        const user = resp.data.user;
                        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                        commit("SET_AUTH_SUCCESS", {token, user});
                        dispatch("fetchUserData");
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit("SET_AUTH_ERROR", err);
                        reject(err);
                    });
            });
        },

        logout({commit}) {
            return new Promise((resolve, reject) => {
                commit("SET_LOGOUT");
                delete axios.defaults.headers.common["Authorization"];
                resolve();
            })
        },

        fetchUserData({commit}) {
            return new Promise((resolve, reject) => {
                axios({
                    url: "/api/user",
                    data: "",
                    method: "GET",
                })
                    .then((resp) => {
                        commit("SET_USER_DATA", resp.data.data);
                        resolve()
                    })
                    .catch((err) => {
                        reject(err)
                    });
            });
        },

        saveUserData({state, dispatch}) {
            return new Promise((resolve, reject) => {
                axios({
                    url: "/api/user/" + state.id,
                    data: state,
                    method: "PUT",
                })
                    .then((resp) => {
                        dispatch("fetchUserData");
                        resolve(resp)
                    })
                    .catch((err) => {
                        reject(err)
                    });
            });
        },
    },

    mutations: {
        SET_AUTH_REQUEST(state) {
            state.status = "loading";
        },

        SET_AUTH_SUCCESS(state, payload) {
            localStorage.setItem("token", payload.token);
            state.status = "success";
            state.token = payload.token;
        },

        SET_AUTH_ERROR(state) {
            state.status = "error";
            localStorage.removeItem("token");
        },

        SET_LOGOUT(state) {
            state.status = "";
            state.token = "";
            localStorage.removeItem("token");
        },

        SET_USER_DATA(state, user) {
            state.id = user.id;
            state.email = user.email;
            state.is_verified = user.is_verified;
            state.firstname = user.firstname;
            state.lastname = user.lastname;
            state.fullname = user.fullname;
            state.phone = user.phone;
            state.about = user.about;
            state.image = user.image;
            state.image_link = user.image_link;
            state.telegram = user.telegram;
            state.linkedin = user.linkedin;
            state.facebook = user.facebook;
            state.twitter = user.twitter;
            state.website = user.website;
            state.city_id = user.city_id;
            state.country_id = user.country_id;
        },

        SET_FIRSTNAME(state, value) {
            state.firstname = value;
        },

        SET_LASTNAME(state, value) {
            state.lastname = value;
        },

        SET_ABOUT(state, value) {
            state.about = value;
        },

        SET_EMAIL(state, value) {
            state.email = value;
        },

        SET_PHONE(state, value) {
            state.phone = value;
        },

        SET_TELEGRAM(state, value) {
            state.telegram = value;
        },

        SET_LINKEDIN(state, value) {
            state.linkedin = value;
        },

        SET_FACEBOOK(state, value) {
            state.facebook = value;
        },

        SET_TWITTER(state, value) {
            state.twitter = value;
        },

        SET_WEBSITE(state, value) {
            state.website = value;
        },

        SET_COUNTRY_ID(state, country_id) {
            state.country_id = country_id;
        },

        SET_CITY_ID(state, city_id) {
            state.city_id = city_id;
        },
        updateField,
    },

    getters: {
        isLoggedIn: (state) => !!state.token,
        getAuthStatus: (state) => state.status,
        getField,
    },
};
