<template>
    <div class="component-help-with-cost">
        <div class="inputs-wrapper">
            <div class="input-wrapper">
                <label for="first-name" class="label">
                    {{ i18n.firstName[$lang] }}
                </label>

                <input id="first-name" class="input input-main"/>
            </div>

            <div class="input-wrapper">
                <label for="last-name" class="label">
                    {{ i18n.lastName[$lang] }}
                </label>

                <input id="last-name" class="input input-main"/>
            </div>
        </div>
        <div class="inputs-wrapper">
            <div class="select-wrapper">
                <div class="label">
                    {{ i18n.country[$lang] }}
                </div>

                <v-select :options="selectCountries" :clearable="false" class="select">
                    <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <svg width="9" height="5" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.498 5L9 2.5V.104L4.498 2.62 0 0v2.5L4.498 5z" fill="#D9D9D9"/></svg>
            </span>
                    </template>
                </v-select>
            </div>
            <div class="input-wrapper">
                <label for="city" class="label">
                    {{ i18n.city[$lang] }}
                </label>

                <input id="city" class="input input-city"/>
            </div>

            <div class="input-wrapper">
                <label for="region" class="label">
                    {{ i18n.region[$lang] }}
                </label>

                <input id="region" class="input input-region"/>
            </div>
        </div>
        <div class="inputs-wrapper">
            <div class="input-wrapper">
                <label for="zip-code" class="label">
                    {{ i18n.zipCode[$lang] }}
                </label>

                <input id="zip-code" class="input input-zip-code"/>
            </div>

            <div class="input-wrapper">
                <label for="address" class="label">
                    {{ i18n.address[$lang] }}
                </label>

                <input id="address" class="input input-address"/>
            </div>
        </div>
        <div class="inputs-wrapper">
            <div class="input-wrapper">
                <label for="phone-number" class="label">
                    {{ i18n.phoneNumber[$lang] }}
                </label>

                <input id="phone-number" class="input input-main"/>
            </div>

            <div class="input-wrapper">
                <label for="email" class="label">
                    {{ i18n.email[$lang] }}
                </label>

                <input id="email" class="input input-main"/>
            </div>
        </div>

        <div class="buttons-wrapper">
            <div class="button" :class="{'is-active': buttonActiveIndex === 0}" @click="handleButtonClick(0)">
                <div class="button-left">
                    <div class="button-title">
                        {{ i18n.fastCostSctructure[$lang] }}
                    </div>

                    <div class="button-days">
                        1-5 {{ i18n.days[$lang] }}
                    </div>
                </div>

                <div class="button-right">
                    <div class="button-cost">
                        0 ₽
                    </div>
                </div>
            </div>
            <div class="button" :class="{'is-active': buttonActiveIndex === 1}" @click="handleButtonClick(1)">
                <div class="button-left">
                    <div class="button-title">
                        {{ i18n.detailed[$lang] }}
                    </div>

                    <div class="button-days">
                        10-15 {{ i18n.days[$lang] }}
                    </div>
                </div>

                <div class="button-right">
                    <div class="button-cost">
                        3 000 ₽
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'HelpWithCost',
        data() {
            return {
                buttonActiveIndex: 0,
                country: 'Russia',
                selectCountries: ['Russia', 'Canada', 'Germany'],
                i18n: {
                    firstName: {
                        en: 'First name',
                        ru: 'Имя'
                    },
                    lastName: {
                        en: 'Last name',
                        ru: 'Фамилия'
                    },
                    country: {
                        en: 'Country',
                        ru: 'Страна'
                    },
                    city: {
                        en: 'City',
                        ru: 'Город'
                    },
                    region: {
                        en: 'Region',
                        ru: 'Область'
                    },
                    zipCode: {
                        en: 'ZIP code',
                        ru: 'Индекс'
                    },
                    address: {
                        en: 'Address',
                        ru: 'Адрес'
                    },
                    phoneNumber: {
                        en: 'Phone number',
                        ru: 'Телефон'
                    },
                    email: {
                        en: 'Email',
                        ru: 'Эл. почта'
                    },
                    fastCostSctructure: {
                        en: 'Fast cost sctructure',
                        ru: 'Быстрый расчёт'
                    },
                    days: {
                        en: 'days',
                        ru: 'дней'
                    },
                    detailed: {
                        en: 'Detailed',
                        ru: 'Детальный'
                    },

                },
            };
        },
        methods: {
            handleButtonClick(index) {
                this.buttonActiveIndex = index;
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
        },
    };
</script>

<style scoped lang="scss">
    .component-help-with-cost {
        padding: 44px 285px 88px 285px;

        @media only screen and (max-width: 1365px) {
            padding: 44px 225px 88px 225px;
        }

        @media only screen and (max-width: 1255px) {
            padding: 44px 200px 88px 200px;
        }

        @media only screen and (max-width: 1205px) {
            padding: 44px 165px 88px 165px;
        }

        @media only screen and (max-width: 1125px) {
            padding: 44px 100px 88px 100px;
        }
    }

    .inputs-wrapper,
    .selects-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 35px;
    }

    .input-wrapper,
    .select-wrapper {
        margin-left: 33px;

        &:nth-child(1) {
            margin-left: 0;
        }
    }

    .wrapper-radio {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 25px;
        margin-left: 0;
    }

    .label {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #666666;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .input {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 2px solid #F5F5F5;
        border-radius: 4px;
        margin-top: 21px;
        width: 250px;
        height: 50px;
        padding: 0 15px;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #666666;
        cursor: text;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .input-city,
    .input-region,
    .input-zip-code {
        width: 160px;
        height: 50px;
    }

    .input-address {
        width: 345px;
        height: 50px;
    }

    .step-buttons::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-top: 2px #F0F0F0 solid;
    }

    .select {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 160px;
        height: 50px;
        border: 2px solid #F5F5F5;
        border-radius: 4px;
        margin-top: 21px;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #666666;
        cursor: pointer;

        &.vs--single.vs--open {
            /deep/ .vs__selected {
                opacity: 0;
            }
        }

        /deep/ .vs__open-indicator {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        /deep/ .vs__dropdown-toggle {
            padding: 0;
            border: 0;
            border-radius: 0;
            width: 100%;
            cursor: pointer;
        }

        /deep/ .vs__selected {
            border: 0;
            margin: 0;
            padding: 0 15px;
            font: inherit;
        }

        /deep/ .vs__search {
            height: 50px;
            margin: 0;
            border: 0;
            padding: 0 15px;
            font: inherit;
        }

        /deep/ .vs__actions {
            padding: 0 15px 0 3px;
        }

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .input-sctructure {
        display: none;
    }

    .label-radio {
        margin-left: 33px;

        &:nth-child(1) {
            margin-left: 0;
        }
    }

    .label-radio-span {
        background: #FFFFFF;
        border: 2px solid #F0F0F0;
        box-sizing: border-box;
        border-radius: 4px;
        width: 255px;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        cursor: pointer;

        .title {
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 15px;
            color: #808080;
            padding: 0px 0 10px 0;

            @media only screen and (max-width: 1030px) {
                font-size: 12px;
            }
        }

        .text {
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
            color: #808080;

            @media only screen and (max-width: 1030px) {
                font-size: 11px;
            }
        }

        .price {
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 90px;
            color: #808080;

            @media only screen and (max-width: 1030px) {
                font-size: 18px;
            }
        }
    }

    .buttons-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 30px;
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // width: 255px;
        width: 100%;
        height: 90px;
        padding: 0 30px;
        border: 2px solid #F0F0F0;
        border-radius: 4px;
        margin-left: 29px;
        cursor: pointer;

        &:nth-child(1) {
            margin-left: 0;
        }

        &.is-active {
            border-color: #50CAB6;

            .button-title,
            .button-days {
                font-weight: bold;
                color: #50CAB6;
            }

            .button-cost {
                color: #50CAB6;
            }
        }

        @media only screen and (max-width: 1130px) {
            padding: 0 15px;
        }
    }

    .button-title {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #808080;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .button-days {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #B3B3B3;
        margin-top: 11px;

        @media only screen and (max-width: 1030px) {
            font-size: 11px;
        }
    }

    .button-cost {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        color: #808080;

        @media only screen and (max-width: 1030px) {
            font-size: 18px;
        }
    }
</style>
