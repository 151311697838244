<template>
    <div class="view-profile-info">
        <Header class="header" />

<!--        <BurgerMenu />-->

        <div class="top-block">
            <div class="top-block-title">
                <h2>{{ i18n.myProfile[$lang] }}</h2>
            </div>
        </div>

        <div class="content">
            <div class="user-info">
                <div class="user-ava">
                    <img :src="user.image_link" alt="" />
                </div>

                <div class="user-descr">
                    <Button v-if="user.is_verified" class="user-descr-button primary" >
                        {{ i18n.verified[$lang] }}
                    </Button>
                    <Button v-else class="user-descr-button " >
                        {{ i18n.notVerified[$lang] }}
                    </Button>
                    <div class="user-name">
                        <template v-if="user.fullname===''">
                            <h3>Имя Фамилия</h3>
                        </template>
                        <template v-else>
                            <h3>{{ user.fullname }}</h3>
                        </template>
                    </div>
                    <div class="user-text">
                        <template v-if="user.about===null">
                            <p>О себе...</p>
                        </template>
                        <template v-else>
                            <p>{{ user.about }}</p>
                        </template>

                    </div>
                    <div class="user-location">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'user-location-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="kspok7gpba" maskUnits="userSpaceOnUse" x="8" y="5" width="14" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 5c-3.87 0-7 3.13-7 7 0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z" fill="#fff"/></mask><g mask="url(#kspok7gpba)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                        <h5>{{ i18n.userLocation[$lang] }}</h5>
                    </div>
                </div>

                <div>
                <Button class="user-button large" variant="round" @click="handleEditButtonClick">
                    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'user-button-img content-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="lafqa7pa4a" maskUnits="userSpaceOnUse" x="6" y="6" width="19" height="19"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 20.253v3.75h3.75l11.06-11.06-3.75-3.75L6 20.253zm17.71-10.21a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" fill="#fff"/></mask><g mask="url(#lafqa7pa4a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

                    <div class="user-button-label">
                        {{ i18n.edit[$lang] }}
                    </div>
                </Button>
                </div>
            </div>
        </div>
        <div class="content-bottom">
            <Deposit />

            <Contacts />
        </div>

        <Footer class="footer" />
    </div>
</template>

<script>
import Header from "@/components/Header";
import BurgerMenu from "@/components/BurgerMenu";
import Footer from "@/components/Footer";
import Button from "@/components/Button";
import Deposit from "./components/Deposit";
import Contacts from "./components/Contacts";
import { mapState } from "vuex";

export default {
    name: "ProfileInfo",
    components: {
        Header,
        BurgerMenu,
        Footer,
        Button,
        Deposit,
        Contacts
    },
    data() {
        return {
            i18n: {
                myProfile: {
                    en: "My Profile",
                    ru: "Мой профиль"
                },
                verified: {
                    en: "Verified",
                    ru: "Верифицирован"
                },
                notVerified: {
                    en: "Not verified",
                    ru: "Не верифицирован"
                },
                userText: {
                    en:
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                    ru:
                        "Пустое подмножество просветляет отрицательный психоз. Анима отталкивает социальный многочлен. Интеракционизм однородно начинает интеграл от функции, обращающейся в бесконечность в изолированной точке."
                },

                userLocation: {
                    en: "Russia, Moscow",
                    ru: "Россия, Москва"
                },
                edit: {
                    en: "edit",
                    ru: "Редактировать"
                }
            }
        };
    },

    computed: {
        ...mapState(["user"]),
        ...mapState({
            $lang: state => state.$lang
        })
    },
    methods: {
        handleEditButtonClick() {
            this.$router.push("profile-settings");
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/sass/mixins.scss";

.view-profile-info {
    padding-top: 90px;
}

.top-block {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 148px;
    width: 100%;
    padding: 0 195px;
    border-bottom: 2px #f0f0f0 solid;

    @media only screen and (max-width: 1200px) {
        padding: 0 120px;
    }

    @media only screen and (max-width: 767px){
        padding: 0 20px;
        height: 80px;
    }
}

.top-block-title {
    color: #4d4d4d;
    margin-bottom: 28px;
}

.content,
.content-bottom {
    padding: 0 165px;
    display: flex;

    @media only screen and (max-width: 1200px) {
        padding: 0 120px;
    }

    @media only screen and (max-width: 767px) {
        padding: 0 10px;
    }
}

.content-bottom {
    padding-top: 30px;
    align-items: flex-start;
    justify-content: center;

    @media only screen and (max-width: 767px){
        flex-direction: column;
    }
}

.user-info {
    width: 1100px;
    height: 100%;
    background: #ffffff;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    padding: 70px 0 40px 13.5%;
    position: relative;

    @media only screen and (max-width: 1300px) {
        padding: 60px 0 20px 5.5%;
    }

    @media only screen and (max-width: 767px){
        flex-direction: column;
    }
}

.user-descr {
    padding: 9px 0px 0 30px;
    max-width: 450px;
    width: 100%;
}

.user-name {
    padding: 20px 0;
    color: #4d4d4d;
}

.user-text {
    color: #999999;
}

.user-location {
    color: #b3b3b3;
    display: flex;
    transform: translateX(-9px);
    padding-top: 10px;
}

.user-descr-button {
    font-size: 9px !important;
    text-transform: uppercase;
    line-height: 20px !important;
    height: 20px !important;
    min-height: 22px !important;
}

.user-button {
    padding: 0 30px 0 30px;
    //transform: translateY(-27px);
    margin: 0 37px 0 0;

    /*position:absolute;*/
    /*top: 60px;*/
    /*right: 0;*/

    @media only screen and (max-width: 1120px) {
        padding: 0 30px 0 30px;
    }
    @media only screen and (max-width: 767px){
        margin-top: 20px;
    }
}

.user-ava img{
    width: 195px;
    height: 195px;
    border-radius: 100px;
    display: block;
    margin: 0 auto;
}

.user-button-label {

    @media only screen and (max-width: 1120px) {
        padding-left: 20px;
    }
}
</style>
