<template>
    <div class="type-comment">
        <div class="type-comment-input">
            <img :src="image_link" alt="" class="type-comment-ava"/>

            <textarea
                    class="textarea"
                    :placeholder="i18n.typeYourComment[$lang]"
                    v-model="commentText"
            />
        </div>

        <div class="type-comment-bottom">
            <div class="add-items-wrapper">
                <div class="add-item">
                    <label for="input-photo">
                        <input id="input-photo" type="file" accept="image/*" hidden/>
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="dviqvb9aha" maskUnits="userSpaceOnUse" x="6" y="6" width="18" height="18"><path fill-rule="evenodd" clip-rule="evenodd" d="M24 22V8c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-12.5-5.5l2.5 3.01L17.5 15l4.5 6H8l3.5-4.5z" fill="#fff"/></mask><g mask="url(#dviqvb9aha)"><path d="M2 2h26v26H2z"/></g></svg>
                    </label>
                </div>

                <div class="add-item">
                    <label for="input-attachment">
                        <input id="input-attachment" type="file" hidden/>
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="378teyknga" maskUnits="userSpaceOnUse" x="5" y="10" width="20" height="11"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 15.5c0-3.04 2.46-5.5 5.5-5.5H21c2.21 0 4 1.79 4 4s-1.79 4-4 4h-8.5a2.5 2.5 0 010-5H20v2h-7.59c-.55 0-.55 1 0 1H21c1.1 0 2-.9 2-2s-.9-2-2-2H10.5C8.57 12 7 13.57 7 15.5S8.57 19 10.5 19H20v2h-9.5C7.46 21 5 18.54 5 15.5z" fill="#fff"/></mask><g mask="url(#378teyknga)"><path d="M2 2h26v26H2z"/></g></svg>
                    </label>
                </div>

                <div class="add-item">
                    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="egut3amqha" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.99 5C9.47 5 5 9.48 5 15s4.47 10 9.99 10C20.52 25 25 20.52 25 15S20.52 5 14.99 5zM15 23c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H9.89c.8 2.04 2.78 3.5 5.11 3.5z" fill="#fff"/></mask><g mask="url(#egut3amqha)"><path d="M2 2h26v26H2z"/></g></svg>
                </div>
            </div>

            <Button
                    class="button-add-comment"
                    :loading="commentAddStatus == 1"
                    @click="handleCommentButtonClick"
            >
                {{ i18n.send[$lang] }}
            </Button>
            <!--            <button type="button" class="button-send">-->
            <!--                {{ i18n.send[$lang] }}-->
            <!--            </button>-->
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";
    import Button from "@/components/Button";

    export default {
        name: "CommentForm",
        components: {
            Button,
        },
        props: {
            parentId: {
                type: Number,
                default: null,
            },
        },
        data() {
            return {
                commentText: "",
                i18n: {
                    typeYourComment: {
                        en: "Type your comment",
                        ru: "Напишите ваш комментарий",
                    },
                    send: {
                        en: "Send",
                        ru: "Отправить",
                    },
                },
            };
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
                image_link: (state) => state.user.image_link,
            }),
            ...mapState("comments", ["commentAddStatus"]),
            ...mapState({
                projectId: (state) => state.projects.project.id,
            }),
        },
        methods: {
            handleCommentButtonClick() {
                this.$store
                    .dispatch("comments/addComment", {
                        projectId: this.projectId,
                        text: this.commentText,
                        parentId: this.parentId,
                    })
                    .then(() => {
                        this.commentText = "";
                        this.$store.dispatch("comments/updateReplyFormParent", this.parentId);
                    });
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "@/sass/mixins.scss";

    .type-comment {
        border: 2px #f5f5f5 solid;
        border-radius: 6px;
        @media only screen and (max-width: 767px) {
            margin-top: 29px;
        }

        .type-comment-input {
            font-family: IBMPlexSans;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
        }

        .textarea {
            width: 100%;
            padding: 30px 15px 30px 25px;
            outline: none;
            border: 0;
            resize: none;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 30px;
            color: #999999;
            @media only screen and (max-width: 767px) {
                font-weight: 400;
                font-size: 11px;
                line-height: 18px;
                padding: 20px 20px;
                padding-left: 6px;
                min-height: 80px;
            }
        }

        .type-comment-ava {
            height: 50px;
            width: 50px;
            margin-left: 20px;
            margin-top: 20px;
            border-radius: 50%;
            @media only screen and (max-width: 767px) {
                height: 20px;
                width: 20px;
            }
        }

        .type-comment-bottom {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 25px;
            border-top: 2px #f5f5f5 solid;
            @media only screen and (max-width: 767px) {
                padding: 10px 20px;
            }
        }

        .add-items-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-right: 27px;
            color: #e6e6e6;

            svg:hover {
                color: #666
            }
        }

        .add-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 26px;
            margin-left: 19px;
            cursor: pointer;

            label {
                height: inherit;
                cursor: pointer;
            }

            &:nth-child(1) {
                margin-left: 0;
            }

            @media only screen and (max-width: 767px) {
                margin-left: 10px;
            }

        }

        &.comment-reply-form {
            margin-top: 25px;
        }

        .button-add-comment {
            letter-spacing: 1.25px;
            min-width: 100px;
        }
    }
</style>
