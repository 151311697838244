<template>
    <div class="component-spinner">
        <div class="loader"/>

        <div class="label">
            {{ i18n.loading[$lang] }}
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Spinner',
        data() {
            return {
                i18n: {
                    loading: {
                        'ru': 'Загрузка',
                        'en': 'Loading'
                    }
                }
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            })
        }
    };
</script>

<style scoped lang="scss">
    .component-spinner {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 30px auto;
        width: 165px;
    }

    .loader {
        border: 6px solid transparent;
        border-radius: 50%;
        border-top: 6px solid #D9D9D9;
        width: 65px;
        height: 65px;
        animation: spin 2s linear infinite;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .label {
        font-family: Roboto;
        font-style: normal;
        font-weight: 900;
        font-size: 12px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #B3B3B3;
        margin-left: 40px;
    }
</style>
