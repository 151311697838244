<template>
  <div class="component-slide-variant1" @click="handleSlideWrapperClick">
    <div class="header" :style="`background-image: url(${imgSrc})`">
      <div class="header-top">
        <div class="buttons-top-wrapper">
          <button type="button" class="button share">
            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'content-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="38byvmejra" maskUnits="userSpaceOnUse" x="6" y="7" width="18" height="15"><path fill-rule="evenodd" clip-rule="evenodd" d="M17 11V7l7 7-7 7v-4.1c-5 0-8.5 1.6-11 5.1 1-5 4-10 11-11z" fill="#fff"/></mask><g mask="url(#38byvmejra)"><path d="M2 2h26v26H2z"/></g></svg>
          </button>

          <button v-if="edit" type="button" class="button edit">
            <div class="button-edit-icon">
              <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'content-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="k6fgnfoafa" maskUnits="userSpaceOnUse" x="4" y="4" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.8 16.202v3h3l8.848-8.848-3-3L4.8 16.202zm14.168-8.168a.797.797 0 000-1.128l-1.872-1.872a.797.797 0 00-1.128 0l-1.464 1.464 3 3 1.464-1.464z" fill="#fff"/></mask><g mask="url(#k6fgnfoafa)"><path fill="#fff" d="M1.6 1.6h20.8v20.8H1.6z"/></g></svg>
            </div>

            <div class="button-edit-label">
              {{ i18n.edit[$lang] }}
            </div>
          </button>

          <button v-else type="button" class="button fav">
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'content-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="9lhc66r3ta" maskUnits="userSpaceOnUse" x="4" y="4" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 16.216l4.944 2.984-1.312-5.624L20 9.792l-5.752-.488L12 4 9.752 9.304 4 9.792l4.368 3.784L7.056 19.2 12 16.216z" fill="#fff"/></mask><g mask="url(#9lhc66r3ta)"><path fill="#fff" d="M1.6 1.6h20.8v20.8H1.6z"/></g></svg>
          </button>
        </div>
      </div>

      <div class="header-bottom">
        <div class="header-tags-wrapper">
          <div v-for="tagCur in tags[$lang]" :key="tagCur" class="header-tag">
            {{ tagCur }}
          </div>
        </div>
        <div class="header-stats-wrapper">
          <div class="header-stat">
            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'header-stat-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="mh3anly7ia" maskUnits="userSpaceOnUse" x="4" y="7" width="22" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 7.5c-5 0-9.27 3.11-11 7.5 1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM15 20c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" fill="#fff"/></mask><g mask="url(#mh3anly7ia)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

            <div class="header-stat-label">
              {{ stats.views }}
            </div>
          </div>

          <div class="header-stat">
            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'header-stat-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="vk694ib1ha" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M24 9h-2v9H9v2c0 .55.45 1 1 1h11l4 4V10c0-.55-.45-1-1-1zm-4 6V6c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z" fill="#fff"/></mask><g mask="url(#vk694ib1ha)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

            <div class="header-stat-label">
              {{ stats.comments }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title">
        <h3>{{ title[$lang] }}</h3>
      </div>

      <div class="text">
        <p>{{ text[$lang] }}</p>
      </div>

      <div class="content-footer">
        <div v-if="progress" class="content-stats-and-progress-bar">
          <ProgressBar
            :progress="progress.percent"
            class="content-progress-bar"
          />

          <div class="content-stats">
            <div class="content-stat-item">
              <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'content-stat-item-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="hqt2pts5ua" maskUnits="userSpaceOnUse" x="4" y="4" width="22" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 24h4V12H4v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L17.17 4l-6.58 6.59c-.37.36-.59.86-.59 1.41v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L26 13z" fill="#fff"/></mask><g mask="url(#hqt2pts5ua)"><path d="M2 2h26v26H2z"/></g></svg>

              <div class="content-stat-item-label">
                {{ progress.support }} %
              </div>
            </div>

            <div class="content-stat-item">
              <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'content-stat-item-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="ty08pp5naa" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 5C9.5 5 5 9.5 5 15s4.5 10 10 10 10-4.5 10-10S20.5 5 15 5zm4.2 14.2L14 16v-6h1.5v5.2l4.5 2.7-.8 1.3z" fill="#fff"/></mask><g mask="url(#ty08pp5naa)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

              <div class="content-stat-item-label">
                {{ progress.remaining }}
                {{ i18n.daysRemaining[$lang] }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="status" class="content-status">
          <img
            :src="getImgSrcForStatus(status)"
            alt=""
            class="content-status-icon"
          />

          <div class="content-status-label">
            {{ status[$lang] }}
          </div>
        </div>

        <Button
          class="content-button"
          :class="{ 'is-cancel': button.en === 'cancel' }"
          @click="handleButtonClick(button)"
          @mouseover="buttonHover = true"
          @mouseleave="buttonHover = false"
        >
          <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="button.en === 'promote'" v-bind:svg-inline="''" v-bind:class="'content-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="sj357jvhba" maskUnits="userSpaceOnUse" x="4" y="4" width="22" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 24h4V12H4v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L17.17 4l-6.58 6.59c-.37.36-.59.86-.59 1.41v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L26 13z" fill="#fff"/></mask><g mask="url(#sj357jvhba)"><path d="M2 2h26v26H2z"/></g></svg>

          <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="button.en === 'cancel'" v-bind:svg-inline="''" v-bind:class="'content-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="uhp5v8vnda" maskUnits="userSpaceOnUse" x="3" y="3" width="22" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.733 22.4h3.734V11.2H3.733v11.2zm20.534-10.267c0-1.026-.84-1.866-1.867-1.866h-5.89L17.398 6l.028-.298c0-.383-.158-.738-.41-.99l-.99-.98-6.14 6.151c-.346.336-.552.803-.552 1.316v9.333c0 1.027.84 1.867 1.867 1.867h8.4c.775 0 1.437-.467 1.717-1.139l2.82-6.58c.083-.214.13-.438.13-.68v-1.784l-.01-.009.01-.075z" fill="#fff"/></mask><g mask="url(#uhp5v8vnda)"><path fill="#fff" d="M1.866 1.867h24.267v24.267H1.866z"/></g></svg>

          <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="button.en === 'view'" v-bind:svg-inline="''" v-bind:class="'content-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="bbiuc8klla" maskUnits="userSpaceOnUse" x="5" y="5" width="18" height="18"><path fill-rule="evenodd" clip-rule="evenodd" d="M20.533 20.533H7.467V7.467H14V5.6H7.467C6.43 5.6 5.6 6.44 5.6 7.467v13.066c0 1.027.83 1.867 1.867 1.867h13.066c1.027 0 1.867-.84 1.867-1.867V14h-1.867v6.533zM15.867 5.6v1.867h3.35l-9.174 9.174 1.316 1.316 9.174-9.174v3.35H22.4V5.6h-6.533z" fill="#fff"/></mask><g mask="url(#bbiuc8klla)"><path fill="#D8D8D8" d="M1.867 1.867h24.267v24.267H1.867z"/></g></svg>

          <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="button.en === 'publish'" v-bind:svg-inline="''" v-bind:class="'content-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="yocv8qf5na" maskUnits="userSpaceOnUse" x="3" y="7" width="24" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.35 13.04A7.49 7.49 0 0015 7c-2.89 0-5.4 1.64-6.65 4.04A5.994 5.994 0 003 17c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 16v4h-4v-4h-3l5-5 5 5h-3z" fill="#fff"/></mask><g mask="url(#yocv8qf5na)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

          <div class="content-button-label">
            {{ button[$lang] }}
            <div class="tooltip">
              Вы можете отменить голос в течение 24 часов
            </div>
          </div>
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import ProgressBar from "@/components/ProgressBar";
import objectValidator from "@/utils/objectValidator.js";
import { mapState } from "vuex";

export default {
  name: "SlideVariant1",
  components: {
    ProgressBar,
    Button,
  },
  props: {
    title: {
      type: [String, Object],
      required: true,
    },
    text: {
      type: [String, Object],
      required: true,
    },
    imgSrc: {
      type: String,
      required: true,
    },
    tags: {
      type: [Array, Object],
      required: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    /**
     * @var button { 'publish' || 'view' || 'promote' }
     */
    button: {
      type: [String, Object],
      required: true,
    },
    /**
     * @var status { 'draft' || 'moderation' }
     */
    status: {
      type: [String, Object],
      default: "",
    },
    stats: {
      type: Object,
      required: true,
      validator(value) {
        const keys = ["comments", "views"];

        return objectValidator(value, keys);
      },
    },
    progress: {
      type: Object,
      default() {
        return null;
      },
      validator(value) {
        const keys = ["support", "remaining", "percent"];

        return objectValidator(value, keys);
      },
    },
  },
  data() {
    return {
      buttonHover: false,
      i18n: {
        daysRemaining: {
          en: "days remaining",
          ru: "дней осталось",
        },
        edit: {
          en: "Edit",
          ru: "Редактировать",
        },
      },
    };
  },
  computed: {
    ...mapState({
      $lang: (state) => state.$lang,
    }),
  },
  methods: {
    getImgSrcForStatus(status) {
      if (status.en === "moderation") {
        return "/img/icons/ic_report.svg";
      }

      if (status.en === "draft") {
        return "/img/icons/ic_restore.svg";
      }
    },
    getImgSrcForButton(button) {
      if (button.en === "promote") {
        if (this.buttonHover) return "/img/icons/ic_thumb_up-white.svg";
        else return "/img/icons/ic_thumb_up.svg";
      }

      if (button.en === "view") {
        return "/img/icons/ic_open_in_new.svg";
      }

      if (button.en === "publish") {
        return "/img/icons/ic_cloud_upload.svg";
      }
    },
    handleButtonClick(button) {
      event.stopPropagation();
      if (button.en === "promote") {
        this.$router.push("profile-supported");
      }
    },
    handleSlideWrapperClick() {
      this.$router.push("profile");
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/sass/mixins.scss";

.component-slide-variant1 {
  // width: 540px;
  width: 100%;
  // max-width: calc(50% - 30px);
  max-width: calc(50% - 15px);
  outline: none;
  cursor: pointer;
  overflow: hidden;
  border-radius: 6px;
  @media only screen and (max-width: 767px){
    max-width: 100%;
  }
  @include box-shadow-1;
}

.tooltip {
  position: absolute;
  top: 35px;
  left: -77px;
  width: 200px;
  border-radius: 6px;
  height: 60px;
  display: none;
  background: #666666;
  font-size: 11px;
  padding: 10px 0;
  color: #fff;
  line-height: 20px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 300px;
  background: no-repeat center;
  background-size: cover;
  border-radius: 6px 6px 0 0;
  @media only screen and (max-width: 479px){
    height: 160px;
  }
}

.header-top {
  padding: 30px 31px;
  width: 100%;
  @media only screen and (max-width: 767px){
    padding: 10px;
  }
}

.header-bottom {
  margin-top: auto;
}

.buttons-top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background: rgba(#000, 0.3);
  border-radius: 50%;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ffffff;
  // @include mark-default;

  .content-button-img {
    color: #ffffff;
  }

  &:hover {
    background: #f7f7f7;
    border: 2px solid #f5f5f5;
    transition: all 0.3s ease;

    .content-button-img {
      color: #b3b3b3;
    }
  }

  &.edit {
    width: 180px;
    border-radius: 20px;
    justify-content: space-around;

    .button-edit-icon {
      margin-left: 10px;
    }

    .button-edit-label {
      margin-right: 25px;
    }
  }
  @media only screen and (max-width: 767px){
    /*&.share,*/
    &.fav{
      svg{
        width: 24px;
        height: 24px;
      }
    }
  }
}

.header-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px 20px 34px;
}

.header-tags-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: IBMPlexSans;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  background: rgba(#000, 0.3);
  margin-left: 10px;

  &:nth-child(1) {
    margin-left: 0;
  }
}

.header-stats-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.header-stat {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 24px;

  &:nth-child(1) {
    margin-left: 0;
  }
}

.header-stat-img {
  width: 26px;
  color: #fff;
}

.header-stat-label {
  font-family: IBMPlexSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-left: 13px;

  @media only screen and (max-width: 1030px) {
    font-size: 12px;
  }
}

.content {
  padding: 30px;
  padding-bottom: 35px;
  background: #fff;
  width: 100%;

  @media only screen and (max-width: 1285px) {
    padding: 15px;
    padding-bottom: 20px;
  }
  @media only screen and (max-width: 767px){
    padding: 20px;
  }
}

.title {
  color: #4d4d4d;

  @media only screen and (max-width: 1388px) {
    font-size: 18px;
  }

  @media only screen and (max-width: 1200px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 767px){
    h3{
      font-size: 15px;
      line-height: 20px;
    }
  }
}

.text {
  color: #3c3c3c;
  margin-top: 10px;

  @media only screen and (max-width: 1030px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 767px){
    font-size: 11px;
    line-height: 18px;
  }
}

.content-footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 25px;
  @media only screen and (max-width: 767px){
    flex-wrap: wrap;
  }
}

.content-stats-and-progress-bar {
  margin-right: 30px;

  @media only screen and (max-width: 1405px) {
    margin-left: 15px;
  }
  @media only screen and (max-width: 767px){
    width: 100%;
    margin: 0;
  }
}

.content-button {
  min-width: 180px;
  margin-left: auto;


  @media only screen and (max-width: 767px){
    margin-left: 0;
    margin-top: 20px;
    display: none;
  }
  &.is-cancel {
    &:hover {
      background-color: #d16861;
      border-color: #d16861;
    }

    .content-button-img {
      transform: rotate(180deg);
    }

    &:hover .tooltip {
      display: block;
    }
  }
}

.content-button-label {
  position: relative;

  @media only screen and (max-width: 1100px) {
    margin-left: 8px;
  }
}

.content-stats {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 17px;
}

.content-stat-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 36px;

  &:nth-child(1) {
    margin-left: 0;
  }

  @media only screen and (max-width: 1405px) {
    margin-left: 15px;
  }

  @media only screen and (max-width: 1040px) {
    margin-left: 10px;
  }
}

.content-stat-item-img {
  width: 26px;
  color: #d8d8d8;
}

.content-stat-item-label {
  font-family: IBMPlexSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #666666;
  margin-left: 10px;
  min-width: 30px;

  @media only screen and (max-width: 1405px) {
    margin-left: 5px;
    font-size: 12px;
  }

  @media only screen and (max-width: 1075px) {
    font-size: 10px;
  }
  @media only screen and (max-width: 767px){
    font-size: 11px;
    line-height: 11px;
  }
}

.content-status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.content-status-icon {
  width: 26px;
}

.content-status-label {
  font-family: IBMPlexSans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #808080;
  margin-left: 12px;

  &:first-letter {
    text-transform: capitalize;
  }
}
</style>
