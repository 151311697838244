import Home from "@/views/Home";
import Err404 from "@/views/Err404";
import Profile from "@/views/Profile";
import Project from "@/views/Project";
import About from "@/views/About";
import Help from "@/views/Help";
import ProfileInfo from "@/views/ProfileInfo";
import ProfileSettings from "@/views/ProfileSettings";
import ProfileDiscovery from "@/views/ProfileDiscovery";
import ProfileMyProjects from "@/views/ProfileMyProjects";
import ProfileFavourites from "@/views/ProfileFavourites";
import NewProjectStep from "@/views/NewProjectStep";
import NewProjectStep2 from "@/views/NewProjectStep2";
import NewProjectStep3 from "@/views/NewProjectStep3";
import NewProjectStep4 from "@/views/NewProjectStep4";
import NewProjectStep5 from "@/views/NewProjectStep5";
import ProfileSupported from "@/views/ProfileSupported";
import ProfileHome from "@/views/ProfileHome";
import LogIn from "@/components/LogIn";
import SignUp from "@/components/SignUp";
import RestoreAccount from "@/components/RestoreAccount";
import NewPassword from "@/components/NewPassword";
import CheckYourEmail from "@/components/CheckYourEmail";
import RegistrationCompleted from "@/components/RegistrationCompleted";
import AskAQuestion from "@/components/AskAQuestion";
import NewsOrArticle from "@/views/NewsOrArticle";

export default [
  {
    path: "*",
    component: Err404,
  },
  {
    path: "/",
    component: Home,
  },
  {
    path: "/profile",
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/project/:id",
    component: Project,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/help",
    component: Help,
  },
  {
    path: "/profile-info",
    component: ProfileInfo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile-settings",
    component: ProfileSettings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile-discovery",
    component: ProfileDiscovery,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile-my-projects",
    component: ProfileMyProjects,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile-favourites",
    component: ProfileFavourites,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/new-project-step",
    component: NewProjectStep,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/new-project-step-2",
    component: NewProjectStep2,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/new-project-step-3",
    component: NewProjectStep3,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/new-project-step-4",
    component: NewProjectStep4,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/new-project-step-5",
    component: NewProjectStep5,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile-supported",
    component: ProfileSupported,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile-home",
    component: ProfileHome,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    component: LogIn,
  },
  {
    path: "/signup",
    component: SignUp,
  },
  {
    path: "/restore-account",
    component: RestoreAccount,
  },
  {
    path: "/new-password",
    component: NewPassword,
  },
  {
    path: "/check-your-email",
    component: CheckYourEmail,
  },
  {
    path: "/registration-completed",
    component: RegistrationCompleted,
  },
  {
    path: "/ask-a-question",
    component: AskAQuestion,
  },
  {
    path: "/news-or-article",
    component: NewsOrArticle,
  },
];
