<template>
    <div class="view-about">
        <div class="about-content">
            <Header
                    :logo-white="true"
                    :invisible-bg="true"
                    class="header"
                    :burger="true"
            />

            <div class="top-block">
                <div class="top-block-title">
                    <h1>{{ i18n.aboutThePlatform[$lang] }}</h1>
                </div>

                <div class="top-block-line"/>
            </div>

            <MainBlock/>

            <Slide :img-src="require('@/assets/img/view-about/slide.png')" class="big-slide">
                <template #title>
                    <h2 v-html="i18n.findAnAnswer[$lang]"/>
                </template>

                <template #text>
                    <p v-html="i18n.sendYourQuestion[$lang]"/>
                </template>

                <template #buttons>
                    <Button class="button primary">
                        {{ i18n.askQuestion[$lang] }}
                    </Button>
                </template>
            </Slide>
        </div>

        <Footer class="footer"/>
    </div>
</template>

<script>
    import Header from '@/components/Header';
    import BurgerMenu from '@/components/BurgerMenu';
    import Footer from '@/components/Footer';
    import MainBlock from './components/MainBlock';
    import Slide from '@/views/Home/components/Slide';
    import Button from '@/components/Button';
    import {mapState} from 'vuex';

    export default {
        name: 'About',
        components: {
            Footer,
            Header,
            BurgerMenu,
            MainBlock,
            Slide,
            Button,
        },
        data() {
            return {
                i18n: {
                    aboutThePlatform: {
                        en: 'About the platform',
                        ru: 'О площадке'
                    },
                    findAnAnswer: {
                        en: `Didn’t find an answer to your
            <br />
            question?`,
                        ru: `Не нашли ответа на свой
            <br />
            вопрос?`
                    },
                    sendYourQuestion: {
                        en: `Send us your question, and our manager will contact you
            <br />
            in 24 hours`,
                        ru: `Напишите что вас интересует и наш оператор свяжется с
            <br />
            вами в течении 24 часов с момента подачи заявки`
                    },
                    askQuestion: {
                        en: 'Ask a question',
                        ru: 'Задать вопрос'
                    },

                }
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
        }
    };
</script>

<style scoped lang="scss">
    .top-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 630px;
        width: 100%;
        background: url("~@/assets/img/view-about/placeholder.png") no-repeat center;
        background-size: cover;
        padding-bottom: 150px;

        @media only screen and (max-width: 767px) {

        }
    }

    .top-block-title {
        text-align: center;
        color: #FFFFFF;

        @media only screen and (max-width: 1030px) {
            font-size: 38px;
        }
    }

    .top-block-line {
        width: 70px;
        height: 2px;
        background: #50CAB6;
        margin-top: 46px;
    }

    .button {
        width: 214px;
        height: 60px;
        transform: translateY(-25px);
    }

    .big-slide {
        // margin-top: 22px;
        // transform: translateY(-200px);
        margin-top: -174px;
        padding: 8% 166px 6.5%;

        @media only screen and (max-width: 767px) {
            padding: 140px 20px 89px;
        }


    }
    .footer {
        margin-top: 0 !important;
        // transform: translateY(-200px);
    }
</style>
