import { axios } from "@/plugins/axios";

export default {
    getComments: function (projectId) {
        return axios.get("/api/projects/" + projectId + "/comments");
    },
    addComment: function (projectId, text, parentId = 0) {
        return axios.post("/api/comment", {
            project_id: projectId,
            comment: text,
            parent_id: parentId,
        });
    },
    like: function (id) {
        return axios.get("/api/comment/" + id + "/like");
    },
    dislike: function (id) {
        return axios.get("/api/comment/" + id + "/dislike");
    },
    unlike: function (id) {
        return axios.get("/api/comment/" + id + "/unlike");
    },
};
