<template>
    <div class="view-home-component-infobox">
        <div class="header">
            <div class="title">
                <h2 v-html="i18n.grossfortIsTheFirst[$lang]"></h2>
            </div>

            <div class="line"/>

            <div class="item">
                <span class="text-lead-2" v-html="i18n.itIsAPlace[$lang]"></span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import prepareSvgElements from '@/mixins/prepareSvgElements.js';

    export default {
        name: "Infobox",

        mixins: [prepareSvgElements],

        data() {
            return {

                i18n: {
                    grossfortIsTheFirst: {
                        en: 'Grossfort — is the first joint-stock<br />crowdinvesting platform',
                        ru: 'Grossfort — первая акционерная<br />краудинвестинговая площадка'
                    },
                    itIsAPlace: {
                        en: `It is a place, where each user is at the same time an investor and a job seeker. Contributions of participants form a common fund, from which the platform finances and helps launch business ideas of participants. The decision on the financing of the project is made by general vote.`,
                        ru: `Это место, где каждый пользователь — одновременно инвестор и соискатель. Взносы участников образуют общий фонд, из которого площадка финансирует и помогает запускать бизнес-идеи участников. Решение о финансировании проекта принимается общим голосованием.`
                    },
                }
            };
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
        },
        // beforeMount() {
        //     this.languageChosen = this.langShort[this.$lang] || 'English';
        // },

    }

</script>

<style scoped lang="scss">
    .view-home-component-infobox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #fff;
        // padding: 120px 355px 75px 355px;
        padding: 120px 24.6% 90px;

        @media only screen and (max-width: 1200px) {
            padding: 120px 18.6% 75px;
        }
        @media only screen and (max-width: 767px){
            padding: 60px 10px 40px;
        }
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .title {
        text-align: center;
        color: #4D4D4D;

        /*@media only screen and (max-width: 1030px) {*/
        /*    font-size: 26px;*/
        /*}*/
        /*@media only screen and (max-width: 767px){*/
        /*    h2{*/
        /*        font-size: 18px;*/
        /*        line-height: 24px;*/
        /*    }*/
        /*}*/
    }

    .line {
        width: 60px;
        height: 2px;
        background: #50CAB6;
        margin-top: 28px;
        @media only screen and (max-width: 767px){
            margin-top: 20px;
        }
    }

    .item {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 400 !important;
        text-align: center;
        color: #666666;
        margin-top: 30px;
        max-width: 730px;
        line-height: 30px;

        @media only screen and (min-width: 768px) {
            font-size: 1.4vw;
        }
        @media only screen and (max-width: 767px){
            line-height: 24px;
            margin-top: 20px;
            font-size:14px;
        }
    }

</style>
