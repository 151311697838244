<template>
    <ul class="view-home-component-line-list">
        <li v-for="(elCur, index) in elements" :key="index" class="line-list-item">
            <h3 class="list-list-title">{{elCur.title}}</h3>
        </li>
    </ul>
</template>

<script>
    export default {
        name: "LineList",
        data() {
            return {
                elements: [
                    {
                        title: 'Право собственности инвесторов инвесторов на долю в компании'
                    },
                    {
                        title: 'Право голоса и влияние на принятие решения'
                    },
                    {
                        title: 'Право на распределение прибыли в виде дивидендов'
                    }
                ]
            }

        }
    }
</script>

<style scoped>

    .line-list {
        //max-width: 390px;
        color: #fff;
    }

    .line-list-item {
        margin-bottom: 30px;

    }

    .line-list-item:before {
        height: 2px;
        width: 30px;
        background: #50CAB6;
        content: '';
        position: absolute;
        margin-top: 13px
    }

    .list-list-title {
        margin-left: 40px;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
    }
    @media only screen and (max-width: 767px){
        .line-list .line-list-item {
            margin-bottom: 20px;
        }
        .line-list .line-list-item:before{
            margin-top: 9px;
        }
        .line-list .line-list-item:last-child{
            margin-bottom: 0;
        }
    }

</style>
