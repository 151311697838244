<template>
    <div class="component-main">
        <div class="block personal-info" data-id="personal-info">
            <div class="title">
                <h3>{{ i18n.personalInformation[$lang] }}</h3>
            </div>

            <div class="inputs-wrapper">
                <div class="input-wrapper">
                    <label for="first-name" class="label">
                        <h5>{{ i18n.firstName[$lang] }}</h5>
                    </label>

                    <input
                            id="first-name"
                            class="input"
                            v-model="firstname"
                            required
                    />
                </div>

                <div class="input-wrapper">
                    <label for="last-name" class="label">
                        <h5>{{ i18n.lastName[$lang] }}</h5>
                    </label>

                    <input
                            id="last-name"
                            class="input"
                            v-model="lastname"
                            required
                    />
                </div>
            </div>

            <div class="user-ava-wrapper">
                <image-uploader :value="image_link" @change="setImage($event)"/>
            </div>
        </div>
        <div class="block location" data-id="location">
            <div class="title">
                <h3>{{ i18n.location[$lang] }}</h3>
            </div>

            <div class="selects-wrapper">
                <div class="select-wrapper">
                    <div class="label">
                        <h5>{{ i18n.country[$lang] }}</h5>
                    </div>

                    <v-select
                            :v-model="country_id"
                            :options="location.countries"
                            :clearable="false"
                            class="select"
                            :value="country_id"
                            :reduce="country => country.id"
                            @input="handleCountrySelected"
                    >
                        <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                                <svg width="9" height="5" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.498 5L9 2.5V.104L4.498 2.62 0 0v2.5L4.498 5z" fill="#D9D9D9"/></svg>
                            </span>
                        </template>
                    </v-select>
                </div>

                <div class="select-wrapper">
                    <label class="label">
                        <h5>{{ i18n.timezone[$lang] }}</h5>
                    </label>

                    <v-select
                            :v-model="city_id"
                            :value="city_id"
                            :options="location.cities"
                            :clearable="false"
                            class="select"
                            :reduce="city => city.id"
                            @input="handleCitySelected"
                    >
                        <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                                <svg width="9" height="5" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.498 5L9 2.5V.104L4.498 2.62 0 0v2.5L4.498 5z" fill="#D9D9D9"/></svg>
                            </span>
                        </template>
                    </v-select>
                </div>
            </div>
        </div>
        <div class="block about-me" data-id="about-me">
            <div class="title title-about-me">
                <h3>{{ i18n.aboutMe[$lang] }}</h3>
            </div>

            <div class="textarea-wrapper">
                <textarea id="about" v-model="about" class="textarea"/>
            </div>

            <Button class="button-save primary" @click="handleSaveButtonClick" :loading="isLoading">
                {{ i18n.save[$lang] }}
            </Button>
        </div>
    </div>
</template>

<script>
    import Button from "@/components/Button";
    import ImageUploader from "./components/ImageUploader";
    import {mapState} from "vuex";
    import {mapFields} from "vuex-map-fields";


    export default {
        name: "Main",
        components: {
            Button,
            ImageUploader
        },
        data() {
            return {
                isLoading: false,
                profile: {
                    photo: null
                },
                Country: "Russia",
                Timezone: "(GMT+03:00) Moscow",
                selectTimezones: [
                    "(GMT+03:00) Moscow",
                    "(GMT+03:00) Toronto",
                    "(GMT+03:00) Berlin"
                ],
                i18n: {
                    personalInformation: {
                        en: "Personal Information",
                        ru: "Личные данные"
                    },
                    firstName: {
                        en: "First name",
                        ru: "Имя"
                    },
                    lastName: {
                        en: "Last name",
                        ru: "Фамилия"
                    },
                    country: {
                        en: "Country",
                        ru: "Страна"
                    },
                    location: {
                        en: "Location",
                        ru: "Местоположение"
                    },
                    timezone: {
                        en: "Timezone",
                        ru: "Часовой пояс"
                    },
                    aboutMe: {
                        en: "About me",
                        ru: "О себе"
                    },
                    save: {
                        en: "Save",
                        ru: "Сохранить"
                    }
                }
            };
        },
        computed: {
            ...mapState({
                location: state => state.location,
            }),
            ...mapFields("user", [
                "country_id",
                "city_id",
                "firstname",
                "lastname",
                "about",
                "image",
                "image_link"
            ]),
            ...mapState({
                $lang: state => state.$lang
            })
        },

        methods: {
            setImage($event){
                this.image = $event;
                console.log(this.image);
            },
            handleSaveButtonClick() {
                this.isLoading=true;
                this.$store.dispatch("user/saveUserData")
                    .then(() => {
                        this.isLoading=false
                    })
            },
            handleCountrySelected(value) {
                this.$store.commit("user/SET_COUNTRY_ID", value);
            },
            handleCitySelected(value) {
                this.$store.commit("user/SET_CITY_ID", value);
            }
        },
        created() {
            this.$store.dispatch("fetchCountries");
            this.$store.dispatch("fetchCities");
        }
    };
</script>

<style scoped lang="scss">
    .component-main {
        padding: 60px 0 0 0;
        @media only screen and(max-width: 767px){
            padding: 25px 0 0 0;
        }
    }

    .block {
        position: relative;
        padding: 0 90px 0 90px;

        @media only screen and (max-width: 1210px) {
            padding: 0 60px 0 60px;
        }

        @media only screen and (max-width: 1130px) {
            padding: 0 25px 0 25px;
        }
    }

    .personal-info {
        padding-bottom: 90px;
        @media only screen and (max-width: 767px){
            padding-bottom: 25px;
        }
    }

    .location,
    .about-me {
        padding-top: 60px;
        padding-bottom: 60px;
        @media only screen and (max-width: 767px){
            padding-top:25px;
            padding-bottom: 25px;
        }
    }

    .location::before,
    .about-me::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-top: 2px #f0f0f0 solid;
    }

    .title {
        color: #4d4d4d;

        @media only screen and (max-width: 1030px) {
            font-size: 18px;
        }
    }

    .title-about-me {
        padding-bottom: 45px;
        @media only screen and (max-width: 767px){
            padding-bottom: 10px;
        }
    }

    .label {
        color: #666666;
    }

    .button-save {
        padding: 0px 65px;
        margin: 55px 0 0 0;
        @media only screen and (max-width: 767px){
            margin: 25px 0 0 0;
        }
    }

    .user-ava-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 30px;
    }

    .select-wrapper {
        width: 50%;
        @media only screen and (max-width: 767px){
            width: 100%;
        }
    }
</style>
