<template>
    <div class="pricing">
        <div class="block">
            <div class="title">
                <h3>{{ i18n.setFundingTitle[$lang] }}</h3>
            </div>
            <div class="desc">
                <p>{{ i18n.setFundingDesc[$lang] }}</p>
            </div>
            <div class="steps-wrapper">
                <div class="inputs-wrapper">
                    <div class="input-wrapper">

                    </div>
                </div>
            </div>
            <div class="pricing-slider center-content">
                <label>
                    <input name="slider"
                           type="range"
                           ref="slider"
                           step="1000"
                           v-model="priceInputValue"
                           @input="handleSliderValuePosition($event.target)">
                </label>
                <div ref="sliderValue" class="pricing-slider-value" style="width: fit-content">
                    <h4>Сумма: {{priceInputValue}} ₽</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "PriceSlider",
        props: ["value"],
        data() {
            return {
                i18n: {
                    setFundingTitle: {
                        ru: "Необходимый бюджет",
                        en: "Initial funding",
                    },
                    setFundingDesc: {
                        ru: "Выберите необходимый объем финансирования для реализации проекта",
                        en: "Please set required funding for the project ",
                    }
                },
                priceInputValue: "0",   // initial input value
            };
        },
        methods: {
            handleSliderValuePosition(input) {
                // const multiplier = input.value / input.max;
                // const thumbOffset = this.thumbSize * multiplier;
                //
                // const priceInputOffset =
                //     (this.thumbSize - this.$refs.sliderValue.clientWidth) / 2;
                // this.$refs.sliderValue.style.left =
                //     input.clientWidth * multiplier - thumbOffset + "px";
                this.$emit("input", input.value)
            },
        },
        mounted() {
            this.$refs.slider.setAttribute("min", 100000);
            this.$refs.slider.setAttribute("max", 10000000);
            this.priceInputValue = this.value;
            this.handleSliderValuePosition(this.$refs.slider);
        },
        computed: {
            ...mapState(["$lang"]),

        }
    }
</script>

<style scoped lang="scss">
    $track-color: #fafafa !default;
    $thumb-color: #50CAB6 !default;

    $thumb-radius: 12px !default;
    $thumb-height: 24px !default;
    $thumb-width: 24px !default;
    $thumb-shadow-size: 4px !default;
    $thumb-shadow-blur: 4px !default;
    $thumb-shadow-color: rgba(0, 0, 0, .2) !default;
    $thumb-border-width: 2px !default;
    $thumb-border-color: #eee !default;

    $track-width: 100% !default;
    $track-height: 8px !default;
    $track-shadow-size: 0px !default;
    $track-shadow-blur: 0px !default;
    $track-shadow-color: rgba(0, 0, 0, .2) !default;
    $track-border-width: 1px !default;
    $track-border-color: #cfd8dc !default;

    $track-radius: 5px !default;
    $contrast: 5% !default;

    @mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
        box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color, 0 0 $shadow-size lighten($shadow-color, 5%);
    }

    .pricing-slider-value {
        position: relative
    }

    @mixin track {
        cursor: pointer;
        height: $track-height;
        transition: all .2s ease;
        width: $track-width;
    }

    @mixin thumb {
        @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
        background: $thumb-color;
        border: $thumb-border-width solid $thumb-border-color;
        border-radius: $thumb-radius;
        cursor: pointer;
        height: $thumb-height;
        width: $thumb-width;
    }

    [type='range'] {
        -webkit-appearance: none;
        margin: $thumb-height / 2 0;
        width: $track-width;

        &:focus {
            outline: 0;

            &::-webkit-slider-runnable-track {
                background: lighten($track-color, $contrast);
            }

            &::-ms-fill-lower {
                background: $track-color;
            }

            &::-ms-fill-upper {
                background: lighten($track-color, $contrast);
            }
        }

        &::-webkit-slider-runnable-track {
            @include track;
            @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
            background: $track-color;
            border: $track-border-width solid $track-border-color;
            border-radius: $track-radius;
        }

        &::-webkit-slider-thumb {
            @include thumb;
            -webkit-appearance: none;
            margin-top: ((-$track-border-width * 2 + $track-height) / 2) - ($thumb-height / 2);
        }

        &::-moz-range-track {
            @include track;
            @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
            background: $track-color;
            border: $track-border-width solid $track-border-color;
            border-radius: $track-radius;
        }

        &::-moz-range-thumb {
            @include thumb;
        }

        &::-ms-track {
            @include track;
            background: transparent;
            border-color: transparent;
            border-width: ($thumb-height / 2) 0;
            color: transparent;
        }

        &::-ms-fill-lower {
            @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
            background: darken($track-color, $contrast);
            border: $track-border-width solid $track-border-color;
            border-radius: $track-radius * 2;
        }

        &::-ms-fill-upper {
            @include shadow($track-shadow-size, $track-shadow-blur, $track-shadow-color);
            background: $track-color;
            border: $track-border-width solid $track-border-color;
            border-radius: $track-radius * 2;
        }

        &::-ms-thumb {
            @include thumb;
            margin-top: 0;
        }
    }

    .title {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #666666;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px){
            h3{
                font-size: 15px;
                line-height: 20px;
            }
        }
    }

    .desc {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #666666;
        margin-top: 18px;
        line-height: 30px;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px){
            margin-top: 10px;
        }
    }

    .pricing-slider {
        margin: 21px 0;
        @media only screen and (max-width: 767px){
            margin: 20px 0 0;
        }
    }

    .pricing-slider-value {
        color: #666;
    }


</style>