<template>
    <div class="footer-card">
        <div class="line">
            <div class="user-info-container left">
                <div class="user-info">
                    <div class="user-ava">
                        <img :src="owner.avatar" alt=""/>
                    </div>

                    <div class="user-about">
                        <div class="user-name">
                            <h5>{{ owner.fullname }}</h5>
                        </div>

                        <div class="user-desc text-micro">
                            {{ i18n.userDesc[$lang] }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="user-info-container right">
                <div class="user-stats">
                    <div class="user-stat-item">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stat-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="q2kai3qx7a" maskUnits="userSpaceOnUse" x="4" y="7" width="22" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 7.5c-5 0-9.27 3.11-11 7.5 1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM15 20c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" fill="#fff"/></mask><g mask="url(#q2kai3qx7a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

                        <div class="stat-count text-micro">
                            {{ stats.views }}
                        </div>
                    </div>

                    <div class="user-stat-item">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stat-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="y5gtdvdgta" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M24 9h-2v9H9v2c0 .55.45 1 1 1h11l4 4V10c0-.55-.45-1-1-1zm-4 6V6c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z" fill="#fff"/></mask><g mask="url(#y5gtdvdgta)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

                        <div class="stat-count text-micro">
                            {{ stats.comments }}
                        </div>
                    </div>
                </div>

                <div class="go-up" @click="handleGoUpButtonClick">
                    <div class="go-up-label">
                        {{ i18n.goUp[$lang] }}
                    </div>

                    <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click="scrollToTop" v-bind:class="'go-up-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="lhle9e4mza" maskUnits="userSpaceOnUse" x="6" y="6" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.533 14l1.316 1.316 5.217-5.208v11.358h1.867V10.108l5.208 5.217L21.467 14 14 6.533 6.533 14z" fill="#fff"/></mask><g mask="url(#lhle9e4mza)"><path fill="#D8D8D8" d="M1.866 1.867h24.267v24.267H1.866z"/></g></svg>
                </div>
            </div>
        </div>
        <div class="line tags-share">
            <div class="tags">
                <div class="tag-item" v-for="tag in tags">
                    {{ tag }}
                </div>
            </div>
            <div class="share">
                <div class="share-label">
                    {{ i18n.share[$lang] }}:
                </div>

                <div class="share-items-wrapper">
                    <div class="share-item">
                        <svg width="31" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'share-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="r6s9403q3a" maskUnits="userSpaceOnUse" x="11" y="6" width="10" height="18"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.025 24v-8.21h2.657l.395-3.199h-3.052v-2.044c0-.925.248-1.557 1.526-1.557h1.633V6.125C19.903 6.083 18.932 6 17.808 6c-2.355 0-3.961 1.495-3.961 4.234v2.364h-2.663v3.198h2.663V24h3.178z" fill="#fff"/></mask><g mask="url(#r6s9403q3a)"><path fill="#D8D8D8" d="M2.184 2h26v26h-26z"/></g></svg>

                        <div class="share-counter">
                            45
                        </div>
                    </div>
                    <div class="share-item">
                        <svg width="31" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'share-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="l1uk32uf1a" maskUnits="userSpaceOnUse" x="6" y="8" width="19" height="15"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.689 9.775a7.19 7.19 0 01-2.122.596 3.786 3.786 0 001.625-2.094 7.286 7.286 0 01-2.345.918A3.65 3.65 0 0019.15 8c-2.04 0-3.692 1.695-3.692 3.786 0 .297.032.587.095.863-3.07-.158-5.79-1.665-7.612-3.956a3.85 3.85 0 00-.5 1.904c0 1.314.652 2.472 1.643 3.152a3.613 3.613 0 01-1.673-.474v.048c0 1.835 1.273 3.366 2.962 3.713a3.629 3.629 0 01-1.667.066c.47 1.503 1.834 2.598 3.45 2.63A7.293 7.293 0 016.687 21.3 10.273 10.273 0 0012.35 23c6.792 0 10.508-5.77 10.508-10.774 0-.164-.005-.328-.012-.49a7.602 7.602 0 001.844-1.96z" fill="#fff"/></mask><g mask="url(#l1uk32uf1a)"><path fill="#D8D8D8" d="M2.688 2h26v26h-26z"/></g></svg>

                        <div class="share-counter">
                            12
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'FooterCard',
        data() {
            return {
                i18n: {
                    userDesc: {
                        en: 'Author',
                        ru: 'Автор'
                    },
                    goUp: {
                        en: 'Go up',
                        ru: 'Наверх'
                    },
                    drones: {
                        en: 'Drones',
                        ru: 'Дроны'
                    },
                    jobs: {
                        en: 'Jobs',
                        ru: 'Роботы'
                    },
                    culture: {
                        en: 'Culture',
                        ru: 'Культура'
                    },
                    share: {
                        en: 'Share',
                        ru: 'Поделиться'
                    },

                },
            }
        },
        methods: {
            handleGoUpButtonClick() {
                window.scrollTo({top: 0, behavior: 'smooth'});
            },
            scrollToTop(){
                window.scroll(0,0);
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
                owner: (state) => state.projects.project.owner,
                stats: (state) => state.projects.project.stats,
                tags: (state) => state.projects.project.tags,
            }),
        }
    };
</script>

<style scoped lang="scss">
    .footer-card {
        width: 100%;
        // padding: 0 65px;
        border-top: 2px #F5F5F5 solid;
        margin-top: 120px;
        @media only screen and (max-width: 767px){
            margin-top: 60px;
            border-top: none;
        }
    }

    .line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 120px;
        width: 100%;
        padding: 0 65px;
        border-top: 2px #F5F5F5 solid;

        &:nth-child(1) {
            border-top: 0;
        }
        @media only screen and (max-width: 767px){
            padding: 0;
            flex-wrap: wrap;
            height: auto;
            &.tags-share{
                display: none;
            }
        }
    }

    .user-ava {
        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
        }
    }

    .user-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .user-about {
        margin-left: 25px;
    }

    .user-name {
        color: #666666;
    }

    .user-desc {
        color: #B3B3B3;
    }

    .user-stats {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .user-stat-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 45px;

        &:nth-child(1) {
            margin-left: 0;
        }
    }

    .stat-icon {
        width: 26px;
    }

    .stat-count {
        color: #B3B3B3;
        margin-left: 17px;
    }

    .go-up {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
    }

    .go-up-label {
        font-style: normal;
        font-weight: 900;
        font-size: 12px;
        line-height: 50px;
        text-align: right;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #B3B3B3;
    }

    .go-up-icon {
        width: 24px;
        margin-left: 12px;
    }

    .tags {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media only screen and (max-width: 767px){
            flex-wrap: wrap;
            width: 100%;
            padding: 20px;
            padding-bottom: 10px;
        }
    }

    .tag-item {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #B3B3B3;
        padding: 0 23px;
        background: #F7F7F7;
        border-radius: 4px;
        margin-left: 10px;

        &:nth-child(1) {
            margin-left: 0;
        }
        @media only screen and (max-width: 767px){
            margin-left: 0;
            margin-right: 10px;
            margin-bottom: 10px;
            &:last-child{
                margin-right: 0;
            }
        }
    }

    .share {
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 767px){
            width: 100%;
            padding: 20px;
            border-top: 2px solid #F5F5F5;
        }
    }

    .share-items-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 22px;
    }

    .share-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 22px;

        &:nth-child(1) {
            margin-left: 0;
        }
    }

    .share-label {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;
        color: #999999;
    }

    .share-icon {
        width: 26px;
    }

    .share-counter {
        position: relative;
    }

    .share-counter {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        color: #B3B3B3;
        margin-left: 12px;
        line-height: 30px;
        border: 2px solid #F5F5F5;
        border-radius: 4px;
        padding: 0 10px;
        background: #fff;

        &:before {
            content: '';
            position: absolute;
            z-index: 1;
            left: -7px;
            top: 0;
            bottom: 0;
            margin: auto;
            border-left: 2px solid #F5F5F5;
            border-bottom: 2px solid #F5F5F5;
            height: 10px;
            width: 10px;
            transform: rotate(45deg);
            background: #fff;
        }
    }
    .user-info-container{
        &.right{
            max-width: 57%;
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        @media only screen and (max-width: 767px){
            border-top: 2px solid #F5F5F5;
            width: 100%;
            padding: 20px;
            &.right{
                max-width: 100%;
            }
        }
    }
</style>
