<template>
    <div class="view-new-project-step">
        <Header class="header" page="create-a-project"/>

<!--        <BurgerMenu/>-->

        <div class="step-main">
            <div class="step-nav">
                <StepNavigation :active-step="3"/>
            </div>

            <div class="step-content-wrapper">
                <div class="step-content">
                    <!--<div class="step-content-nav">
                       <div
                            v-for="(navItemCur, index) in navItems[$lang]"
                            :key="index"
                            class="step-content-nav-item"
                            :class="{'is-active': index === navItemActiveIndex}"
                            @click="handleNavItemClick(index)"
                        >
                            {{ navItemCur }}
                        </div>
                    </div>-->

                    <UploadCost/>
                    <!--<div class="slider">
                        <VueSlickCarousel ref="carousel" v-bind="slickCarouselConfig" class="slides-wrapper">
                            <div class="slide">
                                <UploadCost/>
                            </div>

                            <div class="slide">
                                <HelpWithCost/>
                            </div>
                        </VueSlickCarousel>
                    </div>-->
                </div>

                <div class="step-buttons">
                    <Button
                            class="choose-file-button btn-back"
                            @click="handleButtonBackClick()"
                    >
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'choose-file-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="xjl54wi9ia" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M23 14H10.83l5.59-5.59L15 7l-8 8 8 8 1.41-1.41L10.83 16H23v-2z" fill="#fff"/></mask><g mask="url(#xjl54wi9ia)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

                        <span class="choose-file-button-label">
                            {{ i18n.presentation[$lang] }}
                        </span>
                    </Button>

                    <Button class="choose-file-button primary"
                            :loading="finmodelStoreStatus===1"
                            @click="handleButtonNextClick()"
                    >
                        <span class="choose-file-button-label">
                            {{ i18n.confirmation[$lang] }}
                        </span>

                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'choose-file-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="5d8pyh3pja" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 7l-1.41 1.41L19.17 14H7v2h12.17l-5.58 5.59L15 23l8-8-8-8z" fill="#fff"/></mask><g mask="url(#5d8pyh3pja)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                    </Button>
                </div>
            </div>
        </div>

        <Footer class="footer"/>
    </div>
</template>

<script>
    import Header from '@/components/Header';
    import BurgerMenu from '@/components/BurgerMenu';
    import Footer from '@/components/Footer';
    import Button from '@/components/Button';
    import StepNavigation from '@/components/StepNavigation';
    import HelpWithCost from './components/HelpWithCost';
    import UploadCost from './components/UploadCost';
    import VueSlickCarousel from 'vue-slick-carousel';
    import {mapState} from 'vuex';
    import {mapActions} from 'vuex';

    export default {
        name: 'NewProjectStep',
        components: {
            Header,
            BurgerMenu,
            Footer,
            StepNavigation,
            Button,
            HelpWithCost,
            UploadCost,
            VueSlickCarousel
        },
        data() {
            return {
                navItems: {
                    en: [
                        'Upload cost sctructure',
                        'Need help with cost structure'
                    ],
                    ru: [
                        'Загрузить расчет',
                        'Нужна помощь с расчетом'
                    ],
                },
                navItemActiveIndex: 0,
                slickCarouselConfig: {
                    arrows: false,
                    dots: false,
                    slidesPerRow: 1,
                    swipe: false,
                    draggable: false,
                    adaptiveHeight: true,
                    infinite: false
                },
                i18n: {
                    presentation: {
                        en: 'Presentation',
                        ru: 'Презентация'
                    },
                    confirmation: {
                        en: 'Confirmation',
                        ru: 'Подтверждение'
                    },
                },
            };
        },
        methods: {
            ...mapActions('projectCreate', ['storeFinmodel']),

            handleButtonBackClick() {
                this.$router.push('new-project-step-2');
            },
            handleButtonNextClick() {
                this.storeFinmodel()
                    .then(() => {
                        this.$router.push('new-project-step-4');
                    })
            },
            handleNavItemClick(index) {
                this.navItemActiveIndex = index;

                this.$refs.carousel.goTo(index);
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
            ...mapState('projectCreate', [
                'finmodelStoreStatus'
            ])
        },
    };
</script>

<style scoped lang="scss">
    .step-main {
        padding: 120px 165px 0 165px;
        @media only screen and (max-width: 767px){
            padding: 70px 0 0;
        }
    }

    .step-content-wrapper {
        background: #fff;
        box-shadow: 0 10px 30px -2px #0000000d;
        border-radius: 6px;
    }

    .step-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-top: 30px;
        @media only screen and (max-width: 767px){
            margin-top: 10px;
        }
    }

    .step-buttons {
        display: flex;
        position: relative;
        justify-content: center;
        padding: 48px 0 48px 0;

        .choose-file-button {
            padding: 8px 25px;

            &:nth-child(1) {
                margin-right: 30px;
            }
        }
        @media only screen and (max-width: 767px){
            padding: 18px 20px;
            justify-content: space-between;
            .btn-back{
                span{
                    display: none;
                }
            }
            .choose-file-button{
                padding: 5px 6px !important;
                svg{
                    position: relative;
                    width: 24px;
                    height: 24px;
                    top: -3px;
                    left: -3px;
                }
                &.primary{
                    min-width: 183px;
                }
            }
        }
    }

    .inputs-wrapper,
    .selects-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 35px;
    }

    .input-wrapper,
    .select-wrapper {
        margin-left: 33px;

        &:nth-child(1) {
            margin-left: 0;
        }
    }

    .label {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #666666;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .input {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 2px solid #F5F5F5;
        border-radius: 4px;
        margin-top: 21px;
        width: 250px;
        height: 50px;
        padding: 0 15px;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #666666;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px){
            border-color: #EBEBEB;
        }
    }

    .step-buttons::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-top: 2px #F0F0F0 solid;
        @media only screen and (max-width: 767px){
            border-top-color: #EBEBEB;
        }
    }

    .input-sctructure {
        display: none;
    }

    .label-radio-span {
        background: #FFFFFF;
        border: 2px solid #F0F0F0;
        box-sizing: border-box;
        border-radius: 4px;
        width: 255px;
        height: 90px;
        display: inline-block;
        cursor: pointer;

        .title {
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 15px;
            color: #808080;

            @media only screen and (max-width: 1030px) {
                font-size: 12px;
            }
            @media only screen and (max-width: 767px){
                font-size: 11px;
                line-height: 11px;
                font-weight: 600;
                color: #4D4D4D;
                h3{
                    font-size: 15px;
                    line-height: 20px;
                    color: #4D4D4D;
                }
            }
        }

        .text {
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 15px;
            color: #808080;

            @media only screen and (max-width: 1030px) {
                font-size: 11px;
            }
        }

        .price {
            font-family: IBM Plex Sans;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 90px;
            color: #808080;

            @media only screen and (max-width: 1030px) {
                font-size: 18px;
            }
        }
    }

    .step-content-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 88px;
        width: 100%;
        border-bottom: 2px #F0F0F0 solid;
    }

    .step-content-nav-item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        height: 100%;
        color: #B3B3B3;
        margin-left: 60px;
        cursor: pointer;

        &:nth-child(1) {
            margin-left: 0;
        }

        &.is-active {
            font-weight: bold;
            color: #50CAB6;

            &:after {
                content: '';
                position: absolute;
                z-index: 1;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 110%;
                height: 2px;
                background: #50CAB6;
            }
        }

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .slider {
        width: 100%;
    }

    .input-sctructure:checked + .label-radio-span {

        border: 2px solid #50CAB6;

        .title {
            color: #50cab6;
        }

        .text {
            color: #50cab6;
        }

        .price {
            color: #50cab6;
        }

    }
</style>