<template>
    <div class="view-profile-home">
        <Header class="header"/>

<!--        <BurgerMenu/>-->
        <div class="slider-wrapper">
            <Paragraph
                    id="ending-soon-title"
                    :slick-carousel-config="slickSliderConfigDefault"
                    :title="paragraphs[3].title"
                    slider-variant="CardVariant1"
                    class="paragraph"
                    filter="recommends"
            />
        </div>
        <div class="paragraph-content">
            <div class="paragraphs-wrapper news">
                <!--<Paragraph
                    :paragraph="paragraphNews"
                    :slick-carousel-config="slickSliderConfigNews"
                    slider-variant="SlideVariant2"
                    class="paragraph"
                />-->
                <ParagraphNews
                    :paragraph="paragraphNews"
                    :slick-carousel-config="slickSliderConfigNews"
                    slider-variant="SlideVariant2"
                    class="paragraph"
                />
            </div>

            <div class="paragraphs-wrapper regular">
                <Paragraph
                    id="ending-soon-title"
                    :slick-carousel-config="slickSliderConfigDefault"
                    :title="paragraphs[0].title"
                    slider-variant="CardVariant1"
                    class="paragraph"
                    filter="end"
                />

                <!--<ShowMore
                    class="show-more"
                    @click="showMoreParagraphsClick"
                />-->

                <Paragraph
                    id="most-discussed-title"
                    :title="paragraphs[1].title"
                    :slick-carousel-config="slickSliderConfigDefault"
                    slider-variant="CardVariant1"
                    class="paragraph"
                    filter="most"
                />

                <Paragraph
                    id="new-projects-title"
                    :title="paragraphs[2].title"
                    :slick-carousel-config="slickSliderConfigDefault"
                    slider-variant="CardVariant1"
                    class="paragraph"
                    filter="new"
                />

            </div>
        </div>

        <Subscribe/>
        <Footer class="footer"/>
    </div>
</template>

<script>
    import Header from '@/components/Header';
    import Footer from '@/components/Footer';
    import BurgerMenu from '@/components/BurgerMenu';
    import Paragraph from './components/Paragraph';
    import ShowMore from '@/components/ShowMore';
    import Button from '@/components/Button';
    import Subscribe from './components/Subscribe';
    import ParagraphNews from './components/ParagraphNews';
    import scrollByNewItems from '@/utils/scrollByNewItems.js';
    import getRandomItem from '@/utils/getRandomItem.js';
    import prepareSvgElements from '@/mixins/prepareSvgElements.js';
    import {mapGetters, mapState} from 'vuex';
    import CardVariant2 from "@/components/CardVariant2"
    import VueSlickCarousel from 'vue-slick-carousel';


    export default {
        name: 'Home',
        components: {
            ShowMore,
            Paragraph,
            Header,
            Footer,
            BurgerMenu,
            Button,
            Subscribe,
            ParagraphNews,
            CardVariant2,
            VueSlickCarousel,
        },
        mixins: [prepareSvgElements],
        data() {
            return {
                sliderButtonConfig: {
                    withoutBorder: true
                },
                slickSliderConfigDefault: this.getSlickSliderConfig(),
                slickSliderConfigNews: {
                    ...this.getSlickSliderConfig(),
                    slidesPerRow: 4,
                },
                slickSliderConfigSlide: {
                    ...this.getSlickSliderConfig(),
                    slidesPerRow: 1,
                },
                titleSliderFirst: {
                    en: 'Recomended',
                    ru: 'Рекомендации'
                },
                slidesFirst: [
                    {
                        title: {
                            en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                            ru: 'Дом, позволяющий выращивать Эко продукты и зарабатывать, проживая на земле'
                        },
                        text: {
                            en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                            ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                        },
                        imgSrc: '/img/view-profile-home/img.png',
                        tags: [
                            'Литература',
                            'Культура'
                        ],
                        stats: {
                            views: 275,
                            comments: 12,
                            likes: 57,
                            remaining: 7,
                            progress: 30
                        }
                    },
                    {
                        title: {
                            en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                            ru: 'Трудоустройство в крестьянском хозяйстве «Белые росы»'
                        },
                        text: {
                            en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                            ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                        },
                        imgSrc: '/img/view-profile-home/img.png',
                        tags: [
                            'Литература',
                            'Культура'
                        ],
                        stats: {
                            views: 112,
                            comments: 118,
                            likes: 10,
                            remaining: 5,
                            progress: 66
                        }
                    },
                    {
                        title: {
                            en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                            ru: 'Где искать золото. Практическое руководство для новичка'
                        },
                        text: {
                            en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                            ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                        },
                        imgSrc: '/img/view-profile-home/img.png',
                        tags: [
                            'Литература',
                            'Культура'
                        ],
                        stats: {
                            views: 111,
                            comments: 34,
                            likes: 78,
                            remaining: 3,
                            progress: 15
                        }
                    },
                    {
                        title: {
                            en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                            ru: 'Дом, позволяющий выращивать Эко продукты и зарабатывать, проживая на земле'
                        },
                        text: {
                            en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                            ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                        },
                        imgSrc: '/img/view-profile-home/img.png',
                        tags: [
                            'Литература',
                            'Культура'
                        ],
                        stats: {
                            views: 57,
                            comments: 113,
                            likes: 88,
                            remaining: 100,
                            progress: 99
                        }
                    },
                ],
                paragraphs: [
                    {
                        title: {
                            en: 'Ending soon',
                            ru: 'Скоро завершатся'
                        },
                        slides: [
                            {
                                title: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Где искать золото. Практическое руководство для новичка'
                                },
                                text: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                                },
                                imgSrc: '/img/view-home/img.png',
                                button: {
                                    en: 'promote',
                                    ru: 'Поддержать'
                                },
                                tags: [
                                    'Литература',
                                    'Культура'
                                ],
                                stats: {
                                    views: 275,
                                    comments: 12,
                                },
                                progress: {
                                    support: 57,
                                    remaining: 7,
                                    percent: 30
                                }
                            },
                            {
                                title: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Трудоустройство в крестьянском хозяйстве «Белые росы»'
                                },
                                text: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                                },
                                imgSrc: '/img/view-home/img2.png',
                                button: {
                                    en: 'promote',
                                    ru: 'Поддержать'
                                },
                                tags: [
                                    'Литература',
                                    'Культура'
                                ],
                                stats: {
                                    views: 112,
                                    comments: 118,
                                },
                                progress: {
                                    support: 10,
                                    remaining: 5,
                                    percent: 66,
                                }
                            },
                            {
                                title: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Дом, позволяющий выращивать Эко продукты и зарабатывать, проживая на земле'
                                },
                                text: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                                },
                                imgSrc: '/img/view-home/img3.png',
                                button: {
                                    en: 'promote',
                                    ru: 'Поддержать'
                                },
                                tags: [
                                    'Литература',
                                    'Культура'
                                ],
                                stats: {
                                    views: 111,
                                    comments: 34,
                                },
                                progress: {
                                    support: 78,
                                    remaining: 3,
                                    percent: 15,
                                }
                            },
                            {
                                title: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Чемпионат мира по фридайвингу для сборной команды России'
                                },
                                text: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                                },
                                imgSrc: '/img/view-home/img4.png',
                                button: {
                                    en: 'promote',
                                    ru: 'Поддержать'
                                },
                                tags: [
                                    'Литература',
                                    'Культура'
                                ],
                                stats: {
                                    views: 57,
                                    comments: 113,
                                },
                                progress: {
                                    support: 88,
                                    remaining: 100,
                                    percent: 99,
                                }
                            }
                        ]
                    },
                    {
                        title: {
                            en: 'Most Discoussed',
                            ru: 'Самые обсужаемые'
                        },
                        slides: [
                            {
                                title: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Дом, позволяющий выращивать Эко продукты и зарабатывать, проживая на земле'
                                },
                                text: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                                },
                                imgSrc: '/img/view-home/img3.png',
                                button: {
                                    en: 'promote',
                                    ru: 'Поддержать'
                                },
                                tags: [
                                    'Литература',
                                    'Культура'
                                ],
                                stats: {
                                    views: 111,
                                    comments: 34,
                                },
                                progress: {
                                    support: 78,
                                    remaining: 3,
                                    percent: 15,
                                }
                            },
                            {
                                title: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Чемпионат мира по фридайвингу для сборной команды России'
                                },
                                text: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                                },
                                imgSrc: '/img/view-home/img4.png',
                                button: {
                                    en: 'promote',
                                    ru: 'Поддержать'
                                },
                                tags: [
                                    'Литература',
                                    'Культура'
                                ],
                                stats: {
                                    views: 57,
                                    comments: 113,
                                },
                                progress: {
                                    support: 88,
                                    remaining: 100,
                                    percent: 99,
                                }
                            },
                            {
                                title: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Где искать золото. Практическое руководство для новичка'
                                },
                                text: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                                },
                                imgSrc: '/img/view-home/img5.png',
                                button: {
                                    en: 'promote',
                                    ru: 'Поддержать'
                                },
                                tags: [
                                    'Литература',
                                    'Культура'
                                ],
                                stats: {
                                    views: 111,
                                    comments: 34,
                                },
                                progress: {
                                    support: 78,
                                    remaining: 3,
                                    percent: 15,
                                }
                            },
                            {
                                title: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Где искать золото. Практическое руководство для новичка'
                                },
                                text: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                                },
                                imgSrc: '/img/view-home/img6.png',
                                button: {
                                    en: 'promote',
                                    ru: 'Поддержать'
                                },
                                tags: [
                                    'Литература',
                                    'Культура'
                                ],
                                stats: {
                                    views: 57,
                                    comments: 113,
                                },
                                progress: {
                                    support: 88,
                                    remaining: 100,
                                    percent: 99,
                                }
                            }
                        ]
                    },
                    {
                        title: {
                            en: 'New projects',
                            ru: 'Новые проекты'
                        },
                        slides: [
                            {
                                title: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Где искать золото. Практическое руководство для новичка'
                                },
                                text: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                                },
                                imgSrc: '/img/view-home/img5.png',
                                button: {
                                    en: 'promote',
                                    ru: 'Поддержать'
                                },
                                tags: [
                                    'Литература',
                                    'Культура'
                                ],
                                stats: {
                                    views: 111,
                                    comments: 34,
                                },
                                progress: {
                                    support: 78,
                                    remaining: 3,
                                    percent: 15,
                                }
                            },
                            {
                                title: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Где искать золото. Практическое руководство для новичка'
                                },
                                text: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                                },
                                imgSrc: '/img/view-home/img6.png',
                                button: {
                                    en: 'promote',
                                    ru: 'Поддержать'
                                },
                                tags: [
                                    'Литература',
                                    'Культура'
                                ],
                                stats: {
                                    views: 57,
                                    comments: 113,
                                },
                                progress: {
                                    support: 88,
                                    remaining: 100,
                                    percent: 99,
                                }
                            },
                            {
                                title: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Где искать золото. Практическое руководство для новичка'
                                },
                                text: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                                },
                                imgSrc: '/img/view-home/img.png',
                                button: {
                                    en: 'promote',
                                    ru: 'Поддержать'
                                },
                                tags: [
                                    'Литература',
                                    'Культура'
                                ],
                                stats: {
                                    views: 275,
                                    comments: 12,
                                },
                                progress: {
                                    support: 57,
                                    remaining: 7,
                                    percent: 30
                                }
                            },
                            {
                                title: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Трудоустройство в крестьянском хозяйстве «Белые росы»'
                                },
                                text: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                                },
                                imgSrc: '/img/view-home/img2.png',
                                button: {
                                    en: 'promote',
                                    ru: 'Поддержать'
                                },
                                tags: [
                                    'Литература',
                                    'Культура'
                                ],
                                stats: {
                                    views: 112,
                                    comments: 118,
                                },
                                progress: {
                                    support: 10,
                                    remaining: 5,
                                    percent: 66,
                                }
                            },
                        ]
                    },
                    {
                        title: {
                            en: 'Recommends',
                            ru: 'Рекоммендации'
                        },
                        slides: [
                            {
                                title: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Где искать золото. Практическое руководство для новичка'
                                },
                                text: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                                },
                                imgSrc: '/img/view-home/img5.png',
                                button: {
                                    en: 'promote',
                                    ru: 'Поддержать'
                                },
                                tags: [
                                    'Литература',
                                    'Культура'
                                ],
                                stats: {
                                    views: 111,
                                    comments: 34,
                                },
                                progress: {
                                    support: 78,
                                    remaining: 3,
                                    percent: 15,
                                }
                            },
                            {
                                title: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Где искать золото. Практическое руководство для новичка'
                                },
                                text: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                                },
                                imgSrc: '/img/view-home/img6.png',
                                button: {
                                    en: 'promote',
                                    ru: 'Поддержать'
                                },
                                tags: [
                                    'Литература',
                                    'Культура'
                                ],
                                stats: {
                                    views: 57,
                                    comments: 113,
                                },
                                progress: {
                                    support: 88,
                                    remaining: 100,
                                    percent: 99,
                                }
                            },
                            {
                                title: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Где искать золото. Практическое руководство для новичка'
                                },
                                text: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                                },
                                imgSrc: '/img/view-home/img.png',
                                button: {
                                    en: 'promote',
                                    ru: 'Поддержать'
                                },
                                tags: [
                                    'Литература',
                                    'Культура'
                                ],
                                stats: {
                                    views: 275,
                                    comments: 12,
                                },
                                progress: {
                                    support: 57,
                                    remaining: 7,
                                    percent: 30
                                }
                            },
                            {
                                title: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Трудоустройство в крестьянском хозяйстве «Белые росы»'
                                },
                                text: {
                                    en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                                    ru: 'Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё'
                                },
                                imgSrc: '/img/view-home/img2.png',
                                button: {
                                    en: 'promote',
                                    ru: 'Поддержать'
                                },
                                tags: [
                                    'Литература',
                                    'Культура'
                                ],
                                stats: {
                                    views: 112,
                                    comments: 118,
                                },
                                progress: {
                                    support: 10,
                                    remaining: 5,
                                    percent: 66,
                                }
                            },
                        ]
                    },
                ],
                paragraphNews: {
                    title: {
                        en: 'Grossfort news',
                        ru: 'Новости Grossfort'
                    },
                    slides: [
                        {
                            title: 'Маркетинговая активность ускоряет сублимированный стиль менеджмента',
                            imgSrc: '/img/view-home/news-img1.png',
                            stats: {
                                date: {
                                    en: '2 days ago',
                                    ru: '2 дня назад'
                                },
                                views: {
                                    en: '275 views',
                                    ru: '275 просмотров'
                                },
                            }
                        },
                        {
                            title: 'Дивидендная доходность по российскому фондовому рынку на уровне 7-8%',
                            imgSrc: '/img/view-home/news-img2.png',
                            stats: {
                                date: {
                                    en: '2 days ago',
                                    ru: '2 дня назад'
                                },
                                views: {
                                    en: '390 views',
                                    ru: '390 просмотров'
                                },
                            }
                        },
                        {
                            title: 'Инвестиции в малый бизнес, доходность выше, чем по вкладам',
                            imgSrc: '/img/view-home/news-img3.png',
                            stats: {
                                date: {
                                    en: 'June 20',
                                    ru: '20 июня'
                                },
                                views: {
                                    en: '390 views',
                                    ru: '390 просмотров'
                                },
                            }
                        },
                        {
                            title: 'Трамп потребовал немедленно начать процесс по импичменту в сенате',
                            imgSrc: '/img/view-home/news-img4.png',
                            stats: {
                                date: {
                                    en: 'May 1',
                                    ru: '1 мая'
                                },
                                views: {
                                    en: '390 views',
                                    ru: '390 просмотров'
                                },
                            }
                        },
                        {
                            title: 'Маркетинговая активность ускоряет сублимированный стиль менеджмента',
                            imgSrc: '/img/view-home/news-img1.png',
                            stats: {
                                date: {
                                    en: '2 days ago',
                                    ru: '2 дня назад'
                                },
                                views: {
                                    en: '275 views',
                                    ru: '275 просмотров'
                                },
                            }
                        },
                        {
                            title: 'Дивидендная доходность по российскому фондовому рынку на уровне 7-8%',
                            imgSrc: '/img/view-home/news-img2.png',
                            stats: {
                                date: {
                                    en: '2 days ago',
                                    ru: '2 дня назад'
                                },
                                views: {
                                    en: '390 views',
                                    ru: '390 просмотров'
                                }
                            }
                        },
                        {
                            title: 'Инвестиции в малый бизнес, доходность выше, чем по вкладам',
                            imgSrc: '/img/view-home/news-img3.png',
                            stats: {
                                date: {
                                    en: 'June 20',
                                    ru: '20 июня'
                                },
                                views: {
                                    en: '390 views',
                                    ru: '390 просмотров'
                                },
                            }
                        },
                        {
                            title: 'Трамп потребовал немедленно начать процесс по импичменту в сенате',
                            imgSrc: '/img/view-home/news-img4.png',
                            stats: {
                                date: {
                                    en: 'May 1',
                                    ru: '1 мая'
                                },
                                views: {
                                    en: '390 views',
                                    ru: '390 просмотров'
                                },
                            }
                        },
                    ]
                },
            };
        },
        methods: {
            getSlickSliderConfig() {
                return {
                    arrows: false,
                    dots: false,
                    slidesPerRow: 2,
                    swipe: false,
                    draggable: false,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                swipe: true,
                                draggable: true,
                                slidesPerRow: 1,
                                // autoplay: true,
                                // autoplaySpeed: 3000,
                            }
                        },
                    ]
                }
            },
            async showMoreParagraphsClick() {
                const newParagraph = getRandomItem(this.paragraphs);

                if (this.paragraphs.length <= 5) {
                    this.paragraphs.push(newParagraph);

                    await this.$nextTick();

                    scrollByNewItems('.paragraphs-wrapper.regular', '.paragraph');
                }
            },
            handleSliderPrevBtnClick() {
                this.$refs.carousel.prev();
            },
            handleSliderNextBtnClick() {
                this.$refs.carousel.next();
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
            ...mapGetters('projects', [
                'getProjects',
                "getProjectsFilteredByEnddate",
                "getProjectsFilteredByComments",
                "getProjectsFilteredByCreatedAt",
                "getProjectsFilteredByRecommended"
            ])
        },
        mounted(){}
    };
</script>

<style scoped lang="scss">
    @import "@/sass/mixins.scss";

    .paragraphs-wrapper {
        background: #FAFAFA;
        // padding-top: 82px;
        padding-top: 1.5%;

        &.regular {
            padding-bottom: 65px;
            // padding-left: 40px;
            // padding-right: 40px
        }

        &.news {
            padding-bottom: 60px;
            // padding-left: 40px;
            // padding-right: 10px;
        }
    }

    .paragraph {
        // margin-top: 112px;
        margin-top: 20px;

        &:nth-child(1) {
            margin-top: 0;
        }
    }

    .home-slide2 {
        padding: 12% 165px 6.8% !important;
    }

    .home-slide3 {
        padding: 8.5% 165px 10.1% !important;
    }

    .show-more {
        margin-top: 35px;
    }

    .button {
        height: 60px;
        padding: 0 65px;
        margin-left: 15px;

        &:nth-child(1) {
            margin-left: 0;
        }

        &.how-it-works {
            padding: 0 30px;

            &:hover {
                opacity: 1;
                background-color: transparent !important;
                border-color: transparent !important;
            }
        }
    }

    .footer {
        margin-top: 0 !important;
    }

    .slider-wrapper {
        margin: 180px 0 0 0;
        @media only screen and (max-width: 767px){
            margin: 120px 0 0 0;
        }
    }

    .title {

        color: #4D4D4D;
        margin-left: 35px;

        @media only screen and (max-width: 1030px) {
            font-size: 26px;
        }
        @media only screen and (max-width: 767px){
            margin-left: 0;
        }
    }

    .slider-first,
    .slider-wrapper-top {
        padding: 0 165px;

        @media only screen and (max-width: 1300px) {
            padding: 0 95px;
        }

        @media only screen and (max-width: 1140px) {
            padding: 0 80px;
        }

    }

    .slider-first{
        @media only screen and (max-width: 767px){
            padding: 0 10px;
        }
    }

    .slider-wrapper-top {
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 767px){
            padding: 0;
            margin: 0 20px;
        }
    }

    .line {
        width: 100%;
        height: 2px;
        background: #F0F0F0;
        margin-top: 26px;
        margin-bottom: 30px;
        @media only screen and (max-width: 767px){
            margin: 20px 20px;
            width: auto;
        }
    }

    .buttons-wrapper {
        display: flex;
        justify-content: flex-end;
        @media only screen and (max-width: 767px){
            display: none;
        }
    }

    .slider-button {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: none;

        &.is-next {
            transform: rotate(180deg);
        }
    }


</style>
