<template>
    <label :for="id + '_button'" :class="{'active': isActive}" class="toggle__button">
        <span v-if="isActive" class="toggle__label">{{ enableText }}</span>
        <span v-if="! isActive" class="toggle__label">{{ disabledText }}</span>

        <input type="checkbox" :disabled="disabled" :id="id + '_button'" v-model="checkedValue">
        <span class="toggle__switch"></span>
    </label>
</template>

<script>
    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            labelEnableText: {
                type: String,
                default: ''
            },

            labelDisableText: {
                type: String,
                default: ''
            },
            id: {
                type: Number,
                default: 'primary'
            },
            defaultState: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                currentState: this.defaultState
            }
        },
        watch: {
            defaultState: function defaultState() {
                this.currentState = Boolean(this.defaultState)
            }
        },
        computed: {
            // currentState() {
            //     return this.defaultState;
            // },
            isActive() {
                return this.currentState;
            },
            enableText() {
                return this.labelEnableText;
            },
            disabledText() {
                return this.labelDisableText;
            },
            checkedValue: {
                get() {
                    return this.currentState;
                },
                set(newValue) {
                    this.currentState = newValue;
                    this.$emit('change', newValue);
                }
            }
        }
    }
</script>

<style scoped>
    .toggle__button {
        vertical-align: middle;
        user-select: none;
        cursor: pointer;
    }
    .toggle__button input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        width: 1px;
        height: 1px;
    }
    .toggle__button .toggle__switch {
        display: inline-block;
        height: 30px;
        border-radius: 100px;
        border: 2px solid #F0F0F0;
        width: 50px;
        background: #fff;
        box-shadow: inset 0 0 1px #BFCBD9;
        position: relative;
        margin-left: 10px;
        transition: all .25s;
        box-sizing: border-box;
    }
    .toggle__button .toggle__switch::after,
    .toggle__button .toggle__switch::before {
        content: "";
        position: absolute;
        display: block;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 0;
        left: calc(50% - 20px / 2 - 10px);
        top: 3px;
        transform: translateX(0);
        transition: all .25s cubic-bezier(.5, -.6, .5, 1.6);
    }
    .toggle__button .toggle__switch::after {
        background: #f0f0f0;
        ///box-shadow: 0 0 1px #666;
    }
    .toggle__button .toggle__switch::before {
        background: #fff;
        box-shadow: 0 0 0 3px rgba(0,0,0,0.1);
        opacity:0;
    }
    .active .toggle__switch {
        background: #50CAB6;
        border-color:#50cab6;
        //box-shadow: inset 0 0 1px #50CAB6;
    }
    .active .toggle__switch::after,
    .active .toggle__switch::before{
        transform:translateX(40px - 18px);
    }
    .active .toggle__switch::after {
        left: 23px;
        background: #FFF;
        //box-shadow: 0 0 1px #FFF;
    }
</style>