<template>
    <div class="view-home-component-paragraph">
        <div class="header">
            <div class="title">
                <h2>{{ title[$lang] }}</h2>
            </div>

            <div class="buttons-wrapper">
                <Button v-bind="sliderButtonConfig" class="slider-button is-prev" @click="handleSliderPrevBtnClick">
                    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="scoo5sieia" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M23 14H10.83l5.59-5.59L15 7l-8 8 8 8 1.41-1.41L10.83 16H23v-2z" fill="#fff"/></mask><g mask="url(#scoo5sieia)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                </Button>

                <Button v-bind="sliderButtonConfig" class="slider-button is-next" @click="handleSliderNextBtnClick">
                    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="rh8x1wh26a" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M23 14H10.83l5.59-5.59L15 7l-8 8 8 8 1.41-1.41L10.83 16H23v-2z" fill="#fff"/></mask><g mask="url(#rh8x1wh26a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                </Button>
            </div>
        </div>

        <div class="line"/>

        <div class="slider-wrapper">
            <div class="slider">
                <VueSlickCarousel v-if="items && items.length > 1" ref="carousel" v-bind="slickCarouselConfig" class="slides-wrapper">
                    <component
                        :is="sliderVariant"
                        v-for="(item, index) in items"
                        :key="index"
                        class="slide"
                        v-bind="item"
                    />
                </VueSlickCarousel>
                <Spinner v-else/>
            </div>
        </div>
    </div>
</template>

<script>
    import api from "@/api/project";
    import Button from '@/components/Button';
    import objectValidator from '@/utils/objectValidator.js';
    import SlideVariant1 from '@/components/SlideVariant1';
    import SlideVariant2 from '@/components/SlideVariant2';
    import CardVariant1 from '@/components/CardVariant1';
    import CardVariant2 from '@/components/CardVariant2';
    import VueSlickCarousel from 'vue-slick-carousel';
    import {mapState} from 'vuex';
    import Spinner from "@/components/Spinner";

    export default {
        name: 'ParagraphNews',
        components: {
            SlideVariant1,
            SlideVariant2,
            VueSlickCarousel,
            CardVariant1,
            CardVariant2,
            Button,
            Spinner
        },
        props: {
            sliderVariant: {
                type: String,
                required: true,
            },
            slickCarouselConfig: {
                type: Object,
                required: true,
            },
            filter: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                title: {
                    en: 'Grossfort news',
                    ru: 'Новости Grossfort'
                },
                items:[
                    {
                        title: 'Маркетинговая активность ускоряет сублимированный стиль менеджмента',
                        imgSrc: require("@/assets/img/gallery-placeholder-1.png"),
                        stats: {
                            date: {
                                en: '2 days ago',
                                ru: '2 дня назад'
                            },
                            views: {
                                en: '275 views',
                                ru: '275 просмотров'
                            },
                        }
                    },
                    {
                        title: 'Дивидендная доходность по российскому фондовому рынку на уровне 7-8%',
                        imgSrc: require("@/assets/img/gallery-placeholder-2.png"),
                        stats: {
                            date: {
                                en: '2 days ago',
                                ru: '2 дня назад'
                            },
                            views: {
                                en: '390 views',
                                ru: '390 просмотров'
                            },
                        }
                    },
                    {
                        title: 'Инвестиции в малый бизнес, доходность выше, чем по вкладам',
                        imgSrc: require("@/assets/img/gallery-placeholder-3.png"),
                        stats: {
                            date: {
                                en: 'June 20',
                                ru: '20 июня'
                            },
                            views: {
                                en: '390 views',
                                ru: '390 просмотров'
                            },
                        }
                    },
                    {
                        title: 'Трамп потребовал немедленно начать процесс по импичменту в сенате',
                        imgSrc: require("@/assets/img/gallery-placeholder-1.png"),
                        stats: {
                            date: {
                                en: 'May 1',
                                ru: '1 мая'
                            },
                            views: {
                                en: '390 views',
                                ru: '390 просмотров'
                            },
                        }
                    },
                    {
                        title: 'Маркетинговая активность ускоряет сублимированный стиль менеджмента',
                        imgSrc: '/img/view-home/news-img1.png',
                        stats: {
                            date: {
                                en: '2 days ago',
                                ru: '2 дня назад'
                            },
                            views: {
                                en: '275 views',
                                ru: '275 просмотров'
                            },
                        }
                    },
                    {
                        title: 'Дивидендная доходность по российскому фондовому рынку на уровне 7-8%',
                        imgSrc: require("@/assets/img/gallery-placeholder-3.png"),
                        stats: {
                            date: {
                                en: '2 days ago',
                                ru: '2 дня назад'
                            },
                            views: {
                                en: '390 views',
                                ru: '390 просмотров'
                            }
                        }
                    },
                    {
                        title: 'Инвестиции в малый бизнес, доходность выше, чем по вкладам',
                        imgSrc: require("@/assets/img/gallery-placeholder-2.png"),
                        stats: {
                            date: {
                                en: 'June 20',
                                ru: '20 июня'
                            },
                            views: {
                                en: '390 views',
                                ru: '390 просмотров'
                            },
                        }
                    },
                    {
                        title: 'Трамп потребовал немедленно начать процесс по импичменту в сенате',
                        imgSrc: require("@/assets/img/gallery-placeholder-1.png"),
                        stats: {
                            date: {
                                en: 'May 1',
                                ru: '1 мая'
                            },
                            views: {
                                en: '390 views',
                                ru: '390 просмотров'
                            },
                        }
                    },
                ],
                page: 1,
                hasMorePages: false,
                isLoading: false,
                sliderButtonConfig: {
                    variant: 'round',
                    withoutBorder: true,
                },
            }
        },
        methods: {
            handleSliderPrevBtnClick() {
                this.$refs.carousel.prev();
            },
            handleSliderNextBtnClick() {
                this.$refs.carousel.next();
            }
        },
        computed: {
            ...mapState(["$lang"]),
        },
        created(){}
    };
</script>

<style scoped lang="scss">
    @import "@/sass/mixins.scss";

    .view-home-component-paragraph {
        position: relative;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding: 0 185px;
        // padding: 0 9.65vw;
        // padding: 0px 8.6vw 0 12.6vw;
        padding: 0 165px;

        @media only screen and (max-width: 1300px) {
            padding: 0 95px;
        }

        @media only screen and (max-width: 1140px) {
            padding: 0 80px;
        }

        @media only screen and (max-width: 1030px) {
            font-size: 26px;
        }
        @media only screen and (max-width: 767px){
            padding: 0 20px;
        }
    }


    .title {
        font-family: Raleway;
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 40px;
        color: #4D4D4D;
        margin-left: 35px;
        @media only screen and (max-width: 767px){
            margin-left: 0;
            font-size: 18px;
            line-height: 24px;
        }

    }

    .line {
        width: 100%;
        height: 2px;
        background: #F0F0F0;
        margin-top: 26px;
        margin-bottom: 30px;
        @media only screen and (max-width: 767px){
            margin: 20px 20px;
            width: auto;
        }
    }

    .slides-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 165px;
        // padding: 0 165px;
        // padding: 0 11.5vw;

        @media only screen and (max-width: 1360px) {
            padding: 0 125px;
        }

        @media only screen and (max-width: 1245px) {
            padding: 0 105px;
        }

        @media only screen and (max-width: 1205px) {
            padding: 0 95px;
        }

        @media only screen and (max-width: 1140px) {
            padding: 0 80px;
        }

        // @media only screen and (max-width: 1440px) {
        //   &:nth-child(1) {
        //      padding: 0px 8.5vw;
        //   }
        // }

        // @media only screen and (max-width: 1170px) {
        //   &:nth-child(1) {
        //      padding: 0px 6vw;
        //   }
        // }

        // @media only screen and (max-width: 1060px) {
        //   &:nth-child(1) {
        //      padding: 0px 5vw;
        //   }
        // }
        @media only screen and (max-width: 767px){
            padding: 0 10px;
        }
    }

    .slide {
        margin-left: 30px;

        &:nth-child(1) {
            margin-left: 0;
        }
        @media only screen and (max-width: 767px){
            max-width: 100%;
            margin: 0 !important;
            &:nth-child(1) {
                margin-left: 0 !important;
            }
            box-shadow: 0 10px 30px -2px rgba(79, 65, 124, 0.0533217);
        }
    }

    .buttons-wrapper {
        display: flex;
        @media only screen and (max-width: 767px){
            display: none;
        }
    }

    .slider-button {
        width: 60px;
        height: 60px;

        &.is-next {
            transform: rotate(180deg);
        }
    }
</style>
