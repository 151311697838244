<template>
    <button type="button"
            class="button fav"
            :class="{
                      active: isFavouriteValue,
                      'variant-small': variant === 'small',
                      'variant-big': variant === 'big',
                      'variant-micro': variant === 'micro',
                    }"
            @click="handleFavButtonClick">

        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="variant==='micro'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="ov91g93bda" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="19"><path fill-rule="evenodd" clip-rule="evenodd" d="M25 12.24l-7.19-.62L15 5l-2.81 6.63-7.19.61 5.46 4.73L8.82 24 15 20.27 21.18 24l-1.63-7.03L25 12.24zM15 18.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L15 9.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L15 18.4z" fill="#fff"/></mask><g mask="url(#ov91g93bda)"><path d="M2 2h26v26H2z"/></g></svg>
        <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="mtbfehwana" maskUnits="userSpaceOnUse" x="4" y="4" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 16.216l4.944 2.984-1.312-5.624L20 9.792l-5.752-.488L12 4 9.752 9.304 4 9.792l4.368 3.784L7.056 19.2 12 16.216z" fill="#fff"/></mask><g mask="url(#mtbfehwana)"><path fill="#fff" d="M1.6 1.6h20.8v20.8H1.6z"/></g></svg>
    </button>
</template>

<script>
    import api from "@/api/project";

    export default {
        name: "ToggleFavourite",
        props: {
            projectId: null,
            /**
             * @var variant { 'micro' | 'small' || 'big' }
             */
            variant: {
                type: [String, Object],
                default: "small",
            },
            isFavourite: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isFavouriteValue: false,
            };
        },

        methods: {
            handleFavButtonClick() {
                event.stopPropagation();
                if (this.isFavouriteValue)
                    api.removeFromFavourite(this.projectId)
                        .then(() => {
                            this.isFavouriteValue = false;
                        });
                else
                    api.addToFavourite(this.projectId)
                        .then(() => {
                            this.isFavouriteValue = true;
                        });
            },
        },
        mounted() {
            this.isFavouriteValue = this.isFavourite;
        },
    };
</script>

<style scoped lang="scss">
    @import "@/sass/mixins.scss";

    .variant-micro {
        height: 40px;
        width: 40px;
        border-color: transparent !important;
        border: 0;

        svg {
            color: #b3b3b3;
        }
        &:hover, &.active {
            color: #50CAB6;
            svg {
                color:#50cab6;
            }
        }
    }

    .variant-small {
        @include mark-cards;
    }

    .variant-big {
        @include mark-cards-big;
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
    }
</style>
