<template>
    <div class="component-log-in">
        <div class="modal">
            <div class="head-wrapper">
                <div class="title">
                    {{ i18n.logIn[$lang] }}
                </div>

                <Button
                        class="button button-sign-up"
                        @click="handleSignUpButton"
                >
                    {{ i18n.signUp[$lang] }}
                </Button>
            </div>
            <div class="inputs-wrapper">
                <div class="input-wrapper">
                    <div class="label-wrapper">
                        <img src="@/assets/img/icons/ic_person.svg" alt="" class="icon"/>
                        <label for="email" class="label">
                            {{ i18n.email[$lang] }}
                        </label>
                    </div>

                    <input
                            id="email"
                            name="email"
                            class="input"
                            v-model="email"
                    />
                </div>

                <div class="input-wrapper">
                    <div class="label-wrapper">
                        <img
                                src="@/assets/img/icons/ic_lock.svg"
                                alt=""
                                class="icon"
                        />
                        <label for="Password" class="label">
                            {{ i18n.password[$lang] }}
                        </label>
                    </div>

                    <input
                            id="Password"
                            name="password"
                            class="input"
                            type="password"
                            v-model="password"
                    />
                </div>
            </div>
            <div class="footer-wrapper">
                <Button class="button button-log-in normal" @click="login" :loading="isLoading">
                    {{ i18n.logIn[$lang] }}
                </Button>

                <span
                        class="button forgot-password"
                        @click="handleForgetPassButton"
                >
                        {{ i18n.forgotPassword[$lang] }}
                    </span>
            </div>
        </div>
        <div class="overlay" @click="closeModal">

        </div>
    </div>
</template>

<script>
    import {mapState, mapGetters} from "vuex";
    import Button from "@/components/Button";

    export default {
        name: "LogIn",
        components: {
            Button
        },
        props:{

        },
        data() {
            return {
                isLoading: false,
                email: "",
                password: "",
                i18n: {
                    email: {
                        en: "Email",
                        ru: "Эл. почта"
                    },
                    signUp: {
                        en: "Sign Up",
                        ru: "Регистрация"
                    },
                    logIn: {
                        en: "Log In",
                        ru: "Войти"
                    },
                    forgotPassword: {
                        en: "Forgot password?",
                        ru: "Забыли пароль?"
                    },
                    password: {
                        en: "Password",
                        ru: "Пароль"
                    }
                }
            };
        },
        methods: {
            closeModal:function(){
                this.$emit('input', "" )
            },
            login: function () {
                this.isLoading = true;
                let email = this.email;
                let password = this.password;
                this.$store.dispatch("user/login", {email, password})
                    .then(
                        () => {
                            this.isLoading = false;
                            this.closeModal();
                            this.redirect()
                        }
                    )
                    .catch(
                        err => {
                            this.isLoading = false
                            console.log(err);
                        }
                    );
            },
            handleSignUpButton: function () {
                // this.$router.push("/signup");
                this.$emit('input', 'signup')

            },
            handleForgetPassButton: function () {
                this.$router.push("/restore-account");
            },
            redirect: function () {
                if (this.isLoggedIn) {
                    if (this.$route.query.from != null) {
                        this.$router.push(this.$route.query.from);
                    } else {
                        this.$router.push("/profile-discovery");
                    }
                }
            }
        },
        computed: {
            ...mapState({
                $lang: state => state.$lang
            }),
            ...mapGetters("user", ["isLoggedIn"])
        }
    };
</script>

<style scoped lang="scss">
    .component-login-in {
        position: relative;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.15);
        z-index: 1;
    }

    .modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-sizing: border-box;
        width: 350px;
        height: 330px;
        background-color: #fff;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        z-index: 50;
    }

    .head-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 30px;
    }

    .inputs-wrapper {
        display: flex;
        flex-direction: column;
    }

    .input-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px 20px 40px;
    }

    .label-wrapper {
        display: flex;
    }

    .title {
        // font-family: Raleway;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #4d4d4d;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .button-sign-up {
        font-weight: 900;
        font-size: 10px;
        line-height: 40px;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        padding: 0 31px;
    }

    .label {
        // font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 50px;
        color: #999999;
        padding: 0 0 0 7px;
        cursor: pointer;
    }

    .input {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 2px solid #f5f5f5;
        border-radius: 4px;
        width: 160px;
        height: 50px;
        padding: 0 15px;
        // font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 50px;
        color: #999999;
        cursor: pointer;
    }

    .footer-wrapper {
        display: flex;
        justify-content: flex-start;
        padding: 0 0 0 30px;
    }

    .button-log-in {
        /*padding: 15px 0;*/
        margin: 0 30px 0 0;
        /*font-size: 12px;*/
        letter-spacing: 1.5px;
        //width: 120px;
    }

    .forgot-password {
        background: transparent;
        // font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 50px;
        text-align: center;
        color: #b3b3b3;
        text-transform: inherit;
        border: none;

        &:hover {
            background-color: transparent !important;
            border-color: transparent !important;
            color: #4d4d4d;
        }
    }
</style>
