<template>

    <Button
            class="content-button primary"
            :class="{ 'is-cancel': isUserVoted }"
            :loading="isLoading"
            @click="handleButtonClick()"

    >
        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'content-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="eggduvaqua" maskUnits="userSpaceOnUse" x="4" y="4" width="22" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 24h4V12H4v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L17.17 4l-6.58 6.59c-.37.36-.59.86-.59 1.41v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L26 13z" fill="#fff"/></mask><g mask="url(#eggduvaqua)"><path d="M2 2h26v26H2z"/></g></svg>

        <div class="content-button-label">
            {{ isUserVoted ? i18n.cancel[$lang] : i18n.promote[$lang] }}
            <div class="tooltip">
                Вы можете отменить голос в течение 24 часов
            </div>
        </div>
    </Button>
</template>

<script>
    import Api from "@/api/project.js"
    import Button from "@/components/Button";
    import {mapActions} from 'vuex';
    import {mapState} from 'vuex';

    export default {
        name: "ToggleVoteButton",

        components: {
            Button
        },

        data() {
            return {
                isButtonHovered: false,
                i18n: {
                    promote: {
                        en: 'promote',
                        ru: 'поддержать'
                    },
                    cancel: {
                        en: 'cancel',
                        ru: 'отменить'
                    }
                },
                isLoading: false
            }
        },

        props: {
            projectId: {
                type: Number,
                required: true
            },

            isUserVoted: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            ...mapState(["$lang"]),
        },

        methods: {
            handleButtonClick() {
                event.stopPropagation();
                this.isLoading = true;
                if (this.isUserVoted) {
                    Api.deleteVote(this.projectId)
                        .then((res) => {
                            this.$emit("change", res.data)
                        })
                        .then(() => {
                            this.isLoading = false
                        })
                } else {
                    Api.addVote(this.projectId)
                        .then((res) => {
                            this.$emit("change", res.data)
                        })
                        .then(() => {
                            this.isLoading = false
                        })
                }
            },
        }
    }
</script>

<style scoped lang="scss">
    .tooltip {
        position: absolute;
        top: -62px;
        left: -74px;
        width: 200px;
        border-radius: 6px;
        /* height: 60px; */
        display: none;
        background: #d8d8d8;
        font-size: 9px;
        padding: 10px 5px;
        color: #fff;
        line-height: 20px;
    }

    .content-button {
        min-width: 180px;
        margin-left: auto;


        &.is-cancel {
            &:hover {
                background-color: #d16861;
                border-color: #d16861;
            }

            .content-button-img {
                transform: rotate(180deg);
            }

            &:hover .tooltip {
                display: block;
            }
        }
    }

    .content-button-label {
        position: relative;

        @media only screen and (max-width: 1100px) {
            margin-left: 8px;
        }
    }

</style>
