<template>
  <div class="component-browse-projects" v-on="$listeners">
    <div class="label">Смотреть все 245 проектов</div>

    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'arrow'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="ogrhlp6via" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 7l-1.41 1.41L19.17 14H7v2h12.17l-5.58 5.59L15 23l8-8-8-8z" fill="#fff"/></mask><g mask="url(#ogrhlp6via)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
  </div>
</template>

<script>
export default {
  name: "BrowseProjects",
};
</script>

<style scoped lang="scss">
@import "@/sass/mixins.scss";

.component-browse-projects {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  cursor: pointer;
  width: 280px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #50cab6;
    border-color: #50cab6;
    color: #fff;

    .label {
      color: #fff;
    }
  }
}

.label {
  font-family: IBMPlexSans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #b3b3b3;
}

.arrow {
  margin-left: 12px;
}
</style>
