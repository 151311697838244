import Vue from "vue";

import Fragment from "vue-fragment";
Vue.use(Fragment.Plugin);

import Editor from "@/components/Editor";
import vSelect from "vue-select";
Vue.component("v-select", vSelect);
Vue.component("editor", Editor);

import VModal from "vue-js-modal";
Vue.use(VModal);

import Notification from "vue-notification";
Vue.use(Notification);

import VueParallaxJs from 'vue-parallax-js';
Vue.use(VueParallaxJs);

import LocalStorage from "./utils/LocalStorage.js";

import App from "./components/app";

import router from "./router";
import store from "./store";

import VueAxios from "./plugins/axios";
Vue.use(VueAxios);

var vm = new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
  created() {
    let lang = LocalStorage.read("lang");

    if (!lang) {
      LocalStorage.write("lang", "en");

      lang = "ru";
    }

    this.$store.commit("set", lang);

    if(this.$store.getters["user/isLoggedIn"])
      this.$store.dispatch("user/fetchUserData");
  },
});
global.vm = vm;