<template>
  <div class="view-home-component-for-big-and-small">
    <div class="header">
      <div class="title">
        {{ i18n.forBigAndSmall[$lang] }}
      </div>

      <div class="line" />

      <div class="item">
        {{ i18n.weAreSupported[$lang] }}
      </div>
    </div>

    <div class="content">
      <div class="sponsors-wrapper">
        <!-- <div v-for="(sponsorLogoCur, index) in sponsorLogos" :key="index" class="sponsor">
          <img :src="sponsorLogoCur" alt="" class="logo" />
        </div> -->
        <div
          v-for="(sponsorLogoHoverCur, index) in sponsorLogosHover"
          :key="index"
          class="sponsor"
          :class="{}"
        >
          <img :src="sponsorLogoHoverCur" alt="" class="logo_hover" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'ForBigAndSmall',
    data() {
      return {
        sponsorLogosHover: [
          '/img/view-home/sponsor1_hover.png',
          '/img/view-home/sponsor2_hover.png',
          '/img/view-home/sponsor3_hover.png',
          '/img/view-home/sponsor4_hover.png',
          '/img/view-home/sponsor5_hover.png',
          '/img/view-home/sponsor6_hover.png',
          '/img/view-home/sponsor7_hover.png',
          '/img/view-home/sponsor1_hover.png',
          '/img/view-home/sponsor2_hover.png',
          '/img/view-home/sponsor3_hover.png',
        ],
        i18n: {
          forBigAndSmall: {
            en: 'For big and small',
            ru: 'Для больших и маленьких'
          },
          weAreSupported: {
            en: 'We are supported by big venture capitalists',
            ru: 'Нас поддерживают крупные венчурные фонды'
          },
        },
      }
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    }
  };
</script>

<style scoped lang="scss">
  .view-home-component-for-big-and-small {
    background: #E5E5E5;
    padding: 120px 167px;

    @media only screen and (max-width: 1240px) {
      padding: 120px 200px;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    color: #4D4D4D;

    @media only screen and (max-width: 1030px) {
      font-size: 26px;
    }
  }

  .line {
    width: 60px;
    height: 2px;
    background: #50CAB6;
    margin-top: 28px;
  }

  .item {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #808080;
    margin-top: 30px;

    @media only screen and (max-width: 1030px) {
      font-size: 14px;
    }
  }

  .content {
    margin-top: 50px;
  }

  .sponsors-wrapper {
    display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    grid-template-columns: repeat(auto-fill, minmax(17%, 1fr));
    grid-gap: 4px 4px;

    @media only screen and (max-width: 1400px) {
      grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
    }

    @media only screen and (max-width: 1240px) {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }

  .sponsor {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 116px;
    background: #fff;
    border-radius: 6px;
    cursor: pointer;
    overflow: hidden;

    &:hover > .logo_hover {
      filter: grayscale(0%);
      opacity: 1;
      -webkit-transition: ease 0.3s;
      transition: ease 0.3s;
    }
  }

  .logo_hover {
    filter: grayscale(100%);
    opacity: 0.9;
    -webkit-transition: ease 0.3s;
    transition: ease 0.3s;
  }


</style>
