import {axios} from "@/plugins/axios";

export default {

    storeFile(data) {
        return new Promise((resolve, reject) => {
            axios.post("/api/files", data)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((e) => {
                    reject(e);
                })
        });
    }

}