<template>
  <div class="view-main-component-slide-container">
    <div class="view-main-component-slide">
      <div class="buttons-wrapper">
        <div class="buttons-in-corners">
          <button type="button" class="big-round-slide-button button-share">
            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="5bzijhh8ma" maskUnits="userSpaceOnUse" x="6" y="7" width="18" height="15"><path fill-rule="evenodd" clip-rule="evenodd" d="M17 11V7l7 7-7 7v-4.1c-5 0-8.5 1.6-11 5.1 1-5 4-10 11-11z" fill="#fff"/></mask><g mask="url(#5bzijhh8ma)"><path d="M2 2h26v26H2z"/></g></svg>
          </button>

          <button type="button" class="big-round-slide-button button-favorite">
            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="bygmqo3g2a" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="19"><path fill-rule="evenodd" clip-rule="evenodd" d="M25 12.24l-7.19-.62L15 5l-2.81 6.63-7.19.61 5.46 4.73L8.82 24 15 20.27 21.18 24l-1.63-7.03L25 12.24zM15 18.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L15 9.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L15 18.4z" fill="#fff"/></mask><g mask="url(#bygmqo3g2a)"><path d="M2 2h26v26H2z"/></g></svg>
          </button>
        </div>

        <div class="buttons-in-center">
          <button type="button" class="slide-button">
            {{ i18n.drones[$lang] }}
          </button>

          <button type="button" class="slide-button">
            {{ i18n.technologies[$lang] }}
          </button>

          <button type="button" class="slide-button">
            {{ i18n.culture[$lang] }}
          </button>
        </div>
      </div>

      <div class="slide-title">
        {{ i18n.slideTitle[$lang] }}
      </div>

      <div class="slide-line" />

      <div class="slide-subtitle">
        {{ i18n.slideSubTitle[$lang] }}
      </div>

      <div class="slide-footer">
        <div class="progress-bar">
          <div class="progress-bar-active" />
        </div>

        <div class="people-wrapper">
          <div class="faces-wrapper">
            <div v-for="face in arrayOfPeople" :key="face" class="face">
              <img :src="face" />
            </div>
          </div>

          <div class="number-of-people">
            2414+ {{ i18n.supported[$lang] }}
          </div>

          <div class="author">
            {{ i18n.author[$lang] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'Slide',
    props: {
      arrayOfPeople: {
        type: Array,
        required: true,
      }
    },
    data() {
      return {
        i18n: {
          drones: {
            en: 'Drones',
            ru: 'Дроны'
          },
          technologies: {
            en: 'Technologies',
            ru: 'Технологии'
          },
          culture: {
            en: 'Culture',
            ru: 'Культура'
          },
          slideTitle: {
            en: 'Lorem ipsum dolor sit amet',
            ru: 'Полностью автономные летательные аппараты'
          },
          slideSubTitle: {
            en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            ru: 'Современная парадигма робототехники неэффективна и ограничивает рост многих отраслей'
          },
          supported: {
            en: 'Supported',
            ru: 'поддержали'
          },
          author: {
            en: 'Author: Denis Rubakin',
            ru: 'Автор: Денис Рубакин'
          },

        }
      }
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    }
  };
</script>

<style scoped lang="scss">
  @import "@/sass/mixins.scss";

  .view-main-component-slide {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    background: url("~@/assets/img/slide-bg.png") no-repeat center;
    background-size: cover;
    margin-top: 30px;
    border-radius: 6px;
    overflow: hidden;
    padding: 0 0 30px;
    @media only screen and (max-width: 767px){
      padding: 0 10px 14px;
      margin-top: 0;
    }
  }
  .view-main-component-slide-container{
    @media only screen and (max-width: 767px){
      margin: 0 10px;
    }
  }

  .big-round-slide-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    @include mark-default;
  }

  .button-share {
    position: absolute;
    z-index: 1;
    top: 60px;
    left: 60px;
  }

  .button-favorite {
    position: absolute;
    z-index: 1;
    top: 60px;
    right: 60px;
  }

  .slide-button {
    height: 25px;
    padding: 0 13px;
    border-radius: 4px;
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    @include mark-default;
    @media only screen and (max-width: 767px){
      min-width: 60px;
      border: 2px solid transparent;
      background-color: rgba(51, 51, 51, .5);
      margin-right: 6px;
      margin-bottom: 6px;
      font-size: 9px;
      line-height: 20px;
      height: auto;
    }
  }
  .buttons-in-corners{
    @media only screen and (max-width: 767px){
      display: none;
    }
  }
  .buttons-in-center {
    padding-top: 60px;
    text-align: center;
    @media only screen and (max-width: 767px){
      padding-top: 29px;
    }
  }

  .slide-title {
    margin-top: 57px;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;

    @media only screen and (max-width: 1030px) {
      font-size: 38px;
    }
    @media only screen and (max-width: 767px){
      margin-top: 14px;
      font-size: 22px;
      line-height: 30px;
    }
  }

  .slide-line {
    width: 67px;
    height: 2px;
    background: #50CAB6;
    margin: 88px auto 0 auto;
    @media only screen and (max-width: 767px){
      margin-top: 20px;
    }
  }

  .slide-subtitle {
    text-align: center;
    color: #FFFFFF;
    margin-top: 31px;

    @media only screen and (max-width: 767px){
      margin-top: 20px;
    }
  }

  .slide-footer {
    width: 100%;
    padding: 0 65px;
    @media only screen and (max-width: 767px){
      padding: 0;
      position: relative;
      margin-top: 40px;
    }
  }

  .progress-bar {
    position: relative;
    width: 100%;
    height: 5px;
    background: #F5F5F5;
    border-radius: 2.5px;
    margin-top: 130px;
    @media only screen and (max-width: 767px){
      margin-top: 48px;
    }
  }

  .progress-bar-active {
    position: absolute;
    top: 0;
    left: 0;
    background: #50CAB6;
    border-radius: 5px;
    height: inherit;
    width: 8%;
  }

  .people-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 60px;
    margin-top: 10px;
  }

  .faces-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .face {
    width: 25px;
    height: 30px;
    @media only screen and (max-width: 767px){
      width: 30px;
      display: none;
      &:not(:first-child){
        margin-left: -10px;
      }
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7){
        display: block;
      }
    }

  img {
    width: 30px;
  }
  }

  .number-of-people {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    margin-left: 10px;

    @media only screen and (max-width: 1030px) {
      font-size: 12px;
    }
  }

  .author {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    margin-left: auto;

    @media only screen and (max-width: 1030px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 767px){
      position: absolute;
      top: 0;
      width: 100%;
      text-align: center;
      font-size: 11px;
      line-height: 11px;
    }
  }
</style>
