<template>
  <DiagramWrapper :header-text="headerText">
    <div class="bar">
      <canvas :id="diagramId" width="800" height="500" />
    </div>
  </DiagramWrapper>
</template>

<script>
  import Chart from 'chart.js';
  import DiagramWrapper from '../DiagramWrapper';

  export default {
    name: 'BarDiagram',
    components: { DiagramWrapper },
    props: {
      diagramId: {
        type: String,
        required: true,
      },
      headerText: {
        type: [String, Object],
        required: true
      }
    },
    data() {
      return {
        diagramData: {
          labels: [
            '27-09',
            '',
            '29-09',
            '',
            '31-09',
            '',
            '02-10',
            '',
            '04-10',
            '',
            '06-10',
            '',
            '08-10',
            '',
            '10-10',
            '',
            '12-10',
            ''
          ],
          data: [
            55,
            126,
            90,
            130,
            40,
            125,
            85,
            60,
            83,
            72,
            55,
            90,
            105,
            126,
            76,
            101,
            80,
            96,
          ]
        }
      }
    },
    mounted() {
      const ctx = document.getElementById(this.diagramId);

      const myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.diagramData.labels,
          datasets: [{
            label: '',
            data: this.diagramData.data,
            backgroundColor: this.getColors(),
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      });
    },
    methods: {
      getColors() {
        const colors = [];

        for (let i = 0; i < this.diagramData.data.length; i++) {
          colors.push('#606EFF');
        }

        return colors;
      }
    }
  };
</script>

<style scoped lang="scss">
  .bar {
    height: 500px;
    max-width: 800px;
    width: 100%;
  }
</style>
