<template>
  <div class="view-home-component-subscribe">
    <!-- <div class="line" /> -->

    <div class="title">
      <span v-html="i18n.keepUpToDate[$lang]" />
    </div>

    <div class="action-wrapper">
      <div class="send-wrapper">
        <input class="input" placeholder="Ваша эл. почта" />

        <Button class="button primary">
          {{ i18n.subscribe[$lang] }}
        </Button>
      </div>

      <div class="disclaimer">
        {{ i18n.disclaimer[$lang] }}
      </div>
    </div>
  </div>
</template>

<script>
  import Button from '@/components/Button';
  import { mapState } from 'vuex';

  export default {
    name: 'Subscribe',
    components: {
      Button,
    },
    data() {
      return {
        i18n: {
          keepUpToDate: {
            en: `Keep up to date with the most
            <br />
            interesting business ideas`,
            ru: `Будьте в курсе самых
            <br />
            интересных бизнес идей`
          },
          subscribe: {
            en: 'Subscribe',
            ru: 'Подписаться'
          },
          disclaimer: {
            en: 'By sending this form I agree to the Privacy Policy and Terms of Use',
            ru: 'Отправляя форму я соглашаюсь с условиями передачи данных'
          },

        },
      }
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    }
  };
</script>

<style scoped lang="scss">
  .view-home-component-subscribe {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #444EB4;
    padding: 100px 0 90px 0;

    @media only screen and (max-width: 1210px) {
      flex-direction: column;
    }
    @media only screen and (max-width: 767px){
      padding: 60px 20px;
    }
    @media only screen and (max-width: 479px){
      padding-left: 60px;
    }

  }

  // .line {
  //   width: 60px;
  //   height: 2px;
  //   background: #50CAB6;
  // }

  .title {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    color: #FFFFFF;
    // margin-left: 35px;
    margin-left: 0px;
    position: relative;


    &::before {
      content: '';
      position: absolute;
      top: 25%;
      left: -94px;
      width: 60px;
      height: 2px;
      background: #50CAB6;
    }

    @media only screen and (max-width: 1210px) {
      text-align: center;

      &::before {
        content: '';
        position: absolute;
        top: 110%;
        left: 40%;
        width: 60px;
        height: 2px;
        background: #50CAB6;
      }
    }

    @media only screen and (max-width: 1030px) {
      font-size: 26px;
    }
    @media only screen and (max-width: 767px){
      font-size: 18px;
      line-height: 24px;
    }
    @media only screen and (max-width: 479px){
      text-align: left;
      width: 100%;
      &:before{
        left: -39px;
        top: 12px;
        width: 30px;
      }
    }
  }

  .action-wrapper {
    margin-left: 45px;

    @media only screen and (max-width: 1210px) {
      margin: 35px 0 0 0;
    }
    @media only screen and (max-width: 767px){
      margin: 20px 0 0 0;
    }
    @media only screen and (max-width: 479px){
      width: 100%;
    }
  }

  .send-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media only screen and (max-width: 479px){
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    height: 50px;
    width: 255px;
    background: #FFFFFF;
    border: 2px solid #F5F5F5;
    border-radius: 4px;
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #666666;

    @media only screen and (max-width: 1030px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 767px){
      width: 100%;
      height: 40px;
      font-size: 11px;
      line-height: 11px;
    }
  }

  .button {
    height: 50px;
    width: 180px;
    margin-left: 10px;
    @media only screen and (max-width: 767px){
      width: 150px;
    }
    @media only screen and (max-width: 479px){
      margin-left: 0;
      margin-top: 10px;
    }
  }

  .disclaimer {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #F0F3F6;
    margin-top: 10px;

    @media only screen and (max-width: 1030px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 767px){
      margin-top: 20px;
      font-size: 11px;
      line-height: 18px;
    }
  }
</style>
