import ProjectsApi from "../api/project";

// function getKeyByValue(obj, value) {
//   Object.keys(obj).find((key) => obj[key] === value);
// }

export default {
    namespaced: true,
    state: {
        projectsMap: [],
        projectsLoadStatus: 0,

        project: {},
        projectLoadStatus: 0,

        favouritesId: [],
        favouritesLoadStatus: 0,
    },

    mutations: {
        SET_PROJECTS(state, projects) {
            state.projectsMap = projects;
        },

        SET_PROJECT(state, project) {
            //state.projectsMap[project.data.id] = project.data;
            state.project = project.data;
            state.project.finmodel = JSON.parse(project.data.finmodel);
            state.project.timeline = JSON.parse(project.data.timeline);
            state.project.faq = JSON.parse(project.data.faq);
        },

        SET_FAVOURITES_ID(state, favourites) {
            state.favouritesId = favourites.map(function (el) {
                return parseInt(el);
            });
        },

        SET_PROJECT_LOAD_STATUS(state, status) {
            state.projectLoadStatus = status;
        },

        SET_PROJECTS_LOAD_STATUS(state, status) {
            state.projectsLoadStatus = status;
        },

        SET_FAVOURITES_LOAD_STATUS(state, status) {
            state.favouritesLoadStatus = status;
        },
    },

    actions: {
        loadProjects({commit}) {
            commit("SET_PROJECTS_LOAD_STATUS", 1);
            ProjectsApi.getProjects()
                .then(function (response) {
                    commit("SET_PROJECTS", response.data.data);
                    commit("SET_PROJECTS_LOAD_STATUS", 2);
                })
                .catch(()=>{
                    commit("SET_PROJECTS_LOAD_STATUS", 3);
                });
        },

        loadFavouritesId({commit}) {
            commit("SET_FAVOURITES_LOAD_STATUS", 1);
            ProjectsApi.getFavouriteProjects()
                .then(function (response) {
                    commit("SET_FAVOURITES_ID", Object.keys(response.data.data));
                    commit("SET_FAVOURITES_LOAD_STATUS", 2);
                })
                .catch(function (e) {
                    commit("SET_FAVOURITES_LOAD_STATUS", 3);
                    console.log(e);
                });
        },

        loadProject({commit}, data) {
            commit("SET_PROJECT_LOAD_STATUS", 1);
            ProjectsApi.getProject(data.id)
                .then(function (response) {
                    commit("SET_PROJECT", response.data);
                    commit("SET_PROJECT_LOAD_STATUS", 2);
                })
                .catch(()=>{
                    commit("SET_PROJECT", {});
                    commit("SET_PROJECT_LOAD_STATUS", 3);
                });
        },

        addToFavourite({commit, dispatch}, data) {
            return ProjectsApi.addToFavourite(data.id).then(()=>{
                dispatch("loadFavouritesId");
            });
        },

        removeFromFavourite({commit, dispatch}, data) {
            return ProjectsApi.removeFromFavourite(data.id)
                .then(() => {
                    dispatch("loadFavouritesId");
                });
        },

        addVote({commit, dispatch}, data) {
            //commit('SET_ADD_VOTE_STATUS', 1);
            return ProjectsApi.addVote(data.id)
                .then(function (res) {
                    // commit('SET_ADD_VOTE_STATUS', 2);
                    resolve(res);
                })
                .catch(()=>{
                    // commit('SET_ADD_VOTE_STATUS', 3);
                });
        },

        deleteVote({commit, dispatch}, data) {
            // commit('SET_REMOVE_VOTE_STATUS', 1);
            return ProjectsApi.deleteVote(data.id)
                .then(function (res) {
                    // commit('SET_REMOVE_VOTE_STATUS', 2);
                    resolve(res);
                })
                .catch(()=>{
                    // commit('SET_REMOVE_VOTE_STATUS', 3);
                });
        },
    },

    getters: {
        getFavouriteProjectsId(state) {
            return state.favouritesId;
        },

        getFavouriteProjects(state) {
            return state.favouritesId.map(function (favouriteId) {
                return state.projectsMap[favouriteId];
            });
        },

        getProjectsFilteredByEnddate(state) {
            return state.projectsMap;
        },

        getProjectsFilteredByComments(state) {
            return state.projectsMap;
        },

        getProjectsFilteredByCreatedAt(state) {
            return state.projectsMap;
        },

        getProjectsFilteredByRecommended(state) {
            return state.projectsMap;
        },

        getProjects(state) {
            return state.projectsMap;
        },

        getFavouriteProjectsLoadStatus(state) {
            return state.favouritesLoadStatus;
        },

        isFavouriteProjectsLoading(state) {
            return state.favouritesLoadStatus === 1;
        },

        getProjectsLoadStatus(state) {
            return state.projectsLoadStatus;
        },

        getProjectLoadStatus(state) {
            return state.projectLoadStatus;
        },

        isUserVoteProject(state) {
            return (id) => {
                return state.projectsMap[id].userVote;
            };
        },

        getProjectDigestCapital(state){
            return (typeof state.project.finmodel.digest.capitalCosts !== "undefined" && state.project.finmodel.digest.capitalCosts > 0) ? state.project.finmodel.digest.capitalCosts : 0;
        },

        getProjectDigestFixed(state){
            return (typeof state.project.finmodel.digest.fixedCosts !== "undefined" && state.project.finmodel.digest.fixedCosts > 0) ? state.project.finmodel.digest.fixedCosts : 0;
        }
    },
};
