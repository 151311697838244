<template>
  <div class="component-notification">
    <div class="wrapper">
      <div class="icon" :style="`color: ${iconColor}`">
        <div class="icon-img" v-html="icon" />
      </div>

      <div class="info">
        <div class="desc">
          <div class="name">
            {{ title[$lang] }}
          </div>

          <span class="text">
            {{ text[$lang] }}
          </span>

          <span class="text-bold">
            {{ projectName[$lang] }}
          </span>
        </div>

        <div class="date">
          {{ date[$lang] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'Notification',
    props: {
      title: {
        type: Object,
        required: true
      },
      text: {
        type: Object,
        required: true
      },
      projectName: {
        type: Object,
        default() {
          return {
            en: '',
            ru: '',
          }
        }
      },
      imgSrc: {
        type: String,
        required: true
      },
      date: {
        type: Object,
        required: true
      },
      iconColor: {
        type: String,
        default: '#50CAB6'
      },
      icon: {
        type: String,
        required: true
      },
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    }
  };
</script>

<style scoped lang="scss">
  .component-notification {
    margin-top: 15px;

    &:nth-child(1) {
      margin-top: 0;

      .wrapper {
        padding-top: 25px;
      }

      .icon {
        &:after {
          display: none;
        }
      }
    }

    &:nth-last-child(1) {
      .wrapper {
        padding-bottom: 0;
      }
    }
  }

  .wrapper {
    display: flex;
    padding: 10px;
  }

  .icon {
    border: 2px solid #F5F5F5;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      left: 50%;
      bottom: 56px;
      width: 2px;
      height: 45px;
      background: #F5F5F5;
    }
  }

  .icon-img,
  svg {
    height: 25px;
    width: 25px;
  }

  .info {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 25px;
    color: #999;
    margin-left: 20px;
  }

  .name {
    color: #666;
  }

  .text-bold {
    color: #666;
  }
</style>
