import { axios } from "@/plugins/axios";

export default {

    // createNewProject: function (data) {
    //     return axios.post('/api/project/description', data);
    // },

    fetchProjectDraft: function (id) {
        return new Promise((resolve, reject) => {
            if (!id) throw new Error('Incorrect field ID');
            axios.get(`/api/project/${id}/draft`)
                .then((response) => {
                    resolve(response);
                })
                .catch((e) => {
                    reject(e)
                });
        });
    },

    storeProjectDescription: function (id, payload) {
        return new Promise((resolve, reject) => {
            if (id)
                return axios.post('/api/project/' + id + '/description', payload, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    });
            else
                return axios.post('/api/project/description', payload, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
                    .then((response) => {
                        resolve(response)
                    })
                    .catch((e) => {
                        reject(e)
                    });
        });
    },

    storeProjectPresentation: function (data) {
        return new Promise((resolve, reject) => {
            axios.post(`/api/project/${data.id}/presentation`, {html: data.html})
                .then((response) => {
                    resolve(response)
                })
                .catch((e) => {
                    reject(e);
                })
        });
    },

    storeProjectFinmodel: function (data) {
        return new Promise((resolve, reject) => {
            axios.post('/api/project/' + data.id + '/finmodel', data)
                .then((response) => {
                    resolve(response);
                })
                .catch((e) => {
                    reject(e)
                })
        });
    },

    sendToModeration: function(id){
        return new Promise((resolve, reject)=>{
            if (!id) throw new Error('invalid id');
            axios.post(`/api/project/${id}/sendModeration`)
                .then((response) => {
                    resolve(response);
                })
                .catch((e) => {
                    reject(e)
                })
        });
    }


}
