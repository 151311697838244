<template>
    <div
            class="component-burger-menu"
            :class="{
      'is-active': isActive,
      'invisible-bg': invisibleBg,
      'is-under-the-footer': isUnderTheFooter
    }"
    >
        <button type="button" class="burger-button" @click="handleBurgerButtonClick">
            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-if="isActive" v-bind:key="'active'" v-bind:class="'burger-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="c74ljxun0a" maskUnits="userSpaceOnUse" x="8" y="8" width="14" height="14"><path fill-rule="evenodd" clip-rule="evenodd" d="M22 9.41L20.59 8 15 13.59 9.41 8 8 9.41 13.59 15 8 20.59 9.41 22 15 16.41 20.59 22 22 20.59 16.41 15 22 9.41z" fill="#fff"/></mask><g mask="url(#c74ljxun0a)"><path d="M2 2h26v26H2z"/></g></svg>

            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:key="'inactive'" v-bind:class="'burger-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="4jtryhgxka" maskUnits="userSpaceOnUse" x="6" y="9" width="18" height="12"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 21h18v-2H6v2zm0-5h18v-2H6v2zm0-7v2h18V9H6z" fill="#fff"/></mask><g mask="url(#4jtryhgxka)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
        </button>
        <div class="burger-menu-wrapper">
            <div class="burger-menu-items">
                <div class="burger-menu-items-top">
                    <router-link to="/profile-home" class="item" active-class="active-link">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="d72ld0tfoa" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="17"><path fill-rule="evenodd" clip-rule="evenodd" d="M13 22v-6h4v6h5v-8h3L15 5 5 14h3v8h5z" fill="#fff"/></mask><g mask="url(#d72ld0tfoa)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                    </router-link>

                    <router-link to="/profile-discovery" class="item" active-class="active-link">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="771uo2cp6a" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.99 5C9.47 5 5 9.48 5 15s4.47 10 9.99 10C20.52 25 25 20.52 25 15S20.52 5 14.99 5zm6.93 6h-2.95a15.65 15.65 0 00-1.38-3.56A8.03 8.03 0 0121.92 11zM15 7.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM7.26 17C7.1 16.36 7 15.69 7 15s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H7.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 018.08 19zm2.95-8H8.08a7.987 7.987 0 014.33-3.56A15.65 15.65 0 0011.03 11zM15 22.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM17.34 17h-4.68c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 01-4.33 3.56zM19.36 17c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z" fill="#fff"/></mask><g mask="url(#771uo2cp6a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                    </router-link>

                    <router-link to="/profile-info" class="item" active-class="active-link">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="r9hc3lkx8a" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 5C9.48 5 5 9.48 5 15s4.48 10 10 10 10-4.48 10-10S20.52 5 15 5zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2a7.2 7.2 0 01-6-3.22c.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08a7.2 7.2 0 01-6 3.22z" fill="#fff"/></mask><g mask="url(#r9hc3lkx8a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                    </router-link>

                    <router-link to="/profile-favourites" class="item" active-class="active-link">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="xsxu5sd17a" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="19"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 20.27L21.18 24l-1.64-7.03L25 12.24l-7.19-.61L15 5l-2.81 6.63-7.19.61 5.46 4.73L8.82 24 15 20.27z" fill="#fff"/></mask><g mask="url(#xsxu5sd17a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                    </router-link>

                    <router-link to="/profile-my-projects" class="item" active-class="active-link">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="qerxo5ndea" maskUnits="userSpaceOnUse" x="5" y="7" width="20" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M13 7H7c-1.1 0-1.99.9-1.99 2L5 21c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V11c0-1.1-.9-2-2-2h-8l-2-2z" fill="#fff"/></mask><g mask="url(#qerxo5ndea)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                    </router-link>

                    <router-link to="/profile-supported" class="item" active-class="active-link">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="3bzgg22aqa" maskUnits="userSpaceOnUse" x="4" y="4" width="22" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 24h4V12H4v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L17.17 4l-6.58 6.59c-.37.36-.59.86-.59 1.41v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L26 13z" fill="#fff"/></mask><g mask="url(#3bzgg22aqa)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                    </router-link>
                </div>
                <div class="burger-menu-items-bottom">
                    <router-link to="/help" class="item" active-class="active-link">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="9hhx72ay1a" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M14 21h2v-2h-2v2zm1-16C9.48 5 5 9.48 5 15s4.48 10 10 10 10-4.48 10-10S20.52 5 15 5zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z" fill="#fff"/></mask><g mask="url(#9hhx72ay1a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                    </router-link>

                    <router-link to="/profile-settings" class="item" active-class="active-link">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="05rcct6zra" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.16 15.98c.04-.32.07-.64.07-.98 0-.34-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46a.503.503 0 00-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65a.488.488 0 00-.49-.42h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98 0 .33.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-7.43 2.52c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z" fill="#fff"/></mask><g mask="url(#05rcct6zra)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                    </router-link>

                    <a href="#" class="item" active-class="active-link" @click.prevent="logout">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="xunsimqgja" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 6h-2v10h2V6zm4.83 2.17l-1.42 1.42A6.92 6.92 0 0122 15c0 3.87-3.13 7-7 7a6.995 6.995 0 01-4.42-12.42L9.17 8.17A8.932 8.932 0 006 15a9 9 0 0018 0c0-2.74-1.23-5.18-3.17-6.83z" fill="#fff"/></mask><g mask="url(#xunsimqgja)"><path d="M0 0h26v26H0z"/></g></svg>
                    </a>
                </div>
            </div>
            <div class="burger-menu-lists-wrapper" :class="{'is-active': isActive}">
                <div class="burger-menu-lists">
                    <div class="burger-menu-lists-top">
                        <router-link to="/profile-home" class="list" active-class="active-link">
                            {{ i18n.home[$lang] }}
                        </router-link>

                        <router-link to="/profile-discovery" class="list" active-class="active-link">
                            {{ i18n.explore[$lang] }}
                        </router-link>

                        <router-link to="/profile-info" class="list" active-class="active-link">
                            {{ i18n.account[$lang] }}
                        </router-link>

                        <router-link to="/profile-favourites" class="list" active-class="active-link">
                            {{ i18n.favourites[$lang] }}
                        </router-link>

                        <router-link to="/profile-my-projects" class="list" active-class="active-link">
                            {{ i18n.myProject[$lang] }}
                        </router-link>

                        <router-link to="/profile-supported" class="list" active-class="active-link">
                            {{ i18n.sponsor[$lang] }}
                        </router-link>
                    </div>
                    <div class="burger-menu-lists-bottom">
                        <router-link to="/help" class="list" active-class="active-link">
                            {{ i18n.supportCenter[$lang] }}
                        </router-link>

                        <router-link to="/profile-settings" class="list" active-class="active-link">
                            {{ i18n.settings[$lang] }}
                        </router-link>

                        <a href="#" @click.prevent="logout" class="list" active-class="active-link">
                            {{ i18n.logout[$lang] }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from 'vuex';

    export default {
        name: 'BurgerMenu',
        props: {
            invisibleBg: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                isActive: false,
                isUnderTheFooter: false,
                i18n: {
                    home: {
                        en: 'Home',
                        ru: 'Домашняя'
                    },
                    explore: {
                        en: 'Explore',
                        ru: 'Исследовать'
                    },
                    account: {
                        en: 'Account',
                        ru: 'Аккаунт'
                    },
                    favourites: {
                        en: 'Favourites',
                        ru: 'Избранное'
                    },
                    myProject: {
                        en: 'My project',
                        ru: 'Мои проекты'
                    },
                    sponsor: {
                        en: 'Sponsor',
                        ru: 'Спонсор'
                    },
                    supportCenter: {
                        en: 'Support center',
                        ru: 'Центр поддержки'
                    },
                    settings: {
                        en: 'Settings',
                        ru: 'Настройки'
                    },
                    logout: {
                        en: "Logout",
                        ru: "Выйти"
                    }

                },
            }
        },
        mounted() {
            window.removeEventListener('scroll', this.scrollEventHandler);

            const burgerMenuItemsBottomElement = document.querySelector('.burger-menu-items-bottom');
            const itemElements = [...burgerMenuItemsBottomElement.querySelectorAll('.item')];
            const lastItemElement = itemElements[itemElements.length - 1];
            const footerElement = document.querySelector('.component-footer');
            const lastItemCoords = lastItemElement.getBoundingClientRect();

            this.scrollEventHandler = () => {
                const footerCoords = footerElement.getBoundingClientRect();

                this.isUnderTheFooter = lastItemCoords.top + 50 >= footerCoords.top;
            }

            window.addEventListener('scroll', this.scrollEventHandler);
        },
        destroy() {
            window.removeEventListener('scroll', this.scrollEventHandler);
        },
        methods: {
            logout() {
                this.$store.dispatch("user/logout")
                    .then(() => {
                        this.$router.push("/");
                    })
            },
            handleBurgerButtonClick(e) {
                e.stopPropagation();

                this.isActive = !this.isActive;

                this.misClickHandler = () => {
                    this.isActive = false;
                };

                document.addEventListener('click', this.misClickHandler, {once: true});
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
        }
    };
</script>

<style scoped lang="scss">
    .component-burger-menu {
        position: fixed;
        z-index: 10;
        left: 0;
        top: 0;
        width: 65px;
        height: 100%;
        min-height: 100vh;
        background-color: #fff;
        transition: background-color 0.3s ease;

        &.invisible-bg {
            background-color: transparent;

            .burger-menu-items,
            .burger-menu-lists-wrapper,
            .burger-menu-lists {
                background-color: transparent;
            }
        }

        &.is-under-the-footer {
            .burger-menu-items,
            .burger-menu-lists-wrapper {
                // transform: translateY(-32px);
            }

            .burger-menu-wrapper {
                // margin-top: 2px;
            }

            .burger-menu-items-bottom,
            .burger-menu-lists-bottom {
                margin-top: 30px;
            }
        }
    }

    .burger-menu-wrapper {
        display: flex;
        height: 100%;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
        padding-top: 32px;
        margin-top: 27px;
    }

    .burger-button {
        padding-top: 30px;
        width: 65px;
        color: #d8d8d8;
    }

    .burger-menu-items {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        // margin-top: 49px;
        height: 80%;
        min-height: 80vh;
        z-index: 1;
        padding: 0 16px 0 16px;
        background-color: #fff;
        transition: transform 0.3s ease;
    }

    .burger-menu-lists-wrapper {
        background: #fff;
        transition: transform 0.3s ease;
        transform: translateX(-200px);

        &.is-active {
            transform: translateX(0);
        }
    }

    .burger-menu-lists {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-top: -32px;
        padding: 32px 60px 0 10px;
        background-color: #fff;
        z-index: 0;
        // height: 80%;
        height: calc(80% + 56px);
        min-height: 80vh;
    }

    .burger-menu-items-bottom,
    .burger-menu-lists-bottom {
        margin-top: auto;
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 26px;
        width: 26px;
        margin-top: 30px;

        &:nth-child(1) {
            margin-top: 0;
        }

        &:after {
            content: '';
            display: none;
            position: absolute;
            z-index: 1;
            left: 0;
            width: 3px;
            height: 38px;
            background: #50CAB6;
        }

        // &.is-active-icon {
        //   &:after {
        //     display: block;
        //   }
        // }

        &.active-link {
            &:after {
                display: block;
            }
        }

    }

    .icon {
        width: inherit;
        color: #D8D8D8;
        transition: 0.3s ease;

        &:hover {
            color: #50CAB6;
        }
    }

    .list {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 26px;
        margin-top: 30px;
        font-size: 14px;
        color: #b3b3b3;
        width: 123px;
        font-weight: 500;

        &:nth-child(1) {
            margin-top: 0;
        }

        &:hover {
            color: #50CAB6;
        }
    }

    .active-link {
        color: #50cab6;
        font-weight: 600;

        .icon {
            color: #50CAB6;
        }
    }
</style>
