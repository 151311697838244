<template>
    <div class="component-step-navigation">
        <div class="step-1 step" :class="{'is-active' : activeStep === 1 , 'is-previous' : activeStep > 1}" @click="handleStepNavItemClick(1)">
            <div class="step-ic">
                <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="tgi1q7znja" maskUnits="userSpaceOnUse" x="6" y="9" width="18" height="12"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 21h18v-2H6v2zm0-5h18v-2H6v2zm0-7v2h18V9H6z" fill="#fff"/></mask><g mask="url(#tgi1q7znja)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
            </div>
            <div class="step-text">
                <p>{{ i18n.step[$lang] }} 1</p>
                <h3>{{ i18n.description[$lang] }}</h3>
            </div>
        </div>

        <div class="step-2 step" :class="{'is-active' : activeStep === 2, 'is-previous' : activeStep > 2}" @click="handleStepNavItemClick(2)">
            <div class="step-ic">
                <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="3uopzk5p5a" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M23 11H7V9h16v2zm-2-6H9v2h12V5zm4 10v8c0 1.1-.9 2-2 2H7c-1.1 0-2-.9-2-2v-8c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2zm-6 4l-6-3.27v6.53L19 19z" fill="#fff"/></mask><g mask="url(#3uopzk5p5a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
            </div>
            <div class="step-text">
                <p>{{ i18n.step[$lang] }} 2</p>
                <h3>{{ i18n.presentation[$lang] }}</h3>
            </div>
        </div>

        <div class="step-3 step" :class="{'is-active' : activeStep === 3 , 'is-previous' : activeStep > 3}" @click="handleStepNavItemClick(3)">
            <div class="step-ic">
                <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="qqh7quof8a" maskUnits="userSpaceOnUse" x="5" y="6" width="20" height="18"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 13v-3l5-3 5 4 10-5v3l-10 5-5-4-5 3zm0 2v3l5-3 5 4 10-5v-3l-10 5-5-4-5 3zm0 5v4h20v-8l-10 5-5-4-5 3z" fill="#fff"/></mask><g mask="url(#qqh7quof8a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
            </div>
            <div class="step-text">
                <p>{{ i18n.step[$lang] }} 3</p>
                <h3>{{ i18n.financialPart[$lang] }}</h3>
            </div>
        </div>

        <div class="step-4 step" :class="{'is-active' : activeStep === 4}" @click="handleStepNavItemClick(4)">
            <div class="step-ic">
                <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="8ryjf9fvia" maskUnits="userSpaceOnUse" x="6" y="5" width="18" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M22 7h-4.18C17.4 5.84 16.3 5 15 5c-1.3 0-2.4.84-2.82 2H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L13 18.17l6.59-6.59L21 13l-8 8z" fill="#fff"/></mask><g mask="url(#8ryjf9fvia)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
            </div>
            <div class="step-text">
                <p>{{ i18n.step[$lang] }} 4</p>
                <h3>{{ i18n.confirmation[$lang] }}</h3>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'StepNavigation',
        props: {
            activeStep: {
                type: Number,
                required: true,
            }
        },
        data() {
            return {
                i18n: {
                    step: {
                        en: 'Step',
                        ru: 'Шаг'
                    },
                    description: {
                        en: 'Description',
                        ru: 'Описание'
                    },
                    presentation: {
                        en: 'Presentation',
                        ru: 'Презентация'
                    },
                    financialPart: {
                        en: 'Financial part',
                        ru: 'Финансовая часть'
                    },
                    confirmation: {
                        en: 'Confirmation',
                        ru: 'Подтверждение'
                    },

                }
            }
        },
        methods: {
            handleStepNavItemClick(step) {
                // if (step === 1) {
                //     this.$router.push('new-project-step');
                // } else {
                //     this.$router.push(`new-project-step-${step}`);
                // }
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
        }
    };
</script>

<style scoped lang="scss">
    .component-step-navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 45px 0;
        background: #fff;
        box-shadow: 0 10px 30px -2px #0000000d;
        border-radius: 5px;
        @media only screen and (max-width: 767px){
            border-radius: 6px;
            padding: 20px 10px;
            margin: 0 10px;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
        }
    }

    .step {
        display: flex;

        &.is-active {
            .step-ic {
                background-color: #50CAB6;
                border-color: #50CAB6;

                .img {
                    color: #fff;
                }
            }
            @media only screen and (max-width: 767px) {
                width: 100%;
                .step-text{
                    display: block;
                }
            }
        }
        &.is-previous{
            .step-ic{
                background-image: url('~@/assets/img/icons/is_previous.svg');
                background-size: contain;
                border: none;
                .img{
                    display: none;
                }
            }
        }
        @media only screen and (max-width: 767px){
            &:not(:last-child){
                margin-right: 10px;
            }

        }
    }

    .step-1 {
        padding: 0 0 0 48px;

        @media only screen and (max-width: 1250px) {
            padding: 0 0 0 15px;
        }
        @media only screen and (max-width: 767px){
            padding: 0;
        }
    }

    .step-4 {
        padding: 0 81px 0 0;

        @media only screen and (max-width: 1250px) {
            padding: 0 15px 0 0;
        }
        @media only screen and (max-width: 767px){
            padding: 0;
        }
    }

    .step-ic {
        border: 2px solid #F5F5F5;
        box-sizing: border-box;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin: 0 30px 0 0;
        //cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        .img {
            color: #D8D8D8;
        }

        /*&:hover {*/
        /*    background-color: #50CAB6;*/
        /*    border-color: #50CAB6;*/

        /*    .img {*/
        /*        color: #fff;*/
        /*    }*/
        /*}*/

        @media only screen and (max-width: 1250px) {
            margin: 0 15px 0 0;
        }
        @media only screen and (max-width: 767px){
            margin: 0;
            min-width: 40px;
            width: auto;
            min-height: 40px;
            height: auto;
            border: 1px solid #EBEBEB;

            .img{
                width: 24px;
                height: 24px;
                position: relative;
                top: -3px;
                left: -3px;
            }
        }
    }

    .step-text{
        @media only screen and (max-width: 767px) {
            margin-left: 10px;
            display: none;
        }
    }
    .step-text h3 {
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
        color: #666666;

        @media only screen and (max-width: 1190px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px){
            font-size: 11px;
            line-height: 11px;
            font-weight: 600;
        }
    }

    .step-text p {
        font-weight: bold;
        font-size: 10px;
        line-height: 30px;
        letter-spacing: 0.833333px;
        text-transform: uppercase;
        color: #CCCCCC;
        @media only screen and (max-width: 767px){
            font-size: 9px;
            line-height: 18px;
            letter-spacing: .5px;
        }
    }
</style>
