<template>
    <div class="view-home-component-token-price">
        <span class="price-title text-body">{{ i18n.todayPrice[$lang] }}:</span>
        <span class="price-value text-body">1 {{ i18n.token[$lang] }} = $ 0,01</span>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import prepareSvgElements from '@/mixins/prepareSvgElements.js';

    export default {
        name: "TokenPrice",

        mixins: [prepareSvgElements],

        data() {
            return {

                i18n: {
                    todayPrice: {
                        en: 'Price now',
                        ru: 'Курс на сегодня'
                    },
                    token: {
                        en: `token`,
                        ru: `токен`
                    },
                }
            };
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
        }

    }
</script>

<style scoped>

    .view-home-component-token-price{
        margin-left:21px;
    }
    .price-title{
        color: #fff;
    }
    .price-value {
        color: #50CAB6;
        padding-left: 6px;
    }
    @media only screen and (max-width: 767px){
        .view-home-component-token-price{
            margin-left:0;
            margin-top: 20px;
            width: 100%;
        }
    }
</style>
