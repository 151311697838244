<template>
    <div class="view-main-component-stats">
        <div class="stat supported">
            <div class="stat-circle">
                <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="d32k582vna" maskUnits="userSpaceOnUse" x="4" y="4" width="22" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 24h4V12H4v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L17.17 4l-6.58 6.59c-.37.36-.59.86-.59 1.41v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L26 13z" fill="#fff"/></mask><g mask="url(#d32k582vna)"><path fill="#50CAB6" d="M2 2h26v26H2z"/></g></svg>
            </div>

            <div class="stat-percent-and-desc">
                <div class="stat-percent">
                    {{ stats.rating }}%
                </div>

                <div class="stat-desc">
                    <h5>{{ i18n.supported[$lang] }}</h5>
                </div>
            </div>
        </div>

        <div class="stat remaining">
            <div class="stat-circle">
                <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="91nz1a62wa" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 5C9.5 5 5 9.5 5 15s4.5 10 10 10 10-4.5 10-10S20.5 5 15 5zm4.2 14.2L14 16v-6h1.5v5.2l4.5 2.7-.8 1.3z" fill="#fff"/></mask><g mask="url(#91nz1a62wa)"><path fill="#606EFF" d="M2 2h26v26H2z"/></g></svg>
            </div>

            <div class="stat-percent-and-desc">
                <template v-if="stats.remaining !== null && stats.remaining >= 0">
                    <div class="stat-percent">
                        {{ stats.remaining }} {{ i18n.days[$lang] }}
                    </div>

                    <div class="stat-desc">
                        <h5>{{ i18n.remaining[$lang] }}</h5>
                    </div>
                </template>
                <template v-else>
                    <div class="stat-percent">
                        {{ i18n.poll[$lang] }}
                    </div>

                    <div class="stat-desc">
                        <h5>{{ i18n.finished[$lang] }}</h5>
                    </div>
                </template>
            </div>
        </div>

        <div class="stat budget">
            <div class="stat-circle">
                <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="30bcorpzba" maskUnits="userSpaceOnUse" x="5" y="6" width="20" height="18"><path fill-rule="evenodd" clip-rule="evenodd" d="M13 19v-1H6.01L6 22c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-4h-7v1h-4zm10-9h-4.01V8l-2-2h-4l-2 2v2H7c-1.1 0-2 .9-2 2v3c0 1.11.89 2 2 2h6v-2h4v2h6c1.1 0 2-.9 2-2v-3c0-1.1-.9-2-2-2zm-6 0h-4V8h4v2z" fill="#fff"/></mask><g mask="url(#30bcorpzba)"><path fill="#39A9E4" d="M2 2h26v26H2z"/></g></svg>
            </div>

            <div class="stat-percent-and-desc">
                <div class="stat-percent">
                    {{ (this.getProjectDigestCapital + this.getProjectDigestFixed) / 1000000 }} {{ i18n.m[$lang] }} ₽
                </div>

                <div class="stat-desc">
                    <h5>{{ i18n.budget[$lang] }}</h5>
                </div>
            </div>
        </div>

        <div class="stat period">
            <div class="stat-circle">
                <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="37st7sq1ja" maskUnits="userSpaceOnUse" x="6" y="5" width="18" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M22 7h-1V5h-2v2h-8V5H9v2H8c-1.11 0-1.99.9-1.99 2L6 23a2 2 0 002 2h14c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm0 16H8V12h14v11zm-12-9h5v5h-5v-5z" fill="#fff"/></mask><g mask="url(#37st7sq1ja)"><path fill="#D16861" d="M2 2h26v26H2z"/></g></svg>
            </div>

            <div class="stat-percent-and-desc">
                <div class="stat-percent">
                    18 {{ i18n.M[$lang] }}
                </div>

                <div class="stat-desc">
                    <h5>{{ i18n.implementationPeriod[$lang] }}</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapGetters} from 'vuex';

    export default {
        name: 'Stats',
        data() {
            return {
                i18n: {
                    supported: {
                        en: 'Supported',
                        ru: 'Поддержали'
                    },
                    days: {
                        en: 'days',
                        ru: 'дней'
                    },
                    remaining: {
                        en: 'Remaining',
                        ru: 'Осталось'
                    },
                    poll: {
                        en: 'Poll',
                        ru: 'Голосование'
                    },
                    finished: {
                        en: 'Finished',
                        ru: 'Завершено'
                    },
                    budget: {
                        en: 'Budget',
                        ru: 'Бюджет'
                    },
                    m: {
                        en: 'M',
                        ru: 'Млн'
                    },
                    M: {
                        en: 'm',
                        ru: 'мес'
                    },
                    implementationPeriod: {
                        en: 'Implementation period',
                        ru: 'Срок реализации'
                    },

                },
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
                stats: (state) => state.projects.project.stats,
                finmodel: (state) => state.projects.project.finmodel,
                funding: (state) => {
                    return state.projects.project.funding / 1000000

                }
            }),
            ...mapGetters("projects", [
                "getProjectDigestCapital",
                "getProjectDigestFixed"
            ])
        }
    };
</script>

<style scoped lang="scss">
    .view-main-component-stats {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        min-height: 150px;
        background: #fff;
        padding: 10px 21px 10px 45px;
        margin-top: 30px;
        border-radius: 6px;

        @media only screen and (max-width: 1272px) {
            padding: 10px 15px 10px 15px;
        }
        @media only screen and (max-width: 767px){
            padding: 17px 11px 39px;
            margin-top: 12px;
        }
    }

    .stat {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        margin-left: 15px;

        &:before {
            content: '';
            display: block;
            height: 90px;
            width: 2px;
            background: #F5F5F5;
            margin-right: 30px;
            flex-shrink: 0;

            @media only screen and (max-width: 1272px) {
                margin-right: 15px;
            }
        }

        &:nth-child(1) {
            margin-left: 0;

            &:before {
                display: none;
            }
        }

        @media only screen and (max-width: 1272px) {
            &:nth-last-child(1) {
                // transform: translateX(-47px);

                &:before {
                    // opacity: 0;
                }
            }
        }
        @media only screen and (max-width: 767px){
            max-width: 50%;
            width: 100%;
            margin-left: 0;
            margin-bottom: 20px;
            &:nth-child(2n){
                margin-left: 20px;
                max-width: calc(50% - 20px);
            }
            &:before{
                display: none;
            }

        }
    }

    .stat-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        width: 60px;
        background: #FFFFFF;
        border: 2px solid #F5F5F5;
        border-radius: 50%;
        flex-shrink: 0;
        @media only screen and (max-width: 767px){
            height: 40px;
            width: 40px;
        }
    }

    .stat-percent-and-desc {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left: 30px;
        flex-shrink: 0;

        @media only screen and (max-width: 1272px) {
            margin-left: 15px;
        }
        @media only screen and (max-width: 767px){
            margin-left: 5px;
            flex-shrink: 1;
        }
    }

    .stat-percent {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        text-align: center;
        color: #666666;

        @media only screen and (max-width: 1272px) {
            font-size: 20px;
        }

        @media only screen and (max-width: 1030px) {
            font-size: 18px;
        }
        @media only screen and (max-width: 767px){
            font-size: 16px;
        }
    }

    .stat-desc {
        color: #B3B3B3;
        margin-top: 12px;

        @media only screen and (max-width: 767px){
            margin-top: 0;
        }
    }
</style>
