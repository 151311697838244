export default function scrollByNewItems(
    wrapperSelector,
    elemSelector,
    lastIndex = 1
) {
    const wrapperElem = document.querySelector(wrapperSelector);
    const elems = wrapperElem.querySelectorAll(elemSelector);

    if (elems.length >= lastIndex) {
        const elemLast = elems[elems.length - lastIndex];
        const scrollTop = elemLast.getBoundingClientRect().top - 100; // 100 = header height + a little margin;

        window.scrollBy({
            top: scrollTop,
            behavior: "smooth"
        });
    }
}
