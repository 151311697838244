<template>
    <div class="block" style="margin-top: 76px;">
        <div class="title">
            <h3>{{ i18n.implementationPlan[$lang] }}</h3>
        </div>
        <div class="desc">
            <p>Расскажите об основных вехах (ключевых этапах) развития проекта. Например, старт продаж, выход на новый
                рынок, открытие филиала, запуск франшизы. Расположите их в соответствии с периодами продаж из прошлого
                шага. Некоторые периоды останутся пустыми, это нормально.</p>
        </div>

        <div class="steps-wrapper" :key="stepActiveIndex">
            <div
                    v-for="(stepCur, index) in roadmapModel"
                    :key="index"
                    class="step"
                    :class="{'is-active' : index === stepActiveIndex}"
            >
                <div class="step-left">
                    <div class="step-number" @click="handleStepNumberClick(index)">
                        {{ index + 1 }}
                    </div>
                </div>

                <div class="step-right">
                    <input
                            v-model="stepCur.title"
                            class="step-input"
                            :disabled="index !== stepActiveIndex"
                    />

                    <textarea
                            v-model="stepCur.value"
                            class="step-textarea"
                            :disabled="index !== stepActiveIndex"
                    />

                    <div v-if="index === stepActiveIndex" class="step-buttons-wrapper">
                        <Button class="step-button-continue primary" @click="handleStepButtonContinue">
                            {{ i18n.continue[$lang] }}
                        </Button>

                        <Button class="step-button-cancel" without-border @click="handleStepButtonCancel">
                            {{ i18n.cancel[$lang] }}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Button from '@/components/Button';
    import {mapState} from 'vuex';

    export default {
        name: 'Roadmap',
        components: {
            Button
        },
        props: {
            items: {
                type: [Object, Array]
            }
        },
        data() {
            return {
                stepActiveIndex: 0,
                textAreaPlaceholder: {
                    en: 'The current robotics paradigm is inefficient and limits the growth of many industries',
                    ru: 'Современная парадигма робототехники неэффективна и ограничивает рост многих отраслей промышленности',
                },
                inputPlaceholder: {
                    en: 'Step #',
                    ru: 'Шаг №'
                },
                i18n: {
                    implementationPlan: {
                        en: 'Implementation plan',
                        ru: 'План реализации'
                    },
                    continue: {
                        en: 'Continue',
                        ru: 'Продолжить'
                    },
                    cancel: {
                        en: 'Cancel',
                        ru: 'Отмена'
                    }
                },
            };
        },
        watch: {
            roadmapModel: {
                handler: function (n) {
                    this.$emit('input', JSON.stringify(n))
                },
                deep: true
            }
        },
        computed: {
            ...mapState(["$lang"]),
            roadmapModel: function(){
                if (this.items === null || typeof this.items !== "object" || Array.isArray(this.items))
                    return [
                        {
                            title: 'Шаг №1',
                            value: ''
                        }
                    ]
                else
                    return JSON.parse(JSON.stringify(this.items));
            }
        },
        beforeMount() {
            // const inputPlaceholder = `${this.inputPlaceholder[this.$lang]} ${this.stepActiveIndex + 1}`;
            // const textAreaPlaceholder = `${this.textAreaPlaceholder[this.$lang]}`;
            // this.$set(this.roadmapModel[0], 'title', inputPlaceholder);
            // this.$set(this.roadmapModel[0], 'value', textAreaPlaceholder);
        },

        methods: {
            async handleStepButtonContinue() {
                this.stepActiveIndex++;
                if (this.roadmapModel[this.stepActiveIndex]) {
                    return;
                }
                const inputPlaceholder = `${this.inputPlaceholder[this.$lang]} ${this.stepActiveIndex + 1}`;
                // const textAreaPlaceholder = `${this.textAreaPlaceholder[this.$lang]}`;
                this.roadmapModel.push({
                    title: inputPlaceholder,
                    value: '',
                });
                await this.$nextTick();
                window.dispatchEvent(new Event('resize'));
            },
            async handleStepButtonCancel() {
                if (this.stepActiveIndex === 0) {
                    this.roadmapModel[0].title = this.roadmapModel[0].value = "";
                    return
                }
                this.stepActiveIndex--;
                this.$delete(this.roadmapModel, this.stepActiveIndex + 1)
                await this.$nextTick();
                this.$forceUpdate();
                window.dispatchEvent(new Event('resize'));
            },
            async handleStepNumberClick(index) {
                this.stepActiveIndex = index;
                await this.$nextTick();
                window.dispatchEvent(new Event('resize'));
            }
        },
    };
</script>

<style scoped lang="scss">
    .steps-wrapper {
        margin-top: 30px;
        @media only screen and (max-width: 767px) {
            margin-top: 20px;
        }
    }

    .desc {
        margin-top: 18px;
        line-height: 30px;
        @media only screen and (max-width: 767px) {
            margin-top: 10px;
        }
    }

    .step {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 35px;

        &:after {
            content: '';
            position: absolute;
            z-index: 1;
            top: 67px;
            left: 29px;
            height: calc(100% - 46px);
            width: 2px;
            background: #F0F0F0;
        }

        &:nth-child(1) {
            margin-top: 0;
        }

        &:nth-last-child(1) {
            &:after {
                display: none;
            }
        }

        &.is-active {
            .step-number {
                background: #50CAB6;
                border: 2px solid transparent;
                color: #fff;
            }
        }

        @media only screen and (max-width: 767px) {
            margin-top: 20px;
            &:after {
                top: 45px;
                left: 19px;
                height: calc(100% - 30px);
            }
        }
    }

    .step-number {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        width: 60px;
        border: 2px solid #F5F5F5;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        color: #B3B3B3;
        border-radius: 50%;
        transform: translateY(-5px);
        cursor: pointer;
        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px) {
            height: 40px;
            width: 40px;
            font-size: 9px;
            line-height: 18px;
            letter-spacing: .5px;
            transform: translateY(0);
        }
    }

    .step-right {
        margin-left: 35px;
        @media only screen and (max-width: 767px) {
            margin-left: 10px;
            width: 100%;
        }
    }

    .step-input {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 205px;
        height: 50px;
        padding: 0 20px;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #666666;
        border: 2px solid #F5F5F5;
        border-radius: 4px;
        cursor: text;
        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px) {
            padding: 10px;
            font-size: 11px;
            line-height: 18px;
            height: 40px;
            width: 100%;
            border-color: #EBEBEB;
            font-weight: 400;
        }
    }

    .step-textarea {
        width: 540px;
        height: 120px;
        margin-top: 24px;
        padding: 12px 20px;
        resize: none;
        border: 2px solid #F5F5F5;
        border-radius: 4px;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;
        color: #666666;
        cursor: text;
        @media only screen and (max-width: 1085px) {
            width: 420px;
        }
        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px) {
            padding: 10px;
            font-size: 11px;
            line-height: 18px;
            width: 100%;
            border-color: #EBEBEB;
            margin-top: 10px;
        }
    }

    .step-input,
    .step-textarea {
        &:disabled {
            background: transparent;
            border: 0;
            padding: 0;
        }
    }

    .step-buttons-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 30px;
        @media only screen and (max-width: 767px) {
            margin-top: 10px;
        }
    }

    .step-button-continue {
        width: 148px;
        height: 40px;
        font-size: 10px !important;
    }

    .step-button-cancel {
        width: 90px;
        height: 40px;
        font-size: 10px !important;
        margin-left: 10px;
    }

    .step-title {
        display: flex;
        align-items: center;
        height: 50px;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #666666;
        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .title {
        @media only screen and (max-width: 767px) {
            h3 {
                font-size: 15px;
                line-height: 20px;
                color: #4D4D4D;
            }
        }
    }
</style>