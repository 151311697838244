<template>
    <div class="component-slide-variant2" @click="handleSlideWrapperClick">
        <div class="header" :style="`background-image: url('${imgSrc}')`">
            <div class="tag">
                {{ i18n.news[$lang] }}
            </div>
        </div>

        <div class="content">
            <div class="title text-body">
                {{ title }}
            </div>

            <div class="stats-wrapper">
                <div class="stat-item date text-micro">
                    {{ stats.date[$lang] }}
                </div>

                <div class="stat-separator"/>

                <div class="stat-item views text-micro">
                    {{ stats.views[$lang] }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import objectValidator from '@/utils/objectValidator.js';
    import {mapState} from 'vuex';

    export default {
        name: 'SlideVariant2',
        props: {
            title: {
                type: [String, Object],
                required: true,
            },
            imgSrc: {
                type: String,
                required: true,
            },
            stats: {
                type: Object,
                required: true,
                validator(value) {
                    const keys = [
                        'date',
                        'views',
                    ];

                    return objectValidator(value, keys);
                }
            }
        },
        data() {
            return {
                i18n: {
                    news: {
                        en: 'News',
                        ru: 'Новости'
                    },
                },
            }
        },
        methods: {
            handleSlideWrapperClick() {
                this.$router.push('news-or-article');
            },

        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
        }
    };
</script>

<style scoped lang="scss">
    @import "@/sass/mixins.scss";
    .component-slide-variant2 {
        width: 100%;
        max-width: calc(25% - 30px);
        outline: none;
        margin-left: 30px;
        cursor: pointer;
        //box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        background: #fff;
@include box-shadow-1;
        &:nth-child(1) {
            margin-left: 5px !important;
        }
        @media only screen and (max-width: 767px){
            margin: 0 5px !important;
            max-width: calc(50% - 10px);
            &:nth-child(1) {
                margin-left: 5px !important;
            }
        }
        @media only screen and (max-width: 479px){
            max-width: calc(100% - 15px);
        }
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        width: 100%;
        height: 150px;
        background: no-repeat center;
        background-size: cover;
        border-radius: 6px 6px 0 0;
        @media only screen and (max-width: 767px){
            height: 260px;
        }
        @media only screen and (max-width: 479px){
            height: 160px;
        }
    }

    .tag {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 72px;
        height: 20px;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: bold;
        font-size: 9px;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #FFFFFF;
        background: rgba(#000, 0.3);
        margin-bottom: 15px;
        margin-left: 28px;
        @media only screen and (max-width: 767px){
            min-width: 80px;
            margin-left: 10px;
            margin-bottom: 20px;
            max-width: 160px;
            width: auto;
            font-size: 9px;
            line-height: 20px;
            height: auto;
            border-radius: 6px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 10px 8px 16px 20px;

        @media only screen and (max-width: 1299px) {
            padding: 10px 8px 16px 10px;
        }
        @media only screen and (max-width: 767px){
            padding: 20px;
        }
    }

    .title {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        min-height: 90px;
        color: #666666;

        @media only screen and (max-width: 767px){
            font-weight: 400;
        }
    }

    .stats-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 25px;
        margin-top: 7px;
    }

    .stat-item {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;

        color: #B3B3B3;

        @media only screen and (max-width: 767px){
            font-weight: 400;
            line-height: 18px;
        }
    }

    .stat-separator {
        height: inherit;
        width: 1px;
        background: #F0F0F0;
        margin-left: 24px;
        margin-right: 15px;

        @media only screen and (max-width: 1170px) {
            margin-left: 15px;
        }
        @media only screen and (max-width: 767px){
            margin: 0 10px;
        }
    }
</style>
