<template>
    <div class="component-faq">
        <div class="accordeon-item" :class="{'is-active': isActive}">
            <h4 class="accordeon-item-visible-part">
                <slot name="visible-part"></slot>
            </h4>
            <p class="visible-part-desc"><slot name="visible-part-desc"></slot></p>

            <div class="accordeon-item-invisible-part" :class="{'is-active': isActive}">
                <slot name="invisible-part"></slot>
            </div>
            <Checkbox :id="id" @change="handleItemClick" :defaultState="isActive"></Checkbox>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import Checkbox from "@/components/Checkbox/Checkbox.vue"
    export default {
        components:{
            Checkbox
        },
        name: 'FAQ',
        props: {
            defaultState:{
                type: Boolean,
                default: false
            }
        },
        data: ()=>({
            isActive: false,
            id: null
        }),
        methods: {
            handleItemClick(e) {
                this.isActive = e;
            }
        },
        mounted(){
            this.id = this._uid;
            this.isActive = !!this.defaultState;
        },
        computed: {
            ...mapState(["$lang"]),
        }
    };
</script>

<style scoped lang="scss">
    .accordeon-item {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        color: #666666;
        border-top: 2px solid #F5F5F5;
        padding: 15px 0;
        cursor: pointer;

        &.is-active{
            .visible-part-desc{
                display:none;
            }
        }


        &:nth-child(1) {
            //border-top: 2px solid #F5F5F5;
        }
        @media only screen and (max-width: 767px){
            padding-left: 0;
            &:after{
                right: 0;
                width: 26px;
                height: 26px;
            }

        }

        .visible-part-desc{
            padding-right: 70px;
        }
    }

    .accordeon-item-visible-part {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 30px;
        padding-bottom: 0 !important;
        margin: 0 !important;

        @media only screen and (max-width: 1030px) {
            font-size: 14px;
        }
        @media only screen and (max-width: 767px){
            font-size: 14px;
            line-height: 24px;
        }
    }

    .accordeon-item-invisible-part {
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;
        color: #666666;

        &.is-active {
            height: auto;
            margin-bottom: 18px;
        }
    }
    .toggle__button {
        position: absolute;
        top: 36px;
        right: 20px;
    }
</style>
