<template>
    <div class="component-log-in">
        <div class="overlay">
            <div class="modal">
                <div class="wrapper">
                    <div class="step-ic">
                        <img
                                src="@/assets/img/icons/ic_letter.svg"
                                alt=""
                                class="img"
                        />
                    </div>

                    <div class="title">
                        <h3>{{ i18n.title[$lang] }}</h3>
                    </div>

                    <div class="text">
                        <p>{{ i18n.text[$lang] }}</p>
                    </div>

                    <Button class="button normal" @click.prevent="handleLoginButtonClick()">
                        {{ i18n.logIn[$lang] }}
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import Button from '@/components/Button';

    export default {
        name: 'CheckYourEmail',
        components: {
            Button,
        },
        data() {
            return {
                i18n: {
                    title: {
                        en: 'Check your email',
                        ru: 'Проверьте вашу эл. почту'
                    },
                    logIn: {
                        en: 'Log In',
                        ru: 'Войти'
                    },
                    text: {
                        en: 'We just sent password recovery instructions',
                        ru: 'Мы только что отправили вам инструкции для восстановления пароля'
                    },
                },
            };
        },
        methods: {
            handleLoginButtonClick() {
                this.$router.push('/login');
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
        },
    };
</script>

<style scoped lang="scss">
    .component-login-in {
        position: relative;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.05);
    }

    .modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-sizing: border-box;
        width: 350px;
        height: 330px;
        background-color: #fff;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
    }

    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        height: 100%;
        padding: 30px 35px 35px 35px;
    }

    .step-ic {
        border: 2px solid #F5F5F5;
        box-sizing: border-box;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
    }

    .title {
        color: #4D4D4D;
        text-align: center;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .button {
        // font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 50px;
        text-align: center;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        width: 120px;
    }

    .text {
        // font-family: IBM Plex Sans;
        text-align: center;
        color: #999999;
        padding: 0 0 10px 0;

    }


</style>
