<template>
  <div class="component-restore-account">
    <div class="overlay">
      <div class="modal">
        <div class="head-wrapper">
          <div class="title">
            {{ i18n.restoreAccount[$lang] }}
          </div>

          <Button class="button button-sign-up" @click="handleLoginButtonClick">
            {{ i18n.logIn[$lang] }}
          </Button>
        </div>
        <div class="inputs-wrapper">
          <div class="input-wrapper">
            <div class="label-wrapper">
              <img
                src="@/assets/img/icons/ic_person.svg"
                alt=""
                class="icon"
              />
              <label for="email" class="label">
                {{ i18n.email[$lang] }}
              </label>
            </div>

            <input id="email" class="input" />
          </div>
        </div>
        <div class="footer-wrapper">
          <div class="text">
            {{ i18n.text[$lang] }}
          </div>

          <Button class="button button-log-in">
            {{ i18n.resetThePassword[$lang] }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Button from '@/components/Button';

  export default {
    name: 'RestoreAccount',
    components: {
      Button,
    },
    data() {
      return {
        i18n: {
          email: {
            en: 'Email',
            ru: 'Эл. почта'
          },
          restoreAccount: {
            en: 'Restore account',
            ru: 'Восстановление'
          },
          logIn: {
            en: 'Log In',
            ru: 'Войти'
          },
          text: {
            en: 'We will send you the restore link',
            ru: 'Мы вышлем вам ссылку для восстановления'
          },
          resetThePassword: {
            en: 'Reset the password',
            ru: 'Сбросить пароль'
          },
        },
      };
    },
    methods: {
        handleLoginButtonClick: function(){
            this.$router.push('/login');
        }
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    },
  };
</script>

<style scoped lang="scss">
  .component-login-in {
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    width: 350px;
    height: 340px;
    background-color: #fff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }

  .head-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 30px;
  }

  .inputs-wrapper {
    display: flex;
    flex-direction: column;
  }

  .input-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 20px 40px;
  }

  .label-wrapper {
    display: flex;
  }

  .title {
    // font-family: Raleway;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #4D4D4D;

    @media only screen and (max-width: 1030px) {
      font-size: 12px;
    }
  }

  .button-sign-up {
    font-weight: 900;
    font-size: 10px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    padding: 0 31px;
  }

  .label {
    // font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 50px;
    color: #999999;
    padding: 0 0 0 7px;
    cursor: pointer;
  }

  .input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 2px solid #F5F5F5;
    border-radius: 4px;
    width: 160px;
    height: 50px;
    padding: 0 15px;
    // font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 50px;
    color: #999999;
    cursor: pointer;
  }

  .footer-wrapper {
    padding: 0 0 0 30px;
  }

  .button-log-in {
    padding: 15px 0;
    margin: 0 30px 0 0;
    font-size: 12px;
    letter-spacing: 1.5px;
    width: 200px;
  }

 .text {
  // font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
  color: #B3B3B3;
  padding: 10px 0 30px 15px;
 }


</style>
