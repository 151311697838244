import {axios} from "@/plugins/axios";
import FilesApi from "@/api/file";

export default {
    namespaced: true,
    state: {
        fileUploadStatus: 0
    },
    actions: {
        storeFile({commit}, data) {
            commit("SET_FILE_UPLOAD_STATUS", 1);
            return new Promise((resolve, reject) => {
                FilesApi.storeFile(data)
                    .then((response) => {
                        commit("SET_FILE_UPLOAD_STATUS", 2);
                        resolve(response);
                    })
                    .catch((e) => {
                        commit("SET_FILE_UPLOAD_STATUS", 3);
                        reject(e);
                    })
            });
        }
    },
    mutations: {
        SET_FILE_UPLOAD_STATUS(state, status) {
            state.fileUploadStatus = status;
        }
    },
    getters: {},
}