<template>
  <div class="component-security">
    <div class="block email" data-id="email">
      <div v-if="changeEmail === true" class="title">
        <h3>{{ i18n.changeEmailAddress[$lang] }}</h3>
      </div>

      <div v-else class="title">
        <h3>{{ i18n.changeYourEmailAddress[$lang] }}</h3>
      </div>
      <div class="inputs-wrapper">
        <div class="input-wrapper">
          <label v-if="changeEmail === true" for="email" class="label">
            {{ i18n.currentEmailAddress[$lang] }}
          </label>
          <label v-else for="email" class="label">
            <h5>{{ i18n.emailAddress[$lang] }}</h5>
          </label>

          <input id="email" class="input" />
        </div>

        <Button class="button-change" :withoutBorder="true" @click="changeEmailOnClick">
          {{ i18n.change[$lang] }}
        </Button>
      </div>
      <div v-if="changeEmail === true" class="change-email">
        <div class="inputs-wrapper inputs-wrapper-hidden">
          <div class="input-wrapper">
            <label for="new-email" class="label">
              <h5>{{ i18n.newEmailAddress[$lang] }}</h5>
            </label>

            <input id="new-email" class="input" />
          </div>

          <div class="input-wrapper input-wrapper-second">
            <label for="current-password" class="label">
              <h5>{{ i18n.currentPassword[$lang] }}</h5>
            </label>

            <input id="current-password" type="password" class="input input-current-password" />
          </div>
        </div>

        <Button class="button-save" @click="changeEmail = false">
          {{ i18n.save[$lang] }}
        </Button>
      </div>
      <div v-else class="text text-micro">
        {{ textEmail[$lang] }}
      </div>
    </div>
    <div class="block password" data-id="password">
      <div class="title">
        <h3>{{ i18n.changePassword[$lang] }}</h3>
      </div>
      <div class="inputs-wrapper">
        <div class="input-wrapper">
          <label v-if="changePassword === true" for="password" class="label">
            <h5>{{ i18n.currentPassword[$lang] }}</h5>
          </label>
          <label v-else for="password" class="label">
            <h5>{{ i18n.password[$lang] }}</h5>
          </label>

          <input id="password" type="password" class="input" />
        </div>

        <Button class="button-change" :withoutBorder="true" @click="changePasswordOnClick">
          {{ i18n.change[$lang] }}
        </Button>
      </div>
      <div v-if="changePassword === true" class="change-email">
        <div class="inputs-wrapper inputs-wrapper-hidden">
          <div class="input-wrapper">
            <label for="new-password" class="label">
              <h5>{{ i18n.newPassword[$lang] }}</h5>
            </label>

            <input id="new-password" class="input" type="password" />
          </div>

          <div class="input-wrapper input-wrapper-second">
            <label for="confirm-password" class="label">
              <h5>{{ i18n.confirmYourNewPassword[$lang] }}</h5>
            </label>

            <input id="confirm-password" type="password" class="input" />
          </div>
        </div>

        <Button class="button-save" @click="changePassword = false">
          {{ i18n.save[$lang] }}
        </Button>
      </div>
      <div v-else class="text text-micro">
        Последний раз пароль был изменен {{ updatePassword }}
      </div>
    </div>
    <div class="block delete-account" data-id="account">
      <div class="title">
        <h3>{{ i18n.deleteAccount[$lang] }}</h3>
      </div>
      <div class="inputs-wrapper">
        <div class="input-wrapper">
          <label for="email-delete" class="label">
            <h5>{{ i18n.confirmYourEmail[$lang] }}</h5>
          </label>

          <input id="email-delete" class="input" />
        </div>

        <Button class="button-delete" :withoutBorder="true">
          {{ i18n.toDeleteAccount[$lang] }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
  import Button from '@/components/Button';
  import { mapState } from 'vuex';

  export default {
    name: 'Security',
    components: {
      Button
    },
    data() {
      return {
        textEmail: {
          en: 'This email is used to log in into the system, and only you can see it',
          ru: 'Данный адрес используется для входа в систему и виден только вам'
        },
        updatePassword: '21.02.2020',
        changeEmail: false,
        changePassword: false,
        i18n: {
          changeEmailAddress: {
            en: 'Change email address',
            ru: 'Сменить электронную почту'
          },
          changeYourEmailAddress: {
            en: 'Change your email address',
            ru: 'Сменить электронную почту'
          },
          currentEmailAddress: {
            en: 'Current email address',
            ru: 'Эл. почта'
          },
          emailAddress: {
            en: 'Email',
            ru: 'Эл. почта'
          },
          change: {
            en: 'Change',
            ru: 'Изменить'
          },
          newEmailAddress: {
            en: 'New email address',
            ru: 'Новый адрес'
          },
          currentPassword: {
            en: 'Current password',
            ru: 'Текущий пароль'
          },
          save: {
            en: 'Save',
            ru: 'Сохранить'
          },
          changePassword: {
            en: 'Change password',
            ru: 'Сменить пароль'
          },
          password: {
            en: 'Password',
            ru: 'Пароль'
          },
          newPassword: {
            en: 'New password',
            ru: 'Новый пароль'
          },
          confirmYourNewPassword: {
            en: 'Confirm your new password',
            ru: 'Подтверждение'
          },
          deleteAccount: {
            en: 'Delete Account',
            ru: 'Удаление аккаунта'
          },
          confirmYourEmail: {
            en: 'Confirm your email',
            ru: 'Подтвердите вашу эл. почту'
          },
          toDeleteAccount: {
            en: 'Delete Account',
            ru: 'Удалить аккаунт'
          },

        },
      }
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    },
    methods: {
      async changeEmailOnClick() {
        this.changeEmail = true;

        await this.$nextTick();

        window.dispatchEvent(new Event('resize'));
      },
      async changePasswordOnClick() {
        this.changePassword = true;

        await this.$nextTick();

        window.dispatchEvent(new Event('resize'));
      }
    },
  };
</script>

<style scoped lang="scss">
  .component-security {
    padding: 60px 0 0 0;
    @media only screen and (max-width: 767px){
      padding: 25px 0 0 0;
    }
  }

  .block {
    position: relative;
    padding: 0 145px 0 90px;

    @media only screen and (max-width: 1210px) {
      padding: 0 60px 0 60px;
    }

    @media only screen and (max-width: 1130px) {
      padding: 0 25px 0 25px;
    }
  }

  .email,
  .password,
  .delete-account {
    padding-bottom: 90px;

    @media only screen and (max-width: 767px){
      padding-bottom: 25px;
    }
  }

  .delete-account {
    padding-top: 55px;
    @media only screen and (max-width: 767px){
      padding-top: 25px;
    }
  }

  .password {
    padding-top: 30px;
    padding-bottom: 70px;
    @media only screen and (max-width: 767px){
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }

  .password::before,
  .delete-account::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-top: 2px #F0F0F0 solid;
  }

  .title {
    color: #4D4D4D;
    padding-bottom: 10px;

    @media only screen and (max-width: 1030px) {
      font-size: 18px;
    }
  }


  .inputs-wrapper-hidden {
    justify-content: flex-start;
  }

  .input-wrapper {
    margin-left: 0;
  }

  .input-wrapper-second {
    margin-left: 40px;
  }

  .label {
    color: #666666;
  }

  .delete-account {

    .input{
    }
  }

  .password {

    .input {
      font-size: 40px;
      letter-spacing: -4px;
    }
  }

  .input-current-password {
    font-size: 40px;
    letter-spacing: -4px;
  }

  .text {
    color: #B3B3B3;
    max-width: 185px;
    margin-top:12px;

    @media only screen and (max-width: 1030px) {
      font-size: 11px;
    }
  }

  .button-change,
  .button-delete {
    //padding: 20px 65px;
    margin: 25px 0 0 0px;

  }

  .button-delete  {
    //padding: 20px 40px;
  }

  .button-save {
    padding: 0px 65px;
    margin: 25px 0 0 0px;
    @media only screen and (max-width: 767px){
      margin: 25px 0 0 0;
    }
  }
</style>
