<template>
  <div class="component-comments">
    <div class="title">
      {{ i18n.comments[$lang] }} 12
    </div>

    <div class="type-comment">
      <div class="type-comment-input">
        <img src="@/assets/img/type-your-comment-ava.png" alt="" class="type-comment-ava" />

        <textarea :placeholder="i18n.typeYourComment[$lang]" class="textarea" />
      </div>

      <div class="type-comment-bottom">
        <div class="add-items-wrapper">
          <div class="add-item">
            <label for="input-photo">
              <input
                id="input-photo"
                type="file"
                accept="image/*"
                hidden
              />
              <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="ac41tb7eta" maskUnits="userSpaceOnUse" x="6" y="6" width="18" height="18"><path fill-rule="evenodd" clip-rule="evenodd" d="M24 22V8c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-12.5-5.5l2.5 3.01L17.5 15l4.5 6H8l3.5-4.5z" fill="#fff"/></mask><g mask="url(#ac41tb7eta)"><path d="M2 2h26v26H2z"/></g></svg>
            </label>
          </div>

          <div class="add-item">
            <label for="input-attachment">
              <input id="input-attachment" type="file" hidden />
              <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="tsa4k18iha" maskUnits="userSpaceOnUse" x="5" y="10" width="20" height="11"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 15.5c0-3.04 2.46-5.5 5.5-5.5H21c2.21 0 4 1.79 4 4s-1.79 4-4 4h-8.5a2.5 2.5 0 010-5H20v2h-7.59c-.55 0-.55 1 0 1H21c1.1 0 2-.9 2-2s-.9-2-2-2H10.5C8.57 12 7 13.57 7 15.5S8.57 19 10.5 19H20v2h-9.5C7.46 21 5 18.54 5 15.5z" fill="#fff"/></mask><g mask="url(#tsa4k18iha)"><path d="M2 2h26v26H2z"/></g></svg>
            </label>
          </div>

          <div class="add-item">
            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="wqmx0qc6ua" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.99 5C9.47 5 5 9.48 5 15s4.47 10 9.99 10C20.52 25 25 20.52 25 15S20.52 5 14.99 5zM15 23c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H9.89c.8 2.04 2.78 3.5 5.11 3.5z" fill="#fff"/></mask><g mask="url(#wqmx0qc6ua)"><path d="M2 2h26v26H2z"/></g></svg>
          </div>
        </div>

        <button type="button" class="button-send component-button primary">
          {{ i18n.send[$lang] }}
        </button>
      </div>
    </div>

    <div class="comments-wrapper">
      <div class="comment">
        <div class="comment-left">
          <div class="user-ava">
            <img src="@/assets/img/comment1-ava.png" alt="" />
          </div>
        </div>

        <div class="comment-center">
          <div class="name-and-date">
            <div class="user-name">
              Mathilda Ferguson
            </div>

            <div class="comment-date">
              5 {{ i18n.hoursAgo[$lang] }}
            </div>
          </div>

          <div class="text">
            {{ i18n.textFirst[$lang] }}
          </div>

          <div class="actions">
            <div class="do-answer">
              <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'do-answer-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="4bbvnpp7sa" maskUnits="userSpaceOnUse" x="5" y="6" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.99 8c0-1.1-.89-2-1.99-2H7c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM21 18H9v-2h12v2zm0-3H9v-2h12v2zm0-3H9v-2h12v2z" fill="#fff"/></mask><g mask="url(#4bbvnpp7sa)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

              <div class="do-answer-label">
                {{ i18n.comment[$lang] }}
              </div>
            </div>

            <div class="do-rate">
              <div class="do-rate-like">
                <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="zmqjt4f5aa" maskUnits="userSpaceOnUse" x="4" y="4" width="22" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 24h4V12H4v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L17.17 4l-6.58 6.59c-.37.36-.59.86-.59 1.41v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L26 13z" fill="#fff"/></mask><g mask="url(#zmqjt4f5aa)"><path d="M2 2h26v26H2z"/></g></svg>
              </div>

              <div class="rate-counter good">
                +2
              </div>

              <div class="do-rate-dislike">
                <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="028k8qfmqa" maskUnits="userSpaceOnUse" x="4" y="6" width="22" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M18 6H9c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v1.91l.01.01L4 17c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L12.83 26l6.59-6.59c.36-.36.58-.86.58-1.41V8c0-1.1-.9-2-2-2zm4 0v12h4V6h-4z" fill="#fff"/></mask><g mask="url(#028k8qfmqa)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
              </div>
            </div>
          </div>
        </div>

        <div class="comment-right">
          <div class="comment-menu">
            <img src="@/assets/img/icons/ic_more_horiz.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="comment answer">
        <div class="comment-left">
          <div class="user-ava">
            <img src="@/assets/img/comment2-ava.png" alt="" />
          </div>
        </div>

        <div class="comment-center">
          <div class="name-and-date">
            <div class="user-name">
              Victor Santos
            </div>

            <div class="comment-date">
              10 {{ i18n.daysAgo[$lang] }}
            </div>
          </div>

          <div class="text">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing
            elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur
            adipiscing elit.
          </div>

          <div class="actions">
            <div class="do-answer">
              <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'do-answer-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="8unsxwfx0a" maskUnits="userSpaceOnUse" x="5" y="6" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.99 8c0-1.1-.89-2-1.99-2H7c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM21 18H9v-2h12v2zm0-3H9v-2h12v2zm0-3H9v-2h12v2z" fill="#fff"/></mask><g mask="url(#8unsxwfx0a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

              <div class="do-answer-label">
                {{ i18n.comment[$lang] }}
              </div>
            </div>

            <div class="do-rate">
              <div class="do-rate-like">
                <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="bkewyqvr3a" maskUnits="userSpaceOnUse" x="4" y="4" width="22" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 24h4V12H4v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L17.17 4l-6.58 6.59c-.37.36-.59.86-.59 1.41v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L26 13z" fill="#fff"/></mask><g mask="url(#bkewyqvr3a)"><path d="M2 2h26v26H2z"/></g></svg>
              </div>

              <div class="rate-counter bad">
                -5
              </div>

              <div class="do-rate-dislike">
                <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="torrcn7pna" maskUnits="userSpaceOnUse" x="4" y="6" width="22" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M18 6H9c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v1.91l.01.01L4 17c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L12.83 26l6.59-6.59c.36-.36.58-.86.58-1.41V8c0-1.1-.9-2-2-2zm4 0v12h4V6h-4z" fill="#fff"/></mask><g mask="url(#torrcn7pna)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
              </div>
            </div>
          </div>
        </div>

        <div class="comment-right">
          <div class="comment-menu">
            <img src="@/assets/img/icons/ic_more_horiz.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'Comments',
    data() {
      return {
         i18n: {
          comments: {
            en: 'Comments',
            ru: 'Комментарии'
          },
          send: {
            en: 'Send',
            ru: 'Отправить'
          },
          textFirst: {
            en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            ru: 'Нам всегда нужны сильные программисты со знанием Python и Ruby. Если вы — один из этих героев, напишите нам на почту, пообщаемся.'
          },
          hoursAgo: {
            en: 'hours ago',
            ru: 'часов назад'
          },
          comment: {
            en: 'Comment',
            ru: 'Комментировать'
          },
          daysAgo: {
            en: 'days ago',
            ru: 'дней назад'
          },
          typeYourComment: {
            en: 'Type your comment',
            ru: 'Напишите ваш комментарий'
          },

        },
      }
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    }
  };
</script>

<style scoped lang="scss">
  @import "@/sass/mixins.scss";

  .component-comments {
    padding: 60px 190px;
    width: 100%;
    @media only screen and (max-width: 767px){
      padding: 40px 20px;
    }
  }

  .title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 60px;
    color: #808080;

    @media only screen and (max-width: 1030px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 767px){
      font-size: 11px;
      line-height: 11px;
    }
  }

  .type-comment {
    border: 2px #F5F5F5 solid;
    border-radius: 6px;
    @media only screen and (max-width: 767px){
      margin-top: 29px;
    }
  }

  .type-comment-input {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .textarea {
    width: 100%;
    padding: 30px 15px 30px 25px;
    outline: none;
    border: 0;
    resize: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    color: #999999;
    @media only screen and (max-width: 767px){
      font-weight: 400;
      font-size: 11px;
      line-height: 18px;
      font-family: IBMPlexSans;
      padding: 20px 20px;
      padding-left: 6px;
      min-height: 80px;
    }
  }

  .type-comment-ava {
    height: 50px;
    width: 50px;
    margin-left: 20px;
    margin-top: 20px;
    border-radius: 50%;
    @media only screen and (max-width: 767px){
      height: 20px;
      width: 20px;
    }
  }

  .type-comment-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px;
    border-top: 2px #F5F5F5 solid;
    @media only screen and (max-width: 767px){
      padding: 10px 20px;
    }
  }

  .add-items-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .add-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    margin-left: 19px;
    cursor: pointer;
    color: #E6E6E6;

    label {
      height: inherit;
      cursor: pointer;
    }

    &:nth-child(1) {
      margin-left: 0;
    }
    @media only screen and (max-width: 767px){
      margin-left: 10px;
    }
  }

  .button-send {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    letter-spacing: 1.25px;
    margin-left: 27px;
    @media only screen and (max-width: 767px){
      margin-left: 15px;
      letter-spacing: .5px;
    }
  }

  .comments-wrapper {
    margin-top: 60px;
    @media only screen and (max-width: 767px){
      margin-top: 46px;
    }
  }

  .comment {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 45px;
    padding-top: 45px;
    border-top: 2px #F6F6F6 solid;

    &:nth-child(1) {
      padding-top: 0;
      margin-top: 0;
      border-top: 0;
    }

    &.answer {
      margin-left: 57px;

      &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 45px;
        left: -57px;
        display: block;
        height: 26px;
        width: 26px;
        background: url("~@/assets/img/icons/ic_arrow_forward.svg") no-repeat center;
        background-size: 26px;
      }
    }
    @media only screen and (max-width: 767px){
      margin: 0;
      padding: 26px 0 22px;
      &.answer{
        margin-left: 0;
        padding-left: 30px;
        &:before{
          left: 2px;
          top: 19px;
        }
      }
    }
  }

  .comment-center {
    margin-left: 30px;
    @media only screen and (max-width: 767px){
      margin-left: 10px;
    }
  }

  .user-ava {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transform: translateY(-15px);
    @media only screen and (max-width: 767px){
      width: 20px;
      height: 20px;
      transform: translateY(-4px);
      img{
        width: 100%;
      }
    }
  }

  .name-and-date {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .user-name {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #666666;
    @media only screen and (max-width: 767px){
      font-size: 11px;
      line-height: 11px;
    }
  }

  .comment-date {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #999999;
    margin-left: 16px;
    @media only screen and (max-width: 767px){
      font-size: 10px;
      line-height: 14px;
      margin-left: 10px;
    }
  }

  .comment-menu {
    height: 26px;
    width: 26px;
    margin-left: 30px;
    cursor: pointer;
    @media only screen and (max-width: 767px){
      margin-left: 10px;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 15px;
    color: #D8D8D8;
    @media only screen and (max-width: 767px){
      margin-top: 20px;
      justify-content: space-between;
      padding-right: 36px;
    }
  }

  .do-answer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }

  .do-answer-icon {
    width: 26px;
  }

  .do-answer-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    color: #B3B3B3;
    margin-left: 17px;
    @media only screen and (max-width: 767px){
      font-size: 11px;
      line-height: 11px;
    }
  }

  .do-rate {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 81px;
    @media only screen and (max-width: 767px){
      margin-left: 20px;
    }
  }

  .do-rate-like,
  .do-rate-dislike {
    cursor: pointer;
    @media only screen and (max-width: 767px){
      display: none;
    }
  }

  .rate-counter {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    color: #B6B6B6;
    margin: 0 12px;
    &.good{
      color: #008002;
    }
    &.bad{
      color: #FF0100;
    }
    @media only screen and (max-width: 767px){
      font-size: 11px;
      line-height: 11px;
      margin: 0;
    }
  }

  .text {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 30px;
    color: #999999;
    margin-top: 10px;
    @media only screen and (max-width: 767px){
      font-size: 11px;
      line-height: 18px;
    }
  }
  .comment-right{
    @media only screen and (max-width: 767px){
      position: absolute;
      bottom: 25px;
      right: 0;
    }
  }
</style>
