import CommentsApi from "../api/comments";

export const comments = {
  namespaced: true,
  state: {
    commentsCount: 0,

    commentsMap: {},
    commentsLoadStatus: 0,

    comment: {},
    commentAddStatus: 0,

    activeReplyFormParent: null,
  },
  mutations: {
    SET_COMMENTS(state, comments) {
      state.commentsMap = { ...comments.data };
      state.commentsCount = comments.countComments;
      // state.currentPage = comments.meta.current_page;
      // state.commentIndexFrom = comments.meta.from;
      // state.commentIndexTo = comments.meta.to;
      // state.commentsPerPage = comments.meta.per_page;
    },
    SET_COMMENTS_LOAD_STATUS(state, status) {
      state.commentsLoadStatus = status;
    },
    SET_COMMENT_ADD_STATUS(state, status) {
      state.commentAddStatus = status;
    },
    SET_ACTIVE_REPLYFORM_PARENT_ID(state, id) {
      state.activeReplyFormParent = id;
    },
    UPDATE_LIKE(state, payload) {
      console.log(payload);

      for (var i1 in state.commentsMap) {
        state.commentsMap[i1].forEach((item) => {
          if (item.id == payload.id) {
            item.isLiked = payload.isLiked;
            item.likes_total = payload.likes_total;
          }
        });
      }
    },
  },
  actions: {
    loadComments({ commit }, projectId) {
      commit("SET_COMMENTS_LOAD_STATUS", 1);
      return CommentsApi.getComments(projectId)
        .then(function (response) {
          commit("SET_COMMENTS", response.data);
          commit("SET_COMMENTS_LOAD_STATUS", 2);
        })
        .catch(function (e) {
          commit("SET_COMMENTS", {});
          commit("SET_COMMENTS_LOAD_STATUS", 3);
          console.log(e);
        });
    },

    addComment({ commit, dispatch }, payload) {
      commit("SET_COMMENT_ADD_STATUS", 1);
      return CommentsApi.addComment(
        payload.projectId,
        payload.text,
        payload.parentId
      )
        .then(() => {
          commit("SET_COMMENT_ADD_STATUS", 2);
          dispatch("loadComments", payload.projectId);
        })
        .catch((e) => {
          commit("SET_COMMENT_ADD_STATUS", 3);
          console.log(e);
        });
    },

    updateReplyFormParent({ commit, state }, parentId) {
      if (state.activeReplyFormParent === parentId)
        commit("SET_ACTIVE_REPLYFORM_PARENT_ID", null);
      else commit("SET_ACTIVE_REPLYFORM_PARENT_ID", parentId);
    },

    like({ commit }, id) {
      return CommentsApi.like(id).then(({ data }) => {
        commit("UPDATE_LIKE", { id, ...data.data });
      });
    },
    dislike({ commit }, id) {
      return CommentsApi.dislike(id).then(({ data }) => {
        commit("UPDATE_LIKE", { id, ...data.data });
      });
    },
    unlike({ commit }, id) {
      return CommentsApi.unlike(id).then(({ data }) => {
        commit("UPDATE_LIKE", { id, ...data.data });
      });
    },
  },
  getters: {
    getComments: (state) => state.commentsMap[""],
    getCommentsCollection: (state) => state.commentsMap,
  },
};
