<template>
    <div class="component-upload-cost">
        <div class="block ">
            <div class="title">
                {{ i18n.costStructure[$lang] }}
            </div>

            <div class="desc">
                {{ i18n.selectTheFile[$lang] }}
            </div>

            <div class="inputs-wrapper">
                <Button class="button-choose-file primary" @click="handleChooseFileButtonClick">
                    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'button-choose-file-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="ql4tuszx1a" maskUnits="userSpaceOnUse" x="3" y="7" width="24" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.35 13.04A7.49 7.49 0 0015 7c-2.89 0-5.4 1.64-6.65 4.04A5.994 5.994 0 003 17c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 16v4h-4v-4h-3l5-5 5 5h-3z" fill="#fff"/></mask><g mask="url(#ql4tuszx1a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

                    <input ref="input-file" type="file" hidden/>

                    <span class="button-choose-file-label">
                        {{ i18n.chooseTheFile[$lang] }}
                    </span>
                </Button>

                <Button class="normal without-border button-choose-file">
                    <a :href="require('@/assets/files/finance_sample.xlsx')" download>
                        {{i18n.downloadExample[$lang]}}
                    </a>
                </Button>
            </div>
        </div>

        <!--PriceSlider class="block" :value="funding" @input="setFunding"/-->

        <accordeon class="block" :defaultState="typeof capitalCosts.items !== 'undefined' > 0">
            <template slot="visible-part">1. Капитальные затраты</template>
            <template slot="visible-part-desc">Разовые затраты на покупку активов. Например, покупка оборудования.
            </template>
            <template slot="invisible-part">
                <Finmodel
                        item-title="Статья расходов"
                        item-value-title="Сумма"
                        :items="capitalCosts" @input="setCapitalCosts"
                >
                    <template slot="title"></template>
                    <template slot="description">Сколько денег нужно потратить на страте единовременно? Например, на
                        единоразовую покупку оборудования, софта или лицензий?
                    </template>
                </Finmodel>
            </template>
        </accordeon>

        <accordeon :defaultState="typeof fixedCosts.items !== 'undefined'> 0">
            <template slot="visible-part">2. Постоянные затраты</template>
            <template slot="visible-part-desc">Регулярные фиксированные затраты на обслуживание производства. Например,
                аренда офиса и оплата интернета.
            </template>
            <template slot="invisible-part">
                <Finmodel
                        item-title="Статья расходов"
                        item-value-title="Сумма"
                        :items="fixedCosts" @input="setFixedCosts"
                >
                    <template slot="title"></template>
                    <template slot="description">Постоянные затраты — те, которые не зависят от объёма производства.
                        Сколько
                        денег нужно тратить каждый период? Например, аренда помещения или оборудования, оплата
                        интернета,
                        телефонии, ежемесячных лицензий и подписок, проценты по кредитам.
                    </template>
                </Finmodel>
            </template>
        </accordeon>

        <accordeon :defaultState="typeof variableCosts.items !== 'undefined' > 0">
            <template slot="visible-part">3. Переменные затраты</template>
            <template slot="visible-part-desc">То что зависит от объёма производства и продаж. Например, зарплата, налоги, маркетинг.</template>
            <template slot="invisible-part">
                <Finmodel
                        item-title="Статья расходов"
                        item-value-title="Сумма"
                        :items="variableCosts" @input="setVariableCosts"
                >
                    <template slot="title"></template>
                    <template slot="description">Переменные — это те затраты, которые зависят от объёма производства товаров или услуг. Например, покупка материалов или ресурсов, оплата труда сотрудников, маркетинг. Если организация увеличивает выпуск и продажи продукции, то увеличатся и переменные затраты.</template>
                </Finmodel>
            </template>
        </accordeon>

        <accordeon :defaultState="typeof salesForecast.items !== 'undefined' > 0">
            <template slot="visible-part">4. Прогноз продаж</template>
            <template slot="visible-part-desc">Рыночная стоимость единицы продукции и план роста компании</template>
            <template slot="invisible-part">
                <Finmodel
                        item-title="Период"
                        item-value-title="Количество"
                        :items="salesForecast" @input="setSalesForecast"
                >
                    <template slot="title"></template>
                    <template slot="description">Сколько единиц продукции за период вы рассчитываете продавать? Сколько продают ваши конкуренты? Зависит ли бизнес от сезона? Как быстро вы будете расширяться если всё пойдёт хорошо? А если не очень?</template>
                </Finmodel>
            </template>
        </accordeon>

        <accordeon :defaultState="typeof timeline !== 'undefined' ">
            <template slot="visible-part">5. Дорожная карта</template>
            <template slot="visible-part-desc">Этапы, сроки и бюджеты для реализации проекта</template>
            <template slot="invisible-part">
                <Roadmap :items="timeline" @input="setTimeline"/>
            </template>
        </accordeon>
    </div>
</template>

<script>
    import Roadmap from "./components/Roadmap";
    import Finmodel from "./components/Finmodel";
    import PriceSlider from "./components/PriceSlider";
    import VueSlider from 'vue-slider-component';
    import Button from '@/components/Button';
    import Accordeon from "@/components/Accordeon"
    import {mapMutations, mapActions, mapState} from 'vuex';

    export default {
        name: 'UploadCost',
        components: {
            VueSlider,
            Button,
            Roadmap,
            Finmodel,
            PriceSlider,
            Accordeon
        },
        data() {
            return {
                amount: 200000,
                min: 0,
                max: 1000000,
                i18n: {
                    costStructure: {
                        en: 'Cost structure',
                        ru: 'Загрузить расчёт'
                    },
                    selectTheFile: {
                        en: 'Select the file with the cost structure',
                        ru: 'Можно загрузить готовый файл с расчётами по образцу или заполнить каждую часть вручную ниже.'
                    },
                    chooseTheFile: {
                        en: 'Choose the file',
                        ru: 'Выбрать файл'
                    },
                    downloadExample: {
                        ru: "Скачать пример",
                        en: "Download example"
                    },
                    neededAmount: {
                        en: 'Needed amount',
                        ru: 'Необходимая сумма'
                    },
                    inputAmountDesc: {
                        en: 'The affine transformation imposes a negative gap in the function.',
                        ru: 'Аффинное преобразование накладывает отрицательный разрыв функции. '
                    },
                },
            };
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
            ...mapState('projectCreate', {
                capitalCosts: (state) => state.finmodel.capitalCosts,
                fixedCosts: (state) => state.finmodel.fixedCosts,
                variableCosts: (state) => state.finmodel.variableCosts,
                salesForecast: (state) => state.finmodel.salesForecast,
                timeline: (state) => state.timeline,
                finmodel: (state) => state.finmodel,
                funding: (state) => state.funding
            })
        },
        beforeMount() {

        },
        methods: {
            ...mapActions('projectCreate', [
                'storeFinmodel'
            ]),
            ...mapMutations('projectCreate', [
                'SET_CAPITAL_COSTS',
                'SET_FIXED_COSTS',
                'SET_VARIABLE_COSTS',
                'SET_SALES_FORECAST',
                'SET_TIMELINE',
                'SET_FUNDING'
            ]),

            setCapitalCosts(value) {
                this.SET_CAPITAL_COSTS(value);
            },
            setFixedCosts(value) {
                this.SET_FIXED_COSTS(value)
            },
            setVariableCosts(value) {
                this.SET_VARIABLE_COSTS(value)
            },
            setSalesForecast(value) {
                this.SET_SALES_FORECAST(value)
            },
            setTimeline(value) {
                this.SET_TIMELINE({timeline: value});
            },
            setFunding(value) {
                this.SET_FUNDING(value);
            },

            handleChooseFileButtonClick() {
                this.$refs['input-file'].click();
            },

            handleInput(e) {
                const value = parseInt(e.target.value.replace(/ ₽/g, ''));

                if (value > this.max) {
                    this.amount = this.max;

                    return;
                }

                if (value < this.min) {
                    this.amount = 1;
                    this.amount = this.min;
                }
            }
        },
        mounted() {

        }
    };
</script>

<style scoped lang="scss">
    .inputs-wrapper {
        display: flex;
        flex-direction: row;
        @media only screen and (max-width: 767px) {
            flex-direction: column;
            margin-top: 10px;
        }
    }

    .block {
        margin-top: 60px;
        @media only screen and (max-width: 767px) {
            margin-top: 40px !important;
            &:first-child {
                margin-top: 0;
            }
        }
    }

    .component-upload-cost {
        padding: 20px 195px 48px 195px;

        @media only screen and (max-width: 1200px) {
            padding: 60px 95px 48px 95px;
        }
        @media only screen and (max-width: 767px) {
            padding: 2px 20px 40px;
        }
    }

    @import '~vue-slider-component/theme/default.css';

    .title {
        font-size: 14px;
        color: #666666;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 11px;
            line-height: 11px;
            font-weight: 600;
            color: #4D4D4D;
            h3 {
                font-size: 15px;
                line-height: 20px;
                color: #4D4D4D;
            }
        }
    }

    .desc {
        color: #666666;
        margin-top: 18px;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 11px;
            line-height: 18px;
            margin-top: 17px;
        }
    }

    .button-choose-file {
        margin-top: 21px;
        margin-right: 20px;
        width: 225px;
        @media only screen and (max-width: 767px) {
            margin-top: 10px;
            margin-right: 0;
            width: 160px;
            padding: 6px 20px !important;
            font-size: 9px !important;
            letter-spacing: .5px;
        }
    }


    .button-choose-file-icon {
        transform: scale(0.75);
        @media only screen and (max-width: 767px) {
            transform: scale(1);
            width: 28px;
            height: 28px;
        }
    }

    .button-choose-file-label {
        font-size: 12px;
        margin-left: 7px;
        @media only screen and (max-width: 767px) {
            margin-left: 0;
            font-size: 9px;
            line-height: 18px;
            letter-spacing: .5px;
        }
    }

    .input-amount-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 12px;
    }

    .input-amount {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 160px;
        height: 50px;
        padding: 0 20px;
        border: 2px solid #F5F5F5;
        border-radius: 4px;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #666666;
        cursor: text;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .slider {
        width: 100%;
        margin-left: 30px;
    }

    .input-amount-desc {
        font-family: IBMPle Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: #B3B3B3;
        margin-top: 30px;

        @media only screen and (max-width: 1030px) {
            font-size: 11px;
        }
    }
</style>
