<template>
    <div class="component-contacts">
        <div class="block main" data-id="main">
            <div class="title">
                <h3>{{ i18n.main[$lang] }}</h3>
            </div>

            <div class="inputs-wrapper">
                <div class="input-wrapper">
                    <label for="email" class="label">
                        <h5>{{ i18n.email[$lang] }}</h5>
                    </label>

                    <input
                        id="email"
                        class="input input-main"
                        disabled
                        v-model="email"
                    />
                </div>

                <div class="input-wrapper">
                    <label for="phone-number" class="label">
                        <h5>{{ i18n.phoneNumber[$lang] }}</h5>
                    </label>

                    <input
                        id="phone-number"
                        class="input input-main"
                        v-model="phone"
                        disabled
                    />
                </div>
            </div>
        </div>
        <div class="block social-media" data-id="social-networks">
            <div class="title">
                <h3>{{ i18n.socialMedia[$lang] }}</h3>
            </div>

            <div class="inputs-wrapper">
                <div class="input-wrapper">
                    <label for="telegram" class="label">
                        <h5>Telegram</h5>
                    </label>

                    <input id="telegram" class="input" v-model="telegram" />
                </div>

                <div class="input-wrapper">
                    <label for="linkedin" class="label">
                        <h5>Linkedin</h5>
                    </label>

                    <input id="linkedin" class="input" v-model="linkedin" />
                </div>
            </div>
            <div class="inputs-wrapper">
                <div class="input-wrapper">
                    <label for="facebook" class="label">
                        <h5>Facebook</h5>
                    </label>

                    <input id="facebook" class="input" v-model="facebook" />
                </div>

                <div class="input-wrapper">
                    <label for="twitter" class="label">
                        <h5>Twitter</h5>
                    </label>

                    <input id="twitter" class="input" v-model="twitter" />
                </div>
            </div>
        </div>
        <div class="block website" data-id="website">
            <div class="title title-website">
                <h3>{{ i18n.website[$lang] }}</h3>
            </div>

            <div class="input-wrapper input-wrapper-website">
                <label for="url" class="label">
                    <h5>{{ i18n.url[$lang] }}</h5>
                </label>

                <input id="url" class="input" v-model="website" />
            </div>

            <Button class="button-save primary" @click="handleSaveButtonClick" :loading="isLoading">
                {{ i18n.save[$lang] }}
            </Button>
        </div>
    </div>
</template>

<script>
import Button from "@/components/Button";
import { mapState } from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
    name: "Contacts",
    components: {
        Button
    },
    data() {
        return {
            isLoading: false,
            i18n: {
                main: {
                    en: "Main",
                    ru: "Основные"
                },
                email: {
                    en: "Email",
                    ru: "Эл. почта"
                },
                phoneNumber: {
                    en: "Phone number",
                    ru: "Номер телефона"
                },
                socialMedia: {
                    en: "Social media",
                    ru: "Соц. сети"
                },
                website: {
                    en: "Website",
                    ru: "Сайт"
                },
                url: {
                    en: "URL",
                    ru: "Адрес"
                },
                save: {
                    en: "Save",
                    ru: "Сохранить"
                }
            }
        };
    },
    computed: {
        ...mapFields("user",[
            "website",
            "twitter",
            "facebook",
            "linkedin",
            "telegram",
            "phone",
            "email",
        ]),
        ...mapState(["user"]),
        ...mapState({
            $lang: state => state.$lang
        })
    },
    methods: {
        handleSaveButtonClick() {
            this.isLoading=true;
            this.$store.dispatch("user/saveUserData")
            .then(()=>{
                this.isLoading=false
            })
            .catch(()=>{
                this.isLoading=false
            })
        }
    }
};
</script>

<style scoped lang="scss">
.component-contacts {
    padding: 60px 0 0 0;
    @media only screen and (max-width: 767px){
        padding: 25px 0 0 0;
    }
}

.block {
    position: relative;
    padding: 0 90px 0 90px;

    @media only screen and (max-width: 1210px) {
        padding: 0 60px 0 60px;
    }

    @media only screen and (max-width: 1130px) {
        padding: 0 25px 0 25px;
    }

    @media only screen and (max-width: 767px){

    }
}

.main,
.social-media,
.website {
    padding-bottom: 90px;

    @media only screen and (max-width: 767px){
        padding-bottom: 25px;
    }
}

.social-media,
.website {
    padding-top: 65px;
    @media only screen and (max-width: 767px){
padding-top: 25px;
    }
}

.social-media::before,
.website::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-top: 2px #f0f0f0 solid;
}

.title {
    color: #4d4d4d;
    padding-bottom: 10px;

    @media only screen and (max-width: 1030px) {
        font-size: 18px;
    }
}

.title-website {
    padding-bottom: 45px;

    @media only screen and (max-width: 767px){
        padding-bottom: 10px
    }
}


.input-wrapper-website {
    margin-left: 0;

    .input {
        //width: 350px;
    }
}

.label {
    color: #666666;
}

.button-save {
    padding: 00px 65px;
    margin: 55px 0 0 0;
    @media only screen and (max-width: 767px){
        margin: 25px 0 0 0;
    }
}
</style>
