<template>
  <div class="faq">
    <FAQ :items="items" />
  </div>
</template>

<script>
  import FAQ from '@/components/FAQ';
  import { mapState } from 'vuex';

  export default {
    name: 'ProfileFAQ',
    components: {
      FAQ
    },
    data() {
      return {
        items: [
          {
            visiblePart: {
              en: 'How to get money if my project has received approval?',
              ru: 'Как получить деньги, если мой проект получил одобрение?'
            },
            invisiblePart: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
          },
          {
            visiblePart: {
              en: 'What part of the participants\' contributions goes to the implementation of projects?',
              ru: 'Какая часть взносов участников идёт на реализацию проектов?'
            },
            invisiblePart: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
          },
          {
            visiblePart: {
              en: 'How to propose your project for voting?',
              ru: 'Как предложить свой проект на голосование?'
            },
            invisiblePart: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
          }
        ]
      }
    },
    methods: {
      handleFaqItemClick(e) {
        const parentElem = e.currentTarget;
        const invisibleElem = parentElem.querySelector('.faq-item-invisible-part');
        const action = invisibleElem.classList.contains('is-active') ? 'remove' : 'add';

        invisibleElem.classList[action]('is-active');
      }
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    }
  };
</script>

<style scoped lang="scss">
  .faq {
    margin-top: 60px;
    width: 100%;
    @media only screen and (max-width: 767px){
      margin-top: 20px;
    }
  }

  .faq-item {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: #666666;
    border-bottom: 2px solid #F5F5F5;
    width: 100%;
    min-height: 88px;
    padding-left: 30px;
    padding-right: 60px;
    cursor: pointer;

    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      height: 30px;
      width: 30px;
      top: 0;
      bottom: 0;
      right: 30px;
      margin: auto;
      background: url("~@/assets/img/icons/ic_plus.svg") no-repeat center;
      background-size: 26px;
    }

    &:nth-child(1) {
      border-top: 2px solid #F5F5F5;
    }

    @media only screen and (max-width: 1030px) {
      font-size: 16px;
    }
  }

  .faq-item-visible-part {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 88px;
  }

  .faq-item-invisible-part {
    height: 0;
    overflow: hidden;
    transition: height 0.3s ease;

    &.is-active {
      height: 88px;
    }
  }
</style>
