<template>
    <div class="view-new-project-step">
        <Header class="header" page="create-a-project"/>

<!--        <BurgerMenu/>-->

        <div class="step-main">
            <div class="step-nav">
                <StepNavigation :active-step="1"/>
            </div>
            <div class="step-content-wrapper">
                <div class="step-content">
                    <div class="step-cover">
                        <div class="">
                            <h5>
                                {{ i18n.cover[$lang] }}
                            </h5>
                        </div>
                        <div class="descr-cover">
                            <p>{{ i18n.coverDescr[$lang] }}</p>
                        </div>
                        <div class="choose-file ">
                            <Button class="choose-file-button normal" @click="handleChooseFileClick">
                                <input
                                        ref="input-file"
                                        type="file"
                                        accept="image/*"
                                        hidden
                                        @change="handleFileInputChange"
                                />

                                <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'choose-file-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="zkpkjhsgma" maskUnits="userSpaceOnUse" x="3" y="7" width="24" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.35 13.04A7.49 7.49 0 0015 7c-2.89 0-5.4 1.64-6.65 4.04A5.994 5.994 0 003 17c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 16v4h-4v-4h-3l5-5 5 5h-3z" fill="#fff"/></mask><g mask="url(#zkpkjhsgma)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

                                <div>
                                    {{ i18n.chooseFile[$lang] }}
                                </div>
                            </Button>

                            <div v-if="showFileInfo" class="cur-file-wrapper">
                                <div class="cur-file">
                                    {{ fileName }}
                                </div>

                                <div class="cur-file-kb">
                                    {{ fileSize }}
                                </div>

                                <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'cur-file-button-img'" @click="handleFileInfoClearClick" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="xpnidqe45a" maskUnits="userSpaceOnUse" x="8" y="8" width="14" height="14"><path fill-rule="evenodd" clip-rule="evenodd" d="M22 9.41L20.59 8 15 13.59 9.41 8 8 9.41 13.59 15 8 20.59 9.41 22 15 16.41 20.59 22 22 20.59 16.41 15 22 9.41z" fill="#fff"/></mask><g mask="url(#xpnidqe45a)"><path d="M2 2h26v26H2z"/></g></svg>
                            </div>
                        </div>
                    </div>
                    <div class="step-project-name">
                        <div class="input-wrapper">
                            <label for="name" class="label">
                                {{ i18n.projectName[$lang] }}
                            </label>

                            <input id="name" v-model="name" class="input"/>
                        </div>
                        <div class="project-name-descr descr">
                            {{ i18n.projectNameDescr[$lang] }}
                        </div>
                    </div>
                    <div class="step-short-descr">
                        <div class="textarea-wrapper">
                            <label for="short-descr" class="label">
                                {{ i18n.shortDescriptionLabel[$lang] }}
                            </label>

                            <textarea id="short-descr" v-model="description" class="textarea"/>
                        </div>
                        <div class="short-descr descr">
                            {{ i18n.shortDescription[$lang] }}
                        </div>
                    </div>
                    <div class="step-launch-date">
                        <div class="input-wrapper">
                            <label for="date" class="label">
                                {{ i18n.launchDate[$lang] }}
                            </label>

                            <input id="date" v-model="date_start" type="date" class="input input-date"/>
                        </div>
                        <div class="step-category">
                            <div class="title">
                                {{ i18n.category[$lang] }}
                            </div>
                            <div class="input-wrapper category-wrapper">
                                <v-select
                                        id="choose"
                                        :placeholder="i18n.choose[$lang]"
                                        :options="categoriesMap"
                                        label="name"
                                        item-value="id"
                                        :clearable="false"
                                        class="select"
                                        @input="setSelectedCategoryId"
                                        v-model="category_id"
                                        :reduce="name => name.id"
                                >
                                    <template #open-indicator="{ attributes }">
                                      <span v-bind="attributes">
                                        <svg width="9" height="5" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.498 5L9 2.5V.104L4.498 2.62 0 0v2.5L4.498 5z" fill="#D9D9D9"/></svg>
                                      </span>
                                    </template>
                                </v-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step-buttons">
                    <Button class="choose-file-button normal btn-back" @click="handleButtonBackClick('')">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="wpudahn1ma" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M23 14H10.83l5.59-5.59L15 7l-8 8 8 8 1.41-1.41L10.83 16H23v-2z" fill="#fff"/></mask><g mask="url(#wpudahn1ma)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

                        <span>
                            {{ i18n.back[$lang] }}
                        </span>
                    </Button>

                    <Button class="choose-file-button normal primary" :loading="descriptionStoreStatus===1"
                            @click="handleButtonNextClick('')">
                        <span>
                            {{ i18n.presentation[$lang] }}
                        </span>

                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="5plwoj96pa" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 7l-1.41 1.41L19.17 14H7v2h12.17l-5.58 5.59L15 23l8-8-8-8z" fill="#fff"/></mask><g mask="url(#5plwoj96pa)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                    </Button>
                </div>
            </div>
        </div>

        <Footer class="footer"/>
    </div>
</template>

<script>
    import Header from '@/components/Header';
    import BurgerMenu from '@/components/BurgerMenu';
    import Footer from '@/components/Footer';
    import Button from '@/components/Button';
    import StepNavigation from '@/components/StepNavigation';
    import {mapActions} from 'vuex';
    import {mapState} from 'vuex';
    import {mapFields} from 'vuex-map-fields';


    export default {
        name: 'NewProjectStep',
        components: {
            Header,
            BurgerMenu,
            Footer,
            StepNavigation,
            Button,
        },
        data() {
            return {
                fileName: '',
                fileSize: '',
                showFileInfo: false,
                i18n: {
                    cover: {
                        en: 'Cover',
                        ru: 'Обложка'
                    },
                    coverDescr: {
                        en: 'Choose an image that will present your project',
                        ru: 'Выберите фото которое будет представлять ваш проект'
                    },
                    chooseFile: {
                        en: 'Choose file',
                        ru: 'Выбрать файл'
                    },
                    projectName: {
                        en: 'Project name',
                        ru: 'Название проекта'
                    },
                    projectNameDescr: {
                        en: 'For example «Restaurant in west Brooklyn»',
                        ru: 'Например «Пиццерия в восточном Бруклине» или «Приложение для учёта рабочего времени»'
                    },
                    shortDescriptionLabel: {
                        en: 'Short description',
                        ru: 'Краткое описание'
                    },
                    shortDescription: {
                        en: 'Describe your idea in 1-2 sentences',
                        ru: 'В одном-двух предложениях опишите задачи и суть проекта'
                    },
                    launchDate: {
                        en: 'Launch date',
                        ru: 'Дата запуска'
                    },
                    category: {
                        en: 'Category',
                        ru: 'Категория'
                    },
                    choose: {
                        en: 'Choose',
                        ru: 'Выбрать'
                    },
                    back: {
                        en: 'back',
                        ru: 'Назад'
                    },
                    presentation: {
                        en: 'Presentation',
                        ru: 'Презентация'
                    },


                },
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
            ...mapState('categories', [
                'categoriesMap',
                'categoriesLoadStatus'
            ]),
            ...mapFields('projectCreate', [
                'id',
                'image',
                'name',
                'description',
                'category_id',
                'date_start',
                'descriptionStoreStatus'
            ])
        },
        methods: {
            ...mapActions('projectCreate', [
                'storeDescription',
                'getFormData',
                'fetchProjectDraft'
            ]),
            ...mapActions('categories', [
                'fetchCategoriesMap'
            ]),

            handleFileInfoClearClick() {
                this.showFileInfo = false;
                this.fileSize = '';
                this.fileName = '';
                this.$refs['input-file'].value = '';
            },

            handleChooseFileClick() {
                this.$refs['input-file'].click();
            },

            handleFileInputChange(target) {
                const input = target.target;

                if (!input.files || !input.files[0]) {
                    return;
                }

                const file = input.files[0];

                const size = (file.size / 1024 / 1024).toFixed(2);
                const name = file.name;

                this.image = file;
                this.fileSize = `${size} MB`;
                this.fileName = name;
                this.showFileInfo = true;
            },

            handleButtonNextClick() {
                this.getFormData()
                    .then(() => {
                        this.$router.push('new-project-step-2');
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            },

            handleButtonBackClick() {
                this.$router.go(-1);
            },

            setSelectedCategoryId(value) {
                this.category_id = value;
            },

        },
        mounted() {
            this.fetchCategoriesMap();
            this.fetchProjectDraft(this.id);
        }
    };
</script>

<style scoped lang="scss">

    .step-main {
        padding: 120px 165px 0 165px;
        @media only screen and (max-width: 767px){
            padding: 70px 0 0;
        }
    }

    .step-content-wrapper {
        box-shadow: 0 10px 30px -2px #0000000d;
        border-radius: 6px;
        background: #fff;
        @media only screen and (max-width: 767px){
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
        }
    }

    .step-content {
        padding: 0 0 70px 188px;
        margin-top: 30px;

        @media only screen and (max-width: 1200px) {
            padding: 0 0 70px 90px;
        }
        @media only screen and (max-width: 767px){
            padding: 42px 20px 40px;
            margin-top: 10px;
        }
    }

    .descr-cover {
        color: #666;
        @media only screen and (max-width: 767px){
            margin-top: 17px;
        }
    }

    .step-cover {
        padding: 95px 0 0;
        @media only screen and (max-width: 767px){
            padding: 0;
            &>div{
                &>h5{
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 11px;
                    color: #666666;
                }
            }
        }
    }

    .title {
        padding-bottom: 25px;
    }

    .choose-file {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 20px 0 0;

        .cur-file-wrapper {
            display: flex;
            align-items: center;
        }

        .choose-file-button {
            margin-right: 30px;
        }
        @media only screen and (max-width: 767px){
            flex-wrap: wrap;
            position: relative;
            .choose-file-button{

            }
            .cur-file-wrapper{
                margin-top: 10px;
                width: 100%;
                font-weight: 400;
                font-size: 11px;
                line-height: 18px;
                .cur-file-button-img{
                    position: absolute;
                    top: 25px;
                    left: 180px;
                }
            }
            .choose-file-button{
                padding: 3px 19px !important;
                font-size: 9px !important;
                line-height: 18px;
                letter-spacing: .5px;
                font-weight: 600;
                max-width: 170px;
                width: 100%;
            }
        }
    }

    .step-project-name,
    .step-short-descr,
    .step-launch-date {
        padding: 50px 0 0;
        display: flex;
        justify-content: flex-start;

        .input-wrapper {
            width: 60%;

            .input {
                max-width: 545px;
                width: 100%;
            }
        }
        @media only screen and (max-width: 767px){
            padding: 40px 0 0;
            flex-wrap: wrap;
            .input-wrapper{
                width: 100%;
            }
        }
    }

    .label, .title {
        color: #666666;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .label{
        @media only screen and (max-width: 767px){
            font-size: 11px;
            line-height: 11px;
            font-weight: 600;
        }
    }

    .input,
    .textarea {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 2px solid #F5F5F5;
        border-radius: 4px;
        margin-top: 10px;
        width: 250px;
        height: 50px;
        padding: 0 15px;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #666666;
        cursor: text;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px){
            padding: 10px;
            margin-top: 17px;
            font-size: 11px;
            line-height: 18px;
            height: 40px;
            border-color: #EBEBEB;
        }
    }

    .textarea-wrapper {
        width: 60%;

        .textarea {
            resize: none;
            line-height: 30px;
            padding: 5px 20px;
            margin-top: 15px;
            max-width: 545px;
            width: 100%;
            min-height: 120px;
        }
        @media only screen and (max-width: 767px){
            width: 100%;
            .textarea{
                margin-top: 17px;
                min-height: 114px;
                padding: 10px;
            }
        }
    }

    .input-date {
        position: relative;
        @media only screen and (max-width: 767px){
            padding: 10px 12px 10px 33px;
            /*&:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 30px;
                height: 30px;
                background-image: url('~@/assets/img/icons/ic_today.svg');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }*/
        }
    }

    .step-launch-date {

        .input-wrapper {
            width: 180px;

            .input {
                width: 180px;
                margin-top: 25px;
                @media only screen and (max-width: 767px){
                    width: 170px;
                    margin-top: 17px;
                }
            }
        }

        .category-wrapper {
            display: flex;
            width: 100%;

            .label {
                padding: 20px 125px 0 15px;
            }
        }

    }

    .cur-file-wrapper {
        font-weight: 500;
        font-size: 12px;
        line-height: 30px;
        color: #999999;

        .cur-file {
            margin-right: 10px;
        }

        .cur-file-kb {
            margin-right: 5px;
        }

        @media only screen and (max-width: 1030px) {
            font-size: 11px;
        }
    }

    .descr {
        width: 30%;
        margin: 30px 0 0 20px;
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        color: #B3B3B3;

        @media only screen and (max-width: 1030px) {
            font-size: 11px;
        }
        @media only screen and (max-width: 767px){
            width: 100%;
            margin: 10px 0 0 0;
            font-size: 10px;
            line-height: 14px;
            font-weight: 400;
        }
    }

    .step-category {
        margin-left: 35px;
        width: 350px;
        @media only screen and (max-width: 767px){
            width: 100%;
            margin-left: 0;
            margin-top: 40px;
            .title{
                font-size: 11px;
                line-height: 11px;
                color: #666666;
                font-weight: 600;
                padding-bottom: 17px;
            }
        }
    }

    .select {
        width: 100%;
        cursor: pointer;

        /deep/ .vs__open-indicator {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        /deep/ .vs__dropdown-toggle {
            cursor: pointer;
        }


        /deep/ .vs__selected-options {
            padding: 10px 2px;
        }

        /deep/ .vs__actions {
            padding: 0 15px 0 3px;
        }
        @media only screen and (max-width: 767px){
            font-size: 11px;
            line-height: 18px;
        }
    }

    .step-buttons {
        display: flex;
        position: relative;
        // justify-content: flex-start;
        // padding: 48px 0 48px 35%;
        justify-content: center;
        padding: 48px 0 48px 0;

        .choose-file-button {

            &:nth-child(1) {
                margin-right: 30px;
            }
        }
        @media only screen and (max-width: 767px){
            padding: 18px 20px;
            justify-content: space-between;
            .btn-back{
                span{
                    display: none;
                }
            }
            .choose-file-button{
                padding: 5px 6px !important;
                svg{
                    position: relative;
                    width: 24px;
                    height: 24px;
                    top: -3px;
                    left: -3px;
                }
                &.primary{
                    min-width: 183px;
                }
            }
        }
    }

    .step-buttons::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-top: 2px #F0F0F0 solid;
        @media only screen and (max-width: 767px){
            border-top-color: #EBEBEB;
        }
    }

    .cur-file-button-img {
        cursor: pointer;
    }

    input[type="date"]::-webkit-calendar-picker-indicator {
        color: rgba(0, 0, 0, 0);
        opacity: 1;
        display: block;
        background-image: url('~@/assets/img/icons/ic_today.svg');
        width: 30px;
        height: 30px;
        border-width: thin;
        @media only screen and (max-width: 767px){
            position: absolute;
            left: -25px;
        }
    }
</style>
