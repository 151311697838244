<template>
    <div class="view-new-project-step-2">
        <Header class="header" page="create-a-project"/>

<!--        <BurgerMenu/>-->

        <div class="step-main">
            <div class="step-nav">
                <StepNavigation :active-step="2"/>
            </div>
            <div class="step-content-wrapper">
                <div class="step-content">
                    <TiptapEditor v-model="html"/>
                </div>

                <div class="step-buttons">
                    <Button
                            class="step-button btn-back"
                            @click="handleButtonBackClick('description')"
                    >
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'step-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="594zyny89a" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M23 14H10.83l5.59-5.59L15 7l-8 8 8 8 1.41-1.41L10.83 16H23v-2z" fill="#fff"/></mask><g mask="url(#594zyny89a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

                        <span class="step-button-label">
                            {{ i18n.description[$lang] }}
                        </span>
                    </Button>

                    <Button
                            class="step-button primary"
                            :loading="presentationStoreStatus===1"
                            @click="handleButtonNextClick('financial')"
                    >
                        <span class="step-button-label">
                            {{ i18n.financialPart[$lang] }}
                        </span>

                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'step-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="rcavbyhyka" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 7l-1.41 1.41L19.17 14H7v2h12.17l-5.58 5.59L15 23l8-8-8-8z" fill="#fff"/></mask><g mask="url(#rcavbyhyka)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                    </Button>
                </div>
            </div>
        </div>
        <Footer class="footer"/>
    </div>
</template>

<script>
    import Header from '@/components/Header';
    import BurgerMenu from '@/components/BurgerMenu';
    import Footer from '@/components/Footer';
    import Button from '@/components/Button';
    import StepNavigation from '@/components/StepNavigation';
    import {mapState} from 'vuex';
    import {mapActions} from 'vuex';
    import {mapFields} from 'vuex-map-fields';
    import TiptapEditor from "@/components/TiptapEditor";

    export default {
        name: 'NewProjectStep',
        components: {
            Header,
            BurgerMenu,
            Footer,
            StepNavigation,
            Button,
            TiptapEditor
        },

        data() {
            return {
                secondChange: '',

                i18n: {
                    description: {
                        en: 'description',
                        ru: 'Описание'
                    },
                    financialPart: {
                        en: 'financial part',
                        ru: 'Финансовая часть'
                    },
                },
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
            ...mapFields('projectCreate', [
                'id',
                'presentationStoreStatus',
                'html'
            ])
        },
        mounted() {
        },
        methods: {
            ...mapActions('projectCreate', [
                'storePresentation'
            ]),

            handleButtonNextClick() {
                this.storePresentation()
                    .then(() => {
                        this.$router.push('new-project-step-3');
                    })
                    .catch((e) => {
                        console.log(e)
                    })
            },

            handleButtonBackClick() {
                this.$router.push('new-project-step');
            },
        }
    };
</script>

<style scoped lang="scss">
    .step-main {
        padding: 120px 165px 0 165px;
        @media only screen and (max-width: 767px){
            padding: 70px 0 0;
        }
    }

    .step-content-wrapper {
        background: #fff;
        box-shadow: 0 10px 30px -2px #0000000d;
        border-radius: 6px;
    }

    .step-content {
        padding: 0 0 70px 0;
        margin-top: 30px;
        @media only screen and (max-width: 767px){
            padding: 0;
            margin-top: 10px;
        }
    }


    .step-buttons {
        display: flex;
        position: relative;
        justify-content: flex-start;
        padding: 61px 0 48px 28%;

        .step-button {
            padding: 8px 25px;

            &:nth-child(1) {
                margin-right: 30px;
            }
        }
        @media only screen and (max-width: 767px){
            padding: 18px 20px;
            justify-content: space-between;
            .btn-back{
                span{
                    display: none;
                }
            }
            .step-button{
                padding: 5px 6px !important;
                svg{
                    position: relative;
                    width: 24px;
                    height: 24px;
                    top: -3px;
                    left: -3px;
                }
                &.primary{
                    min-width: 183px;
                }
            }
        }
    }

    .step-buttons::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-top: 2px #F0F0F0 solid;
        @media only screen and (max-width: 767px){
            border-top-color: #EBEBEB;
        }
    }

    .problem-button {
        border-radius: 4px;

        &:hover {
            background-color: #F7F7F7 !important;
            border-color: #F7F7F7 !important;
        }
    }

    .problem-button-label {
        font-weight: bold;
        font-size: 10px;
        padding: 0 15px;
        line-height: 25px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: #B3B3B3;
    }
</style>

<style lang="scss">
    .view-new-project-step-2 {
        .ql-toolbar.ql-snow {
            border: 0;
            border-bottom: 2px #F0F0F0 solid;
        }

        .ql-editor {
            min-height: 50vh;
        }

        .ql-container.ql-snow {
            border: 0;
        }
    }
</style>
