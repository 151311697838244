<template>
    <div class="nav">
        <router-link
            v-for="(itemCur, index) in items[$lang]"
            :key="index"
            :to="itemCur.link"
            :class="{'nav-item':true, 'is-active': index === Object.keys(items).length}"
        >
            <div class="nav-item-label">
                {{ itemCur.label }}
            </div>

            <div v-if="index !== items.length - 1" class="nav-item-arrow"/>
        </router-link>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'Nav',
        props: {
            items: {
                type: [Array, Object],
                required: true,
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
        }
    };
</script>

<style scoped lang="scss">
    .nav {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 60px;
        padding: 0 28px;
        margin: 120px 0 0 0;
        @media only screen and (max-width: 767px){
            display: none;
        }
    }

    .nav-item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #B3B3B3;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }

        .nav-item-arrow {
            width: 26px;
            height: 26px;
            background: url("~@/assets/img/icons/ic_keyboard_arrow_right.svg") no-repeat center;
            margin-left: 12px;
            margin-right: 12px;
        }

        &.is-active {
            color: #808080;

            .nav-item-arrow {
                display: none;
            }
        }
    }
</style>
