<template>
  <div class="table-wrapper">
    <div class="table">
      <!-- header -->
      <div class="table-item header" />
      <div class="table-item header">
        Lorem
      </div>
      <div class="table-item header">
        Lorem
      </div>
      <div class="table-item header">
        Lorem
      </div>

      <!-- price -->
      <div class="table-item">
        Цена
      </div>

      <div class="table-item">
        <div class="price">
          <div class="price-value">
            1500
          </div>

          <div class="price-desc">
            Per mouth
          </div>
        </div>
      </div>

      <div class="table-item">
        <div class="price">
          <div class="price-value">
            750
          </div>

          <div class="price-desc">
            Per mouth
          </div>
        </div>
      </div>

      <div class="table-item">
        <div class="price">
          <div class="price-value">
            7500
          </div>

          <div class="price-desc">
            Per mouth
          </div>
        </div>
      </div>

      <!-- other -->
      <div class="table-item">
        Lorem
      </div>

      <div class="table-item bold">
        10
      </div>

      <div class="table-item bold">
        20
      </div>

      <div class="table-item bold">
        30
      </div>

      <div class="table-item">
        Lorem
      </div>

      <div class="table-item">
        <img src="@/assets/img/icons/ic_done.svg" alt="" />
      </div>

      <div class="table-item">
        <img src="@/assets/img/icons/ic_done.svg" alt="" />
      </div>

      <div class="table-item">
        <img src="@/assets/img/icons/ic_done.svg" alt="" />
      </div>

      <div class="table-item">
        Lorem
      </div>

      <div class="table-item">
        <img src="@/assets/img/icons/ic_clear.svg" alt="" />
      </div>

      <div class="table-item">
        <img src="@/assets/img/icons/ic_done.svg" alt="" />
      </div>

      <div class="table-item">
        <img src="@/assets/img/icons/ic_done.svg" alt="" />
      </div>

      <div class="table-item">
        Lorem
      </div>

      <div class="table-item">
        <img src="@/assets/img/icons/ic_clear.svg" alt="" />
      </div>

      <div class="table-item">
        <img src="@/assets/img/icons/ic_clear.svg" alt="" />
      </div>

      <div class="table-item">
        <img src="@/assets/img/icons/ic_done.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Table'
  };
</script>

<style scoped lang="scss">
  .table-wrapper {
    border: 2px solid #F5F5F5;
    border-radius: 6px;
    margin-top: 30px;
  }

  .table {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 88px 148px;
    grid-auto-rows: 60px;
  }

  .table-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: #999999;
    border-right: 2px solid #F5F5F5;

  &.bold {
     color: #808080;
     font-weight: 500;
     font-size: 16px;
   }
  }

  .table-item:nth-child(-n + 12) {
    border-top: 2px solid #F5F5F5;
  }

  .table-item:nth-child(-n + 4) {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #808080;
    border-top: 0;
    justify-content: flex-start;
    padding-left: 48px;
  }

  .table-item:nth-child(4n) {
    border-right: 0;
  }

  .table-item:nth-child(4n + 1) {
    justify-content: flex-end;
    padding-right: 22px;
  }

  .price-value {
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: #4D4D4D;
  }

  .price-desc {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 1;
    margin-top: 5px;
    color: #B3B3B3;
  }
</style>
