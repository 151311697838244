<template>
  <div class="team">
    <div v-for="memberCur in team" :key="memberCur.name" class="team-member">
      <div class="team-member-ava">
        <img :src="memberCur.imgSrc" alt="" />
      </div>

      <div class="team-member-about">
        <div class="team-member-name">
          {{ memberCur.name }}
        </div>

        <div class="team-member-desc">
          {{ memberCur.desc }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Team',
    data() {
      return {
        team: [
          {
            imgSrc: require('@/assets/img/team-ava.png'),
            name: 'Denis Rubakin',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
          },
          {
            imgSrc: require('@/assets/img/team-ava2.png'),
            name: 'Metis Rubakin',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
          },
          {
            imgSrc: require('@/assets/img/team-ava3.png'),
            name: 'Karniz Rubakin',
            desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
          }
        ]
      }
    }
  };
</script>

<style scoped lang="scss">
  .team {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 52px;
    @media only screen and (max-width: 767px){
      margin-top: 20px;
    }
  }

  .team-member {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 65px;
    margin-top: 10px;

    &:nth-child(1) {
      margin-left: 0;
    }

    @media only screen and (max-width: 1409px) {
      margin-left: 0;
    }
    @media only screen and (max-width: 767px){
      margin-top: 20px;
    }
  }

  .team-member-ava {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    @media only screen and (max-width: 767px){
      height: 40px;
      width: 40px;
      img{
        width: 100%;
      }
    }
  }

  .team-member-about {
    margin-left: 20px;
    width: 120px;
    @media only screen and (max-width: 767px){
      margin-left: 10px;
    }
    @media only screen and (max-width: 479px){
      width: 100%;
    }
  }

  .team-member-name {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #666666;
    @media only screen and (max-width: 767px){
      font-size: 11px;
      line-height: 11px;
    }
  }

  .team-member-desc {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #B3B3B3;
    margin-top: 7px;
    @media only screen and (max-width: 767px){
      font-size: 10px;
      line-height: 14px;
    }
  }
</style>
