<template>
    <DiagramWrapper class="wrapper" :header-text="headerText">
        <div class="lines">
            <canvas :id="diagramId" width="800" height="500"/>
        </div>

        <div class="legend">
            <div class="legend-item">
                <div class="legend-item-dot" style="background: #50CAB6"/>
                <div class="legend-item-label">
                    {{ i18n.income[$lang] }}
                </div>
            </div>

            <div class="legend-item">
                <div class="legend-item-dot" style="background: #CA5074"/>
                <div class="legend-item-label">
                    {{ i18n.costs[$lang] }}
                </div>
            </div>
        </div>
    </DiagramWrapper>
</template>

<script>
    import Chart from 'chart.js';
    import DiagramWrapper from '../DiagramWrapper';
    import {mapState} from 'vuex';

    export default {
        name: 'LineDiagram',
        components: {DiagramWrapper},
        props: {
            diagramId: {
                type: String,
                required: true
            },
            headerText: {
                type: [String, Object],
                required: true
            },
            data: {
                required: true,
                type: [Object, Array]
            }
        },
        data() {
            return {
                labels: [],
                diagramData1: [],
                diagramData2: [],
                i18n: {
                    costs: {
                        en: 'Costs',
                        ru: 'Расход'
                    },
                    income: {
                        en: 'Income',
                        ru: 'Доход'
                    }
                }
            };
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang
            }),
        },
        mounted() {

            const ctx = document.getElementById(this.diagramId);
            const income = this.getIncomeData();
            const expence = this.getExpenceData();
            const myChart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: income.labels,
                    datasets: [
                        this.getDataSet(income.data, '#50CAB6'),
                        this.getDataSet(expence.data, '#CA5074')
                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    },
                    elements: {
                        point: {
                            radius: 0
                        }
                    }
                }
            });
        },
        methods: {
            getIncomeData() {
                const income = this.data.salesForecast.items.reduce((acc, cur) => {
                    acc.labels.push(cur.title);
                    acc.data.push(cur.value * this.data.salesForecast.averageCheck);
                    return acc;
                }, {labels: [], data: []});
                return income
            },
            getExpenceData() {
                const income = this.data.salesForecast.items.reduce((acc, cur) => {
                    acc.labels.push(cur.title);
                    acc.data.push(cur.value * this.data.digest.variableCosts);
                    return acc;
                }, {labels: [], data: []});
                return income
            },
            getDataSet(data, color) {
                return {
                    label: '',
                    data,
                    backgroundColor: color,
                    borderColor: color,
                    fill: false
                };
            },
            getColors() {
                const colors = [];

                for (let i = 0; i < this.diagramData.data.length; i++) {
                    colors.push('#606EFF');
                }

                return colors;
            }
        }
    };
</script>

<style scoped lang="scss">
    .wrapper {
        padding-bottom: 0;
    }

    .bar {
        height: 500px;
        max-width: 800px;
        width: 100%;
    }

    .legend {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 58px;
        border-top: 2px solid #F5F5F5;
        border-radius: 6px;
        width: 100%;
        margin-top: 15px;
    }

    .legend-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 30px;

        &:nth-child(1) {
            margin-left: 0;
        }
    }

    .legend-item-dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
    }

    .legend-item-label {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        color: #B3B3B3;
        margin-left: 8px;
    }
</style>
