import Vue from "vue";

import Vuex from "vuex";

Vue.use(Vuex);

import {getField, updateField} from "vuex-map-fields";

import createPersistedState from "vuex-persistedstate";

import noty from "./_noty";
import user from "./user";
import location from "./location";
import categories from "./categories";
import projectCreate from "./projectCreate";
import files from "./files";
import projects from "./_projects";
import {comments} from "./_comments";

export default new Vuex.Store({
    strict: true,
    modules: {
        noty,
        user,
        location,
        projects,
        comments,
        categories,
        projectCreate,
        files
    },
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
        }),
    ],
    state: {
        $activeModal: null,
        $lang: "en",
    },
    actions: {},
    mutations: {
        set(state, lang) {
            state.$lang = lang;
        },
        updateField,
    },
    getters: {
        getField,
        isModalLoginOpened: (state) => state.$activeModal === 'login',
        isModalSignupOpened: (state) => state.$activeModal === 'signup'
    },
});
