<template>
    <div class="view-main-component-slide-container">
        <div
            :style="{ backgroundImage: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.5)), url(\'' + project.image_link + '\')' }"
            class="view-main-component-slide">
            <div class="buttons-wrapper">
                <div class="buttons-in-corners">
                    <ButtonShare
                        class="button-share"
                        variant="big"
                    />

                    <toggle-favourite
                        class="button-favorite"
                        :projectId="$route.params.id"
                        variant="big"
                    />
                </div>

                <div class="buttons-in-center">
                    <Button v-for="tag in project.tags" class="slide-button small">
                        {{tag}}
                    </Button>
                </div>
            </div>

            <div class="slide-title">
                <h1>{{ project.title }}</h1>
            </div>

            <div class="slide-line"/>

            <div class="slide-subtitle text-lead-2">
                {{ project.description }}
            </div>

            <div class="slide-footer">
                <div class="progress-bar">
                    <div class="progress-bar-active"
                         :style="{width: progressBarValue + '%'}"
                    />
                </div>

                <div class="people-wrapper">
                    <div v-if="Array.isArray(project.votes)" class="faces-wrapper">
                        <div v-for="face in project.votes.avatars" :key="face" class="face">

                            <img :src="face"/>
                        </div>
                    </div>

                    <div class="number-of-people">
                        <h5>{{project.votes.count}}+ {{ i18n.supported[$lang] }}</h5>
                    </div>

                    <div class="author">
                        <h5>{{ i18n.author[$lang] }}
                            {{ project.owner.fullname }}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ToggleFavourite from "@/components/ToggleFavourite";
    import {mapState} from "vuex";
    import ButtonShare from "@/components/ButtonShare/";
    import Button from "@/components/Button";

    export default {
        name: "Slide",
        components: {
            ButtonShare,
            ToggleFavourite,
            Button
        },

        props: {
            arrayOfPeople: {
                type: Array,
                default:()=>([]),
            },
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
            ...mapState('projects', [
                'project'
            ]),
            progressBarValue() {
                return this.project.stats.rating;
            },
            userVotes() {
                return this.project.votes;
            }
        },
        data() {
            return {
                i18n: {
                    drones: {
                        en: 'Drones',
                        ru: 'Дроны'
                    },
                    technologies: {
                        en: 'Technologies',
                        ru: 'Технологии'
                    },
                    culture: {
                        en: 'Culture',
                        ru: 'Культура'
                    },
                    slideTitle: {
                        en: 'Lorem ipsum dolor sit amet',
                        ru: 'Полностью автономные летательные аппараты'
                    },
                    slideSubTitle: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
                        ru: 'Современная парадигма робототехники неэффективна и ограничивает рост многих отраслей'
                    },
                    supported: {
                        en: 'Supported',
                        ru: 'поддержали'
                    },
                    author: {
                        en: 'Author:',
                        ru: 'Автор:'
                    },
                }
            }
        },
    };
</script>

<style scoped lang="scss">
    @import "@/sass/mixins.scss";

    .view-main-component-slide {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        background-size: cover;
        margin-top: 30px;
        border-radius: 6px;
        overflow: hidden;
        padding: 0 0 30px;
        @media only screen and (max-width: 767px){
            padding: 0 10px 14px;
            margin-top: 0;
        }
    }
    .view-main-component-slide-container{
        @media only screen and (max-width: 767px){
            margin: 0 10px;
        }
    }

    .button-share {
        position: absolute;
        z-index: 1;
        top: 60px;
        left: 60px;
    }

    .button-favorite {
        position: absolute;
        z-index: 1;
        top: 60px;
        right: 60px;
    }

    .button-share {
        position: absolute;
        z-index: 1;
        top: 60px;
        left: 60px;
    }

    .slide-button {
        float: left;
        height: 25px;
        padding: 0 13px;
        border-radius: 4px;
        text-align: center;

        text-transform: uppercase;
        @include mark-default;
        @media only screen and (max-width: 767px){
            min-width: 60px;
            border: 2px solid transparent;
            background-color: rgba(51, 51, 51, .5);
            margin-right: 6px;
            margin-bottom: 6px;
            height: auto;
        }
    }
    .buttons-in-corners{
        @media only screen and (max-width: 767px){
            display: none;
        }
    }
    .buttons-in-center {
        padding-top: 60px;
        text-align: center;
        @media only screen and (max-width: 767px){
            padding-top: 29px;
        }
    }

    .slide-title {
        margin-top: 57px;
        font-family: Raleway;
        font-style: normal;
        font-weight: 600;
        font-size: 42px;
        line-height: 60px;
        text-align: center;
        color: #ffffff;

        @media only screen and (max-width: 1030px) {
            font-size: 38px;
        }
        @media only screen and (max-width: 767px){
            margin-top: 14px;
            font-size: 22px;
            line-height: 30px;
        }
    }

    .slide-line {
        width: 67px;
        height: 2px;
        background: #50cab6;
        margin: 88px auto 0 auto;
        @media only screen and (max-width: 767px){
            margin-top: 20px;
        }
    }

    .slide-subtitle {
        text-align: center;
        color: #ffffff;
        margin-top: 31px;

        @media only screen and (max-width: 767px){
            margin-top: 20px;
            line-height: 24px;
        }
    }

    .slide-footer {
        width: 100%;
        padding: 0 65px;
        @media only screen and (max-width: 767px){
            padding: 0;
            position: relative;
            margin-top: 40px;
        }
    }

    .progress-bar {
        position: relative;
        width: 100%;
        height: 5px;
        background: #f5f5f5;
        border-radius: 2.5px;
        margin-top: 130px;
        @media only screen and (max-width: 767px){
            margin-top: 48px;
        }
    }

    .progress-bar-active {
        position: absolute;
        top: 0;
        left: 0;
        background: #50cab6;
        border-radius: 5px;
        height: inherit;
    }

    .people-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 60px;
        margin-top: 10px;
    }

    .faces-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .face {
        width: 25px;
        height: 30px;
        @media only screen and (max-width: 767px){
            width: 30px;
            display: none;
            &:not(:first-child){
                margin-left: -10px;
            }
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6),
            &:nth-child(7){
                display: block;
            }
        }
        img {
            width: 30px;
        }
    }

    .number-of-people {
        color: #ffffff;
        margin-left: 10px;
    }

    .author {
        color: #ffffff;
        margin-left: auto;

        @media only screen and (max-width: 767px){
            position: absolute;
            top: 0;
            width: 100%;
            text-align: center;
        }
    }
</style>
