<template>
    <div class="view-new-project-step">
        <Header class="header" page="create-a-project"/>

<!--        <BurgerMenu/>-->

        <div class="step-main">
            <div class="step-content-wrapper">
                <div class="step-content">
                    <div class="step-done">
                        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="fg985qryma" maskUnits="userSpaceOnUse" x="6" y="8" width="18" height="14"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.6 18.6l-4.2-4.2L6 15.8l5.6 5.6 12-12L22.2 8 11.6 18.6z" fill="#fff"/></mask><g mask="url(#fg985qryma)"><path fill="#fff" d="M2 2h26v26H2z"/></g></svg>
                    </div>

                    <div class="step-title">
                        {{ i18n.stepTitle[$lang] }}
                    </div>

                    <div class="step-descr">
                        <span v-html="i18n.stepDescr[$lang]"/>
                    </div>
                </div>
                <div class="step-buttons" @click="handleButtonClick">
                    <Button class="choose-file-button primary">
                        <div class="choose-file-button-label">
                            {{ i18n.backToMyProjects[$lang] }}
                        </div>
                    </Button>
                </div>
            </div>
        </div>

        <Footer class="footer"/>
    </div>
</template>

<script>
    import Header from '@/components/Header';
    import BurgerMenu from '@/components/BurgerMenu';
    import Footer from '@/components/Footer';
    import Button from '@/components/Button';
    import {mapState} from 'vuex';

    export default {
        name: 'NewProjectStep',
        components: {
            Header,
            BurgerMenu,
            Footer,
            Button,
        },
        data() {
            return {
                i18n: {
                    stepTitle: {
                        en: 'Congradulations! Your project is now on moderation.',
                        ru: 'Поздравляем, ваш проект отправился на модерацию'
                    },
                    stepDescr: {
                        en: `We will contact you soon`,
                        ru: `Мы сообщим вам, когда он будет выставлен на согласование или свяжемся, чтобы исправить неточности.`
                    },
                    backToMyProjects: {
                        en: ' Back to my projects',
                        ru: 'Вернуться в мои проекты'
                    },


                },
            }
        },
        methods: {
            handleButtonClick() {
                this.$router.push('/profile-my-projects');
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
        }
    };
</script>

<style scoped lang="scss">

    .step-main {
        padding: 120px 165px 0 165px;
        @media only screen and (max-width: 767px){
            padding: 70px 10px 0;
        }
    }

    .step-content-wrapper {
        box-shadow: 0 10px 30px -2px #0000000d;
        background: #fff;
        border-radius: 6px;
    }

    .step-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 90px 0 150px 0;
        @media only screen and (max-width: 767px){
            padding: 40px 25px;
        }
    }

    .step-done {
        box-sizing: border-box;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #50CAB6;
        color: #fff;
        @media only screen and (max-width: 767px){
            width: 40px;
            height: 40px;
            svg{
                width: 24px;
                height: 24px;
                position: relative;
                top: -3px;
                left: -3px;
            }
        }
    }

    .step-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        padding: 60px 0 0 0;
        color: #4D4D4D;

        @media only screen and (max-width: 1030px) {
            font-size: 18px;
        }
        @media only screen and (max-width: 767px){
            padding: 20px 0 0 0;
            font-size: 15px;
            line-height: 20px;
        }
    }

    .step-descr {
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;
        padding: 30px 0 0 0;
        text-align: center;
        color: #999999;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px){
            padding: 10px 0 40px 0;
            font-size: 11px;
            line-height: 18px;
        }
    }

    .step-buttons {
        display: flex;
        position: relative;
        justify-content: center;
        padding: 48px 0 48px 0;
        width: 100%;

        .choose-file-button {
            padding: 17px 35px;

        }
        @media only screen and (max-width: 767px){
            padding: 20px;
            .choose-file-button{
                padding: 5px 6px !important;
                svg{
                    position: relative;
                    width: 24px;
                    height: 24px;
                    top: -3px;
                    left: -3px;
                }
                &.primary{
                    min-width: 183px;
                }
            }
        }

    }

    .step-buttons::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-top: 2px #F0F0F0 solid;
        @media only screen and (max-width: 767px){
            border-top-color: #EBEBEB;
        }
    }


</style>
