<template>
  <div class="slide-wrapper">
    <card-variant
      class="slide"
      v-for="(project, index) in projects"
      :key="index"
      :project="project"
    />

    <slot></slot>

    <div class="show-more-wrapper">
      <Spinner v-if="isLoading" />
      <ShowMore v-else-if="hasMorePages" class="show-more" @click="load" />
    </div>
  </div>
</template>

<script>
import api from "@/api/project";
import CardVariant from "@/components/CardVariant2";
import ShowMore from "@/components/ShowMore";
import Spinner from "@/components/Spinner/Spinner.vue";

export default {
  components: {
    ShowMore,
    Spinner,
    CardVariant,
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    filter: {
      type: String,
    },
  },
  data: () => ({
    projects: [],
    page: 1,
    hasMorePages: true,
    isLoading: false,
  }),
  watch: {
    isActive(active) {
      if (active && this.page === 1) {
        this.load();
      }
    },
  },
  methods: {
    load() {
      this.isLoading = true;
      this.resize();

      api
        .getProjects(this.page, this.filter)
        .then(({ data }) => {
          this.projects = [...this.projects, ...data.data];
          this.page = data.meta.current_page + 1;
          this.hasMorePages = data.meta.current_page < data.meta.last_page;
        })
        .catch()
        .then(() => {
          this.isLoading = false;
          this.resize();
        });
    },
    resize() {
      window.dispatchEvent(new Event("resize"));
    },
  },
  mounted() {},
};
</script>
