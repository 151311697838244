<template>
  <div class="view-about-component-main-rules">
    <div class="nav">
      <div
        v-for="(navItemCur, index) in navItems[$lang]"
        :key="index"
        class="nav-item"
        :class="{'is-active' : navItemActiveIndex === index}"
        @click="handleNavItemClick(index)"
      >
        {{ navItemCur.label }}
      </div>
    </div>
    <div class="nav-mobile">
      <v-select
          :options='navItems[$lang]'
          :clearable="false"
          :value="navItems[$lang][0].label"
          @input="handleNavSelectChange"
          class="select"
      >
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">
            <svg width="9" height="5" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.498 5L9 2.5V.104L4.498 2.62 0 0v2.5L4.498 5z" fill="#D9D9D9"/></svg>
          </span>
        </template>
      </v-select>
    </div>
    <div class="wrapper">
      <transition name="fade">
        <div v-if="menuItemsCurrent.length" class="menu">
          <a
            v-for="(menuItemCur, index) in menuItemsCurrent"
            :key="index"
            :href="menuItemCur.href"
            class="menu-item"
            :class="{'is-active': index === menuItemActiveIndex}"
            @click="handleMenuItemClick($event, menuItemCur, index)"
          >
            {{ menuItemCur.label }}
          </a>
        </div>
      </transition>
      <!-- <div class="content"> -->
      <div class="slider" :class="{'no-menu' : !menuItemsCurrent.length}">
        <VueSlickCarousel ref="carousel" v-bind="slickCarouselConfig" class="slides-wrapper">
          <div class="slide-item-wrapper">
            <div class="slide">
              <MainRules />
            </div>
          </div>
          <div class="slide-item-wrapper">
            <div class="slide">
              <Contacts />
            </div>
          </div>
        </VueSlickCarousel>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel';
  import MainRules from './components/MainRules';
  import Contacts from './components/Contacts';
  import { mapState } from 'vuex';

  export default {
    name: 'MainBlock',
    components: {
      MainRules,
      VueSlickCarousel,
      Contacts,
    },
    data() {
      return {
        menuItems:
        {
          en:  {
            'What is Grossfort': [
              {
                label: 'Main Rules',
                href: '#main-rules'
              },
              {
                label: 'Allowed',
                href: '#allowed'
              },
              {
                label: 'Not allowed',
                href: '#not-allowed'
              },
              {
                label: 'Special conditions',
                href: '#special-conditions'
              },
              {
                label: 'Forbidden',
                href: '#forbidden'
              },
            ],
            'How it works': [],
            'Rules of the platform': [],
            'Financial reports': [],
            'Vacancies': [],
            'Contacts': [],
          },
          ru:  {
            'Что такое Grossfort': [
              {
                label: 'Основные правила',
                href: '#main-rules'
              },
              {
                label: 'Допускается',
                href: '#allowed'
              },
              {
                label: 'Не допускается',
                href: '#not-allowed'
              },
              {
                label: 'Особые условия',
                href: '#special-conditions'
              },
              {
                label: 'Запрещенo',
                href: '#forbidden'
              },
            ],
            'Как это работает': [],
            'Правила площадки': [],
            'Финансовые отчёты': [],
            'Вакансии': [],
            'Контакты': [],
          },
        },
        menuItemActiveIndex: 0,
        navItemActiveIndex: 0,
        navItems: {
          en: [
            {"label":"What is Grossfort","code":"0"},
            {"label":"How it works","code":"1"},
            {"label":"Rules of the platform","code":"2"},
            {"label":"Financial reports","code":"3"},
            {"label":"Vacancies","code":"4"},
            {"label":"Contacts","code":"5"}
          ],
          ru: [
            {"label":"Что такое Grossfort","code":"0"},
            {"label":"Как это работает","code":"1"},
            {"label":"Правила площадки","code":"2"},
            {"label":"Финансовые отчёты","code":"3"},
            {"label":"Vacancies","code":"4"},
            {"label":"Контакты","code":"5"}
          ]
        },
        slickCarouselConfig: {
          arrows: false,
          dots: false,
          slidesPerRow: 1,
          swipe: false,
          draggable: false,
          adaptiveHeight: true,
          infinite: false,
        },
      }
    },
    computed: {
      menuItemsCurrent(){
        const key = this.navItems[this.$lang][this.navItemActiveIndex].label;

        return this.menuItems[this.$lang][key];
      },
      ...mapState(["$lang"]),
    },
    methods: {
      handleNavItemClick(index) {
        this.navItemActiveIndex = index;
        this.$refs.carousel.goTo(index);
      },

      handleNavSelectChange(e) {
        this.handleNavItemClick(e.code);
      },

      handleMenuItemClick(e, menuItem, index) {
        e.preventDefault();

        const href = menuItem.href.split('').slice(1).join('');
        const selector = `[data-id='${href}']`;
        const scrollTop = document.querySelector(selector).getBoundingClientRect().top - 100;

        window.scrollBy({
          top: scrollTop,
          behavior: 'smooth'
        });

        this.menuItemActiveIndex = index;
      },
    }
  };
</script>

<style scoped lang="scss">
  .view-about-component-main-rules {
    // margin: 0 165px;
    margin: 0 11.5vw;
    background: #FFFFFF;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    transform: translateY(-210px);
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 88px;
    padding: 0 6vw;
    border-bottom: 1px #F0F0F0 solid;

    @media only screen and(max-width: 767px){
      display: none;
    }
  }

  .nav-mobile{
    display: none;
    @media only screen and(max-width: 767px){
      display: flex;
      padding: 0 20px;
      align-items: center;
      justify-content: flex-start;
      min-height: 90px;
      border-bottom: #f5f5f5 2px solid;
    }
  }

  .nav-item {
    position: relative;
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 90px;
    text-align: center;
    color: #B3B3B3;
    cursor: pointer;

    &:after {
      content: '';
      display: none;
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: #50CAB6;
    }

    &.is-active {
      &:after {
        display: block;
      }
    }

    @media only screen and (max-width: 1030px) {
      font-size: 12px;
    }
  }
  .wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    // min-height: 100vh;
  }

  .content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .menu {
    position: sticky;
    top: 280px; // height of a header
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    width: 287px;
    padding-right: 60px;
    padding-top: 72px;
    padding-bottom: 72px;
    flex-shrink: 0;

    &.fade-leave-active {
      transition-duration: 0s;
    }
    @media only screen and (max-width: 767px){
      display: none;
    }
  }

  .menu-item {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    color: #B3B3B3;
    cursor: pointer;
    // margin-top: 44px;
    margin-top: 46px;
    width: 100%;

    &:nth-child(1) {
      margin-top: 0;
    }

    &.is-active {
      color: #666666;
    }

    @media only screen and (max-width: 1030px) {
      font-size: 12px;
    }
  }

  .slider {
    width: calc(100% - 287px);
    @media only screen and(max-width: 767px){
      width: 100%;
      border-left: none;
    }

    &.no-menu {
      width: 100%;
    }
  }
</style>

<style lang="scss">
  .view-about-component-main-rules {
    .fade-leave-active {
      // transition: opacity 0s;
    }
  }
</style>
