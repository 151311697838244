<template>
    <div ref="editor"></div>
</template>

<script>
    import Quill from 'quill';

    export default {
        name: "Editor",
        props: {
            value: {
                type: String,
                default: ''
            }
        },

        data() {
            return {
                editor: null
            };
        },

        mounted() {
            this.editor = new Quill(this.$refs.editor, {
                modules: {
                    toolbar: [
                        [{header: [1, 2, 3, 4, 5, 6, false]}],
                        ['bold', 'italic', 'underline'],
                        ['link', 'image'],
                        [{'list': 'ordered'}, {'list': 'bullet'}],
                        [{'color': []}, {'background': []}],
                        [{'font': []}],
                        [{'align': []}],
                        ['blockquote'],
                        ['clean'],
                    ]
                },
                placeholder: '',
                theme: 'snow'
            });
            //this.editor.root.innerHTML = this.value;
            this.editor.clipboard.dangerouslyPasteHTML(0, this.value);

            this.editor.on('text-change', () => {
                this.update()
            });
        },

        methods: {
            update() {
                //this.$emit('input', this.editor.getText());
                this.$emit('input', this.editor.getText() ? this.editor.root.innerHTML : '');
            }

        }
    }
</script>

<style scoped>

</style>
