<template>
  <div class="view-main-component-project">
    <modal
      name="gallery-modal"
      :adaptive="true"
      width="90%"
      height="90%"
      class="modal"
    >
      <img :src="fullscreenSrc" alt="" @click="handleModalContentClick" />
    </modal>

    <HeadCard />
    <div class="main">
      <div class="steps">
        <!-- <div class="video">
          <button type="button" class="video-play-button">
            <span class="video-play-button-label">
              {{ i18n.watchPresentation[$lang] }}
            </span>

            <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'video-play-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="l8hh8x4oka" maskUnits="userSpaceOnUse" x="9" y="7" width="11" height="14"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.333 7.467v13.067L19.6 14 9.333 7.467z" fill="#fff"/></mask><g mask="url(#l8hh8x4oka)"><path fill="#fff" d="M1.866 1.867h24.267v24.267H1.866z"/></g></svg>
          </button>
        </div> -->

        <div class="content">
          <div class="paragraph">
            <!-- <div class="paragraph-name">
              {{ i18n.problem[$lang] }}
            </div>

            <div class="paragraph-title">
              {{ i18n.paragraphTitle[$lang] }}
            </div>

            <div class="paragraph-text">
              {{ i18n.paragraphText[$lang] }}
            </div>

            <div class="paragraph-text-highlight">
              {{ i18n.paragraphTextHighlight[$lang] }}
            </div>
          </div>
          <div class="paragraph">
            <div class="paragraph-name">
              {{ i18n.product[$lang] }}
            </div>

            <div class="paragraph-title">
              {{ i18n.paragraphTitleSecond[$lang] }}
            </div> -->

            <div class="paragraph-text first" style="margin-top: 70px">
              {{ i18n.paragraphTextSecond[$lang] }}
            </div>

            <div class="slider">
              <div class="slider-body">
                <button
                  type="button"
                  class="slider-button is-prev"
                  @click="handleSliderPrevBtnClick"
                >
                  <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="pir9349lsa" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M23 14H10.83l5.59-5.59L15 7l-8 8 8 8 1.41-1.41L10.83 16H23v-2z" fill="#fff"/></mask><g mask="url(#pir9349lsa)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                </button>

                <button
                  type="button"
                  class="slider-button is-next"
                  @click="handleSliderNextBtnClick"
                >
                  <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="xirvqky5wa" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M23 14H10.83l5.59-5.59L15 7l-8 8 8 8 1.41-1.41L10.83 16H23v-2z" fill="#fff"/></mask><g mask="url(#xirvqky5wa)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                </button>

                <div
                  ref="sliderSlidesWrapper"
                  class="slider-slides-wrapper js-slider"
                >
                  <div class="slider-slide">
                    <img
                      src="@/assets/img/view-news-or-articule/placeholder.png"
                      alt=""
                    />
                  </div>

                  <div class="slider-slide">
                    <img
                      src="@/assets/img/view-news-or-articule/placeholder.png"
                      alt=""
                    />
                  </div>

                  <div class="slider-slide">
                    <img
                      src="@/assets/img/view-news-or-articule/placeholder.png"
                      alt=""
                    />
                  </div>

                  <div class="slider-slide">
                    <img
                      src="@/assets/img/view-news-or-articule/placeholder.png"
                      alt=""
                    />
                  </div>

                  <div class="slider-slide">
                    <img
                      src="@/assets/img/view-news-or-articule/placeholder.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <div class="slider-footer">
                <div class="slider-counter">
                  {{ activeSlideNumber }} / {{ slidesAmount }}
                </div>

                <div class="slider-desc">
                  {{ i18n.sliderDesc[$lang] }}
                </div>
              </div>
            </div>
          </div>
          <div class="paragraph">
            <!-- <div class="paragraph-name">
              {{ i18n.solution[$lang] }}
            </div> -->

            <div class="paragraph-title">
              {{ i18n.paragraphTitleThird[$lang] }}
            </div>

            <div class="paragraph-text">
              {{ i18n.paragraphTextThird[$lang] }}
            </div>

            <div class="paragraph-text">
              {{ i18n.paragraphTextThird2[$lang] }}
            </div>

            <div class="gallery">
              <div class="gallery-item" @click="handleGalleryItemClick">
                <div class="gallery-item-content">
                  <img
                    src="@/assets/img/gallery-placeholder-1.png"
                    data-fullscreen-src="@/assets/img/view-news-or-articule/gallery-image-1.png"
                    alt=""
                  />
                </div>

                <div class="gallery-item-desc">
                  {{ i18n.lookUpInTheSky[$lang] }}
                </div>
              </div>

              <div class="gallery-item" @click="handleGalleryItemClick">
                <div class="gallery-item-content">
                  <img
                    src="@/assets/img/gallery-placeholder-2.png"
                    data-fullscreen-src="@/assets/img/view-news-or-articule/gallery-image-2.png"
                    alt=""
                  />
                </div>

                <div class="gallery-item-desc">
                  {{ i18n.dudeYouReGettingATelescope[$lang] }}
                </div>
              </div>

              <div class="gallery-item" @click="handleGalleryItemClick">
                <div class="gallery-item-content">
                  <img
                    src="@/assets/img/gallery-placeholder-3.png"
                    data-fullscreen-src="@/assets/img/view-news-or-articule/gallery-image-3.png"
                    alt=""
                  />
                </div>

                <div class="gallery-item-desc">
                  {{ i18n.theKansasCityChiefs[$lang] }}
                </div>
              </div>
            </div>
          </div>
          <div class="paragraph">
            <!-- <div class="paragraph-name">
              {{ i18n.market[$lang] }}
            </div>

            <div class="paragraph-title">
              {{ i18n.paragraphTitleFourth[$lang] }}
            </div> -->

            <div class="paragraph-text">
              {{ i18n.paragraphTextFourth[$lang] }}
            </div>

            <!-- <CircleDiagram diagram-id="circle1" :header-text="i18n.circleOneHeaderText[$lang]" /> -->
          </div>
          <!-- <div class="paragraph" style="margin-top: 75px;">
            <div class="paragraph-name">
              {{ i18n.market[$lang] }}
            </div>

            <div class="paragraph-title">
              {{ i18n.paragraphTitleFifth[$lang] }}
            </div>

            <div class="paragraph-text-with-subtitles">
              <div class="paragraph-text-subtitle">
                {{ i18n.paragraphTextSubtitleFirst[$lang] }}
              </div>

              <div class="paragraph-text">
                {{ i18n.paragraphTextFifth[$lang] }}
              </div>
            </div>

            <div class="paragraph-text-with-subtitles">
              <div class="paragraph-text-subtitle">
                {{ i18n.paragraphTextSubtitleSecond[$lang] }}
              </div>

              <div class="paragraph-text">
                {{ i18n.paragraphTextSixth[$lang] }}
              </div>
            </div>

            <div class="paragraph-text-with-subtitles">
              <div class="paragraph-text-subtitle">
                {{ i18n.paragraphTextSubtitleThird[$lang] }}
              </div>

              <div class="paragraph-text">
                {{ i18n.paragraphTextSeventh[$lang] }}
              </div>
            </div>

            <div class="paragraph-text-with-subtitles">
              <div class="paragraph-text-subtitle">
                {{ i18n.paragraphTextSubtitleFourth[$lang] }}
              </div>

              <div class="paragraph-text">
                {{ i18n.paragraphTextEighth[$lang] }}
              </div>
            </div>
          </div>
          <div class="paragraph">
            <div class="paragraph-name">
              {{ i18n.monetisation[$lang] }}
            </div>

            <div class="paragraph-title" style="margin-top: 40px;">
              {{ i18n.paragraphTitleSixth[$lang] }}
            </div>

            <div class="paragraph-text">
              {{ i18n.paragraphTextNinth[$lang] }}
            </div>

            <Table />
          </div> -->
          <!-- <div class="paragraph">
            <div class="paragraph-name">
              {{ i18n.competition[$lang] }}
            </div>

            <div class="paragraph-title">
              {{ i18n.technologySoar[$lang] }}
            </div>

            <div class="paragraph-img">
              <img src="@/assets/img/paragraph-competition-placeholder.png" alt="" />
            </div>
          </div>
          <div class="paragraph">
            <div class="paragraph-name">
              {{ i18n.costStructure[$lang] }}
            </div>

            <div class="paragraph-title">
              {{ i18n.paragraphTitleSeventh[$lang] }}
            </div>

            <div class="paragraph-text">
              {{ i18n.paragraphTextTenth[$lang] }}
            </div>

            <CircleDiagram diagram-id="circle2" :header-text="i18n.circleTwoHeaderText[$lang]" />

            <div class="paragraph-title">
              {{ i18n.implementationPlan[$lang] }}
            </div>

            <ImplementationPlan />
          </div> -->
          <!-- <div class="paragraph">
            <div class="paragraph-name">
              {{ i18n.salesForecast[$lang] }}
            </div>

            <div class="paragraph-title">
              {{ i18n.paragraphTitleEighth[$lang] }}
            </div>

            <div class="paragraph-text">
              {{ i18n.paragraphTextEleventh[$lang] }}
            </div>

            <BarDiagram diagram-id="bar1" :header-text="i18n.barHeaderText[$lang]" />
            <LineDiagram diagram-id="line1" :header-text="i18n.lineHeaderText[$lang]" />
          </div>
          <div class="paragraph">
            <div class="paragraph-name">
              {{ i18n.executors[$lang] }}
            </div> -->

          <!-- <div class="paragraph-title">
              {{ i18n.team[$lang] }}
            </div> -->

          <!-- <Team /> -->

          <!-- <div class="paragraph-title" style="margin-top: 80px">
              {{ i18n.faq[$lang] }}
            </div> -->

          <!-- <FAQ /> -->
          <!-- </div> -->
        </div>
      </div>

      <FooterCard />
    </div>

    <div class="footer">
      <!-- <Comments /> -->
    </div>
  </div>
</template>

<script>
// import CircleDiagram from './components/CircleDiagram';
// import Table from './components/Table';
// import ImplementationPlan from './components/ImpementationPlan';
// import BarDiagram from './components/BarDiagram';
// import LineDiagram from './components/LineDiagram';
// import Team from './components/Team';
// import FAQ from './components/FAQ';
import FooterCard from "./components/FooterCard";
// import Comments from './components/Comments';
import { tns } from "tiny-slider/src/tiny-slider";
import { mapState } from "vuex";
import HeadCard from "./components/HeadCard";

export default {
  name: "Project",
  components: {
    // Comments,
    FooterCard,
    HeadCard,
    // FAQ,
    // Team,
    // BarDiagram,
    // ImplementationPlan,
    // CircleDiagram,
    // Table,
    // LineDiagram,
  },
  data() {
    return {
      slidesAmount: 0,
      activeSlideNumber: 1,
      fullscreenSrc: "",
      i18n: {
        circleOneHeaderText: {
          ru: "Глобальная рыночная стоимость коммерческих решений",
          en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        circleTwoHeaderText: {
          ru: "Затраты на запуск и первый год продаж",
          en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        barHeaderText: {
          ru: "Динамика продаж дронов за последние 3 года",
          en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },
        lineHeaderText: {
          ru: "Расчёт окупаемости",
          en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
        },

        watchPresentation: {
          en: "Watch presentation",
          ru: "Смотреть презентацию",
        },
        problem: {
          en: "Problem",
          ru: "Проблема",
        },
        paragraphTitle: {
          en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          ru: "Дронам не хватает достаточной автономии и интеллекта",
        },
        paragraphText: {
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et nunc eget ante pellentesque malesuada placerat quis augue. Aenean at tristique mi. Vestibulum at egestas justo. Vivamus iaculis eros non dapibus suscipit. Vivamus quam tellus, iaculis ac nulla nec, luctus cursus augue. Phasellus eget placerat nisi, ac sagittis mi. Donec commodo dolor quis lectus aliquet, condimentum blandit felis aliquam. Duis ac diam quis nisi egestas aliquam sed nec ex. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce et purus at nulla maximus sollicitudin. Ut commodo lorem sem, sed mattis velit venenatis at. Nulla dolor mi, blandit non orci nec, congue auctor nisl. Nam accumsan nulla sapien, vel vehicula nisl consectetur at. Proin congue venenatis eros, vel imperdiet nunc dictum vel. Proin pulvinar nibh eget lacus molestie lobortis. Pellentesque vitae consectetur arcu, eget vestibulum dolor.",
          ru:
            "Отрасли промышленности не могут эффективно, надежно и быстро использовать беспилотники и летательные аппараты для промышленного применения. В результате мы не можем повысить эффективность человеческого труда. Демонстрируются неэффективные и примитивные инструменты в руках человека-оператора с точки зрения обработки данных как в полете, так и после операции. Это экономическое и экономическое развитие, безопасность и многое другое.",
        },
        paragraphTextHighlight: {
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et nunc eget ante pellentesque malesuada placerat quis augue. Aenean at tristique mi. Vestibulum at egestas justo. Vivamus iaculis eros non dapibus suscipit. Vivamus quam tellus, iaculis ac nulla nec, luctus cursus augue. Phasellus eget placerat nisi, ac sagittis mi. Donec commodo dolor quis lectus aliquet, c",
          ru:
            "Современная парадигма робототехники неэффективна и ограничивает рост многих отраслей. Кроме того, эти роботы имеют очень ограниченный доступ к интеллектуальным и долговременным интеллектуальным роботам в режиме реального времени, которые они могут использовать, чтобы стать умнее и более автономными.",
        },
        product: {
          en: "Product",
          ru: "Продукт",
        },
        paragraphTitleSecond: {
          en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          ru:
            "Soar Robotics разрабатывает облачный роботизированный интеллект для летательных аппаратов",
        },
        paragraphTextSecond: {
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et nunc eget ante pellentesque malesuada placerat quis augue. Aenean at tristique mi. Vestibulum at egestas justo. Vivamus iaculis eros non dapibus suscipit. Vivamus quam tellus, iaculis ac nulla nec, luctus cursus augue. Phasellus eget placerat nisi, ac sagittis mi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam et nunc eget ante pellentesque malesuada placerat quis augue. Aenean at tristique mi. Vestibulum at egestas justo. Vivamus iaculis eros non dapibus suscipit. Vivamus quam tellus, iaculis ac nulla nec, luctus cursus augue. Phasellus eget placerat nisi, ac sagittis mi.",
          ru:
            "Поле направлений переворачивает интеграл от функции комплексной переменной. Аффинное преобразование накладывает отрицательный разрыв функции. Матожидание, как следует из вышесказанного, стабилизирует интеграл Дирихле. Матожидание, следовательно, порождает ряд Тейлора, таким образом сбылась мечта идиота - утверждение полностью доказано. Линейное уравнение монотонно.",
        },
        sliderDesc: {
          en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          ru: "Контрпример, в первом приближении, неоднозначен.",
        },
        solution: {
          en: "Solution",
          ru: "Решение",
        },
        paragraphTitleThird: {
          en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          ru: "Методологический вектор: бессознательное или чувство",
        },
        paragraphTextThird: {
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          ru:
            "Доказательство, как следует из вышесказанного, искажает контрпример. Ввиду непрерывности функции f ( x ), разрыв функции естественно позиционирует положительный полином. Начало координат продуцирует ряд Тейлора. Полином, не вдаваясь в подробности, отражает бином Ньютона. Нормальное распределение обуславливает равновероятный интеграл Пуассона. Следствие: интеграл по ориентированной области.",
        },
        paragraphTextThird2: {
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          ru:
            'В связи с этим нужно подчеркнуть, что интеллект одинаково отталкивает коллинеарный вектор. Можно предположить, что связь наблюдаема. Предсознательное представляет собой ассоцианизм, в частности, "тюремные психозы", индуцируемые при различных психопатологических типологиях. Гештальт, конечно, небезынтересно притягивает тригонометрический стимул.',
        },
        market: {
          en: "Market",
          ru: "Рынок",
        },
        paragraphTitleFourth: {
          en: "300 Lorem ipsum dolor sit amet,",
          ru: "300 миллиардов долларов",
        },
        paragraphTextFourth: {
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          ru:
            "Интеграл Гамильтона категорически интегрирует конвергентный комплекс. Связь, в первом приближении, представляет собой действительный онтогенез речи, хотя этот факт нуждается в дальнейшей проверке наблюдением. Согласно последним исследованиям, критерий сходимости Коши правомочен. Геодезическая линия отражает аутизм. Нормальное распределение просветляет критерий сходимости Коши. Выготский разработал, ориентируясь на методологию марксизма, учение которое утверждает что, иррациональное число.",
        },
        paragraphTitleFifth: {
          en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
          ru: "Работа с клиентами в четырех отраслях",
        },
        paragraphTextSubtitleFirst: {
          en: "Lorem",
          ru: "Энергия",
        },
        paragraphTextFifth: {
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          ru:
            "Наши беспилотники будут проверять и обеспечивать безопасность инфраструктуры для выработки 450 МВт электроэнергии.",
        },
        paragraphTextSubtitleSecond: {
          en: "Lorem",
          ru: "Безопасность",
        },
        paragraphTextSixth: {
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          ru:
            "Мы внедряем 20 дроновых подразделений в охранную компанию, которая предоставляет услуги мониторинга для городов и крупных инфраструктурных подразделений. Беспилотные летательные аппараты выполняют обычные, полностью автономные миссии наблюдения.",
        },
        paragraphTextSubtitleThird: {
          en: "Lorem",
          ru: "Эргономика",
        },
        paragraphTextSeventh: {
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing",
          ru:
            "Мы тестировали нашу систему через компанию, предоставляющую сельскохозяйственные данные, в базе данных которой содержится более 20 000 фермеров, чтобы предоставить точные и действенные данные об их полях.",
        },
        paragraphTextSubtitleFourth: {
          en: "Lorem",
          ru: "Инфраструктура",
        },
        paragraphTextEighth: {
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          ru:
            "Наши беспилотники будут проверять и обеспечивать безопасность инфраструктуры для выработки 450 МВт электроэнергии.",
        },
        monetisation: {
          en: "Monetisation",
          ru: "Монетизация",
        },
        paragraphTitleSixth: {
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet,",
          ru:
            "Получение постоянного дохода с помощью модели Robotics-as-a-Service (RaaS)",
        },
        paragraphTextNinth: {
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          ru:
            "Клиенты могут пользоваться только софтом, или заказать себе сразу готового и прошитого дрона на нашей платформе. Модель оплаты — месячная подписка.",
        },
        competition: {
          en: "Competition",
          ru: "Конкуренты",
        },
        technologySoar: {
          en:
            "Technology Soar Robotics lorem tefwefwf Technology Soar Robotics lorem tefwefwfTechnology Soar Robotics lorem tefwefwf",
          ru:
            "Технология Soar Robotics не зависит от аппаратного обеспечения и полностью автономна",
        },
        costStructure: {
          en: "Cost structure",
          ru: "Структура затрат",
        },
        paragraphTitleSeventh: {
          en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          ru: "Основная масса средств уйдёт на разработку и продвижение софта",
        },
        paragraphTextTenth: {
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          ru:
            "Согласно рыночным прогнозам PWC, глобальная рыночная стоимость коммерческих решений с поддержкой дронов оценивается в 127 миллиардов долларов. Мы ожидаем, что с широким распространением рейсов BVLOS и созданием правительствами нормативно-правовой базы вокруг него коммерческие беспилотники сделают шаг ближе к своему полному потенциалу и охватят рынок с адресом + 300 млрд долларов до 2025 года.",
        },
        implementationPlan: {
          en: "Implementation plan",
          ru: "План реализации",
        },
        salesForecast: {
          en: "Sales forecast",
          ru: "Прогноз продаж",
        },
        paragraphTitleEighth: {
          en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          ru:
            "Для прогноза продаж мы взяли динамику продаж дронов на b2b-рынке за последние 3 года",
        },
        paragraphTextEleventh: {
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
          ru:
            "Мы планируем постепенно увеличивать маркетинговые бюджеты и привлекать новую аудиторию. В первый год важно будет собрать первый фидбек и доработать продукт, чтобы затем выходить на более массовый рынок.",
        },
        executors: {
          en: "Executors",
          ru: "Исполнители",
        },
        lookUpInTheSky: {
          en: "Look Up In The Sky",
          ru: "Уравнение в частных производных",
        },
        dudeYouReGettingATelescope: {
          en: "Dude You Re Getting A Telescope",
          ru: "Пустое подмножество",
        },
        theKansasCityChiefs: {
          en: "The Kansas City Chiefs",
          ru:
            "Умножение вектора на число стремительно развивает сходящийся ряд",
        },
      },
    };
  },
  mounted() {
    this.slider = tns({
      container: ".js-slider",
      items: 1,
      slideBy: "page",
      autoplay: false,
      controls: false,
    });

    const info = this.slider.getInfo();
    this.slidesAmount = info.slideCount;
  },
  methods: {
    updateActiveSlideNumber() {
      const info = this.slider.getInfo();
      const { navCurrentIndex } = info;

      this.activeSlideNumber = navCurrentIndex + 1;
    },

    handleGalleryItemClick(e) {
      const parentElem = e.currentTarget;
      const img = parentElem.querySelector("img");
      this.fullscreenSrc = img.dataset.fullscreenSrc;

      this.$modal.show("gallery-modal");
    },
    handleModalContentClick() {
      this.$modal.hide("gallery-modal");
    },
    handleSliderPrevBtnClick() {
      this.slider.goTo("prev");
      this.updateActiveSlideNumber();
    },
    handleSliderNextBtnClick() {
      this.slider.goTo("next");
      this.updateActiveSlideNumber();
    },
    handleSlidePromoteClick() {
      this.$router.push("profile-supported");
    },
  },
  computed: {
    ...mapState({
      $lang: (state) => state.$lang,
    }),
  },
};
</script>

<style scoped lang="scss">
@import "@/sass/mixins.scss";

.main {
  background: #fff;
  border-radius: 6px;
}

.footer {
  background: #fff;
  margin-top: 30px;
  border-radius: 6px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 90px;
  padding: 0 60px 0 82px;

  @media only screen and (max-width: 1200px) {
    flex-wrap: wrap;
  }
}

.steps {
  // padding: 0 190px;
  padding: 0 15.5vw;
}

.paragraph-name {
  display: inline-block;
  background: #f7f7f7;
  border-radius: 4px;
  font-family: IBMPlexSans;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #b3b3b3;
  padding: 0 12px;
}

.paragraph-title {
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 40px;
  color: #4d4d4d;
  margin-top: 30px;

  @media only screen and (max-width: 1030px) {
    font-size: 28px;
  }
}

.paragraph-text {
  font-family: IBMPlexSans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 30px;
  color: #666666;
  padding: 30px 0 0 0;

  .paragraph-text-with-subtitles & {
    color: #999999;
    margin-top: 10px;
  }

  @media only screen and (max-width: 1030px) {
    font-size: 12px;
  }
}
.first {
  text-align: center;
  margin-top: 0px !important;
}

.paragraph-text-highlight {
  position: relative;
  font-family: IBMPlexSans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: #666666;
  padding: 0 65px;
  margin-top: 60px;

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto 0;
    content: "";
    display: block;
    height: 125%;
    width: 2px;
    background: #50cab6;
  }

  @media only screen and (max-width: 1030px) {
    font-size: 14px;
  }
}

.paragraph {
  padding-top: 106px;
}

.slider {
  position: relative;
  width: 100%;
  height: 300px;
  max-width: 730px;
  margin: 120px auto 0 auto;
}

.slider-body {
  position: relative;
}

.slider-slide {
  img {
    object-fit: cover;
  }
}

.slider-button {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background: #ffffff;
  border: 2px solid #f5f5f5;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  @include button-default;

  &.is-prev {
    left: -95px;
  }

  &.is-next {
    right: -95px;
    transform: rotate(180deg);
  }

  @media only screen and (max-width: 1120px) {
    &.is-prev {
      left: -70px;
    }

    &.is-next {
      right: -70px;
      transform: rotate(180deg);
    }
  }
}

.slider-footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
}

.slider-counter {
  width: 57px;
  background: #f7f7f7;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #b3b3b3;
}

.slider-desc {
  margin-left: 13px;
  color: #666666;
}

.gallery {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  width: 100%;
  max-width: 730px;
  margin: 30px auto 0 auto;
}

.gallery-item {
  position: relative;
  cursor: pointer;

  &:before {
    content: "";
    display: none;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    mix-blend-mode: normal;
    width: 60px;
    height: 60px;
    background: rgba(#000, 0.3) url("~@/assets/img/icons/ic_search.svg") no-repeat center;
    background-size: 26px;
    border-radius: 50%;
  }

  &:nth-child(3n) {
    grid-column: span 2;
  }

  &:hover {
    &:before {
      display: block;
    }
  }

  img {
    width: 100%;
  }
}

.gallery-item-desc {
  position: absolute;
  left: 30px;
  bottom: 30px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  color: #ffffff;
}

.paragraph-text-subtitle {
  display: inline-block;
  font-family: IBMPlexSans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: #666666;
  padding-bottom: 8px;
  border-bottom: 1px solid #50cab6;
}

.paragraph-text-with-subtitles {
  margin-top: 30px;
}

.paragraph-img {
  margin-top: 30px;
  text-align: center;
}

.modal {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
