<template>
  <div class="component-log-in">
    <div class="overlay">
      <div class="modal">
        <div class="head-wrapper">
          <div class="title">
            {{ i18n.title[$lang] }}
          </div>
        </div>
        <div class="inputs-wrapper">
          <div class="input-wrapper">
            <div class="label-wrapper">
              <img
                src="@/assets/img/icons/ic_account_circle.svg"
                alt=""
                class="icon"
              />
            </div>

            <input v-model="email[$lang]" class="input" />
          </div>

          <div class="input-wrapper">
            <div class="label-wrapper">
              <img
                src="@/assets/img/icons/ic_letter.svg"
                alt=""
                class="icon"
              />
            </div>

            <input v-model="yourName[$lang]" class="input" />
          </div>
        </div>
        <div class="textarea-wrapper">
          <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="d2di9bimaa" maskUnits="userSpaceOnUse" x="5" y="6" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.99 8c0-1.1-.89-2-1.99-2H7c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM21 18H9v-2h12v2zm0-3H9v-2h12v2zm0-3H9v-2h12v2z" fill="#fff"/></mask><g mask="url(#d2di9bimaa)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

          <textarea v-model="textArea[$lang]" class="textarea" />
        </div>
        <div class="footer-wrapper">
          <Button class="button button-log-in">
            {{ i18n.send[$lang] }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Button from '@/components/Button';

  export default {
    name: 'AskAQuestion',
    components: {
      Button,
    },
    data() {
      return {
        email: {
          en: 'Email',
          ru: 'Эл. почта'
        },
        yourName: {
          en: 'Your name',
          ru: 'Ваше имя'
        },
        textArea: {
          en: 'Question',
          ru: 'Вопрос'
        },
        i18n: {
          send: {
            en: 'Send',
            ru: 'Отправить'
          },
          title: {
            en: 'Ask a question',
            ru: 'Задать вопрос'
          },
        },
      };
    },
    methods: {

    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    },
  };
</script>

<style scoped lang="scss">
  .component-login-in {
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    width: 540px;
    height: 510px;
    background-color: #fff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }

  .head-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 30px 50px 95px;
  }

  .inputs-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 65px 30px 95px;
  }

  .input-wrapper {
    display: flex;
    padding: 0;
    border: 2px solid #F5F5F5;
    border-radius: 4px;
    width: 160px;
    height: 50px;
    padding: 0 5px;
  }

  .label-wrapper {
    display: flex;

    .img {
      padding: 0px 7px 0px 12px;
    }
  }

  .title {
    // font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #808080;

    @media only screen and (max-width: 1030px) {
      font-size: 12px;
    }
  }


  .label {
    // font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 50px;
    color: #999999;
    padding: 0 0 0 7px;
    cursor: pointer;
  }

  .input,
  .textarea {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
    width: 100%;
    height: 45px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 50px;
    color: #999999;
    cursor: pointer;
  }

  .textarea-wrapper {
    border-radius: 4px;
    border: 2px solid #F5F5F5;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 380px;
    height: 150px;
    margin: 0 65px 30px 95px;
    padding: 12px 5px 0 5px;

    .img {
      padding: 0px 7px 0px 12px;
    }
  }
  .textarea {
    resize: none;
    min-height: 130px;
    padding: 0px 0;
    width: 100%;
    border: none;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 30px;
    color: #999999;
  }

  .footer-wrapper {
    display: flex;
    justify-content: flex-start;
    padding: 0 0 0 95px;
  }

  .button-log-in {
    padding: 15px 0;
    margin: 0 30px 0 0;
    font-size: 12px;
    letter-spacing: 1.5px;
    width: 160px;
  }


</style>
