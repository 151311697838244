import { Node } from "tiptap";

export default class Paragraph extends Node {
    get name() {
        return 'paragraph';
    }

    get schema() {
        return {
            content: 'inline*',
            draggable: false,
            group: 'block',
            parseDOM: [{
                tag: 'div',
            }],
            toDOM() {
                return ['div', 0];
            },
        };
    }
}
