<template>
    <div>
        <router-view/>
        <noty/>
    </div>
</template>

<script>
    import Noty from "./Noty.vue";
    import {mapState, mapGetters} from 'vuex';

    export default {
        components: {
            Noty,
        },
        computed: {
            ...mapState('projects', ['projectsLoadStatus']),
        },
        created: function () {

        },
        methods:{
            ...mapGetters("user",["isLoggedIn"])
        },
        updated() {
            if (this.isLoggedIn()){
                if (this.projectsLoadStatus === 0) {
                    this.$store.dispatch("projects/loadProjects");
                    this.$store.dispatch("projects/loadFavouritesId");
                }
            }
        }
    };
</script>

<style scoped></style>
