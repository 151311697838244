<template>
    <div class="component-image-uploader">
        <img
                ref="user-ava-img"
                :src="imgSrc"
                alt=""
                class="use-ava-img"
        />

        <input
                ref="user-ava-input"
                type="file"
                accept="image/*"
                hidden
                class="user-ava-input"
                @change="handleUserAvaInputChange"
        />

        <div class="user-ava-controls">
            <Button class="button-upload" variant="round" @click="handleButtonUploadClick">
                {{ i18n.upload[$lang] }}
            </Button>

            <Button class="button-delete" variant="round" without-border @click="handleButtonDeleteClick">
                {{ i18n.delete[$lang] }}
            </Button>
        </div>
    </div>
</template>

<script>
    import {mapState, mapActions} from "vuex";
    import Button from "@/components/Button";
    import Vue from "vue";

    export default {
        name: "ImageUploader",
        components: {
            Button
        },
        props: ["value"],
        data() {
            return {
                imgSrc: this.value,
                imgSrcDefault: require("@/assets/img/view-profile-settings/user-ava.png"),
                i18n: {
                    upload: {
                        en: "Upload",
                        ru: "Загрузить"
                    },
                    delete: {
                        en: "Delete",
                        ru: "Удалить"
                    },
                }
            }
        },
        computed: {
            ...mapState({
                $lang: state => state.$lang,
                user: state => state.user
            }),

        },
        methods: {
            ...mapActions("files", [
                "storeFile"
            ]),
            handleButtonUploadClick() {
                this.$refs["user-ava-input"].click();
            },
            handleButtonDeleteClick() {
                this.imgSrc = this.imgSrcDefault;
            },
            handleUserAvaInputChange(target) {
                const input = target.target;

                if (input.files && input.files[0]) {
                    const reader = new FileReader();

                    // reader.onload = e => {
                    //     this.imgSrc = e.target.result;
                    // };

                    reader.readAsDataURL(input.files[0]);
                    this.storeFile(this.storeImage(input.files[0]))
                        .then((response) => {
                            this.imgSrc = response.data.url;
                            this.$emit('change', response.data.path)

                        })
                        .catch((e) => {
                            console.log(e);
                        })
                }
            },
            storeImage(file) {
                const data = new FormData();
                data.append('file', file)
                data.append('fileable_type', "\\App\\User");
                data.append('fileable_id', this.user.id);
                data.append('fileable_relationship', "avatar");
                return data;
            }
        },
    }
</script>

<style scoped lang="scss">
    .component-image-uploader {
        display: flex;

        .use-ava-img {
            width: 125px;
            height: 125px;
            object-fit: cover;
        }

        .user-ava-controls {
            margin-left: 10px;
        }

        .button-upload,
        .button-delete {
            width: 131px;
            height: 40px;
            font-size: 10px !important;
        }

        .button-delete {
            margin-top: 5px;
        }
    }
</style>