<template>
  <div class="component-comments">
    <div class="title">
      <h4>{{ i18n.comments[$lang] }} {{ commentsCount }}</h4>
    </div>

    <CommentForm :projectId="projectId" />

    <div class="comments-wrapper">
      <comment-list
        v-if="commentsLoadStatus === 2 && comments"
        :collection="commentsCollection"
        :comments="comments"
      />
      <Spinner v-else />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import CommentList from "../CommentList";
import CommentForm from "../CommentForm";
import Spinner from "@/components/Spinner";

export default {
  name: "Comments",
  components: {
    CommentList,
    CommentForm,
    Spinner,
  },
  data() {
    return {
      i18n: {
        comments: {
          en: "Comments",
          ru: "Комментарии",
        },
        hoursAgo: {
          en: "hours ago",
          ru: "часов назад",
        },
        daysAgo: {
          en: "days ago",
          ru: "дней назад",
        },
      },
    };
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState({
      $lang: (state) => state.$lang,
    }),
    ...mapState("comments", ["commentsLoadStatus", "commentsCount"]),
    ...mapGetters({
      comments: "comments/getComments",
      commentsCollection: "comments/getCommentsCollection",
    }),
  },
  methods: {
    ...mapActions("comments", ["loadComments"]),
  },
  mounted: function () {
    this.loadComments(this.projectId);
  },
};
</script>

<style scoped lang="scss">
.component-comments {
  padding: 60px 190px;
  width: 100%;
  @media only screen and (max-width: 767px){
    padding: 40px 20px;
  }
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 60px;
  color: #808080;

  @media only screen and (max-width: 1030px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 767px){
    font-size: 11px;
    line-height: 11px;
  }
}

.comments-wrapper {
  margin-top: 60px;
  @media only screen and (max-width: 767px){
    margin-top: 46px;
  }
}
</style>
