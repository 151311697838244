<template>
  <div class="diagram-wrapper">
    <div class="diagram-header">
      <div class="diagram-title">
        <h5>{{ headerText }}</h5>
      </div>
      <div class="diagram-menu" />
    </div>

    <div class="diagram-body">
      <slot />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DiagramWrapper',
    props: {
      headerText: {
        type: String,
        default: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
      }
    }
  };
</script>

<style scoped lang="scss">
  .diagram-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 30px;
    border: 2px solid #F5F5F5;
    border-radius: 6px;
    padding-bottom: 40px;
    @media only screen and (max-width: 767px){
      margin-top: 20px;
    }
  }

  .diagram-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 100%;
    border-bottom: 2px solid #F5F5F5;
    padding: 0 17px 0 20px;
    @media only screen and (max-width: 767px){
      padding: 0 20px;
      height: 50px;
    }
  }

  .diagram-title {
    color: #666666;
  }

  .diagram-menu {
    width: 30px;
    height: 30px;
    background: url("~@/assets/img/icons/ic_more_horiz.svg") no-repeat center;
    background-size: 26px;
    cursor: pointer;
  }

  .diagram-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    width: 100%;
    canvas{
      width: 100% !important;
      height: auto !important;
    }
    @media only screen and (max-width: 767px){
      margin-top: 20px;
    }
  }
</style>
