<template>
  <div class="component-finance">
    <div class="block" data-id="balance">
      <div class="title">
        <h3>{{ i18n.balance[$lang] }}</h3>
      </div>

      <div class="profit-wrapper">
        <div class="profit-title">
          34 000 {{ i18n.tokens[$lang] }}
        </div>

        <div class="profit-total">
          / 2% {{ i18n.ofTheTotal[$lang] }}
        </div>
      </div>

      <div class="buttons-wrapper">
        <Button class="button-deposit primary">
          {{ i18n.deposit[$lang] }}
        </Button>

        <Button class="button-withdraw">
          {{ i18n.withdraw[$lang] }}
        </Button>
      </div>
    </div>

    <div class="block" data-id="current-rate">
      <div class="title">
        <h3>{{ i18n.currentRate[$lang] }}</h3>
      </div>

      <div class="current-rate">
        1 {{ i18n.token[$lang] }} = $ 240 000
      </div>

      <div class="cost-chart-wrapper">
        <div class="cost-chart-header">
          <div class="cost-chart-title">
            {{ i18n.costChart[$lang] }}
          </div>

          <img src="@/assets/img/icons/ic_more_horiz.svg" class="cost-chart-menu" alt="" />
        </div>

        <div class="cost-chart-lines">
          <canvas class="cost-chart" />
        </div>

        <div class="cost-chart-footer">
          <div class="cost-chart-footer-info">
            <div class="cost-chart-footer-title">
              $ 240 000
            </div>

            <div class="cost-chart-footer-desc">
              {{ i18n.today[$lang] }}
            </div>
          </div>

          <div class="cost-chart-footer-info">
            <div class="cost-chart-footer-title">
              $ 200 000
            </div>

            <div class="cost-chart-footer-desc">
              {{ i18n.onTheLastWeek[$lang] }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="block" data-id="transaction-history">
      <div class="title">
        <h3>{{ i18n.transactionHistory[$lang] }}</h3>
      </div>

      <table class="table">
        <tr class="table-header">
          <th class="table-item">
            {{ i18n.amount[$lang] }}
          </th>

          <th class="table-item">
            {{ i18n.date[$lang] }}
          </th>

          <th class="table-item">
            {{ i18n.type[$lang] }}
          </th>

          <th class="table-item">
            ID
          </th>

          <th class="table-item">
            {{ i18n.status[$lang] }}
          </th>
        </tr>

        <tr v-for="(itemCur, index) in transactionHistory" :key="index" class="table-line">
          <td class="table-item">
            {{ itemCur.amount }}
          </td>

          <td class="table-item">
            {{ itemCur.date }}
          </td>

          <td class="table-item">
            {{ itemCur.type }}
          </td>

          <td class="table-item">
            {{ itemCur.id }}
          </td>

          <td class="table-item">
            {{ itemCur.status }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  import Button from '@/components/Button';
  import Chart from 'chart.js';
  import { mapState } from 'vuex';


  export default {
    name: 'Finance',
    components: {
      Button
    },
    data() {
      return {
        transactionHistory: [
          {
            amount: '2 000 ₽',
            date: '12.06.2019',
            type: 'Ввод',
            id: '2312491',
            status: 'Completed'
          },
          {
            amount: '400 000 ₽',
            date: '22.04.2019',
            type: 'Вывод',
            id: '5421223',
            status: 'In progress'
          },
          {
            amount: '2 000 ₽',
            date: '12.06.2019',
            type: 'Ввод',
            id: '2312491',
            status: 'Completed'
          },
          {
            amount: '400 000 ₽',
            date: '22.04.2019',
            type: 'Вывод',
            id: '5421223',
            status: 'In progress'
          },
          {
            amount: '2 000 ₽',
            date: '12.06.2019',
            type: 'Ввод',
            id: '2312491',
            status: 'Completed'
          },
        ],
        i18n: {
          balance: {
            en: 'Balance',
            ru: 'Баланс'
          },
          tokens: {
            en: 'Tokens',
            ru: 'Токенов'
          },
          ofTheTotal: {
            en: 'of the total',
            ru: 'от общего числа'
          },
          deposit: {
            en: 'Deposit',
            ru: 'Пополнить'
          },
          withdraw: {
            en: 'Withdraw',
            ru: 'Вывести'
          },
          currentRate: {
            en: 'Current rate',
            ru: 'Текущий курс'
          },
          token: {
            en: 'token',
            ru: 'Токен'
          },
          costChart: {
            en: 'Cost Chart',
            ru: 'Динамика стоимости'
          },
          today: {
            en: 'Today',
            ru: 'Сегодня'
          },
          onTheLastWeek: {
            en: 'On the last week',
            ru: 'На прошлой неделе'
          },
          transactionHistory: {
            en: 'Transaction history',
            ru: 'История денежных операций'
          },
          amount: {
            en: 'Amount',
            ru: 'Сумма'
          },
          date: {
            en: 'Date',
            ru: 'Дата'
          },
          type: {
            en: 'Type',
            ru: 'Тип'
          },
          status: {
            en: 'Status',
            ru: 'Статус'
          },

        },
      }
    },
    mounted() {
      this.chartData = [];

      for (let i = 0; i < 100; i++) {
        const randomInt = this.getRandom(150, 200);

        this.chartData.push(randomInt);
      }

      this.chartInit();
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    },
    methods: {
      getRandom(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);

        return Math.floor(Math.random() * (max - min + 1)) + min;
      },
      chartInit() {
        console.log('chart init');

        const element = document.querySelector('.cost-chart');
        const ctx = element.getContext('2d');

        const gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, 'rgba(246, 246, 255, 1)');
        gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');

        const myChart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: this.chartData,
            datasets: [{
              label: '',
              data: this.chartData,
              borderWidth: 1,
              borderColor: '#5553CE',
              backgroundColor: gradient,
              fill: true,
              lineTension: 1
            }]
          },
          options: {
            tooltips: {
              position: 'average',
              mode: 'index',
              intersect: false,
              displayColors: false,
              caretSize: 0,
              callbacks: {
                title() {},
                label(tooltipItem, data) {
                  return `$ ${tooltipItem.label}`;
                }
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            scales: {
              xAxes: [{
                ticks: {
                  display: false
                },
                gridLines: {
                  display: false
                }
              }],
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                  display: false,
                },
                gridLines: {
                  display: false
                }
              }]
            },
            layout: {
              padding: {
                left: -15,
                bottom: -15,
              }
            },
            elements: {
              point: {
                radius: 0
              }
            }
          }
        });
      }
    }
  };
</script>

<style scoped lang="scss">

  .block {
    padding: 60px 190px 61px 90px;
    border-bottom: 2px #F5F5F5 solid;

    @media only screen and (max-width: 1130px){
      padding: 60px 25px 65px 25px;
    }

    @media only screen and (max-width: 767px) {
      padding: 25px;
    }

    &:nth-last-child(1) {
      padding-bottom: 80px;
      border-bottom: 0;
    }
  }

  .title {
    color: #4D4D4D;

    @media only screen and (max-width: 1030px) {
      font-size: 18px;
    }
  }

  .profit-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 36px;
  }

  .profit-title {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #666666;

    @media only screen and (max-width: 1030px) {
      font-size: 22px;
    }
  }

  .profit-total {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #999999;
    margin-left: 26px;

    @media only screen and (max-width: 1030px) {
      font-size: 12px;
    }
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 24px;
  }

  .button-deposit {
    width: 148px;
  }

  .button-withdraw {
    width: 128px;
    margin-left: 20px;
  }

  .current-rate {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #666666;
    margin-top: 38px;

    @media only screen and (max-width: 1030px) {
      font-size: 22px;
    }
  }

  .cost-chart-wrapper {
    border: 2px solid #F5F5F5;
    border-radius: 6px;
    margin-top: 28px;
  }

  .cost-chart-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 58px;
    padding: 0 20px;
    border-bottom: 2px #F5F5F5 solid;
  }

  .cost-chart-title {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #666666;

    @media only screen and (max-width: 1030px) {
      font-size: 16px;
    }
  }

  .cost-chart-menu {
    height: 30px;
    width: 30px;
    cursor: pointer;
  }

  .cost-chart-footer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 60px;
    border-top: 2px #F5F5F5 solid;
  }

  .cost-chart-footer-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .cost-chart-footer-info {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #808080;

    @media only screen and (max-width: 1030px) {
      font-size: 11px;
    }
  }

  .cost-chart-footer-desc {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #B3B3B3;
    margin-left: 10px;

    @media only screen and (max-width: 1030px) {
      font-size: 11px;
    }
  }

  .cost-chart-lines {
    margin-top: 26px;
  }

  .table {
    border: 2px solid #F5F5F5;
    border-radius: 6px;
    margin-top: 30px;
    width: 100%;
    border-collapse: separate;
  }

  .table-header {
    height: 58px;
    vertical-align: middle;
  }

  .table-line {
    height: 58px;
    vertical-align: middle;
    border-top: 2px solid #F5F5F5;
  }

  .table-item {
    vertical-align: middle;
    text-align: center;
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #B3B3B3;

    .table-header & {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      color: #666666;

    }

    @media only screen and (max-width: 1030px) {
      font-size: 11px;
    }
  }
</style>
