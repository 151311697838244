<template>
    <div class="view-home-component-slide">
        <div v-parallax="0.2" class="background" :style="{'background-image':'url(\'' + imgSrc + '\')'}"></div>
        <div class="wrapper foreground">
            <div class="title">
                <slot name="title"/>
            </div>

            <div class="line"/>

            <div class="text">
                <slot name="text"/>
            </div>

            <div class="lineList">
                <slot name="lineList"/>
            </div>
            <div class="buttons-wrapper">
                <slot name="buttons"/>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'Slide',
        props: {
            imgSrc: {
                type: String,
                required: true,
            }
        },
    };
</script>

<style scoped lang="scss">

    $parallax-perspective: 4px;

    @function parallax-scale-factor($z-distance) {
        @return ($parallax-perspective - $z-distance)/$parallax-perspective;
    }

    .view-home-component-slide {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        min-height: 80vh;
        padding: 12% 165px 10.8%;
        background: #22275A no-repeat right;
        z-index: 0;
        overflow: hidden;

        @media only screen and (max-height: 767px) {
            padding: 12% 20px 10.8%;
            @media only screen and (min-height: 479px) {
                height: 100vh;
            }
        }
    }

    .background {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        // transform: translateZ(-1px) scale(2);
        background: #22275A no-repeat right;
        background-size: contain;
        z-index: -1;

        @media only screen and (max-width: 767px) {
            padding: 140px 20px 89px;
            background-size: cover;
            background-position: left bottom;
        }
    }


    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        max-width: 48%;
        z-index: 1;
        @media only screen and (max-width: 767px) {
            max-width: 100%;
            width: 100%;
        }
    }

    .title {
        color: #FFFFFF;

        /*@media only screen and (max-width: 1030px) {*/
        /*    font-size: 40px;*/
        /*}*/
        /*@media only screen and (max-width: 767px) {*/
        /*    h1 {*/
        /*        font-size: 24px;*/
        /*        line-height: 30px;*/
        /*    }*/
        /*    h2 {*/
        /*        font-size: 18px;*/
        /*        line-height: 24px;*/
        /*    }*/
        /*}*/
    }

    .line {
        height: 2px;
        width: 66px;
        background: #50CAB6;
        //margin-top: 30px;
        margin-top: 7%;
        @media only screen and (max-width: 767px) {
            margin-top: 20px;
        }
    }

    .text {
        color: #F0F3F6;
        //margin-top: 30px;
        margin-top: 7%;

        @media only screen and (max-width: 1030px) {
            /*font-size: 18px;*/
        }
        @media only screen and (max-width: 767px) {
            margin-top: 20px;
            /*font-size: 11px;*/
            /*line-height: 18px;*/
            /*.text-lead {*/
            /*    font-size: 11px;*/
            /*    line-height: 18px;*/
            /*}*/
        }
    }

    .buttons-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        //margin-top: 90px;
        margin-top: 21%;
        @media only screen and (max-width: 767px) {
            margin-top: 40px;
            flex-wrap: wrap;
        }
    }

    .home-slide1 .background{
        @media only screen and (max-width: 767px){
            background-position: left 8% bottom;
        }
    }

    .home-slide3 .background{
        @media only screen and (max-width: 767px){
            background-position: left 8% center;
            background-size: contain;
        }
    }

</style>
