<template>
    <div class="view-home-component-icons">
        <div class="stat-item">
            <div class="icon-svg">
                <svg width="48" height="46" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g stroke="#50CAB6" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M47 13c0 6.626-5.374 12-12 12s-12-5.374-12-12S28.374 1 35 1s12 5.374 12 12z"/><path d="M38.77 8.373a3.474 3.474 0 00-.63-1.06 2.946 2.946 0 00-.934-.718 2.62 2.62 0 00-1.14-.262h-2.132c-.404 0-.79.094-1.138.262-.352.17-.668.414-.934.718a3.44 3.44 0 00-.632 1.06A3.71 3.71 0 0031 9.665c0 .46.082.898.23 1.296.15.398.366.758.632 1.06.266.302.582.548.934.716.348.17.734.262 1.138.262h2.132c.406 0 .79.094 1.14.264.352.168.668.414.934.716.264.302.48.662.63 1.06.148.398.23.836.23 1.294 0 .458-.082.896-.23 1.294-.15.398-.366.758-.63 1.06a2.946 2.946 0 01-.934.718c-.35.168-.734.26-1.14.26h-2.132a2.61 2.61 0 01-1.138-.26 2.946 2.946 0 01-.934-.718 3.44 3.44 0 01-.632-1.06M35 5v16M5.857 45L1 42.686l6.708-15.08 4.858 2.312zM11.887 31.45l7.402-3.897c1.9-1.01 4.214-.61 5.718.974l.774.816-8.408 4.436-2.41 1.266"/><path d="M17.318 33.8l6.66 4.18 7.3-3.48c1.96-.86 3.742-.981 5.34.14l2.36 1.52-13.2 6.84H6.738"/></g></svg>
            </div>
            <div class="item ">
                <h3>{{ i18n.initialDeposit[$lang] }}</h3>
            </div>
        </div>
        <div class="stat-item">
            <div class="icon-svg">
                <svg width="38" height="48" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g stroke="#50CAB6" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M1 4.12c10.44-4.16 25.56-4.16 36 0M37 4.12v18.76c0 8.72-4.72 16.74-12.34 20.98L19 47l-5.66-3.14A23.998 23.998 0 011 22.88V4.12"/><path d="M29 15L17 29l-6-6"/></g></svg>
            </div>
            <div class="item ">
                <h3>{{ i18n.strictSelection[$lang] }}</h3>
            </div>
        </div>
        <div class="stat-item">
            <div class="icon-svg">
                <svg width="48" height="44" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g stroke="#50CAB6" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M33.08 27h-4.16M5.08 27H1V1h46v26h-4.08M19.08 27h-4.16"/><path d="M29 25.857C29 28.697 26.762 31 24 31s-5-2.302-5-5.142c0-2.84 2.238-5.144 5-5.144s5 2.304 5 5.144zM43 25.857C43 28.697 40.762 31 38 31s-5-2.302-5-5.142c0-2.84 2.238-5.144 5-5.144s5 2.304 5 5.144zM15 25.857C15 28.697 12.762 31 10 31s-5-2.302-5-5.142c0-2.84 2.238-5.144 5-5.144s5 2.304 5 5.144zM3 37v6h14v-6c0-2.208-1.792-4-4-4H7c-2.208 0-4 1.792-4 4zM31 37v6h14v-6c0-2.208-1.792-4-4-4h-6c-2.208 0-4 1.792-4 4zM17 37v6h14v-6c0-2.208-1.792-4-4-4h-6c-2.208 0-4 1.792-4 4z"/></g></svg>
            </div>
            <div class="item ">
                <h3>{{ i18n.businessCommunity[$lang] }}</h3>
            </div>
        </div>
        <div class="stat-item">
            <div class="icon-svg">
                <svg width="48" height="48" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g stroke="#50CAB6" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M47 1l-8.296 16.624-6.764-2.784-7.036 10.116-6.518-13.448-5.064 20.632-8.378-6.412L1 35"/><path d="M39 20c0 5.248-2.126 9.998-5.564 13.434A18.94 18.94 0 0120 39a18.94 18.94 0 01-13.436-5.566A18.932 18.932 0 011 20c0-5.248 2.126-9.998 5.564-13.434A18.94 18.94 0 0120 1a18.94 18.94 0 0113.436 5.566A18.932 18.932 0 0139 20zM33.436 33.435L47 47.001"/></g></svg>
            </div>
            <div class="item ">
                <h3>{{ i18n.transparentStat[$lang] }}</h3>
            </div>
        </div>
    </div>
</template>


<script>
    import {mapState} from 'vuex';
    import prepareSvgElements from '@/mixins/prepareSvgElements.js';

    export default {
        name: "Icons",

        mixins: [prepareSvgElements],

        data() {
            return {
                i18n: {
                    initialDeposit: {
                        en: 'Initial deposit — from $ 100',
                        ru: 'Стартовый депозит - от 100$'
                    },
                    strictSelection: {
                        en: 'Strict selection and financial analysis of projects',
                        ru: 'Строгий отбор и финансовый анализ проектов'
                    },
                    businessCommunity: {
                        en: 'Business community',
                        ru: 'Комьюнити единомышленников'
                    },
                    transparentStat: {
                        en: 'Transparent statistics',
                        ru: 'Прозрачная статистика'
                    },

                }
            };
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
        },
        // beforeMount() {
        //     this.languageChosen = this.langShort[this.$lang] || 'English';
        // },
    }

</script>

<style scoped lang="scss">
    .view-home-component-icons {
        display: flex;
        align-items: baseline;
        justify-content: space-evenly;
        /*height: 180px;*/
        width: 100%;
        background: #fff;
        padding: 0 11.5vw;
        margin-bottom: 80px;
        @media only screen and (max-width: 767px) {
            padding: 0 20px;
            margin-bottom: 46px;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    .icon-svg img {
        height: 46px;
        width: auto;
    }

    .stat-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 255px;
        @media only screen and (max-width: 767px) {
            max-width: 50%;
            width: 100%;
            padding: 0 15px;
            margin-bottom: 40px;
        }
        @media only screen and (max-width: 479px) {
            max-width: 100%;
            padding: 0;
        }
    }

    .line {
        width: 37px;
        height: 2px;
        background: #50CAB6;
    }

    .amount {
        font-family: Raleway;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        color: #666666;
        margin-top: 15px;

        @media only screen and (max-width: 1030px) {
            font-size: 20px;
        }
    }

    .item {
        color: #4D4D4D;
        text-align: center;
        margin-top: 20px;
        /*@media only screen and (max-width: 1030px) {*/
        /*    font-size: 12px;*/
        /*}*/
        @media only screen and (max-width: 767px) {
            max-width: 280px;
        }
    }
</style>
