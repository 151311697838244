import {axios} from "@/plugins/axios";

export default {
    /*
     * GET /api/projects
     * */
    getProjects: function (page, filter) {
        page = page || 1;
        filter = filter || "";

        return axios.get("/api/projects", {
            params: {
                page,
                filter,
            },
        });
    },

    /*
     * GET /api/projects/{projectId}
     * */
    getProject: function (projectId) {
        return axios.get("/api/projects/" + projectId);
    },

    /*
     * GET /api/projects/my
     * */
    getMyProjects: function (page) {
        page = page || 1;

        return axios.get("/api/projects/my", {
            params: {
                page
            }
        });
    },

    /*
     * GET /api/projects/favourites
     * */
    getFavouriteProjects: function (page) {
        page = page || 1;
        return axios.get("/api/projects/favourites", {
            params: {page}
        });
    },

    /*
     * GET /api/projects/supported
     * */
    getSupportedProjects: function (page) {
        page = page || 1;
        return axios.get("/api/projects/supported", {
            params: {page}
        });
    },

    /*
     * POST /api/projects/{id}/favourite
     * */
    addToFavourite: function (projectId) {
        return axios.post("/api/projects/" + projectId + "/favourite");
    },

    /*
     * DELETE /api/projects/{id}/favourite
     * */
    removeFromFavourite: function (projectId) {
        return axios.delete("/api/projects/" + projectId + "/favourite");
    },

    /*
     * POST /api/projects/{id}/vote
     * */
    addVote(projectId) {
        return axios.post("/api/projects/" + projectId + "/vote");
    },

    deleteVote(projectId) {
        return axios.delete("/api/projects/" + projectId + "/vote");
    },

    postAddNewProject: function () {
    },
};
