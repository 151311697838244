<template>
  <div class="view-profile">
    <Header />
<!--    <BurgerMenu />-->

    <div class="steps">
      <Nav :items="navItems" />
      <Slide :array-of-people="arrayOfPeople" />
      <Stats />
      <Project />
    </div>

    <Footer class="profile-footer" />
  </div>
</template>

<script>
  import Header from '@/components/Header';
  import Nav from '@/components/Nav';
  import Footer from '@/components/Footer';
  import Stats from './components/Stats';
  import Slide from './components/Slide';
  import Project from './components/Project';
  import BurgerMenu from '@/components/BurgerMenu';

  export default {
    name: 'Profile',
    components: {
      BurgerMenu,
      Header,
      Nav,
      Stats,
      Slide,
      Project,
      Footer
    },
    data() {
      return {
        navItems: {
          en: [
            {
              label: 'Home',
              link: '/',
            },
            {
              label: 'Projects',
              link: '/profile-discovery',
            },
            {
              label: 'Current Page',
              link: '#',
            }
          ],
          ru: [
            {
              label: 'Главная',
              link: '/',
            },
            {
              label: 'Проекты',
              link: '/profile-discovery',
            },
            {
              label: 'Полностью автономные летательные аппараты',
              link: '#',
            }
          ],
        },
        arrayOfPeople: this.getArrayOfPeople(),
      }
    },
    methods: {
      getArrayOfPeople() {
        const array = [];

        for (let i = 1; i <= 11; i++) {
          array.push(`img/people/placeholder${i}.png`);
        }

        return array;
      },
    }
  };
</script>

<style scoped lang="scss">
  .view-profile {
    min-height: 100vh;
    width: 100%;
    background: #FAFAFA;
  }

  .steps {
    width: 100%;
    // max-width: 1100px;
    // padding: 0 165px;
    padding: 0 8.5vw;
    margin: 0 auto;
    margin-top: 30px;
    @media only screen and (max-width: 767px){
      margin-top: 0;
      padding: 70px 0 0;
    }
  }
  .profile-footer{
    margin-top: 0;
  }
</style>
