import ProjectCreateApi from "../api/projectCreate";
import {getField, updateField} from "vuex-map-fields";

// import {createHelpers} from 'vuex-map-fields';
//
// const { getProjectCreateField, updateProjectCreateField } = createHelpers({
//     getterType: 'getProjectCreateField',
//     mutationType:'updateProjectCreateField'
// });

export default {
    namespaced: true,

    state: {
        id: false,

        image: "",
        image_link: "",
        name: "",
        description: "",
        date_start: "",
        category_id: "",
        descriptionStoreStatus: 0,

        html: "",
        presentationStoreStatus: 0,

        funding: 0,
        finmodel: {
            capitalCosts: {
                items: [
                    {
                        title: "",
                        value: "",
                    },
                ],
            },
            fixedCosts: {
                interval: "",
                items: [
                    {
                        title: "",
                        value: "",
                    },
                ],
            },
            variableCosts: {
                salesQ: "",
                items: [
                    {
                        title: "",
                        value: "",
                    },
                ],
            },
            salesForecast: {
                averageCheck: "",
                items: [
                    {
                        title: "",
                        value: "",
                    },
                ],
            },
        },

        timeline: [
            {
                title: "",
                value: "",
            },
        ],
        finmodelStoreStatus: 0,

        fetchingDraftStatus: 0,

        sendModerationStatus: 0,
    },

    actions: {
        getFormData({dispatch, state}) {
            const data = new FormData();
            data.append("id", state.id);
            data.append("image", state.image);
            data.append("name", state.name);
            data.append("description", state.description);
            data.append("date_start", state.date_start);
            data.append("category_id", state.category_id);

            return dispatch("storeDescription", data);
        },

        storeDescription({commit, state}, data) {
            return new Promise((resolve, reject) => {
                commit("SET_DESCRIPTION_STORE_STATUS", 1);
                ProjectCreateApi.storeProjectDescription(state.id, data)
                    .then((response) => {
                        commit("SET_DESCRIPTION_STORE_STATUS", 2);
                        commit("SET_DESCRIPTION", response.data.data);
                        resolve(response);
                    })
                    .catch((e) => {
                        commit("SET_DESCRIPTION_STORE_STATUS", 3);
                        reject(e);
                    });
            });
        },

        storePresentation({commit, state}) {
            commit("SET_PRESENTATION_STORE_STATUS", 1);
            return ProjectCreateApi.storeProjectPresentation({
                id: state.id,
                html: state.html,
            })
                .then((response) => {
                    commit("SET_PRESENTATION_STORE_STATUS", 2);
                    commit("SET_PRESENTATION", response.data.data);
                })
                .catch((e) => {
                    commit("SET_PRESENTATION_STORE_STATUS", 3);
                });
        },

        storeFinmodel({commit, state}) {
            commit("SET_FINMODEL_STORE_STATUS", 1);
            return ProjectCreateApi.storeProjectFinmodel({
                id: state.id,
                funding: state.funding,
                finmodel: state.finmodel,
                timeline: state.timeline,
            })
                .then((response) => {
                    commit("SET_FINMODEL_STORE_STATUS", 2);
                    //commit("SET_FINMODEL", response.data.data);
                })
                .catch((e) => {
                    commit("SET_FINMODEL_STORE_STATUS", 3);
                    commit("SET_FINMODEL", {});
                });
        },

        fetchProjectDraft({commit}, id) {
            commit("SET_FETCHING_DRAFT_STATUS", 1);
            return ProjectCreateApi.fetchProjectDraft(id)
                .then((response) => {
                    commit("SET_FETCHING_DRAFT_STATUS", 2);
                    commit("SET_DESCRIPTION", response.data.data);
                    commit("SET_FINMODEL", response.data.data);
                    commit("SET_PRESENTATION", response.data.data);
                    commit("SET_TIMELINE", response.data.data);
                    commit("SET_FUNDING", response.data.data.funding);
                })
                .catch((e) => {
                    commit("SET_DESCRIPTION", {});
                    commit("SET_FINMODEL", {});
                    commit("SET_PRESENTATION", {});
                    commit("SET_FETCHING_DRAFT_STATUS", 3);
                });
        },

        sendToModeration({commit, dispatch}, id) {
            commit("SET_SENDING_MODERATION_STATUS", 1);
            return ProjectCreateApi.sendToModeration(id)
                .then((response) => {
                    commit("SET_SENDING_MODERATION_STATUS", 2);
                    this.dispatch("projects/loadProjects");
                })
                .catch((e) => {
                    commit("SET_SENDING_MODERATION_STATUS", 3);
                });
        },
    },

    mutations: {
        SET_DESCRIPTION(state, obj) {
            state.id = obj.id || false;
            state.image = obj.image || "";
            state.image_link = obj.image_link || "";
            state.name = obj.name || "";
            state.description = obj.description || "";
            state.date_start = obj.date_start || "";
            state.category_id = obj.category_id || "";
        },
        SET_DESCRIPTION_STORE_STATUS(state, status) {
            state.descriptionStoreStatus = status;
        },

        SET_PRESENTATION(state, obj) {
            if (obj && obj.html) state.html = obj.html;
            else state.html = "";
        },
        SET_PRESENTATION_STORE_STATUS(state, status) {
            state.presentationStoreStatus = status;
        },

        SET_FINMODEL_STORE_STATUS(state, status) {
            state.finmodelStoreStatus = status;
        },

        SET_FUNDING(state, value) {
            if (value) state.funding = parseInt(value);
        },

        SET_FINMODEL(state, obj) {
            if (obj.finmodel) state.finmodel = JSON.parse(obj.finmodel);
            else
                state.finmodel = {
                    capitalCosts: {
                        items: [
                            {
                                title: "",
                                value: "",
                            },
                        ],
                    },
                    fixedCosts: {
                        interval: "",
                        items: [
                            {
                                title: "",
                                value: "",
                            },
                        ],
                    },
                    variableCosts: {
                        salesQ: "",
                        items: [
                            {
                                title: "",
                                value: "",
                            },
                        ],
                    },
                    salesForecast: {
                        averageCheck: "",
                        items: [
                            {
                                title: "",
                                value: "",
                            },
                        ],
                    },
                };
        },
        SET_CAPITAL_COSTS(state, payload) {
            if (payload.items && payload.items.length)
                state.finmodel.capitalCosts.items = payload.items;
            else state.finmodel.capitalCosts.items = [{title: "", value: ""}];
        },
        SET_FIXED_COSTS(state, payload) {
            if (payload.items && payload.items.length) {
                state.finmodel.fixedCosts.items = payload.items;
                state.finmodel.fixedCosts.interval = payload.interval;
            } else {
                state.finmodel.fixedCosts = {
                    interval: "",
                    items: [{title: "", value: ""}],
                };
            }
        },
        SET_VARIABLE_COSTS(state, payload) {
            if (payload.items && payload.items.length) {
                state.finmodel.variableCosts.items = payload.items;
                state.finmodel.variableCosts.salesQ = payload.salesQ;
            } else {
                state.finmodel.variableCosts = {
                    salesQ: "",
                    items: [{title: "", value: ""}],
                };
            }
        },
        SET_SALES_FORECAST(state, payload) {
            if (payload.items && payload.items.length) {
                state.finmodel.salesForecast.items = payload.items;
                state.finmodel.salesForecast.averageCheck = payload.averageCheck;
            } else {
                state.finmodel.salesForecast = {
                    averageCheck: "",
                    items: [{title: "", value: ""}],
                };
            }
        },

        SET_TIMELINE(state, obj) {
            state.timeline = JSON.parse(obj.timeline);
        },

        SET_FETCHING_DRAFT_STATUS(state, status) {
            state.fetchingDraftStatus = status;
        },

        SET_PROJECT_DRAFT(state, payload, commit) {
        },

        SET_SENDING_MODERATION_STATUS(state, status) {
            state.sendModerationStatus = status;
        },

        updateField,
    },

    getters: {
        getField,
    },
};
