<template>
  <div class="component-notification">
    <div class="block on-website" data-id="on-the-website">
      <div class="title">
        <h3>{{ i18n.onTheWebsite[$lang] }}</h3>
      </div>

      <div class="switches-wrapper">
        <div class="switch-wrapper">
          <div class="text">
            <h5>{{ i18n.showInstantNotifications[$lang] }}</h5>
          </div>
          <div class="switch-content" @click="switchValue1 = !switchValue1">
            <div class="component-switch" :class="{'on': switchValue1, 'off': !switchValue1 }">
              <div class="circle" />
            </div>
          </div>
          <!-- <Switch /> -->
        </div>

        <div class="switch-wrapper">
          <div class="text">
            <h5>{{ i18n.notifyMe[$lang] }}</h5>
          </div>

          <div class="switch-content" @click="switchValue2 = !switchValue2">
            <div class="component-switch" :class="{'on': switchValue2, 'off': !switchValue2 }">
              <div class="circle" />
            </div>
          </div>
        </div>

        <div class="switch-wrapper">
          <div class="text">
            <h5>{{ i18n.projectsWereCommented[$lang] }}</h5>
          </div>

          <div class="switch-content" @click="switchValue3 = !switchValue3">
            <div class="component-switch" :class="{'on': switchValue3, 'off': !switchValue3 }">
              <div class="circle" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block via-email" data-id="via-email">
      <div class="title">
        <h3>{{ i18n.viaEmail[$lang] }}</h3>
      </div>

      <div class="switches-wrapper">
        <div class="switch-wrapper">
          <div class="text">
            <h5>{{ i18n.sendNotifications[$lang] }}</h5>
          </div>

          <div class="switch-content" @click="switchValue4 = !switchValue4">
            <div class="component-switch" :class="{'on': switchValue4, 'off': !switchValue4 }">
              <div class="circle" />
            </div>
          </div>
        </div>

        <div class="switch-wrapper">
          <div class="text">
            <h5>{{ i18n.notifyMe[$lang] }}</h5>
          </div>

          <div class="switch-content" @click="switchValue5 = !switchValue5">
            <div class="component-switch" :class="{'on': switchValue5, 'off': !switchValue5 }">
              <div class="circle" />
            </div>
          </div>
        </div>

        <div class="switch-wrapper">
          <div class="text">
            <h5>{{ i18n.projectsWereCommented[$lang] }}</h5>
          </div>

          <div class="switch-content" @click="switchValue6 = !switchValue6">
            <div class="component-switch" :class="{'on': switchValue6, 'off': !switchValue6 }">
              <div class="circle" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block other" data-id="other">
      <div class="title">
        <h3>{{ i18n.other[$lang] }}</h3>
      </div>

      <div class="switches-wrapper">
        <div class="switch-wrapper">
          <div class="text">
            <h5>{{ i18n.showInstantNotifications[$lang] }}</h5>
          </div>

          <div class="switch-content" @click="switchValue7 = !switchValue7">
            <div class="component-switch" :class="{'on': switchValue7, 'off': !switchValue7 }">
              <div class="circle" />
            </div>
          </div>
        </div>

        <div class="switch-wrapper">
          <div class="text">
            <h5>{{ i18n.showInstantNotifications[$lang] }}</h5>
          </div>

          <div class="switch-content" @click="switchValue8 = !switchValue8 ">
            <div class="component-switch" :class="{'on': switchValue8, 'off': !switchValue8 }">
              <div class="circle" />
            </div>
          </div>
        </div>

        <Button class="button-save primary">
          {{ i18n.save[$lang] }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
  import Button from '@/components/Button';
  import { mapState } from 'vuex';

  export default {
    name: 'Notifications',
    components: {
      Button,
    },
    data() {
      return {
        switchValue1: false,
        switchValue2: false,
        switchValue3: false,
        switchValue4: false,
        switchValue5: false,
        switchValue6: false,
        switchValue7: false,
        switchValue8: false,
        i18n: {
          onTheWebsite: {
            en: 'On the website',
            ru: 'На сайте'
          },
          showInstantNotifications: {
            en: 'Show instant notifications',
            ru: 'Показывать мгновенные уведомления'
          },
          notifyMe: {
            en: 'Notify me, when my projects were promoted',
            ru: 'Сообщать, когда мои проекты поддержаны'
          },
          projectsWereCommented: {
            en: 'Notify me, when my projects were commented',
            ru: 'Сообщать, когда мои проекты прокомментированны'
          },
          viaEmail: {
            en: 'Via email',
            ru: 'По электронной почте'
          },
          sendNotifications: {
            en: 'Send notifications',
            ru: 'Присылать уведовления'
          },
          other: {
            en: 'Other',
            ru: 'Другое'
          },
          showInstantNotifications: {
            en: 'Show instant notifications',
            ru: 'Показывать мгновенные уведомления'
          },
          save: {
            en: 'Save',
            ru: 'Сохранить'
          },

        },

      }
    },
    methods: {
      chengeSwitchValue() {
        this.switchValue = !this.switchValue
      }
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    }
  };
</script>

<style scoped lang="scss">

  .component-notification {
    padding: 60px 0 0 0;

    @media only screen and (max-width: 767px) {
        padding: 25px 0 0 0;
    }
  }

  .block {
    position: relative;
    padding: 0 145px 65px 90px;

    @media only screen and (max-width: 1210px) {
      padding: 0 60px 65px 60px;
    }

    @media only screen and (max-width: 1130px) {
      padding: 0 25px 65px 25px;
    }

    @media only screen and (max-width: 767px){
      padding: 0 25px 25px 25px;
    }
  }

  .via-email {
    padding-top: 55px;
    @media only screen and (max-width: 767px) {
      padding-top: 25px;
    }
  }
  .other {
    padding-top: 70px;
    @media only screen and (max-width: 767px) {
padding-top: 25px;
    }
  }

  .via-email::before,
  .other::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-top: 2px #F0F0F0 solid;
  }

  .switch-wrapper{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .title {
    color: #4D4D4D;

    @media only screen and (max-width: 1030px) {
      font-size: 18px;
    }
  }

  // .checkboxes-wrapper {
  //   display: flex;
  //   align-items: flex-start;
  //   justify-content: space-between;
  //   margin-top: 35px;
  // }

  .text {
    color: #666666;
    padding: 47px 0 0;

    @media only screen and (max-width: 767px){
      padding: 25px 0 0;
    }
  }

  .button-save {
    padding: 0px 65px;
    margin: 85px 0 0 0px;
    @media only screen and (max-width: 767px){
      margin: 25px 0 0 0;
    }
  }



    .switch-content {
      display: flex;
      align-items: center;
    }

    .component-switch {
        width: 40px;
        min-height: 24px;
        display: flex;
        cursor: pointer;
        border-radius: 22px;
        padding: 2px;
        transition: all .3s;
        // background: #50CAB6;
    }

    .on {
      background: #50CAB6;
      justify-content: flex-end;
      border: 1px solid #50CAB6;

      .circle {
        background: #fff;
      }
    }

    .off {
      background: #fff;
      justify-content: flex-start;
      border: 1px solid #F0F0F0;
    }

    .circle {
        background: #E6E6E6;
        width: 18px;
        height: 18px;
        border-radius: 50%;
    }

</style>
