import CategoryApi from '../api/category';

export default {
    namespaced: true,
    state: {
        categoriesMap: [],
        categoryLoadStatus: 0
    },

    mutations: {
        SET_CATEGORIES_MAP(state, data) {
            state.categoriesMap = data;
        },

        SET_CATEGORIES_LOAD_STATUS(state, status) {
            state.categoryLoadStatus = status
        }
    },

    actions: {

        fetchCategoriesMap({commit}) {
            commit('SET_CATEGORIES_LOAD_STATUS', 1);
            return CategoryApi.getCategories()
                .then((response) => {
                    commit('SET_CATEGORIES_LOAD_STATUS', 2);
                    commit('SET_CATEGORIES_MAP', response.data.data);
                })
                .catch((e) => {
                    commit('SET_CATEGORIES_LOAD_STATUS', 3)
                    commit('SET_CATEGORIES_MAP', {})
                })
        },

    }

}
