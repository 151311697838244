<template>
    <DiagramWrapper :header-text="headerText">
        <div class="circle">
            <canvas :id="diagramId" width="160" height="160"/>
        </div>

        <div class="circle-legend">
            <div v-for="(legendItemCur, index) in circleData.labels" :key="index" class="legend-item">
                <div class="legend-color" :style="`background-color: ${circleData.colors[index]}`"/>

                <div class="legend-label text-micro">
                    {{ legendItemCur }}
                </div>

                <div class="legend-value text-micro">
                    {{ circleData.data[index] }}%
                </div>
            </div>
        </div>
    </DiagramWrapper>
</template>

<script>
    import Chart from 'chart.js';
    import DiagramWrapper from '../DiagramWrapper';
    import {mapState} from 'vuex';

    export default {
        name: 'CircleDiagram',
        components: {DiagramWrapper},
        props: {
            diagramId: {
                type: String,
                required: true
            },
            headerText: {
                type: [String, Object],
                required: true
            },
            data: {
                required: true,
                type: [Object, Array],
            }
        },
        data() {
            return {
                circleData: {
                    labels: [],
                    data: [],
                    total: 0,
                    colors: [
                        '#3AA4D2',
                        '#1875F0',
                        '#F18F1C',
                        '#5553CE',
                        '#9013FE',
                        '#50D166'
                    ]
                }
            };
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang
            }),
        },
        mounted() {
            const total = this.data.reduce((acc, cur) => acc += parseInt(cur.value), 0);
            const model = this.data.reduce(function (acc, cur) {
                acc.labels.push(cur.title);
                acc.data.push(Math.round(parseInt(cur.value) / parseInt(total) * 100));
                return acc;
            }, {total: total, labels: [], data: []})

            this.$set(this.circleData, 'labels', model.labels);
            this.$set(this.circleData, 'data', model.data);

            const ctx = document.getElementById(this.diagramId);
            const myChart = new Chart(ctx, {
                type: 'doughnut',
                data: {
                    labels: model.labels,
                    datasets: [{
                        label: '',
                        data: model.data,
                        backgroundColor: this.circleData.colors
                    }]
                },
                options: {
                    cutoutPercentage: 70,
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    scales: {
                        display: false
                    }
                }
            });
        },
        methods: {
            /*getLabels() {
                if (this.$lang === 'ru') {
                    return [
                        'Входящие запросы',
                        'Первый контакт',
                        'Офер сделан',
                        'Переговоры',
                        'Контракт',
                        'Выигрыш',
                    ]
                }

                if (this.$lang === 'en') {
                    return [
                        'Incoming requests',
                        'Intial Contact',
                        'Offer Made',
                        'Negotiation',
                        'Contract',
                        'Won leads',
                    ]
                }
            }*/
        }
    };
</script>

<style scoped lang="scss">
    .circle {
        height: 160px;
        width: 160px;
    }

    .circle-legend {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        justify-items: start;
        align-items: start;
        grid-column-gap: 46px;
        grid-row-gap: 20px;
        margin-top: 50px;
        width: 100%;
        max-width: 454px;
        @media only screen and (max-width: 767px){
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            margin-top: 18px;
            padding: 0 40px;
        }
    }

    .legend-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
    }

    .legend-color {
        height: 10px;
        min-width: 10px;
        border-radius: 50%;
    }

    .legend-label {
        color: #B3B3B3;
        margin-left: 15px;
        @media only screen and (max-width: 767px){
            margin-left: 10px;
        }
    }

    .legend-value {
        color: #B3B3B3;
        margin-left: auto;
    }
</style>
