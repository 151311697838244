<template>
  <div class="component-footer">
    <div class="wrapper">
      <div
        v-for="(sectionCur, index) in sections"
        :key="index"
        class="footer-col"
      >
        <div class="footer-section-name">
          <h3 class="footer-section-title">
            {{ sectionCur.name[$lang] }}
          </h3>
        </div>

        <div
          v-for="(pageCur, pageIndex) in sectionCur.pages[$lang]"
          :key="pageIndex"
          class="footer-section-pages"
        >
          <router-link :to="getLink(index)" class="footer-section-page-link text-body">
            {{ pageCur }}
          </router-link>
        </div>
      </div>
    </div>
    <div class="legal-info">
      <div class="legal-info-item">
        <router-link to="#">
          {{ i18n.legalInformation[$lang] }}
        </router-link>
      </div>
      <div class="legal-info-item">
        <router-link to="#">
          {{ i19n.legalInformation[$lang] }}
        </router-link>
      </div>


      <!-- <div class="legal-info-item">
              <router-link to="#">
                Legal information
              </router-link>
            </div> -->

      <div class="legal-info-item copyright">© Grossfort 2020</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Footer",
  data() {
    return {
      sections: [
        {
          name: {
            en: "About the platform",
            ru: "О площадке",
          },
          pages: {
            en: [
              "What is Grossfort",
              "О площадке",
              "How it works",
              "Financial reports",
              "They write about us",
              "Vacancies",
              "Contacts",
            ],
            ru: [
              "Что такое Grossfort",
              "Как это работает",
              "Финансовые отчёты",
              "О нас пишут",
              "Вакансии",
              "Контакты",
            ],
          },
        },
        {
          name: {
            en: "Responsibility",
            ru: "Ответственность",
          },
          pages: {
            en: [
              "Risk declaration",
              "Personal information",
              "Terms of use",
              "Rules of the platform",
              "Privacy Policy",
            ],
            ru: [
              "Декларация о рисках",
              "Персональные данные",
              "Пользовательское соглашение",
              "Правила площадки",
              "Политика конфиденциальности",
            ],
          },
        },
        {
          name: {
            en: "Support Center",
            ru: "Центр поддержки",
          },
          pages: {
            en: [
              "FAQ",
              "Articles and courses",
              "Technical support",
              "Help in project preparation",
              "Ask a question",
            ],
            ru: [
              "Частые вопросы",
              "Статьи и курсы",
              "Техническая поддержка",
              "Помощь в подготовке проектов",
              "Задать вопрос",
            ],
          },
        },
        {
          name: {
            en: "Our Social Media",
            ru: "Мы в соцсетях",
          },
          pages: {
            en: ["Telegram", "Instagram", "Twitter", "Youtube"],
            ru: ["Telegram", "Instagram", "Twitter", "Youtube"],
          },
        },
      ],
      i18n: {
        legalInformation: {
          en: "Legal information",
          ru: "Правовая информация",
        },
      },
      i19n: {
        legalInformation: {
          en: "Privacy Policy",
          ru: "Политика конфиденциальности",
        },
      },
    };
  },
  computed: {
    ...mapState({
      $lang: (state) => state.$lang,
    }),
  },
  methods: {
    getLink(sectionIndex) {
      const section = this.sections[sectionIndex].name.en;

      if (section === "About the platform") {
        return "/about";
      }

      if (section === "Support Center") {
        return "/help";
      }

      return "#";
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/sass/mixins.scss";

.component-footer {
  position: relative;
  z-index: 40;
  background: #12163b;
  margin-top: 90px;
  // padding: 80px 8.5vw 30px 8.5vw;
  padding: 93px 11.5vw 40px;

  @media only screen and (max-width: 1049px) {
    padding-left: 7vw;
    padding-right: 7vw;
  }
  @media only screen and (max-width: 767px) {
    padding: 60px 20px 20px;
  }
}

.wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media only screen and (max-width: 767px){
    flex-wrap: wrap;
    margin: 0 -10px;
  }
}

.footer-col {
  margin-left: 15px;

  &:nth-child(1) {
    margin-left: 0;
  }
  @media only screen and (max-width: 767px) {
    max-width: 50%;
    width: 100%;
    margin-bottom: 40px;
    margin-left: 0;
    padding: 0 9px;
  }
}

.footer-section-name {
  color: #f0f3f6;
  margin-bottom: 30px;
  @media only screen and (max-width: 767px){
    margin-bottom: 20px;
  }
  .footer-section-title{
    @media only screen and (max-width: 1030px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 767px){
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.footer-section-pages {
  margin-top: 10px;
  @media only screen and (max-width: 767px){
    margin-top: 12px;
  }
}

.footer-section-page-link {
  color: #f0f3f6;
  margin-top: 15px;

  &:nth-child(1) {
    margin-top: 0;
  }

  @media only screen and (max-width: 1030px) {
    font-size: 12px;
    line-height: 18px;
    @media only screen and (max-width: 767px){
      font-size: 14px;
    }
  }

  @include hover-default();
}

.legal-info {
  // margin-top: 50px;
  // padding-top: 30px;
  margin-top: 45px;
  padding-top: 45px;
  border-top: 1px solid #50cab6;
  /*height: 30px;*/
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media only screen and (max-width: 767px){
    flex-wrap: wrap;
    margin-top: 0;
    padding-top: 9px;
  }
}

.legal-info-item {
  font-family: IBMPlexSans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
  margin-left: 15px;
  &.copyright {
    margin-left: auto;
    margin-right: 50px;
  }
  @media only screen and (max-width: 767px){
    margin-top: 10px;
    font-size: 10px;
    line-height: 14px;
    margin-left: 0;
    margin-right: 10px;
    &.copyright{
      margin-left: 0;
      margin-right: auto;
      width: 100%;
      opacity: .8;
    }
    a{
      opacity: .8;
      &:hover{
        opacity: 1;
      }
    }
  }
}
</style>
