<template>
  <div class="component-log-in">
    <div class="overlay">
      <div class="modal">
        <div class="wrapper">
          <div class="step-ic">
            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="xokwdfagya" maskUnits="userSpaceOnUse" x="6" y="8" width="18" height="14"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.6 18.6l-4.2-4.2L6 15.8l5.6 5.6 12-12L22.2 8 11.6 18.6z" fill="#fff"/></mask><g mask="url(#xokwdfagya)"><path fill="#fff" d="M2 2h26v26H2z"/></g></svg>
          </div>

          <div class="title">
            {{ i18n.title[$lang] }}
          </div>

          <div class="text">
            {{ i18n.text[$lang] }}
          </div>

          <Button class="button button-log-in">
            {{ i18n.logIn[$lang] }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import Button from '@/components/Button';

  export default {
    name: 'RegistrationCompleted',
    components: {
      Button,
    },
    data() {
      return {
        i18n: {
          title: {
            en: 'Registration completed',
            ru: 'Регистрация завершена'
          },
          logIn: {
            en: 'Log In',
            ru: 'Войти'
          },
          text: {
            en: 'We just sent password recovery instructions',
            ru: 'Мы только что отправили вам инструкции для восстановления пароля'
          },
        },
      };
    },
    methods: {

    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    },
  };
</script>

<style scoped lang="scss">
  .component-login-in {
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    width: 350px;
    height: 330px;
    background-color: #fff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 100%;
    padding: 30px 60px 35px;
  }

  .step-ic {
    border: 2px solid #F5F5F5;
    box-sizing: border-box;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    .img {
      color: #D8D8D8;
    }
  }

  .title {
    // font-family: Raleway;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #4D4D4D;
    text-align: center;

    @media only screen and (max-width: 1030px) {
      font-size: 12px;
    }
  }

  .button {
    // font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 50px;
    text-align: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    width: 120px;
  }

  .text {
    // font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    color: #999999;
    padding: 0 0 10px 0;
  }


</style>
