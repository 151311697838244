import axios from "axios";

export default {
    state: {
        countries: [],
        cities: []
    },

    mutations: {
        SET_COUNTRIES(state, countries) {
            state.countries = countries.data;
        },

        SET_CITIES(state, cities) {
            state.cities = cities.data;
        },
    },

    actions: {
        async fetchCountries({commit}) {
            return new Promise((resolve, reject) => {
                axios({
                    url: "/api/countries",
                    data: {},
                    method: "GET"
                })
                    .then(resp => {
                        commit("SET_COUNTRIES", resp.data);
                    })
                    .catch(err => console.log(err));
            });
        },
        async fetchCities({commit}) {
            return new Promise((resolve, reject) => {
                axios({
                    url: "/api/cities",
                    data: {},
                    method: "GET"
                })
                    .then(resp => {
                        commit("SET_CITIES", resp.data);
                    })
                    .catch(err => console.log(err));
            });
        }
    },

    getters: {}
};
