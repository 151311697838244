<template>
  <div class="component-deposit">
    <div class="deposit-balans">
      <div class="deposit-price text-plex24-30">
        {{ depositBalance }} ₽
      </div>

      <div class="deposit-text ">
        {{ i18n.balance[$lang] }}
      </div>
    </div>

    <Button class="deposit-button primary">
      <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'user-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="pos9ybh5ya" maskUnits="userSpaceOnUse" x="8" y="8" width="14" height="14"><path fill-rule="evenodd" clip-rule="evenodd" d="M22 16h-6v6h-2v-6H8v-2h6V8h2v6h6v2z" fill="#fff"/></mask><g mask="url(#pos9ybh5ya)"><path d="M2 2h26v26H2z"/></g></svg>

      <div class="deposit-button-label">
        {{ i18n.deposit[$lang] }}
      </div>
    </Button>
  </div>
</template>

<script>
  import Button from '@/components/Button';
  import { mapState } from 'vuex';

  export default {
    name: 'Deposit',

    components: {
      Button,
    },

    data() {
      return {
        depositBalance: '450 000',
        i18n: {
          balance: {
            en: 'Balance',
            ru: 'Баланс'
          },
          deposit: {
            en: 'Deposit',
            ru: 'Пополнить'
          },
        }
      }
    },

    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    },
  };
</script>

<style scoped lang="scss">
  @import "@/sass/mixins.scss";

  .component-deposit {
    background: #FFFFFF;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    width: 50%;
    max-width: 538px;
    display: flex;
    justify-content: space-between;
    margin-right: 12px;
    align-items: center;

    @media only screen and (max-width: 767px){
      width: 100%;
      margin-right: 0;
    }
  }

  .deposit-balans {
    padding: 50px 0 50px 60px;

    @media only screen and (max-width: 1310px) {
      padding: 50px 0 50px 40px;
    }

    @media only screen and (max-width: 1250px) {
      padding: 50px 0 50px 30px;
    }

    @media only screen and (max-width: 1065px) {
      padding: 50px 0 50px 20px;
    }
  }

  .deposit-price {
    color: #666666;
  }

  .deposit-text {
    font-weight: 600;
    font-size: 14px;
    color: #B3B3B3;
    padding-top: 20px;

    @media only screen and (max-width: 1065px) {
      font-size: 12px;
    }
  }

  .deposit-button {
    height: 55px;
    padding: 0 40px 0 20px;
    margin: 0 74px 0 0;

    @media only screen and (max-width: 1250px) {
      margin: 0 30px 0 0;
    }
  }

  .deposit-button-label {
    padding-left: 20px;
  }

</style>
