<template>
  <button
    type="button"
    class="button share"
    :class="{
      'variant-small': variant === 'small',
      'variant-big': variant === 'big',
      'variant-micro': variant === 'micro',
    }"
  >
    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="njk5z72ada" maskUnits="userSpaceOnUse" x="6" y="7" width="18" height="15"><path fill-rule="evenodd" clip-rule="evenodd" d="M17 11V7l7 7-7 7v-4.1c-5 0-8.5 1.6-11 5.1 1-5 4-10 11-11z" fill="#fff"/></mask><g mask="url(#njk5z72ada)"><path d="M2 2h26v26H2z"/></g></svg>
  </button>
</template>

<script>
export default {
  name: "ButtonShare",
  props: {
    variant: {
      type: [String, Object],
      default: "small",
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/sass/mixins.scss";

.variant-micro {
  height: 40px;
  width: 40px;
  border-color: transparent !important;
  border: 0;

  svg {
    color: #b3b3b3;
  }
  &:hover, &.active {
    color: #50CAB6;
    svg {
      color:#50cab6;
    }
  }
}

.variant-small {
  @include mark-cards;
}

.variant-big {
  @include mark-cards-big;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;

  .content-button-img {
    color: #ffffff;
  }
}
</style>
