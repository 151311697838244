<template>
    <div class="component-sign-up">
        <div class="overlay" @click="closeModal">

        </div>
        <div class="modal">
            <div class="head-wrapper">
                <div class="title">
                    {{ i18n.signUp[$lang] }}
                </div>

                <Button class="button button-sign-up" @click="handleLoginButtonClick">
                    {{ i18n.logIn[$lang] }}
                </Button>
            </div>
            <div class="inputs-wrapper">
                <div class="input-wrapper">
                    <div class="label-wrapper">
                        <img
                                src="@/assets/img/icons/ic_person.svg"
                                alt=""
                                class="icon"
                        />
                        <label for="email" class="label">
                            {{ i18n.email[$lang] }}
                        </label>
                    </div>

                    <input
                            id="email"
                            name="email"
                            class="input"
                            v-model="email"/>
                </div>

                <div class="input-wrapper">
                    <div class="label-wrapper">
                        <img
                                src="@/assets/img/icons/ic_lock.svg"
                                alt=""
                                class="icon"
                        />
                        <label for="Password" class="label">
                            {{ i18n.password[$lang] }}
                        </label>
                    </div>

                    <input
                            id="Password"
                            name="password"
                            class="input"
                            type="password"
                            v-model="password"
                    />
                </div>

                <!--                    <div class="input-wrapper">-->
                <!--                        <div class="label-wrapper">-->
                <!--                            <img-->
                <!--                                src="@/assets/img/icons/ic_lock.svg"-->
                <!--                                alt=""-->
                <!--                                class="icon"-->
                <!--                            />-->
                <!--                            <label for="Password_confirmation" class="label">-->
                <!--                                {{ i18n.password_confirmation[$lang] }}-->
                <!--                            </label>-->
                <!--                        </div>-->

                <!--                        <input-->
                <!--                            id="Password_confirmation"-->
                <!--                            name="password_confirmation"-->
                <!--                            type="password"-->
                <!--                            class="input"-->
                <!--                            v-model="password_confirmation"-->
                <!--                        />-->
                <!--                    </div>-->
            </div>
            <div class="footer-wrapper">
                <Button class="button button-log-in" @click="register">
                    {{ i18n.signUp[$lang] }}
                </Button>

                <div class="text">
                    {{ i18n.text[$lang] }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import Button from '@/components/Button';

    export default {
        name: 'SignUp',
        components: {
            Button,
        },
        data() {
            return {
                email: '',
                password: '',
                password_confirmation: '',
                i18n: {
                    email: {
                        en: 'Email',
                        ru: 'Эл. почта'
                    },
                    signUp: {
                        en: 'Sign Up',
                        ru: 'Регистрация'
                    },
                    logIn: {
                        en: 'Log In',
                        ru: 'Войти'
                    },
                    password: {
                        en: 'Password',
                        ru: 'Пароль'
                    },
                    password_confirmation: {
                        en: 'Confirmation',
                        ru: 'Подтверждение'
                    },
                    text: {
                        en: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum voluptates animi sequi temporibus libero, reprehenderit corporis recusandae quibusdam perspiciatis nisi dolorem',
                        ru: 'Нажав кнопку «Зарегистрироваться» вы принимаете условия пользовательского соглашения и условия политики конфиденциальности и даете согласие на обработку персональных данных'
                    },
                },
            };
        },
        methods: {
            closeModal: function () {
                this.$emit('input', "")
            },
            register: function () {
                let email = this.email;
                let password = this.password;
                this.$store.dispatch('user/register', {email, password})
                    .then(() => {
                            this.closeModal();
                            this.$router.push('/profile-discovery');
                        }
                    ).catch((err) =>
                    console.log(err)
                );
            },
            handleLoginButtonClick: function () {
                // this.$router.push('/login')
                this.$emit("input", "login")
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
        },
    };
</script>

<style scoped lang="scss">
    .component-login-in {
        position: relative;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.15);
        z-index: 1;
    }

    .modal {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-sizing: border-box;
        width: 350px;
        /*height: 450px;*/
        //height: 525px;
        background-color: #fff;
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        z-index: 50;
    }

    .head-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 30px;
    }

    .inputs-wrapper {
        display: flex;
        flex-direction: column;
    }

    .input-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px 20px 40px;
    }

    .label-wrapper {
        display: flex;
    }

    .title {
        // font-family: Raleway;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #4D4D4D;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .button-sign-up {
        font-weight: 900;
        font-size: 10px;
        line-height: 40px;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        padding: 0 31px;
    }

    .label {
        // font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 50px;
        color: #999999;
        padding: 0 0 0 7px;
        cursor: pointer;
    }

    .input {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 2px solid #F5F5F5;
        border-radius: 4px;
        width: 160px;
        height: 50px;
        padding: 0 15px;
        // font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 50px;
        color: #999999;
        cursor: pointer;
    }

    .footer-wrapper {
        padding: 0 0 30px 30px;
    }

    .button-log-in {
        padding: 15px 0;
        margin: 0 30px 0 0;
        font-size: 12px;
        letter-spacing: 1.5px;
        width: 220px;
    }

    .text {
        // font-family: IBM Plex Sans;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        color: #B3B3B3;
        padding: 20px 60px 0 0;
    }


</style>
