<template>
    <Button class="component-show-more normal" v-on="$listeners" :withoutBorder="true">
        <template>
            {{ i18n.showMore[$lang] }}
            <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'arrow'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="6dogbsck7a" maskUnits="userSpaceOnUse" x="6" y="6" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M21.466 14l-1.316-1.316-5.217 5.208V6.533h-1.867v11.36L7.86 12.674 6.533 14 14 21.467 21.466 14z" fill="#fff"/></mask><g mask="url(#6dogbsck7a)"><path fill="#D8D8D8" d="M1.866 1.867h24.267v24.267H1.866z"/></g></svg>
        </template>
    </Button>
</template>

<script>
    import Button from "@/components/Button"
    import {mapState} from 'vuex';

    export default {
        name: 'ShowMore',
        components:{
            Button
        },
        data() {
            return {
                i18n: {
                    showMore: {
                        en: 'Show more',
                        ru: 'Показать ещё'
                    },
                },
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
        }
    };
</script>

<style lang="scss">;
    .component-show-more {
        margin-left: auto;
        margin-right: auto;
        .arrow{
            @media only screen and (max-width: 767px){
                transform: rotate(-90deg);
            }
        }
    }
</style>
