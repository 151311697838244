<template>
    <div>
        <div class="title">
            <h3>
                <slot name="title"></slot>
            </h3>
        </div>
        <div class="desc">
            <p>
                <slot name="description"></slot>
            </p>
        </div>


        <template v-if="typeof finmodel.interval !== 'undefined'
                        || typeof finmodel.salesQ !== 'undefined'
                        ||typeof finmodel.averageCheck !== 'undefined'">
            <div class="steps-wrapper">
                <div class="inputs-wrapper">
                    <div v-if="typeof finmodel.interval !== 'undefined'" class="select-wrapper">
                        <div class="col-2-wrapper">
                            <div>
                                <label for="finmodel-extra" class="label">Выберите период</label>
                                <v-select v-model="finmodel.interval" :options="selectIntervalItems" :clearable="false"
                                          class="select">
                                    <template #open-indicator="{ attributes }">
                                <span v-bind="attributes">
                                    <svg width="9" height="5" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.498 5L9 2.5V.104L4.498 2.62 0 0v2.5L4.498 5z" fill="#D9D9D9"/></svg>
                                </span>
                                    </template>
                                </v-select>
                            </div>
                            <div class="period-selector">
                                <p>Зависит от объёмов бизнеса и скорости его роста. Для небольшого
                                    приложения нужно несколько месяцев. Для крупного завода — несколько лет.</p>
                            </div>
                        </div>

                    </div>

                    <div v-if="typeof finmodel.salesQ !== 'undefined'" class="input-wrapper">
                        <label for="finmodel-extra" class="label">Средний прогнозируемый объем продаж, едениц</label>
                        <input id="finmodel-extra" v-model="finmodel.salesQ" class="input input-main"/>
                    </div>

                    <div v-if="typeof finmodel.averageCheck !== 'undefined'" class="input-wrapper">
                        <label for="finmodel-extra" class="label">Средний чек</label>
                        <input id="finmodel-extra" v-model="finmodel.averageCheck" class="input input-main"/>
                    </div>
                </div>
            </div>
        </template>


        <div class="steps-wrapper">
            <div
                    v-for="(stepCur, index) in finmodel.items"
                    :key="index"
                    class="step"
            >
                <div class="inputs-wrapper">
                    <div class="input-wrapper step-left">
                        <label :for="'title-'+index" class="label">
                            {{itemTitle}}
                        </label>
                        <input :id="'title-'+index"
                               v-model="stepCur.title"
                               :placeholder="itemTitle"
                               class="input input-main"/>
                    </div>

                    <div class="input-wrapper step-right">
                        <label :for="'value-'+index" class="label">
                            {{itemValueTitle}}
                        </label>
                        <input :id="'value-'+index"
                               v-model="stepCur.value"
                               placeholder="0"
                               class="input input-main"/>
                    </div>
                </div>
            </div>

        </div>
        <div class="step-buttons-wrapper">
            <Button class="normal step-button-continue" @click="handleStepButtonContinue">
                {{ i18n.continue[$lang] }}
            </Button>

            <Button class="normal step-button-cancel" without-border @click="handleStepButtonCancel">
                {{ i18n.cancel[$lang] }}
            </Button>
        </div>
    </div>
</template>

<script>

    import Button from '@/components/Button';
    import {mapState} from 'vuex';

    export default {
        name: 'Finmodel',
        components: {
            Button
        },
        props: {
            items: {
                default: () => ({
                    items: [{
                        "title": "",
                        "value": ""
                    }]
                }),
            },
            itemTitle: {
                type: String,
                required: true,
            },
            itemValueTitle:{
                type: String,
                required: true
            }
        },
        data() {
            return {
                stepActiveIndex: 0,
                finmodel: JSON.parse(JSON.stringify(this.items)),
                selectIntervalItems: ['day', 'month', 'quarter', 'year'],
                selectedInterval: false,
                i18n: {
                    continue: {
                        en: 'Add row',
                        ru: 'Добавить строку'
                    },
                    cancel: {
                        en: 'Cancel',
                        ru: 'Отмена'
                    },
                },
            };
        },

        watch: {
            finmodel: {
                handler: function (val) {
                    this.$emit('input', JSON.parse(JSON.stringify(val)));
                },
                deep: true
            },
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
        },

        methods: {
            updateTitle: function (id, e) {
                if (this.finmodel[id])
                    this.finmodel[id].title = e.target.value;
            },
            updateValue: function (id, e) {
                if (this.finmodel[id])
                    this.finmodel[id].value = parseInt(e.target.value);
            },
            async handleStepButtonContinue() {
                this.stepActiveIndex++;

                if (this.finmodel[this.stepActiveIndex]) {
                    return;
                }

                this.finmodel.items.push({
                    title: '',
                    value: null,
                });

                await this.$nextTick();

                window.dispatchEvent(new Event('resize'));
            },
            async handleStepButtonCancel() {
                this.finmodel.items.pop();

                await this.$nextTick();

                window.dispatchEvent(new Event('resize'));
            },
        },
    };
</script>

<style scoped lang="scss">

    .label {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #666666;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 11px;
            line-height: 11px;
        }
    }

    .title {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #666666;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 11px;
            line-height: 11px;
            font-weight: 600;
            color: #4D4D4D;
            h3 {
                font-size: 15px;
                line-height: 20px;
                color: #4D4D4D;
            }
        }
    }

    .desc {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #666666;
        margin-top: 18px;
        line-height: 30px;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px) {
            margin-top: 10px;
        }
    }

    .step-buttons-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 30px;
    }

    .step-button-continue {

    }

    .step-button-cancel {
        margin-left: 10px;
    }

    .step-left {
        width: 70%;
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
    }

    .inputs-wrapper,
    .selects-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        @media only screen and (max-width: 767px) {
            flex-direction: column;
        }
    }

    .input-wrapper{
        @media only screen and (max-width: 767px){
            margin-top: 20px
        }
    }

    .input-wrapper,
    .select-wrapper {
        margin-left: 33px;
        //display: flex;

        &:nth-child(1) {
            margin-left: 0;
        }

        @media only screen and (max-width: 767px) {
            width: 100%;
        }
    }

    .col-2-wrapper {
        display: flex;
        @media only screen and (max-width: 767px) {
            display: block;
        }
    }

    .period-selector {
        padding-top: 44px;
        padding-left: 20px;
        @media only screen and (max-width: 767px) {
            width: 100%;
            padding-top: 10px;
            padding-left: 0;
        }
    }

    .input {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 2px solid #F5F5F5;
        border-radius: 4px;
        margin-top: 21px;
        width: 100%;
        height: 50px;
        padding: 0 15px;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #666666;
        cursor: text;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px) {
            margin-top: 17px;
            padding: 10px;
            font-size: 11px;
            line-height: 18px;
            height: 40px;
            border-color: #EBEBEB;
        }
    }

    .select {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 160px;
        height: 50px;
        border: 2px solid #F5F5F5;
        border-radius: 4px;
        margin-top: 21px;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #666666;
        cursor: pointer;

        &.vs--single.vs--open {
            /deep/ .vs__selected {
                opacity: 0;
            }
        }

        /deep/ .vs__open-indicator {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        /deep/ .vs__dropdown-toggle {
            padding: 0;
            border: 0;
            border-radius: 0;
            width: 100%;
            cursor: pointer;
        }

        /deep/ .vs__selected {
            border: 0;
            margin: 0;
            padding: 0 15px;
            font: inherit;
        }

        /deep/ .vs__search {
            height: 50px;
            margin: 0;
            border: 0;
            padding: 0 15px;
            font: inherit;
        }

        /deep/ .vs__actions {
            padding: 0 15px 0 3px;
        }

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px) {
            width: 100%;
            padding: 10px;
            font-size: 11px;
            line-height: 18px;
            height: 40px;
        }
    }

    .steps-wrapper {
        margin-top: 30px;
        @media only screen and (max-width: 767px) {
            margin-top: 20px;
        }

        .step:not(:first-child) .label{
            @media only screen and (min-width: 767px){
                display: none;
            }
        }
    }

    .step {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        &:nth-child(1) {
            margin-top: 0;
        }


        &.is-active {
            .step-number {
                background: #50CAB6;
                border: 2px solid transparent;
                color: #fff;
            }
        }
    }

    .step-number {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        width: 60px;
        border: 2px solid #F5F5F5;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        color: #B3B3B3;
        border-radius: 50%;
        transform: translateY(-5px);
        cursor: pointer;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .step-right {
        margin-left: 35px;
        @media only screen and (max-width: 767px) {
            margin-left: 0;
            padding-bottom: 19px;
            width: 100%;
            border-bottom: 2px solid #EBEBEB;
        }
    }

    .step-input {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 205px;
        height: 50px;
        padding: 0 20px;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #666666;
        border: 2px solid #F5F5F5;
        border-radius: 4px;
        cursor: text;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .step-textarea {
        width: 540px;
        height: 120px;
        margin-top: 24px;
        padding: 12px 20px;
        resize: none;
        border: 2px solid #F5F5F5;
        border-radius: 4px;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;
        color: #666666;
        cursor: pointer;

        @media only screen and (max-width: 1085px) {
            width: 420px;
        }

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .step-input,
    .step-textarea {
        &:disabled {
            background: transparent;
            border: 0;
            padding: 0;
        }
    }

    .step-buttons-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 30px;
        @media only screen and (max-width: 767px) {
            margin-top: 20px;
            padding-top: 20px;
            //border-top: 2px solid #EBEBEB;
        }
    }

    .step-button-continue {
        font-size: 10px !important;
        @media only screen and (max-width: 767px) {
            padding: 11px 15px !important;
            border-color: #EBEBEB;
        }
    }

    .step-button-cancel {
        margin-left: 10px;
    }

    .step-title {
        display: flex;
        align-items: center;
        height: 50px;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #666666;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }
</style>
