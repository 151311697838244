<template>
  <div class="view-main-component-stats">
    <div class="stat supported">
      <div class="stat-circle">
        <img src="@/assets/img/icons/icon-stats-like.svg" alt="" />
      </div>

      <div class="stat-percent-and-desc">
        <div class="stat-percent">
          76%
        </div>

        <div class="stat-desc">
          {{ i18n.supported[$lang] }}
        </div>
      </div>
    </div>

    <div class="stat remaining">
      <div class="stat-circle">
        <img src="@/assets/img/icons/icon-stats-clock.svg" alt="" />
      </div>

      <div class="stat-percent-and-desc">
        <div class="stat-percent">
          5 {{ i18n.days[$lang] }}
        </div>

        <div class="stat-desc">
          {{ i18n.remaining[$lang] }}
        </div>
      </div>
    </div>

    <div class="stat budget">
      <div class="stat-circle">
        <img src="@/assets/img/icons/icon-stats-bag.svg" alt="" />
      </div>

      <div class="stat-percent-and-desc">
        <div class="stat-percent">
          4,6 {{ i18n.m[$lang] }} ₽
        </div>

        <div class="stat-desc">
          {{ i18n.budget[$lang] }}
        </div>
      </div>
    </div>

    <div class="stat period">
      <div class="stat-circle">
        <img src="@/assets/img/icons/icon-stats-calendar.svg" alt="" />
      </div>

      <div class="stat-percent-and-desc">
        <div class="stat-percent">
          18 {{ i18n.M[$lang] }}
        </div>

        <div class="stat-desc">
          {{ i18n.implementationPeriod[$lang] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'Stats',
    data() {
      return {
        i18n: {
          supported: {
            en: 'Supported',
            ru: 'Поддержали'
          },
          days: {
            en: 'days',
            ru: 'дней'
          },
          remaining: {
            en: 'Remaining',
            ru: 'Осталось'
          },
          budget: {
            en: 'Budget',
            ru: 'Бюджет'
          },
          m: {
            en: 'M',
            ru: 'Млн'
          },
          M: {
            en: 'm',
            ru: 'мес'
          },
          implementationPeriod: {
            en: 'Implementation period',
            ru: 'Срок реализации'
          },

        },
      }
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    }
  };
</script>

<style scoped lang="scss">
  .view-main-component-stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    min-height: 150px;
    background: #fff;
    padding: 10px 21px 10px 45px;
    margin-top: 30px;
    border-radius: 6px;

    @media only screen and (max-width: 1272px) {
      padding: 10px 15px 10px 15px;
    }
    @media only screen and (max-width: 767px){
      padding: 17px 11px 19px;
      margin-top: 12px;
    }
  }

  .stat {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    margin-left: 15px;

    &:before {
      content: '';
      display: block;
      height: 90px;
      width: 2px;
      background: #F5F5F5;
      margin-right: 30px;
      flex-shrink: 0;

      @media only screen and (max-width: 1272px) {
        margin-right: 15px;
      }
    }

    &:nth-child(1) {
      margin-left: 0;

      &:before {
        display: none;
      }
    }

    @media only screen and (max-width: 1272px) {
      &:nth-last-child(1) {
        // transform: translateX(-47px);

        &:before {
          // opacity: 0;
        }
      }
    }
    @media only screen and (max-width: 767px){
      max-width: 50%;
      width: 100%;
      margin-left: 0;
      margin-bottom: 20px;
      &:nth-child(2n){
        margin-left: 20px;
        max-width: calc(50% - 20px);
      }
      &:before{
        display: none;
      }

    }
  }

  .stat-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    background: #FFFFFF;
    border: 2px solid #F5F5F5;
    border-radius: 50%;
    flex-shrink: 0;
    @media only screen and (max-width: 767px){
      height: 40px;
      width: 40px;
    }
  }

  .stat-percent-and-desc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 30px;
    flex-shrink: 0;

    @media only screen and (max-width: 1272px) {
      margin-left: 15px;
    }

    @media only screen and (max-width: 767px){
      margin-left: 5px;
      flex-shrink: 1;
    }
  }

  .stat-percent {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    color: #666666;

    @media only screen and (max-width: 1272px) {
      font-size: 20px;
    }

    @media only screen and (max-width: 1030px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 767px){
      font-size: 16px;
    }
  }

  .stat-desc {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #B3B3B3;
    margin-top: 12px;

    @media only screen and (max-width: 1272px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 767px){
      font-size: 11px;
      margin-top: 0;
    }
  }
</style>
