<template>
  <div class="component-contacts">
    <div class="desc">
      <div class="title">
        <h3>{{ i18n.alwaysInTouch[$lang] }}</h3>
      </div>

      <div class="block phone">
        <img src="@/assets/img/icons/ic_call.svg" alt="" class="icon" />

        <div class="label">
          <p>{{ i18n.phoneNumber[$lang] }}: +1 (451) 068-060-5322
          <br />
          {{ i18n.fax[$lang] }}: +1 (415) 775-1805</p>
        </div>
      </div>

      <div class="block email">
        <img src="@/assets/img/icons/ic_email.svg" alt="" class="icon" />

        <div class="label">
          <p>info@grossfort.com</p>
        </div>
      </div>

      <div class="title our-location">
        <h3>{{ i18n.ourLocation[$lang] }}</h3>
      </div>

      <div class="block location">
        <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="meh6iqucsa" maskUnits="userSpaceOnUse" x="8" y="5" width="14" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 5c-3.87 0-7 3.13-7 7 0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z" fill="#fff"/></mask><g mask="url(#meh6iqucsa)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

        <div class="label">
          <p>239 E 5th St New York, NY 10003 b/t<br />
            Cooper Sq & 2nd Ave East Village</p>
        </div>
      </div>

      <Button class="button">
        {{ i18n.howToGetThere[$lang] }}
        <img src="@/assets/img/icons/ic_arrow_forward.svg" class="button-icon" alt="" />
      </Button>
    </div>

    <div class="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12448.640125614527!2d-73.99615042767105!3d40.727637908654735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2599b5a05194f%3A0xeadc618d06b71934!2zMjM5IEUgNXRoIFN0LCBOZXcgWW9yaywgTlkgMTAwMDMsINCh0KjQkA!5e0!3m2!1sru!2sru!4v1591629276763!5m2!1sru!2sru"
        width="100%"
        height="100%"
        frameborder="0"
        style="border:0;"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      />
    </div>
  </div>
</template>

<script>
  import Button from '@/components/Button';
  import { mapState } from 'vuex';

  export default {
    name: 'Contacts',
    components: {
      Button
    },
    data() {
      return {
        i18n: {
          alwaysInTouch: {
            en: 'Always in touch',
            ru: 'Всегда на связи'
          },
          phoneNumber: {
            en: 'Phone number',
            ru: 'Телефон'
          },
          fax: {
            en: 'Fax',
            ru: 'Факс'
          },
          ourLocation: {
            en: 'Our location',
            ru: 'Мы находимся'
          },
          howToGetThere: {
            en: 'How to get there',
            ru: 'Как добраться'
          },

        },
      }
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    }
  };
</script>

<style scoped lang="scss">
  .component-contacts {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 63px 95px 60px 67px;

    @media only screen and (max-width: 1280px) {
      padding: 63px 30px 60px 35px;
    }

    @media only screen and (max-width: 1060px) {
      padding: 63px 15px 60px 25px;
    }

    @media only screen and(max-width: 767px){
      display: block;
      padding: 25px;
    }
  }

  .block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
  }

  .our-location {
    margin-top: 60px;
  }

  .title {
    color: #4D4D4D;
  }

  .label {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: #666666;
    margin-left: 32px;

    @media only screen and (max-width: 1280px) {
      margin-left: 15px;
    }

    @media only screen and (max-width: 1150px) {
      font-size: 12px;
    }
  }

  .button {
    min-width: 166px;
    height: 40px;
    margin-top: 30px;
    margin-left: 45px;
    @media only screen and (max-width: 767px ){
      margin: 30px auto;
    }
  }

  .button-label {
    font-size: 10px;
  }

  .button-icon {
    width: 20px;
    height: 20px;
  }

  .map {
    height: 477px;
    width: 475px;
    
    @media only screen and(max-width: 767px){
      margin-top: 25px;
      width: 100%
    }
  }
</style>
