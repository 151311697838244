<template>
    <div>
        <LogIn v-if="isModalLoginOpened" v-model="$activeModal"/>
        <SignUp v-if="isModalSignupOpened" v-model="$activeModal"/>
        <div
                class="component-header"
                :class="{
      'invisible-bg': invisibleBg,
      'logo-white': logoWhite,
      'without-burger': !burger,
      'if-scroll': ifScroll,
      'has-new-notifications': hasNewNotifications,
    }"
        >
            <div class="burger-and-logo">
                <button v-if="burger" type="button" class="burger-button burger-button-header" @click="showMenu"
                        :class="{'is-active': isActiveMenu}">
                    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-if="isActiveMenu" v-bind:key="'active'" v-bind:class="'burger-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="0g8ja9t1na" maskUnits="userSpaceOnUse" x="8" y="8" width="14" height="14"><path fill-rule="evenodd" clip-rule="evenodd" d="M22 9.41L20.59 8 15 13.59 9.41 8 8 9.41 13.59 15 8 20.59 9.41 22 15 16.41 20.59 22 22 20.59 16.41 15 22 9.41z" fill="#fff"/></mask><g mask="url(#0g8ja9t1na)"><path d="M2 2h26v26H2z"/></g></svg>
                    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:key="'inactive'" v-bind:class="'burger-button-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="uhcs9grhra" maskUnits="userSpaceOnUse" x="6" y="9" width="18" height="12"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 21h18v-2H6v2zm0-5h18v-2H6v2zm0-7v2h18V9H6z" fill="#fff"/></mask><g mask="url(#uhcs9grhra)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                </button>
                <router-link to="/" class="logo">
                    <img svg-inline :src="logoSrc" alt="" class="logo-img"/>
                </router-link>
            </div>
            <div class="mob-menu" :class="{'is-active': isActiveMenu}">
                <div class="mob-menu-container">
                    <template v-if="isLoggedIn">
                        <BurgerMenu2/>
                        <div class="mob-menu-LoggedIn">
                            <MainMenu2 :page="page"/>
                        </div>
                    </template>
                    <template v-else>
                        <MainMenu2 :page="page"/>
                    </template>
                    <!--                <div class="nav">-->
                    <!--                    <router-link-->
                    <!--                            to="/profile-discovery"-->
                    <!--                            class="nav-item"-->
                    <!--                            :class="{ 'is-active': page === 'view-projects' }"-->
                    <!--                    >-->
                    <!--                        {{ i18n.nav.viewProjects[$lang] }}-->
                    <!--                    </router-link>-->

                    <!--                    <router-link-->
                    <!--                            to="/new-project-step"-->
                    <!--                            class="nav-item"-->
                    <!--                            :class="{ 'is-active': page === 'create-a-project' }"-->
                    <!--                    >-->
                    <!--                        {{ i18n.nav.createAProject[$lang] }}-->
                    <!--                    </router-link>-->

                    <!--                    <router-link-->
                    <!--                            to="/help"-->
                    <!--                            class="nav-item"-->
                    <!--                            :class="{ 'is-active': page === 'support-center' }"-->
                    <!--                    >-->
                    <!--                        {{ i18n.nav.supportCenter[$lang] }}-->
                    <!--                    </router-link>-->
                    <!--                </div>-->

                    <div class="settings">
                        <div class="language-changer">
                            <div
                                    class="language-changer-active-variant"
                                    @click="handleLanguageChangerActiveVariantClick"
                            >
                                <img :src="imgScrLabel" alt="" class="language-icon"/>
                                {{ languageChosen }}
                            </div>

                            <div
                                    v-show="languageChangerActive"
                                    class="language-changer-variants-wrapper"
                                    :class="{ 'is-active': languageChangerActive }"
                            >
                                <div
                                        class="language-changer-variant"
                                        @click="handleLanguageChangerVariantClick"
                                >
                                    <img :src="imgScr" alt="" class="language-icon"/>

                                    <div class="language-label">
                                        {{ languageVariantLeft }}
                                    </div>
                                </div>
                            </div>

                            <div
                                    class="language-changer-arrow"
                                    :class="{ 'is-active': languageChangerActive }"
                                    @click="handleLanguageChangerActiveVariantClickOnArrow"
                            >
                                <svg width="9" height="6" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path opacity=".5" fill-rule="evenodd" clip-rule="evenodd" d="M4.499 5.5L8.5 3V.604L4.499 3.12.5.5V3l3.999 2.5z" fill="gray"/></svg>
                            </div>
                        </div>
                        <div class="settings-users">
                            <template v-if="isLoggedIn">
                                <div class="notifications" @click="handleNotificationsClick">
                                    <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'notifications-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="dx00ntym2a" maskUnits="userSpaceOnUse" x="7" y="5" width="16" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 24.5c.14 0 .27-.01.4-.04.65-.14 1.18-.58 1.44-1.18.1-.24.15-.5.15-.78h-4c.01 1.1.9 2 2.01 2zm6-11c0-3.07-1.64-5.64-4.5-6.32V6.5c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C10.63 7.86 9 10.42 9 13.5v5l-2 2v1h16v-1l-2-2v-5z" fill="#fff"/></mask><g mask="url(#dx00ntym2a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>
                                </div>

                                <router-link to="/profile-home" class="avatar">
                                    <img :src="user.image_link" alt="" class="avatar-img"/>
                                </router-link>
                            </template>

                            <Button v-else class="button-login" @click="handleLoginButtonClick">
                                {{ i18n.logIn[$lang] }}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="notificationsIsActive" class="notifications-wrapper">
                <div class="notifications-header">
                    <div class="notifications-title">
                        {{ i18n.notifications[$lang] }}
                    </div>

                    <img
                            src="@/assets/img/icons/ic_clear.svg"
                            alt=""
                            class="notifications-clear"
                    />
                </div>

                <div class="notification-lists-wrapper">
                    <div class="notifications-lists">
                        <Notification
                                v-for="(notItem, index) in notItems"
                                :key="index"
                                v-bind="notItem"
                                :icon="getIcon(notItem.imgSrc)"
                                class="notifications-item"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import prepareSvgElements from "@/mixins/prepareSvgElements.js";
    import Notification from "./components/Notification";
    import BurgerMenu2 from "./components/BurgerMenu";
    import MainMenu2 from "./components/MainMenu";
    import Button from "@/components/Button";
    import LocalStorage from "@/utils/LocalStorage.js";
    import {mapState, mapGetters} from "vuex";
    import LogIn from "@/components/LogIn";
    import SignUp from "@/components/SignUp";
    import {mapFields} from "vuex-map-fields";

    export default {
        name: "Header",
        components: {
            Button,
            Notification,
            BurgerMenu2,
            MainMenu2,
            LogIn,
            SignUp
        },
        mixins: [prepareSvgElements],
        props: {
            logoWhite: {
                type: Boolean,
                default: false,
            },
            invisibleBg: {
                type: Boolean,
                default: false,
            },
            // loginButton: {
            //     type: Boolean,
            //     default: false
            // },
            burger: {
                type: Boolean,
                default: true,
            },
            hasNewNotifications: {
                type: Boolean,
                default: false,
            },
            page: {
                type: String,
                default: "",
            },
        },
        data() {
            return {
                isActive: false,
                isActiveMenu: false,
                languageVariants: ["English", "Russian"],
                languageChosen: "English",
                languageChangerActive: false,
                ifScroll: false,
                notificationsIsActive: false,
                notItems: [
                    {
                        title: {
                            en: "John Snow",
                            ru: "Джон Сноу",
                        },
                        text: {
                            en: "commented your project",
                            ru: "прокомментировал ваш проект",
                        },
                        projectName: {
                            en: "Drones",
                            ru: "Дроны",
                        },
                        date: {
                            en: "12 hours ago",
                            ru: "12 часов назад",
                        },
                        imgSrc: require("@/assets/img/icons/ic_forum.svg"),
                    },
                    {
                        title: {
                            en: "John Connor",
                            ru: "Джон Коннор",
                        },
                        text: {
                            en: "promoted your project",
                            ru: "поддержал ваш проект",
                        },
                        projectName: {
                            en: "Drones",
                            ru: "Дроны",
                        },
                        date: {
                            en: "2 days ago",
                            ru: "2 дня назад",
                        },
                        imgSrc: require("@/assets/img/icons/ic_thumb_up.svg"),
                    },
                    {
                        title: {
                            en: "Sara Connor",
                            ru: "Сара Коннор",
                        },
                        text: {
                            en: "subscribed to you",
                            ru: "подписалась на вас",
                        },
                        date: {
                            en: "4 days ago",
                            ru: "4 дня назад",
                        },
                        imgSrc: require("@/assets/img/icons/ic_done.svg"),
                    },
                    {
                        title: {
                            en: "Arya Stark",
                            ru: "Арья Старк",
                        },
                        text: {
                            en: "commented your project",
                            ru: "прокомментировала ваш проект",
                        },
                        projectName: {
                            en: "Drones",
                            ru: "Дроны",
                        },
                        date: {
                            en: "5 days ago",
                            ru: "5 дней назад",
                        },
                        imgSrc: require("@/assets/img/icons/ic_forum.svg"),
                    },
                    {
                        title: {
                            en: "Moderation passed",
                            ru: "Модерация пройдена",
                        },
                        text: {
                            en: "now everybody can see your project",
                            ru: "теперь все видят ваш проект",
                        },
                        projectName: {
                            en: "Drones",
                            ru: "Дроны",
                        },
                        date: {
                            en: "8 days ago",
                            ru: "8 дней назад",
                        },
                        imgSrc: require("@/assets/img/icons/ic_flag.svg"),
                        iconColor: "#D16861",
                    },
                    {
                        title: {
                            en: "John Snow",
                            ru: "Джон Сноу",
                        },
                        text: {
                            en: "commented your project",
                            ru: "прокомментировал ваш проект",
                        },
                        projectName: {
                            en: "Drones",
                            ru: "Дроны",
                        },
                        date: {
                            en: "12 hours ago",
                            ru: "12 часов назад",
                        },
                        imgSrc: require("@/assets/img/icons/ic_forum.svg"),
                    },
                    {
                        title: {
                            en: "John Connor",
                            ru: "Джон Коннор",
                        },
                        text: {
                            en: "promoted your project",
                            ru: "поддержал ваш проект",
                        },
                        projectName: {
                            en: "Drones",
                            ru: "Дроны",
                        },
                        date: {
                            en: "2 days ago",
                            ru: "2 дня назад",
                        },
                        imgSrc: require("@/assets/img/icons/ic_thumb_up.svg"),
                    },
                    {
                        title: {
                            en: "Sara Connor",
                            ru: "Сара Коннор",
                        },
                        text: {
                            en: "subscribed to you",
                            ru: "подписалась на вас",
                        },
                        date: {
                            en: "4 days ago",
                            ru: "4 дня назад",
                        },
                        imgSrc: require("@/assets/img/icons/ic_done.svg"),
                    },
                ],
                langShort: {
                    English: "en",
                    en: "English",
                    Russian: "ru",
                    ru: "Russian",
                },

                i18n: {
                    nav: {
                        viewProjects: {
                            en: "View Projects",
                            ru: "Смотреть проекты",
                        },
                        createAProject: {
                            en: "Create a Project",
                            ru: "Создать проект",
                        },
                        supportCenter: {
                            en: "Support Center",
                            ru: "Центр поддержки",
                        },
                    },
                    logIn: {
                        en: "Log in",
                        ru: "Войти",
                    },
                    notifications: {
                        en: "Notifications",
                        ru: "Уведомления",
                    },
                },
            };
        },
        computed: {
            ...mapState(["$lang", "user"]),
            ...mapFields(["$activeModal"]),
            ...mapGetters("user", ["isLoggedIn"]),
            ...mapGetters(["isModalLoginOpened", "isModalSignupOpened"]),
            logoSrc() {
                return this.logoWhite
                    ? require("@/assets/img/grossfort-logo-white.svg")
                    : require("@/assets/img/grossfort-logo.svg");
            },
            languageVariantLeft() {
                return this.languageVariants.filter(
                    (cur) => cur !== this.languageChosen
                )[0];
            },
            imgScr() {
                return this.languageVariantLeft === "English"
                    ? require("@/assets/img/icons/i-flag-of-the-US.svg")
                    : require("@/assets/img/icons/i-flag-of-Russia.svg");
            },
            imgScrLabel() {
                return this.languageChosen === "English"
                    ? require("@/assets/img/icons/i-flag-of-the-US.svg")
                    : require("@/assets/img/icons/i-flag-of-Russia.svg");
            },
        },
        beforeMount() {
            this.languageChosen = this.langShort[this.$lang] || "English";
        },
        mounted() {
            if (this.invisibleBg) {
                window.addEventListener("scroll", () => {
                    this.ifScroll = window.scrollY > 90;
                });
            }
        },
        methods: {
            getIcon(imgSrc) {
                //return require('!!svg-inline-loader!${imgSrc}');
                return "imgSrc";
            },

            handleNotificationsClick(e) {
                e.stopPropagation();

                this.notificationsIsActive = !this.notificationsIsActive;

                const misClickHandler = () => {
                    this.notificationsIsActive = false;
                };

                document.addEventListener("click", misClickHandler, {once: true});
            },
            handleLoginButtonClick() {
                // this.$router.push("/login");
                this.$activeModal = 'login';

            },
            handleLanguageChangerActiveVariantClick(e) {
                e.stopPropagation();

                this.languageChangerActive = !this.languageChangerActive;

                this.misClickHandler = () => {
                    this.languageChangerActive = false;
                };

                document.addEventListener("click", this.misClickHandler, {
                    once: true,
                });
            },
            handleLanguageChangerVariantClick(e) {
                e.stopPropagation();

                this.languageChosen = this.languageVariantLeft;
                this.languageChangerActive = false;

                const lang = this.langShort[this.languageChosen] || "en";
                LocalStorage.write("lang", lang);

                this.$store.commit("set", lang);

                document.removeEventListener("click", this.misClickHandler);
            },
            handleLanguageChangerActiveVariantClickOnArrow() {
                this.languageChangerActive = !this.languageChangerActive;
            },
            showMenu(e) {
                e.stopPropagation();

                this.isActiveMenu = !this.isActiveMenu;

                this.misClickHandler = () => {
                    this.isActiveMenu = false;
                };

                this.addEventListener('click', this.misClickHandler);
            }
        },
    };
</script>

<style scoped lang="scss">
    .component-header {
        position: fixed;
        z-index: 45;
        top: 0;
        width: 100%;
        height: 90px;
        background: #fff;
        padding: 0 40px 0 18px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
        padding: 0 2.8vw 0 1.25vw;

        &.invisible-bg {
            background-color: transparent;
            box-shadow: none;
        }

        &.logo-white {
            .language-changer {
                opacity: 1;
                @media only screen and (min-width: 768px) {
                    color: #fff;
                }
            }

            /*.nav-item {
                @media only screen and (min-width: 768px){
                    color: #fff;
                }

            }*/

            .burger-button {
                color: #fff;
            }
        }

        &.without-burger {
            .logo {
                margin-left: 18px;
            }
        }

        &.has-new-notifications {
            .notifications {
                &:after {
                    display: block;
                }
            }
        }

        @media only screen and (max-width: 767px) {
            padding: 15px 20px;
            height: 60px;
        }
    }

    .if-scroll {
        background-color: #12163b !important;
        -webkit-animation: fadeIn 0.3s ease;
        animation: fadeIn 0.3s ease;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    }

    @-webkit-keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    .burger-and-logo {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        @media only screen and (max-width: 767px) {
            width: 100%;
            justify-content: space-between;
        }
    }

    .burger-button-img {
        opacity: 0;
        width: 26px;
        height: 26px;
        @media only screen and (max-width: 767px) {
            opacity: 1;
        }
    }

    .burger-button {
        color: #d8d8d8;
        margin-right: 41px;
        @media only screen and (max-width: 767px) {
            order: 2;
            margin-left: 25px;
            margin-right: 0;
        }
    }

    .logo {
        cursor: pointer;
        @media only screen and (max-width: 767px) {
            max-width: 114px;
            img {
                width: 100%;
            }
        }
    }

    /*.nav {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: 30px;
        @media only screen and (max-width: 767px){
            flex-direction: column;
            align-items: flex-start;
            order: 2;
            margin-left: 0;
        }
    }

    .nav-item {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #808080;
        mix-blend-mode: normal;
        opacity: 1;
        margin-left: 30px;
        cursor: pointer;

        &:nth-child(1) {
            margin-left: 0;
        }

        &.is-active,
        &:hover {
            opacity: 1;
            color: #50cab6;
        }

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px){
            margin-left: 0;
            font-size: 11px;
            line-height: 30px;
            margin-bottom: 24px;
        }
    }*/

    .notifications {
        position: relative;
        margin-left: 32px;
        cursor: pointer;
        color: #d8d8d8;

        &:after {
            content: "";
            display: none;
            position: absolute;
            top: 3px;
            right: 0;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: #50cab6;
        }
    }

    .notifications-img {
        width: 30px;
        height: 30px;
    }

    .avatar {
        margin-left: 32px;
        cursor: pointer;
    }

    .avatar-img {
        width: 40px;
        height: 40px;
    }

    .settings {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        order: 10;
        @media only screen and (max-width: 767px) {
            order: 1;
            margin-left: 0;
            width: 100%;
            justify-content: space-between;
            padding: 10px 20px;
            /*border-bottom: 2px solid #F0F0F0;*/
            margin-bottom: 22px;
            position: relative;
            &:after {
                content: '';
                border-bottom: 2px solid #F0F0F0;
                position: absolute;
                left: 20px;
                right: 20px;
                top: 100%;
            }
        }
    }

    .language-changer {
        position: relative;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #808080;
        mix-blend-mode: normal;
        opacity: 0.5;
        margin-left: 30px;
        cursor: pointer;
        user-select: none;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
        @media only screen and (max-width: 767px) {
            margin-left: 0;
        }
    }

    .language-changer-active-variant {
        display: flex;
        align-items: center;
    }

    .language-changer-select {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #808080;
        border: 0;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        min-width: 64px;
        margin-right: 24px;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .language-changer-option {
        min-width: 64px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #808080;

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .language-changer-arrow {
        content: "";
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 1;
        top: 0;
        right: -18px;
        bottom: 0;
        margin: auto;
        width: 8px;
        height: 8px;

        &.is-active {
            transform: rotate(180deg);
        }

        svg {
            path {
                opacity: 1 !important;
            }
        }
    }

    a.avatar img {
        border-radius: 40px;
    }

    .select {
        border: 0;
        outline: none;
    }

    .language-changer-variants-wrapper {
        width: 100px;
        position: absolute;
        z-index: 1;
        top: 18px;
        left: 50%;
        transform: translateX(-50%);
        @media only screen and (max-width: 767px) {
            left: 0;
            transform: translateX(0);
        }
    }

    .language-changer-variant {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 25px;
        padding: 0 9px;
        border-radius: 8px;
        font-family: IBMPlexSans;
        font-size: 14px;
        background: #f0f0f0;
        color: #808080;
        margin-top: 2px;

        &:nth-child(1) {
            margin-top: 0;
        }

        @media only screen and (max-width: 1030px) {
            font-size: 12px;
        }
    }

    .language-icon {
        width: 16px;
        padding: 0 5px 0 0;
    }

    .language-label {
        margin-left: 7px;
    }

    .button-login {
        width: 104px;
        height: 40px;
        font-size: 10px !important;
        margin-left: 55px;
        @media only screen and (max-width: 767px) {
            background-color: #50CAB6;
            border-color: #50CAB6;
            color: #fff;
            letter-spacing: .5px;
            font-size: 12px !important;
            line-height: 18px;
            &:hover {
                background-color: #39AEA0;
                border-color: #39AEA0;
            }
        }
    }

    .notifications-wrapper {
        position: absolute;
        z-index: 11;
        top: 103px;
        right: 100px;
        display: flex;
        flex-direction: column;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: -4px;
            right: 13px;
            width: 30px;
            height: 30px;
            background: #fff;
            transform: rotate(45deg);
        }
    }

    .notifications-header {
        width: 350px;
        height: 88px;
        padding: 0 20px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #666666;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
        border-radius: 6px 6px 0 0;
        cursor: pointer;

        &.notifications-title {
            font-size: 16px;
            line-height: 90px;
            color: #666666;
        }
    }

    .notification-lists-wrapper {
        width: 350px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
        border-radius: 0 0 6px 6px;
        justify-content: flex-start;
        position: absolute;
        top: 90px;
        left: 0;
        padding-right: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
        background: #ffffff;
    }

    .notifications-lists {
        max-height: 510px;
        overflow: auto;
        background: #ffffff;

        /* width */
        &::-webkit-scrollbar {
            width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #f0f0f0;
            border-radius: 100px;
        }
    }

    .mob-menu {
        width: 100%;

        &-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        @media only screen and (max-width: 767px) {
            position: absolute;
            top: 100%;
            left: 0;
            background-color: #ffffff;
            box-shadow: 0 10px 30px -2px rgba(0, 0, 0, 0.05) inset;
            visibility: hidden;
            opacity: 0;
            overflow: hidden;
            transition: all .3s ease-in-out;
            transform: translateX(-100%);
            &.is-active {
                visibility: visible;
                opacity: 1;
                transform: translateX(0);
            }
            &-container {
                width: auto;
                flex-direction: column;
                align-items: flex-start;
                height: calc(100vh - 60px);
                overflow-y: auto;
                margin-bottom: 40px;
            }
        }
    }

    .settings-users {
        display: flex;
        align-items: center;
    }

    .mob-menu-LoggedIn {
        order: 2;
        @media only screen and (max-width: 767px) {
            display: none;
        }
    }

    /*.view-home{
        .burger-button{
            @media only screen and (min-width: 768px){
                display: none;
            }
        }
        .mob-menu-LoggedIn{
            @media only screen and (max-width: 767px){
                display: block;
            }
        }

    }*/
</style>
