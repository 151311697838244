<template>
  <div class="view-main-component-slide">
    <div class="buttons-wrapper">
      <div class="buttons-in-center">
        <button type="button" class="slide-button">
          {{ i18n.drones[$lang] }}
        </button>

        <button type="button" class="slide-button">
          {{ i18n.technologies[$lang] }}
        </button>

        <button type="button" class="slide-button">
          {{ i18n.culture[$lang] }}
        </button>
      </div>
    </div>

    <div class="slide-title">
      {{ i18n.slideTitle[$lang] }}
    </div>

    <div class="slide-line" />

    <div class="slide-subtitle">
      {{ i18n.slideSubTitle[$lang] }}
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'Slide',
    props: {
      arrayOfPeople: {
        type: Array,
        required: true,
      }
    },
    data() {
      return {
        i18n: {
          drones: {
            en: 'Drones',
            ru: 'Исследование'
          },
          technologies: {
            en: 'Technologies',
            ru: 'Новости'
          },
          culture: {
            en: 'Culture',
            ru: 'Интеграл'
          },
          slideTitle: {
            en: 'Lorem ipsum dolor sit amet',
            ru: 'Равновероятный абсолютно сходящийся ряд'
          },
          slideSubTitle: {
            en: '22 march 2020',
            ru: '22 марта 2020'
          },
        }
      }
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    }
  };
</script>

<style scoped lang="scss">
  @import "@/sass/mixins.scss";

  .view-main-component-slide {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    margin-top: 30px;
    border-radius: 6px;
    overflow: hidden;
    padding: 0 0 30px;
    background: #FFFFFF;
    // box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  }

  .big-round-slide-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    @include mark-default;
  }

  .button-share {
    position: absolute;
    z-index: 1;
    top: 60px;
    left: 60px;
  }

  .button-favorite {
    position: absolute;
    z-index: 1;
    top: 60px;
    right: 60px;
  }

  .slide-button {
    height: 25px;
    padding: 0 13px;
    border-radius: 4px;
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    @include mark-default;
  }

  .buttons-in-center {
    padding-top: 60px;
    text-align: center;
  }

  .slide-title {
    margin-top: 57px;
    text-align: center;
    color: #4D4D4D;

  }

  .slide-line {
    width: 67px;
    height: 2px;
    background: #50CAB6;
    margin: 88px auto 0 auto;
  }

  .slide-subtitle {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    margin-top: 31px;
    font-size: 14px;
    line-height: 60px;
    color: #B3B3B3;

    @media only screen and (max-width: 1030px) {
      font-size: 18px;
    }
  }

  .slide-footer {
    width: 100%;
    padding: 0 65px;
  }

  .progress-bar {
    position: relative;
    width: 100%;
    height: 5px;
    background: #F5F5F5;
    border-radius: 2.5px;
    margin-top: 130px;
  }

  .progress-bar-active {
    position: absolute;
    top: 0;
    left: 0;
    background: #50CAB6;
    border-radius: 5px;
    height: inherit;
    width: 8%;
  }

  .people-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 60px;
    margin-top: 10px;
  }

  .faces-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .face {
    width: 25px;
    height: 30px;

  img {
    width: 30px;
  }
  }

  .number-of-people {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #FFFFFF;
    margin-left: 10px;

    @media only screen and (max-width: 1030px) {
      font-size: 12px;
    }
  }

</style>
