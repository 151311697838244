<template>
  <div class="component-main-rules">
    <!-- <div class="menu">
      <a
        v-for="(menuItemCur, index) in menuItems"
        :key="index"
        :href="menuItemCur.href"
        class="menu-item"
        :class="{'is-active': index === menuItemActiveIndex}"
        @click="handleMenuItemClick($event, menuItemCur, index)"
      >
        {{ menuItemCur.label }}
      </a>
    </div> -->

    <div class="item">
      <div class="header-block" data-id="main-rules">
        <div class="title">
          <h3>{{ i18n.mainRulesTitle[$lang] }}</h3>
        </div>

        <div class="header-block-text">
          <p>{{ i18n.mainRulesText[$lang] }}</p>
        </div>
      </div>

      <div class="content-blocks-wrapper">
        <div
          v-for="(blockCur, index) in blocks"
          :key="index"
          class="block"
          :data-id="blockCur.dataId"
        >
          <div class="title">
            <h3>{{ index + 1 }}. {{ blockCur.title[$lang] }}</h3>
          </div>

          <div class="text">
            <div v-for="(textItemCur, textItemIndex) in blockCur.textItems[$lang]" :key="textItemIndex" class="text-item">
              <p>{{ textItemCur }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

  export default {
    name: 'MainRules',
    data() {
      return {
        // menuItems: [
        //   {
        //     label: 'Main Rules',
        //     href: '#main-rules'
        //   },
        //   {
        //     label: 'Allowed',
        //     href: '#allowed'
        //   },
        //   {
        //     label: 'Not allowed',
        //     href: '#not-allowed'
        //   },
        //   {
        //     label: 'Special conditions',
        //     href: '#special-conditions'
        //   },
        //   {
        //     label: 'Forbidden',
        //     href: '#forbidden'
        //   },
        // ],
        // menuItemActiveIndex: 0,
        blocks: [
          {
            title: {
              en: 'To participate the project must have:',
              ru: 'Допускаем к участию только проекты, которые имеют:'
            },
            dataId: 'allowed',
            textItems: {
              en: [
                'A clear goal (for example: launching a new product, recording a music album, publishing a book or creating a piece of art).',
                'For projects that are considered, as “all or nothing”: limited timeframe, minimal amount of money, required for the project implementation.',
                'For projects that are considered, as “To the goal”: limit on the amount of money required for the project implementation.',
                'Prototipe (for those, who are crearing a new product)',
                'The project should correspond to one of the categories: Design, Food, Games, Editions, Art, Events, Fashion, Music, Society, Sports, Theater, Technology, Films and Video, Photography, Choreography.',
              ],
              ru: [
                'Четко сформулированную цель (например, выпуск нового продукта, запись музыкального альбома, издание книги или создание произведения искусства).',
                'Для типов Проектов «Всё или ничего»: ограничение по временным рамкам и минимально необходимой суммы для реализации Проекта.',
                'Для типов Проектов «До цели»: ограничение по сумме, необходимой для реализации Проекта.',
                'Прототип (для создающих новый продукт)',
                'Проект должен соответствовать одной из категорий: Дизайн, Еда, Игры, Издания, Искусство, Мероприятия, Мода, Музыка, Общество, Спорт, Театр, Технологии, Фильмы и видео, Фотография, Хореография.',
              ],
            },
          },
          {
            title: {
              en: 'Projects that are not allowed:',
              ru: 'Не допускаются Проекты:'
            },
            dataId: 'not-allowed',
            textItems: {
              en:  [
                'Offers opening a business (selling shares, stocks, receiving dividends)',
                'Offering fundraising for advertising and campaigning',
                'Violating the laws of the Russian Federation or our internal beliefs',
                'Directed in support or against political candidates',
              ],
              ru: [
                'Предлагающие открытие бизнеса (продажа долей, акций, получение дивидендов)',
                'Предлагающие сбор средств на рекламные и агитационные компании',
                'Нарушающие законодательство Российской федерации или наши внутренние убеждения',
                'Направленные в поддержку или против политических кандидатов',
              ],
            },
          },
          {
            title: {
              en: 'Special conditions',
              ru: 'Особые условия'
            },
            dataId: 'special-conditions',
            textItems: {
              en:  [
                'For projects that are considered, as “To the goal”: limit on the amount of money required for the project implementation.',
                'For projects that are considered, as “To the goal”: limit on the amount of money required for the project implementation.',
                'For projects that are considered, as “To the goal”: limit on the amount of money required for the project implementation.',
                'For projects that are considered, as “To the goal”: limit on the amount of money.',
                'For projects that are considered, as “To the goal”: limit on the amount of money required for the project implementation.',
              ],
              ru: [
                'Четко сформулированную цель (например, выпуск нового продукта, запись музыкального альбома, издание книги или создание произведения искусства).',
                'Для типов Проектов «Всё или ничего»: ограничение по временным рамкам и минимально необходимой суммы для реализации Проекта.',
                'Для типов Проектов «До цели»: ограничение по сумме, необходимой для реализации Проекта.',
                'Прототип (для создающих новый продукт)',
                'Проект должен соответствовать одной из категорий: Дизайн, Еда, Игры, Издания, Искусство, Мероприятия, Мода, Музыка, Общество, Спорт, Театр, Технологии, Фильмы и видео, Фотография, Хореография.',
              ],
            },
          },
          {
            title: {
              en: 'The list of prohibited areas in the implementation of projects3',
              ru: 'Перечень запрещённых направлений при реализации проектов'
            },
            dataId: 'forbidden',
            textItems: {
              en: [
                'For projects that are considered, as “To the goal”: limit on the amount of money required for the project implementation.',
                'For projects that are considered, as “To the goal”: limit on the amount of money.',
                'For projects that are considered, as “To the goal”: limit on the amount of money required for the project implementation.',
                'For projects that are considered, as “To the goal”: limit on the amount of money.',
                'For projects that are considered, as “To the goal”: limit on the amount of money required for the project implementation.',
                'For projects that are considered, as “To the goal”: limit on the amount of money.',
                'For projects that are considered, as “To the goal”: limit on the amount of money required for the project implementation.',
                'For projects that are considered, as “To the goal”: limit on the amount of money.',
                'For projects that are considered, as “To the goal”: limit on the amount of money required for the project implementation.',
                'For projects that are considered, as “To the goal”: limit on the amount of money.',
                'For projects that are considered, as “To the goal”: limit on the amount of money required for the project implementation.',
                'Купоны, скидки, подарочные карты и сертификаты',
                'Направленные в поддержку или против политических кандидатов',
                'Направленные на передачу части средств, собранных при реализации Проекта, на благотворительные или целевые программы',
                'Предлагающие сбор средств на рекламные и агитационные компании',
                'Направленные на передачу части средств, собранных при реализации Проекта, на благотворительные или целевые программы'
              ],
              ru: [
                'Направленные на передачу части средств, собранных при реализации Проекта, на благотворительные или целевые программы',
                'Предлагающие сбор средств на рекламные и агитационные компании',
                'Направленные на передачу части средств, собранных при реализации Проекта, на благотворительные или целевые программы',
                'Товары для детей, требующие сертификации',
                'Направленные на передачу части средств, собранных при реализации Проекта, на благотворительные или целевые программы',
                'Предлагающие сбор средств на рекламные и агитационные компании',
                'Направленные на передачу части средств, собранных при реализации Проекта, на благотворительные или целевые программы',
                'Направленные в поддержку или против политических кандидатов',
                'Направленные на передачу части средств, собранных при реализации Проекта, на благотворительные или целевые программы',
                'Предлагающие сбор средств на рекламные и агитационные компании',
                'Направленные на передачу части средств, собранных при реализации Проекта, на благотворительные или целевые программы',
                'Купоны, скидки, подарочные карты и сертификаты',
                'Направленные в поддержку или против политических кандидатов',
                'Направленные на передачу части средств, собранных при реализации Проекта, на благотворительные или целевые программы',
                'Предлагающие сбор средств на рекламные и агитационные компании',
                'Направленные на передачу части средств, собранных при реализации Проекта, на благотворительные или целевые программы'
              ],
            },
          }
        ],
        i18n: {
          mainRulesTitle: {
            en: 'Main rules',
            ru: 'Основные правила'
          },
          mainRulesText: {
            en: 'Grossfort — is the first joint-stock crowdinvesting platform. It is a place, where each user is at the same time an investor and a job seeker. Contributions of participants form a common fund, from which the platform finances and helps launch business ideas of participants. The decision on the financing of the project is made by general vote. Some work that does not meet the requirements below may not be published.',
            ru: 'Grossfort — первая акционерная краудинвестинговая площадка. Это место, где каждый пользователь — одновременно инвестор и соискатель. Взносы участников образуют общий фонд, из которого площадка финансирует и помогает запускать бизнес-идеи участников. Решение о финансировании проекта принимается общим голосованием. Некоторым работам, не соответствующим представленным ниже требованиям, может быть отказано в публикации.'
          },
        },
      }
    },
    methods: {
      // handleMenuItemClick(e, menuItem, index) {
      //   e.preventDefault();

      //   const href = menuItem.href.split('').slice(1).join('');
      //   const selector = `[data-id='${href}']`;
      //   const scrollTop = document.querySelector(selector).getBoundingClientRect().top - 100;

      //   window.scrollBy({
      //     top: scrollTop,
      //     behavior: 'smooth'
      //   });

      //   this.menuItemActiveIndex = index;
      // }
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    }
  };
</script>

<style scoped lang="scss">
  // .menu {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-start;
  //   justify-content: flex-end;
  //   width: 287px;
  //   padding-right: 60px;
  //   // padding-top: 60px;
  //   padding-top: 72px;
  //   flex-shrink: 0;
  // }

  // .menu-item {
  //   font-family: IBMPlexSans;
  //   font-style: normal;
  //   font-weight: 600;
  //   font-size: 14px;
  //   text-align: right;
  //   color: #B3B3B3;
  //   cursor: pointer;
  //   // margin-top: 44px;
  //   margin-top: 46px;
  //   width: 100%;

  //   &:nth-child(1) {
  //     margin-top: 0;
  //   }

  //   &.is-active {
  //     color: #666666;
  //   }
  // }

  .component-main-rules {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @media only screen and(max-width: 767px){
      padding: 25px 0 25px 0;
    }
  }

  .item {
    width: 100%;
    padding: 60px 189px 90px 90px;
    border-left: 1px #F0F0F0 solid;

    @media only screen and (max-width: 767px){
      padding: 0 25px 0px 25px;
      border-left: 0;
    }
  }

  .title {
    color: #4D4D4D;
  }

  .header-block-text {
    color: #666666;
    margin-top: 30px;

    @media only screen and (max-width: 767px) {
      margin-top: 10px;
    }
  }

  .text-item {
    color: #666666;
    position: relative;
    padding-left: 29px;
    margin-top: 30px;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      height: 9px;
      width: 9px;
      border-radius: 50%;
      background: #D8D8D8;
    }
  }

  .content-blocks-wrapper {
    margin-top: 120px;
    @media only screen and(max-width: 767px){
      margin-top: 25px;
    }
  }

  .block {
    margin-top: 60px;

    &:nth-child(1) {
      margin-top: 0;
    }
  }
</style>
