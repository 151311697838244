<template>
  <notifications group="top-right" position="top right" />
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    messageLength() {
      return this.message.length;
    },
    successLength() {
      return this.success.length;
    },
    warningLength() {
      return this.warning.length;
    },
    errorLength() {
      return this.error.length;
    },
    ...mapState("noty", ["message", "success", "warning", "error"]),
  },
  watch: {
    messageLength(v, old) {
      this.message.slice(old).forEach((message) => {
        this.$notify({
          group: "top-right",
          text: message,
        });
      });
    },
    successLength(v, old) {
      this.success.slice(old).forEach((message) => {
        this.$notify({
          group: "top-right",
          text: message,
          type: "success",
        });
      });
    },
    warningLength(v, old) {
      this.warning.slice(old).forEach((message) => {
        this.$notify({
          group: "top-right",
          text: message,
          type: "warn",
        });
      });
    },
    errorLength(v, old) {
      this.error.slice(old).forEach((message) => {
        this.$notify({
          group: "top-right",
          text: message,
          type: "error",
        });
      });
    },
  },
};
</script>
