<template>
  <div class="footer-card">
    <div class="line">
      <div class="user-info">
        <div class="user-ava">
          <img src="@/assets/img/view-news-or-articule/placeholder2.png" alt="" />
        </div>

        <div class="user-about">
          <div class="user-name">
            Артур Куракин
          </div>

          <div class="user-desc">
            {{ i18n.userDesc[$lang] }}
          </div>
        </div>
      </div>

      <div class="user-stats">
        <div class="user-stat-item">
          <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stat-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="yr1oa6gjna" maskUnits="userSpaceOnUse" x="4" y="7" width="22" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 7.5c-5 0-9.27 3.11-11 7.5 1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM15 20c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" fill="#fff"/></mask><g mask="url(#yr1oa6gjna)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

          <div class="stat-count">
            275
          </div>
        </div>

        <div class="user-stat-item">
          <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'stat-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="uu8vbrgcka" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M24 9h-2v9H9v2c0 .55.45 1 1 1h11l4 4V10c0-.55-.45-1-1-1zm-4 6V6c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z" fill="#fff"/></mask><g mask="url(#uu8vbrgcka)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

          <div class="stat-count">
            12
          </div>
        </div>
      </div>

      <div class="go-up" @click="handleGoUpButtonClick">
        <div class="go-up-label">
          {{ i18n.goUp[$lang] }}
        </div>

        <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'go-up-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="7flzr9yqta" maskUnits="userSpaceOnUse" x="6" y="6" width="16" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.533 14l1.316 1.316 5.217-5.208v11.358h1.867V10.108l5.208 5.217L21.467 14 14 6.533 6.533 14z" fill="#fff"/></mask><g mask="url(#7flzr9yqta)"><path fill="#D8D8D8" d="M1.866 1.867h24.267v24.267H1.866z"/></g></svg>
      </div>
    </div>
    <div class="line">
      <div class="tags">
        <div class="tag-item">
          {{ i18n.drones[$lang] }}
        </div>

        <div class="tag-item">
          {{ i18n.jobs[$lang] }}
        </div>

        <div class="tag-item">
          {{ i18n.culture[$lang] }}
        </div>
      </div>
      <div class="share">
        <div class="share-label">
          {{ i18n.share[$lang] }}:
        </div>

        <div class="share-items-wrapper">
          <div class="share-item">
            <svg width="31" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'share-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="072jboozna" maskUnits="userSpaceOnUse" x="11" y="6" width="10" height="18"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.025 24v-8.21h2.657l.395-3.199h-3.052v-2.044c0-.925.248-1.557 1.526-1.557h1.633V6.125C19.903 6.083 18.932 6 17.808 6c-2.355 0-3.961 1.495-3.961 4.234v2.364h-2.663v3.198h2.663V24h3.178z" fill="#fff"/></mask><g mask="url(#072jboozna)"><path fill="#D8D8D8" d="M2.184 2h26v26h-26z"/></g></svg>

            <div class="share-counter">
              45
            </div>
          </div>
          <div class="share-item">
            <svg width="31" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'share-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="799ftgd07a" maskUnits="userSpaceOnUse" x="6" y="8" width="19" height="15"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.689 9.775a7.19 7.19 0 01-2.122.596 3.786 3.786 0 001.625-2.094 7.286 7.286 0 01-2.345.918A3.65 3.65 0 0019.15 8c-2.04 0-3.692 1.695-3.692 3.786 0 .297.032.587.095.863-3.07-.158-5.79-1.665-7.612-3.956a3.85 3.85 0 00-.5 1.904c0 1.314.652 2.472 1.643 3.152a3.613 3.613 0 01-1.673-.474v.048c0 1.835 1.273 3.366 2.962 3.713a3.629 3.629 0 01-1.667.066c.47 1.503 1.834 2.598 3.45 2.63A7.293 7.293 0 016.687 21.3 10.273 10.273 0 0012.35 23c6.792 0 10.508-5.77 10.508-10.774 0-.164-.005-.328-.012-.49a7.602 7.602 0 001.844-1.96z" fill="#fff"/></mask><g mask="url(#799ftgd07a)"><path fill="#D8D8D8" d="M2.688 2h26v26h-26z"/></g></svg>

            <div class="share-counter">
              12
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'FooterCard',
    data() {
      return {
        i18n: {
          userDesc: {
            en: 'Author',
            ru: 'Дрессировщик'
          },
          goUp: {
            en: 'Go up',
            ru: 'Наверх'
          },
          drones: {
            en: 'Drones',
            ru: 'Новости'
          },
          jobs: {
            en: 'Jobs',
            ru: 'Интеграл'
          },
          culture: {
            en: 'Culture',
            ru: 'Исследование'
          },
          share: {
            en: 'Share',
            ru: 'Поделиться:'
          },

        },
      }
    },
    methods: {
      handleGoUpButtonClick() {
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    }
  };
</script>

<style scoped lang="scss">
  .footer-card {
    width: 100%;
    // padding: 0 65px;
    border-top: 2px #F5F5F5 solid;
    margin-top: 120px;
  }

  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 120px;
    width: 100%;
    padding: 0 65px;
    border-top: 2px #F5F5F5 solid;

    &:nth-child(1) {
      border-top: 0;
    }
  }

  .user-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .user-about {
    margin-left: 25px;
  }

  .user-name {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #666666;
  }

  .user-desc {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #B3B3B3;
    margin-top: 7px;
  }

  .user-stats {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .user-stat-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 45px;

    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .stat-icon {
    width: 26px;
  }

  .stat-count {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #B3B3B3;
    margin-left: 17px;
  }

  .go-up {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }

  .go-up-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 12px;
    line-height: 50px;
    text-align: right;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #B3B3B3;
  }

  .go-up-icon {
    width: 24px;
    margin-left: 12px;
  }

  .tags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .tag-item {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #B3B3B3;
    padding: 0 23px;
    background: #F7F7F7;
    border-radius: 4px;
    margin-left: 10px;

    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .share {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .share-items-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 22px;
  }

  .share-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 22px;

    &:nth-child(1) {
      margin-left: 0;
    }
  }

  .share-label {
    font-family: IBMPlexSans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    color: #999999;
  }

  .share-icon {
    width: 26px;
  }

  .share-counter {
    position: relative;
  }

  .share-counter {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #B3B3B3;
    margin-left: 12px;
    line-height: 30px;
    border: 2px solid #F5F5F5;
    border-radius: 4px;
    padding: 0 10px;
    background: #fff;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      left: -7px;
      top: 0;
      bottom: 0;
      margin: auto;
      border-left: 2px solid #F5F5F5;
      border-bottom: 2px solid #F5F5F5;
      height: 10px;
      width: 10px;
      transform: rotate(45deg);
      background: #fff;
    }
  }
</style>
