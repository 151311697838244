<template>
  <div>
    <comment
      v-for="comment in comments"
      :comment="comment"
      :collection="collection"
      :key="comment.id"
    />
  </div>
</template>

<script>
export default {
  name: "CommentList",
  components: {
    Comment: () => import("../Comment"),
  },
  props: {
    collection: {
      type: [Object, Array],
      required: true,
    },
    comments: {
      type: [Object, Array],
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.comment {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 45px;
  padding-top: 45px;
  border-top: 2px #f6f6f6 solid;

  &:nth-child(1) {
    padding-top: 0;
    margin-top: 0;
    border-top: 0;
  }

  &.answer {
    margin-left: 57px;

    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      top: 45px;
      left: -45px;
      display: block;
      height: 26px;
      width: 26px;
      background: url("~@/assets/img/icons/ic_arrow_forward.svg") no-repeat center;
      background-size: 26px;
    }
  }
}
</style>
