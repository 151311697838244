export default {
  mounted() {
    const svgElements = [...document.querySelectorAll("svg")];

    svgElements.forEach((svgCur, index) => {
      const maskElement = svgCur.querySelector("mask");
      const gElement = svgCur.querySelector("g");

      if (!maskElement || !gElement) {
        return;
      }

      const id = maskElement.id;
      const newId = `${id}___${index}`;
      const newMask = gElement.getAttribute("mask").replace(id, newId);

      gElement.setAttribute("mask", newMask);
      maskElement.setAttribute("id", newId);

      const elementsWithFill = [...svgCur.querySelectorAll("[fill]")];

      elementsWithFill.forEach((elementWithFillCur) => {
        elementWithFillCur.removeAttribute("fill");
      });
    });
  },
};
