<template>
    <div class="component-contacts">
        <div
            v-for="(contact, index) in contacts"
            :key="index"
            class="contacts-list"
        >
            <div class="contacts-name text-plex13-30">
                {{ contact.name[$lang] }}:
            </div>
            <div class="contacts-link text-plex13-30">
                {{ contact.link }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "Contacts",
    data() {
        return {};
    },
    computed: {
        ...mapState(["user"]),
        contacts() {
            return [
                {
                    name: {
                        en: "Phone number",
                        ru: "Мобильный"
                    },
                    link: this.user.phone
                },
                {
                    name: {
                        en: "Email",
                        ru: "Эл. почта"
                    },
                    link: this.user.email
                },
                {
                    name: {
                        en: "Facebook",
                        ru: "Facebook"
                    },
                    link: this.user.facebook
                },
                {
                    name: {
                        en: "Linkedin",
                        ru: "Linkedin"
                    },
                    link: this.user.linkedin
                },
                {
                    name: {
                        en: "Telegram",
                        ru: "Telegram"
                    },
                    link: this.user.telegram
                }
            ];
        },
        ...mapState({
            $lang: state => state.$lang
        })
    }
};
</script>

<style scoped lang="scss">
@import "@/sass/mixins.scss";

.component-contacts {
    background: #ffffff;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    width: 50%;
    max-width: 538px;
    margin-left: 12px;
    padding: 40px 0 40px 70px;

    @media only screen and (max-width: 1110px) {
        padding: 40px 0 40px 30px;
    }

    @media only screen and (max-width: 767px){
        width: 100%;
        margin: 30px 0;
    }
}

.contacts-list {
    display: flex;
    padding: 0 0 15px;
}

.contacts-name,
.contacts-link {
    color: #b3b3b3;
}

.contacts-name {
    width: 130px;
}

.contacts-link {
    color: #808080;
}
</style>
