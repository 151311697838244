<template>
    <div class="component-faq">
        <div
            v-for="(itemCur, index) in items"
            :key="index"
            class="faq-item"
            @click="handleFaqItemClick"
        >
            <h4 class="faq-item-visible-part">
                {{ itemCur.visiblePart[$lang] }}
            </h4>

            <div class="faq-item-invisible-part">
                <p>{{ itemCur.invisiblePart }}</p>
            </div>

            <div class="faq-icon faq-icon-show">
                <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'header-stat-img ic_eye header-stat-img-ic_eye'" v-bind:id="'header-stat-img-ic_eye'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="5cmj98oh2a" maskUnits="userSpaceOnUse" x="8" y="8" width="14" height="14"><path fill-rule="evenodd" clip-rule="evenodd" d="M22 16h-6v6h-2v-6H8v-2h6V8h2v6h6v2z" fill="#fff"/></mask><g mask="url(#5cmj98oh2a)"><path d="M2 2h26v26H2z"/></g></svg>
            </div>
            <div class="faq-icon faq-icon-hide">
                <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'header-stat-img ic_eye header-stat-img-ic_eye'" v-bind:id="'header-stat-img-ic_eye'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="f9o4vn2w4a" maskUnits="userSpaceOnUse" x="8" y="8" width="14" height="14"><path fill-rule="evenodd" clip-rule="evenodd" d="M22 9.41L20.59 8 15 13.59 9.41 8 8 9.41 13.59 15 8 20.59 9.41 22 15 16.41 20.59 22 22 20.59 16.41 15 22 9.41z" fill="#fff"/></mask><g mask="url(#f9o4vn2w4a)"><path d="M2 2h26v26H2z"/></g></svg>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'FAQ',
        props: {
            items: {
                type: Array,
                required: true,
            }
        },
        methods: {
            handleFaqItemClick(e) {
                const parentElem = e.currentTarget;
                const invisibleElem = parentElem.querySelector('.faq-item-invisible-part');
                const action = invisibleElem.classList.contains('is-active') ? 'remove' : 'add';

                invisibleElem.classList[action]('is-active');
                parentElem.classList[action]('is-active');
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
        }
    };
</script>

<style scoped lang="scss">
    .faq-item {
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        color: #666666;
        border-bottom: 2px solid #F5F5F5;
        width: 100%;
        min-height: 88px;
        padding-left: 30px;
        padding-right: 60px;
        cursor: pointer;

        &:nth-child(1) {
            border-top: 2px solid #F5F5F5;
        }
        @media only screen and (max-width: 767px){
            padding-left: 0;
            padding-right: 40px;
            &:after{
                right: 0;
                width: 26px;
                height: 26px;
            }

        }
        &.is-active{
            .faq-icon-show{
                display: none;
            }
            .faq-icon-hide{
                display:block;
            }
        }
    }

    .faq-icon{
        position: absolute;
        z-index: 1;
        height: 30px;
        width: 30px;
        top: 30px;
        //bottom: 0;
        right: 30px;
        margin: auto;
        background-size: 26px;
        color: #50cab6;
        &:hover{
            color: #4d4d4d;
        }
    }

    .faq-icon-show{
        display: block;
    }
    .faq-icon-hide{
        display: none;
    }

    .faq-item-visible-part {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 88px;
        padding-bottom: 0 !important;
        margin: 0 !important;
    }

    .faq-item-invisible-part {
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 30px;
        color: #666666;

        &.is-active {
            height: 88px;
            margin-bottom: 18px;
        }
    }
</style>
