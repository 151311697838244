<template>
  <div
    class="view-profile-discovery-component-card"
    @click="handleSlideWrapperClick"
  >
    <div class="header" :style="`background-image: url(${projectData.imgSrc})`">
      <div class="header-top">
        <div class="buttons-top-wrapper">
          <ButtonShare />

          <ToggleFavourite :projectId="projectData.id" :isFavourite="projectData.userFavourite" />
        </div>
      </div>

      <div class="header-bottom">
        <div class="header-tags-wrapper">
          <div
            v-for="tagCur in projectData.tags"
            :key="projectData.tagCur"
            class="header-tag"
          >
            {{ tagCur }}
          </div>
        </div>
        <div class="header-stats-wrapper">
          <div class="header-stat header-stat-eye">
            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'header-stat-img ic_eye header-stat-img-ic_eye'" v-bind:id="'header-stat-img-ic_eye'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="jxf2obtp3a" maskUnits="userSpaceOnUse" x="4" y="7" width="22" height="16"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 7.5c-5 0-9.27 3.11-11 7.5 1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM15 20c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" fill="#fff"/></mask><g mask="url(#jxf2obtp3a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

            <div class="header-stat-label">
              {{ projectData.stats.views }}
            </div>
          </div>

          <div class="header-stat header-stat-comment">
            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'header-stat-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="jmbkkdwwba" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M24 9h-2v9H9v2c0 .55.45 1 1 1h11l4 4V10c0-.55-.45-1-1-1zm-4 6V6c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z" fill="#fff"/></mask><g mask="url(#jmbkkdwwba)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>

            <div class="header-stat-label">
              {{ projectData.stats.comments }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title">
        <h3>{{ projectData.title }}</h3>
      </div>

      <div class="text">
        <p>{{ projectData.text }}</p>
      </div>

      <div class="content-footer">
        <div class="content-stats-and-progress-bar">
          <ProgressBar
            :progress="projectData.stats.rating"
            class="content-progress-bar"
          />

          <div class="content-stats">
            <div class="content-stat-item">
              <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'content-stat-item-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="xd3ph0cdoa" maskUnits="userSpaceOnUse" x="4" y="4" width="22" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 24h4V12H4v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L17.17 4l-6.58 6.59c-.37.36-.59.86-.59 1.41v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L26 13z" fill="#fff"/></mask><g mask="url(#xd3ph0cdoa)"><path d="M2 2h26v26H2z"/></g></svg>

              <div class="content-stat-item-label">
                <h5>{{ projectData.stats.rating }}%</h5>
              </div>
            </div>

            <div class="content-stat-item">
              <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'content-stat-item-img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><mask id="m837gp688a" maskUnits="userSpaceOnUse" x="5" y="5" width="20" height="20"><path fill-rule="evenodd" clip-rule="evenodd" d="M15 5C9.5 5 5 9.5 5 15s4.5 10 10 10 10-4.5 10-10S20.5 5 15 5zm4.2 14.2L14 16v-6h1.5v5.2l4.5 2.7-.8 1.3z" fill="#fff"/></mask><g mask="url(#m837gp688a)"><path fill="#D8D8D8" d="M2 2h26v26H2z"/></g></svg>



              <template v-if="projectData.stats.remaining !== null && projectData.stats.remaining >= 0">
                <div class="content-stat-item-label">
                  <h5>{{ projectData.stats.remaining }} {{ i18n.daysRemaining[$lang] }}</h5>
                </div>
              </template>
              <template v-else>
                <div class="content-stat-item-label">
                  <h5>{{ i18n.pollFinished[$lang] }}</h5>
                </div>
              </template>

            </div>
          </div>
        </div>
        <ToggleVoteButton
                :is-user-voted="projectData.userVote"
                :project-id="projectData.id"
                @change="handleVoteStatusChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import ProgressBar from "@/components/ProgressBar";
import objectValidator from "@/utils/objectValidator.js";
import ToggleFavourite from "@/components/ToggleFavourite";
import ToggleVoteButton from "@/components/ToggleVoteButton";
import ButtonShare from "@/components/ButtonShare";
import { mapState } from "vuex";

export default {
  name: "CardVariant2",
  components: {
    ProgressBar,
    Button,
    ToggleVoteButton,
    ToggleFavourite,
    ButtonShare,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    status: {
      type: [String, Object],
      default: "",
    }
  },
  data() {
    return {
      projectData:{},
      i18n: {
        daysRemaining: {
          en: "days remaining",
          ru: "дней осталось",
        },
        pollFinished:{
          en: "Finished",
          ru: "Голосование завершено"
        }
      },
    };
  },
  computed: {
    ...mapState(["$lang"]),
  },
  methods: {
    handleSlideWrapperClick() {
      this.$router.push("/project/" + this.project.id);
    },
    handleVoteStatusChange(project) {
      vm.$set(this.projectData, "stats", project.data.stats);
      vm.$set(this.projectData, "userVote", project.data.userVote);
    }
  },
  mounted(){
    this.projectData = this.project
  }
};
</script>

<style scoped lang="scss">
@import "@/sass/mixins.scss";

.slides-wrapper .view-profile-discovery-component-card{
  @media only screen and (max-width: 767px) {
    margin: 0 0px 30px 0px;
    width: 100%;
  }
}

.view-profile-discovery .view-profile-discovery-component-card {
  @media only screen and (max-width: 767px) {
    margin: 0 10px 30px 10px;
    width: auto;
  }
}

.view-profile-discovery-component-card {
  display: flex !important;
  align-items: stretch;
  justify-content: flex-start;
  outline: none;
  margin: 0 0 30px 2px;
  width: 100%;
  /*box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);*/
  cursor: pointer;
  background: #fff;
  border-radius: 6px;
  @media only screen and (max-width: 767px){
    flex-direction: column;
    //box-shadow: 0 10px 30px -2px rgba(79, 65, 124, 0.0533217);
  }

  @include box-shadow-1;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  height: 300px;
  background: no-repeat center;
  background-size: cover;
  border-radius: 6px 0 0 6px;
  @media only screen and (max-width: 767px){
    width: 100%;
    border-radius: 6px 6px 0 0;
  }
  @media only screen and (max-width: 479px){
    height: 160px;
  }
}

.header-top {
  padding: 30px 31px;
  width: 100%;
  @media only screen and (max-width: 767px){
    padding: 10px;
  }
}

.header-bottom {
  margin-top: auto;
}

.buttons-top-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background: rgba(#000, 0.3);
  border-radius: 50%;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: #ffffff;

  .content-button-img {
    color: #ffffff;
  }

  /*&:hover {*/
  /*    background: #F7F7F7;*/
  /*    border: 2px solid #f5f5f5;*/
  /*    transition: all 0.3s ease;*/

  /*    .content-button-img {*/
  /*        color: #B3B3B3;*/
  /*    }*/
  /*}*/
}

.content-button-img {
  width: 21px;
}

.header-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px 20px 34px;
  @media only screen and (max-width: 767px){
    padding: 20px 10px;
  }
}

.header-tags-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.header-tag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: IBMPlexSans;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  background: rgba(#000, 0.3);
  margin-left: 10px;

  &:nth-child(1) {
    margin-left: 0;
  }
}

.header-stats-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  @media only screen and (max-width: 767px){
    display: none;
  }
}

.header-stat {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 24px;

  &:nth-child(1) {
    margin-left: 0;
  }
}

.header-stat-img {
  width: 26px;
  color: #fff;
}

.header-stat-label {
  font-family: IBMPlexSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-left: 13px;

  @media only screen and (max-width: 1030px) {
    font-size: 12px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 30px;
  padding-bottom: 35px;
  width: 50%;

  @media only screen and (max-width: 1285px) {
    padding: 15px;
    padding-bottom: 20px;
  }
  @media only screen and (max-width: 767px){
    width: 100%;
    padding: 20px;
  }
}

.title {
  color: #4d4d4d;
}

.text {
  color: #3c3c3c;
  margin-top: 10px;
}

.content-footer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: auto;
}

.content-stats-and-progress-bar {
  margin-right: 30px;
  cursor:default;

  @media only screen and (max-width: 1405px) {
    margin-left: 15px;
  }
  @media only screen and (max-width: 767px){
    width: 100%;
    margin: 0;
  }
}

.content-button {
  // margin-left: 30px;
  margin-left: auto;
  min-width: 180px;

  @media only screen and (max-width: 767px){
    display: none;
  }
}

.content-button-img {
  width: 24px;
}

.content-button-label {
  font-size: 12px !important;
  margin-left: 22px;

  @media only screen and (max-width: 1100px) {
    margin-left: 8px;
  }
}

.content-stats {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 17px;
  @media only screen and (max-width: 767px){
    margin-top: 20px;
  }
}

.content-stat-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 36px;
  white-space: nowrap;

  &:nth-child(1) {
    margin-left: 0;
  }

  @media only screen and (max-width: 1405px) {
    margin-left: 15px;
  }

  @media only screen and (max-width: 1040px) {
    margin-left: 10px;
  }
  @media only screen and (max-width: 767px){
    margin-left: 12px;
  }
}

.content-stat-item-img {
  color: #d8d8d8;
  width: 26px;
  @media only screen and (max-width: 767px){
    width: 30px;
    height: 30px;
  }
}

.content-stat-item-label {
  color: #666666;
  margin-left: 10px;

  @media only screen and (max-width: 1405px) {
    margin-left: 5px;
  }

  @media only screen and (max-width: 767px){
    margin-left: 4px;
  }
}
</style>
