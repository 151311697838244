<template>
    <div  class="implementation-plan">
        <div  class="header">
            <div  class="header-item">
                <div  class="big-round with-bg">
                    1
                </div>
                <div  class="header-text">
                    <div  class="header-title">
                        1 квартал
                    </div>
                    <div  class="subtitle">
                        Разработка
                    </div>
                </div>
            </div>
            <div  class="header-item">
                <div  class="big-round">
                    2
                </div>
                <div  class="header-text">
                    <div  class="header-title">
                        2 Квартал
                    </div>
                    <div  class="subtitle">
                        Старт продаж
                    </div>
                </div>
            </div>
            <div  class="header-item">
                <div  class="big-round">
                    3
                </div>
                <div  class="header-text">
                    <div  class="header-title">
                        3 Квартал
                    </div>
                    <div  class="subtitle">
                        Масштабирование
                    </div>
                </div>
            </div>
            <div class="break"></div>
            <div  class="header-item">
                <div  class="big-round">
                    4
                </div>
                <div  class="header-text">
                    <div  class="header-title">
                        4 квартал
                    </div>
                    <div  class="subtitle">
                        Разработка
                    </div>
                </div>
            </div>

            <div  class="header-item">
                <div  class="big-round">
                    5
                </div>
                <div  class="header-text">
                    <div  class="header-title">
                        5 Квартал
                    </div>
                    <div  class="subtitle">
                        Старт продаж
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'ImplementationPlan',
        data() {
            return {
                i18n: {
                    firstStep: {
                        en: 'First Step',
                        ru: 'Первая стадия'
                    },
                    development: {
                        en: 'Development',
                        ru: 'Разработка'
                    },
                    secondStep: {
                        en: 'Second Step',
                        ru: 'Вторая стадия'
                    },
                    startOfSales: {
                        en: 'Start of sales',
                        ru: 'Старт продаж'
                    },
                    thirdStep: {
                        en: 'Third Step',
                        ru: 'Третья стадия'
                    },
                    scale: {
                        en: 'Scale',
                        ru: 'Масштабирование'
                    },
                    tableItem1: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                        ru: 'Дизайн интерфейса'
                    },
                    tableItem2: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                        ru: 'Подготовка программного модуля'
                    },
                    tableItem3: {
                        en: 'Lorem ipsum dolor sit amet,',
                        ru: 'Разработка библиотек'
                    },
                    tableItem4: {
                        en: 'Lorem ipsum dolor sit amet,',
                        ru: 'Тестирование и отладка'
                    },
                    month: {
                        en: 'month',
                        ru: 'месяц'
                    },
                    tableItem5: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                        ru: 'Закрытое бета-тестирование'
                    },
                    tableItem6: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                        ru: 'Разработка сайта'
                    },
                    tableItem7: {
                        en: 'Lorem ipsum dolor sit amet,',
                        ru: 'Доработка и релиз'
                    },
                    tableItem8: {
                        en: 'Lorem ipsum dolor sit amet,',
                        ru: 'Рекламная кампания'
                    },
                    tableItem9: {
                        en: 'Lorem ipsum dolor sit amet,',
                        ru: 'Запуск клиентского офиса'
                    },
                    tableItem10: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                        ru: 'Массированная рекламная кампания'
                    },
                    tableItem11: {
                        en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
                        ru: 'Аналитика и поддержка'
                    },
                    tableItem12: {
                        en: 'Lorem ipsum dolor sit amet,',
                        ru: 'Прямые продажи через менеджеров'
                    },
                },
            }
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
        }
    };
</script>

<style scoped lang="scss">
    .implementation-plan {
        margin-top: 60px;
        @media only screen and (max-width: 767px){
            margin-top: 20px;
        }
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        &:after{
            content: '';
            flex-basis: 33.33%;
        }
    }

    .header .break {
        flex-basis: 100%;
        height: 29px;
        @media only screen and (max-width: 767px){
            display: none;
        }
    }

    .header-item {
        display: flex;
        align-items: center;
        justify-content: left;
        flex-basis: 33.3%;
        @media only screen and (max-width: 767px){
            margin-bottom: 20px;
            flex-basis: 50%;
        }
        @media only screen and (max-width: 479px){
            flex-basis: 100%;
        }
    }

    .big-round {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        width: 60px;
        border: 2px solid #F5F5F5;
        border-radius: 50%;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #B3B3B3;
        text-align: center;
        letter-spacing: 2px;
        text-transform: uppercase;
        min-width: 60px;
        @media only screen and (max-width: 767px){
            min-width: 40px;
            width: 40px;
            height: 40px;
            font-size: 10px;
        }

        &.with-bg {
            color: #fff;
            border: 0;
            background-color: #50CAB6
        }
    }

    .header-text {
        margin-left: 30px;
        @media only screen and (max-width: 767px){
            margin-left: 10px;
        }
    }

    .header-title {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #B3B3B3;
        @media only screen and (max-width: 767px){
            font-size: 9px;
            line-height: 18px;
        }
    }

    .subtitle {
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #666666;
        @media only screen and (max-width: 767px){
            font-size: 11px;
            line-height: 11px;
        }
    }

    .steps {
        margin-top: 60px;
    }

    .step-item {
        display: flex;
        align-items: stretch;
        justify-content: flex-start;

        &:nth-last-child(1) {
            .step-item-line {
                display: none;
            }
        }
    }

    .step-item-left {
        position: relative;
    }

    .step-item-right {
        margin-left: 35px;
        margin-top: 30px;
        width: 100%;
    }

    .table {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        grid-auto-rows: 60px;
        grid-column-gap: 15px;
    }

    .table-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: IBMPlexSans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #B3B3B3;
    }

    .step-item-line {
        position: absolute;
        top: 67px;
        left: 0;
        right: 0;
        margin: auto;
        width: 1px;
        height: calc(100% - 60px - 20px);
        background: #F0F0F0;
    }
</style>
