<template>
    <div class="view-home">
        <Header
                :logo-white="true"
                :invisible-bg="true"
                :login-button="true"

                class="header"
        />

        <!--    <BurgerMenu v-if="false" invisible-bg />-->

        <div class="home-content">
            <div class="block-2">
                <Slide class="home-slide1" :img-src="require('@/assets/img/view-home/slide1.png')">
                    <template #title>
                        <h1 v-html="i18n.bigProjectsStart[$lang]"/>
                    </template>

                    <template #text>
                        <span class="text-lead" v-html="i18n.takePartInFinancing[$lang]"/>
                    </template>

                    <template #buttons>
                        <Button
                                class="button button-connect primary"
                                @click="handleConnectButtonClick"
                        >
                            {{ i18n.connect[$lang] }}
                        </Button>
                        <TokenPrice/>
                    </template>
                </Slide>
            </div>
            <div class="block-1">

                <!--                <Stats/>-->

                <infobox/>
                <icons/>

                <div class="paragraphs-wrapper regular">
                    <Paragraph
                            v-for="(paragraphCur, index) in paragraphs"
                            :key="`${index}__${paragraphCur.title}`"
                            :paragraph="paragraphCur"
                            :slick-carousel-config="slickSliderConfigDefault"
                            slider-variant="SlideVariant1"
                            class="paragraph"
                    />
                    <!--          <div v-if="showMoreParagraphs == true">-->
                    <!--            <Paragraph-->
                    <!--              v-for="(paragraphCur, index) in paragraphsHidden"-->
                    <!--              :key="index"-->
                    <!--              :paragraph="paragraphCur"-->
                    <!--              :slick-carousel-config="slickSliderConfigDefault"-->
                    <!--              slider-variant="SlideVariant1"-->
                    <!--              class="paragraph"-->
                    <!--              style="transition: height 0.3s ease;"-->
                    <!--            />-->
                    <!--          </div>-->

                    <Button class="normal browse-projects" :without-border="false" @click="handleConnectButtonClick">
                        <template>
                            Смотреть все 245 проектов
                        </template>
                    </Button>
                </div>
                <div class="block-how-it-works">
                    <HowItWorks/>
                    <Button class="button show-whitepaper" :without-border="false">
                        Whitepaper проекта
                    </Button>
                </div>
            </div>

            <div class="block-2">
                <Slide
                        :img-src="require('@/assets/img/view-home/slide2.png')"
                        class="home-slide2"
                >
                    <template #title>
                        <h2 v-html="i18n.feauturesOfTokens[$lang]"/>
                    </template>

                    <template #text>
                        <p v-html="i18n.grossfortTokensAreCreatedOnDAO[$lang]"/>
                    </template>
                    <template #lineList>
                        <span></span>
                        <LineList class="line-list"/>
                    </template>

                    <!--                    <template #buttons>-->
                    <!--                        <Button class="button propose-a-project">-->
                    <!--                            {{ i18n.proposeAProject[$lang] }}-->
                    <!--                        </Button>-->
                    <!--                    </template>-->
                </Slide>

                <div class="paragraphs-wrapper news">
                    <Paragraph
                            :paragraph="paragraphNews"
                            :slick-carousel-config="slickSliderConfigNews"
                            slider-variant="SlideVariant2"
                            class="paragraph"
                    />
                    <div v-if="showMoreParagraphNews == true">
                        <Paragraph
                                :paragraph="paragraphNewsHidden"
                                :slick-carousel-config="slickSliderConfigNews"
                                slider-variant="SlideVariant2"
                                class="paragraph"
                                style="transition: height 0.3s ease"
                        />
                    </div>

                    <ShowMore class="show-more" @click="showMoreParagraphNewsClick"/>
                </div>
                <div class="paragraphs-wrapper roadmap">
                    <Paragraph
                            :paragraph="paragraphRoadmap"
                            :slick-carousel-config="slickSliderConfigRoadmap"
                            slider-variant="Roadmap"
                            class="paragraph"
                            :line="paragraphRoadmap.paragraphLine"
                    />
                </div>
            </div>

            <div class="block-3">
                <Slide :img-src="require('@/assets/img/view-home/slide3.png')" class="home-slide3">

                    <template #title>
                        <h2 v-html="i18n.warrantiesAndReporting[$lang]"/>
                    </template>

                    <template #text>
                        <div v-html="i18n.weHaveNothingToHide[$lang]"></div>

                        <Button class="button connect button-show-report primary">
                            Смотреть Примеры отчётов →
                        </Button>
                    </template>
                </Slide>

                <FAQ/>
                <!--        <ForBigAndSmall />-->
                <Subscribe/>
            </div>
        </div>

        <Footer class="footer"/>
    </div>
</template>

<script>
    import Header from "@/components/Header";
    import Footer from "@/components/Footer";
    import BurgerMenu from "@/components/BurgerMenu";
    import Slide from "./components/Slide";
    import Stats from "./components/Stats";
    import Paragraph from "./components/Paragraph";
    import ShowMore from "@/components/ShowMore";
    import Button from "@/components/Button";
    import HowItWorks from "./components/HowItWorks";
    import FAQ from "./components/FAQ";
    import ForBigAndSmall from "./components/ForBigAndSmall";
    import Infobox from "./components/Infobox/Infobox";
    import Icons from "./components/Icons/Icons";
    import BrowseProjects from "./components/BrowseProjects";
    import LineList from "./components/LineList/LineList";
    import TokenPrice from "./components/TokenPrice/TokenPrice";
    import Subscribe from "./components/Subscribe";
    import scrollByNewItems from "@/utils/scrollByNewItems.js";
    import getRandomItem from "@/utils/getRandomItem.js";
    import prepareSvgElements from "@/mixins/prepareSvgElements.js";
    import {mapState, mapGetters} from "vuex";

    export default {
        name: "Home",
        components: {
            HowItWorks,
            ShowMore,
            Paragraph,
            Infobox,
            Icons,
            Stats,
            Slide,
            BrowseProjects,
            Header,
            Footer,
            BurgerMenu,
            Button,
            LineList,
            FAQ,
            //ForBigAndSmall,
            Subscribe,
            TokenPrice,
        },
        mixins: [prepareSvgElements],
        data() {
            return {
                slickSliderConfigDefault: this.getSlickSliderConfig(),
                slickSliderConfigNews: {
                    ...this.getSlickSliderConfig(),
                    slidesPerRow: 4,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesPerRow: 2,
                                autoplay: true,
                                autoplaySpeed: 3000,
                            }
                        }, {
                            breakpoint: 480,
                            settings: {
                                slidesPerRow: 1,
                                autoplay: true,
                                autoplaySpeed: 3000,
                            }
                        },
                    ]
                },
                slickSliderConfigRoadmap: {
                    ...this.getSlickSliderConfig(),
                    slidesPerRow: 6,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesPerRow: 2,
                                autoplay: true,
                                autoplaySpeed: 3000,
                            }
                        }, {
                            breakpoint: 480,
                            settings: {
                                slidesPerRow: 1,
                                autoplay: true,
                                autoplaySpeed: 3000,
                            }
                        },
                    ]
                },
                showMoreParagraphNews: false,
                paragraphs: [
                    {
                        title: {
                            en: "Ending soon",
                            ru: "Скоро завершатся",
                        },
                        slides: [
                            {
                                title: {
                                    en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                    ru: "Где искать золото. Практическое руководство для новичка",
                                },
                                text: {
                                    en:
                                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                    ru:
                                        "Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё",
                                },
                                imgSrc: require("@/assets/img/view-home/img.png"),
                                button: {
                                    en: "promote",
                                    ru: "Поддержать",
                                },
                                tags: ["Литература", "Культура"],
                                stats: {
                                    views: 275,
                                    comments: 12,
                                },
                                progress: {
                                    support: 57,
                                    remaining: 7,
                                    percent: 30,
                                },
                            },
                            {
                                title: {
                                    en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                    ru: "Трудоустройство в крестьянском хозяйстве «Белые росы»",
                                },
                                text: {
                                    en:
                                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                    ru:
                                        "Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё",
                                },
                                imgSrc: require("@/assets/img/view-home/img2.png"),
                                button: {
                                    en: "promote",
                                    ru: "Поддержать",
                                },
                                tags: ["Литература", "Культура"],
                                stats: {
                                    views: 112,
                                    comments: 118,
                                },
                                progress: {
                                    support: 10,
                                    remaining: 5,
                                    percent: 66,
                                },
                            },
                            {
                                title: {
                                    en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                    ru:
                                        "Дом, позволяющий выращивать Эко продукты и зарабатывать, проживая на земле",
                                },
                                text: {
                                    en:
                                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                    ru:
                                        "Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё",
                                },
                                imgSrc: require("@/assets/img/view-home/img3.png"),
                                button: {
                                    en: "promote",
                                    ru: "Поддержать",
                                },
                                tags: ["Литература", "Культура"],
                                stats: {
                                    views: 111,
                                    comments: 34,
                                },
                                progress: {
                                    support: 78,
                                    remaining: 3,
                                    percent: 15,
                                },
                            },
                            {
                                title: {
                                    en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                    ru: "Чемпионат мира по фридайвингу для сборной команды России",
                                },
                                text: {
                                    en:
                                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                                    ru:
                                        "Форум заслуженно признан культурным событием мирового уровня, ежегодно притягивающим тысячи экспертов в области культуры со всего мира: звезды эстрады и стран ближнего зарубежья… ещё",
                                },
                                imgSrc: require("@/assets/img/view-home/img4.png"),
                                button: {
                                    en: "promote",
                                    ru: "Поддержать",
                                },
                                tags: ["Литература", "Культура"],
                                stats: {
                                    views: 57,
                                    comments: 113,
                                },
                                progress: {
                                    support: 88,
                                    remaining: 100,
                                    percent: 99,
                                },
                            },
                        ],
                    },
                ],
                paragraphNews: {
                    title: {
                        en: "Grossfort news",
                        ru: "Свежие новости и статьи",
                    },
                    slides: [
                        {
                            title:
                                "Маркетинговая активность ускоряет сублимированный стиль менеджмента",
                            imgSrc: require("@/assets/img/view-home/news-img1.png"),
                            stats: {
                                date: {
                                    en: "2 days ago",
                                    ru: "2 дня назад",
                                },
                                views: {
                                    en: "275 views",
                                    ru: "275 просмотров",
                                },
                            },
                        },
                        {
                            title:
                                "Дивидендная доходность по российскому фондовому рынку на уровне 7-8%",
                            imgSrc: require("@/assets/img/view-home/news-img2.png"),
                            stats: {
                                date: {
                                    en: "2 days ago",
                                    ru: "2 дня назад",
                                },
                                views: {
                                    en: "390 views",
                                    ru: "390 просмотров",
                                },
                            },
                        },
                        {
                            title: "Инвестиции в малый бизнес, доходность выше, чем по вкладам",
                            imgSrc: require("@/assets/img/view-home/news-img3.png"),
                            stats: {
                                date: {
                                    en: "June 20",
                                    ru: "20 июня",
                                },
                                views: {
                                    en: "390 views",
                                    ru: "390 просмотров",
                                },
                            },
                        },
                        {
                            title:
                                "Трамп потребовал немедленно начать процесс по импичменту в сенате",
                            imgSrc: require("@/assets/img/view-home/news-img4.png"),
                            stats: {
                                date: {
                                    en: "May 1",
                                    ru: "1 мая",
                                },
                                views: {
                                    en: "390 views",
                                    ru: "390 просмотров",
                                },
                            },
                        },
                        {
                            title:
                                "Маркетинговая активность ускоряет сублимированный стиль менеджмента",
                            imgSrc: require("@/assets/img/view-home/news-img1.png"),
                            stats: {
                                date: {
                                    en: "2 days ago",
                                    ru: "2 дня назад",
                                },
                                views: {
                                    en: "275 views",
                                    ru: "275 просмотров",
                                },
                            },
                        },
                        {
                            title:
                                "Дивидендная доходность по российскому фондовому рынку на уровне 7-8%",
                            imgSrc: require("@/assets/img/view-home/news-img2.png"),
                            stats: {
                                date: {
                                    en: "2 days ago",
                                    ru: "2 дня назад",
                                },
                                views: {
                                    en: "390 views",
                                    ru: "390 просмотров",
                                },
                            },
                        },
                        {
                            title: "Инвестиции в малый бизнес, доходность выше, чем по вкладам",
                            imgSrc: require("@/assets/img/view-home/news-img3.png"),
                            stats: {
                                date: {
                                    en: "June 20",
                                    ru: "20 июня",
                                },
                                views: {
                                    en: "390 views",
                                    ru: "390 просмотров",
                                },
                            },
                        },
                        {
                            title:
                                "Трамп потребовал немедленно начать процесс по импичменту в сенате",
                            imgSrc: require("@/assets/img/view-home/news-img4.png"),
                            stats: {
                                date: {
                                    en: "May 1",
                                    ru: "1 мая",
                                },
                                views: {
                                    en: "390 views",
                                    ru: "390 просмотров",
                                },
                            },
                        },
                    ],
                },
                paragraphRoadmap: {
                    paragraphLine: false,
                    title: {
                        en: "Roadmap",
                        ru: "Дорожная карта",
                    },
                    slides: [
                        {
                            name: "2018 Q1",
                            desc: {
                                en: "Зарождение идеи проекта",
                                ru: "Зарождение идеи проекта",
                            },
                        },
                        {
                            name: "2018 Q2",
                            desc: {
                                en:
                                    "A key team of developers, analysts and experienced managers is formed, MVP is in development",
                                ru:
                                    "Формируется ключевая команда разработчиков, аналитиков и опытных управленцев, начинается работа над MVP",
                            },
                        },
                        {
                            name: "2018 Q3",
                            desc: {
                                en:
                                    "We put $ 15 million in various investment products to test the hypothesis of collective investment with a low threshold of entry",
                                ru:
                                    "Разместили $15 миллионов в различные инвестиционные продукты, чтобы протестировать гипотезу о коллективном инвестировании с низким порогом входа",
                            },
                        },
                        {
                            name: "2018 Q4",
                            desc: {
                                en:
                                    "Received licenses for the storage and exchange of cryptocurrencies in the European Union",
                                ru:
                                    "Получили лицензии на хранение и обмен криптовалют в Евросоюзе",
                            },
                        },
                        {
                            name: "2019 Q1",
                            desc: {
                                en:
                                    "The total investor community has exceeded 300,000 people",
                                ru: "Общее комьюнити инвесторов превысило 300,000 человек",
                            },
                        },
                        {
                            name: "2019 Q4",
                            desc: {
                                en:
                                    "Investors check finances, get to know the owners, buy shares in projects or lend money. You can make a deal and follow the development of your business online.",
                                ru:
                                    "Инвесторы проверяют финансы, знакомятся с собственниками, покупают доли в проектах или дают деньги взаймы. Заключить сделку и следить за развитием бизнеса можно онлайн.",
                            },
                        },
                    ],
                },
                i18n: {
                    bigProjectsStart: {
                        en: `Big projects start with small steps`,
                        ru: `Большие проекты начинаются с малого`,
                    },
                    feauturesOfTokens: {
                        en: "Features of Grossfort Tokens",
                        ru: "Особенности токенов Grossfort",
                    },
                    grossfortTokensAreCreatedOnDAO: {
                        en:
                            'Grossfort tokens are created on the DAO (decentralized autonomous organization) blockchain. It is a digital decentralized autonomous organization and a form of investment venture capital. It works on the Ethereum blockchain platform. DAO Tokens are an electronic alternative to stocks. They assign a number of rights to the owner. Read more about DAO <a href="javascript:void">in the report</a> of the US Securities and Exchange Commission (SEC)',
                        ru:
                            'Токены Grossfort создаются на блокчейне DAO (decentralized autonomous organization). Это цифровая децентрализованная автономная организация и форма инвестиционного венчурного капитала. Работает на блокчейн-платформе Ethereum. Токены DAO — электронная альтернатива акциям. Они закрепляют за владельцем ряд прав. Подробнее о DAO — <a href="javascript:void">в отчёте комиссии</a> по ценным бумагам и биржам США (SEC)',
                    },
                    theFirstCroudinvest: {
                        en: `Grossfort - the first stock crowd-investing platform on Blockchain`,
                        ru: `Grossfort - первая акционерная краудинвестинговая площадка на Blockchain`,
                    },
                    takePartInFinancing: {
                        en: `Take part in financing thousands of business ideas around the world, receive bonuses and annual dividends`,
                        ru: `Примите участие в финансировании тысяч бизнес-идей по всему миру, получайте бонусы и ежегодные дивиденды`,
                    },
                    connect: {
                        en: "Connect",
                        ru: "Присоединиться",
                    },
                    howItWorks: {
                        en: "How it works",
                        ru: "Как это работает",
                    },
                    grossfortIsTheFirst: {
                        en: `Grossfort — is the first joint-stock crowdinvesting platform`,
                        ru: `Grossfort — первая акционерная краудинвестинговая площадка`,
                    },
                    itIsAPlace: {
                        en: `It is a place, where each user is at the same time an investor and a job seeker. Contributions of participants form a common fund, from which the platform finances and helps launch business ideas of participants. The decision on the financing of the project is made by general vote.`,
                        ru: `Это место, где каждый пользователь — одновременно инвестор и соискатель. Взносы участников образуют общий фонд, из которого площадка финансирует и помогает запускать бизнес-идеи участников. Решение о финансировании проекта принимается общим голосованием.`,
                    },
                    proposeAProject: {
                        en: "Propose a project",
                        ru: "Предложить проект",
                    },
                    warrantiesAndReporting: {
                        en: "Warranties and reporting",
                        ru: "Гарантии и отчётность",
                    },
                    weHaveNothingToHide: {
                        en: `<p>We have nothing to hide. All information about the site and attracted investments is in the public domain, and the mechanics of work are described in detail by law. There will be no fake projects. Because all participants pay dues and moderators carefully check each project.
            </p>
            <p>
            Every year we report on the results of financial activities to all participants of the site, the Central Bank and the Tax Service of the Russian Federation. It tells in detail how much money the site raised, how much was spent on its work, how much was invested in businesses and what profit they brought.Sample reports here.
            </p>`,
                        ru: `<p>Нам нечего скрывать. Вся информация о площадке и привлечённых инвестициях находится в
                            открытом
                            доступе, а механика работы подробно описана законом.</p><br/>
                        <p>Деятельность краудфандинговых и краудинвестиционных площадок регулируется федеральным законом
                            №
                            ____ «О привлечении инвестиций с использованием инвестиционных платформ» от 24 июля 2019
                            г.</p>`,
                    },
                },
            };
        },
        methods: {
            getSlickSliderConfig() {
                return {
                    arrows: false,
                    dots: false,
                    slidesPerRow: 2,
                    swipe: true,
                    draggable: true,
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesPerRow: 1,
                                autoplay: true,
                                autoplaySpeed: 3000,
                            }
                        }
                    ]
                };
            },
            showMoreParagraphNewsClick() {
                this.showMoreParagraphNews = true;
            },
            async showMoreParagraphsClick() {
                const newParagraph = getRandomItem(this.paragraphs);

                if (this.paragraphs.length <= 5) {
                    this.paragraphs.push(newParagraph);

                    await this.$nextTick();

                    scrollByNewItems(".paragraphs-wrapper.regular", ".paragraph");
                }
            },
            handleConnectButtonClick() {
                if (this.isLoggedIn) this.$router.push("/profile-home");
                else this.$router.push("/signup");
            },
        },
        computed: {
            ...mapState({
                $lang: (state) => state.$lang,
            }),
            ...mapGetters("user", ["isLoggedIn"]),
        },
        // beforeCreate: function() {
        //     document.body.className = 'home';
        //     document.documentElement.className = 'home'
        // }
    };
</script>

<style scoped lang="scss">
    .paragraphs-wrapper {
        background: #fafafa;
        // padding-top: 82px;
        padding-top: 4.3%;

        &.regular {
            padding-bottom: 65px;
        }

        &.news {
            padding-bottom: 95px;
        }

        &.roadmap {
            padding-bottom: 90px;
        }

        @media only screen and (max-width: 767px) {
            padding: 60px 0;
            &.news, &.roadmap, &.regular {
                padding-bottom: 60px;
            }

        }
    }

    .paragraphs-wrapper.roadmap {
        background: #fff;

        .line {
            background: none !important;
            margin: 16px 0;
        }
    }

    .paragraph {
        // margin-top: 112px;
        margin-top: 90px;

        &:nth-child(1) {
            margin-top: 0;
        }
    }

    .home-slide1,
    .home-slide2,
    .home-slide3 {
        //padding: 6% 165px 3% !important;
        padding: 6% 12% 3% !important;

        .wrapper {
            max-width: 540px !important;
        }

        .text * {
            //font-size: 14px !important;
        }

        @media only screen and (max-width: 767px) {
            padding: 60px 20px 40px !important;
            .text * {
                //font-size: 11px !important;
                //line-height: 18px;
            }
            .button-show-report {
                font-size: 9px !important;
                line-height: 18px;
                letter-spacing: .5px;
                height: auto;
                padding: 9px 28px;
            }
        }
    }

    .show-more {
        margin-top: 0;
    }

    .browse-projects {
        margin: 0 auto;
    }

    .show-whitepaper {
        margin: 0 auto;
        margin-bottom: 30px;
        @media only screen and (max-width: 767px) {
            width: auto;
            padding: 0 15px !important;
        }
    }

    .block-how-it-works {
        background: #fff;
        padding-bottom: 59px;
    }

    .button-connect {
        margin-left: 15px;
    }

    .button {
        padding: 0 35px;

        &:nth-child(1) {
            margin-left: 0;
        }

        &.how-it-works {
            padding: 0 30px;

            &:hover {
                opacity: 1;
                background-color: transparent !important;
                border-color: transparent !important;
            }
        }
    }

    .block-1 {
        background: #fff;
    }

    .footer {
        margin-top: 0 !important;
    }

    .line-list {
        margin-top: 74px;
        @media only screen and (max-width: 767px) {
            margin-top: 40px;
        }
    }

    .button-show-report {
        margin-top: 60px;
        @media only screen and (max-width: 767px) {
            margin-top: 40px;
        }
    }
</style>
