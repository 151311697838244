<template>
  <div class="view-home-component-faq">
    <div class="header">
      <h2 class="title">
        {{ i18n.faq[$lang] }}
      </h2>

      <div class="line" />

      <div class="item text-lead-2">
        {{ i18n.toCreateAProject[$lang] }}
      </div>
    </div>

    <div class="content">
      <FAQ :items="items" />
    </div>

    <ShowMore class="show-more" @click="handleShowMoreClick" />
  </div>
</template>

<script>
  import FAQ from '@/components/FAQ';
  import ShowMore from '@/components/ShowMore';
  import getRandomItem from '@/utils/getRandomItem.js';
  import scrollByNewItems from '@/utils/scrollByNewItems.js';
  import { mapState } from 'vuex';

  export default {
    name: 'HomeFAQ',
    components: {
      ShowMore,
      FAQ,
    },
    data() {
      return {
        items: [
          {
            visiblePart: {
              en: 'I have a question / suggestion. Where to go?',
              ru: 'У меня есть вопрос/предложение. Куда обратиться?'
            },
            invisiblePart: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
          },
          {
            visiblePart: {
              en: 'I am looking for work and can help in the implementation of the project. How to contact the owner?',
              ru: 'Я ищу работу и могу помочь в реализации проекта. Как связаться с владельцем?'
            },
            invisiblePart: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
          },
          {
            visiblePart: {
              en: 'Do I need to register a legal entity to register on the site?',
              ru: 'Нужно ли мне регистрировать юрлицо чтобы зарегистрироваться на площадке?'
            },
            invisiblePart: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
          },
          {
            visiblePart: {
              en: 'How to propose your project for voting?',
              ru: 'Как предложить свой проект на голосование?'
            },
            invisiblePart: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
          },
          {
            visiblePart: {
              en: 'What part of the participants\' contributions goes to the implementation of projects?',
              ru: 'Какая часть взносов участников идёт на реализацию проектов?'
            },
            invisiblePart: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
          },
          {
            visiblePart: {
              en: 'How to report on the progress of the project, if it was approved?',
              ru: 'Как отчитываться о ходе реализации проекта, если его одобрили?'
            },
            invisiblePart: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
          },
          {
            visiblePart: {
              en: 'How to get money if my project has received approval?',
              ru: 'Как получить деньги, если мой проект получил одобрение?'
            },
            invisiblePart: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
          },
          {
            visiblePart: {
              en: 'What share of profit from the project does the site take to itself?',
              ru: 'Какую долю прибыли с проекта забирает себе площадка?'
            },
            invisiblePart: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
          }
        ],
        i18n: {
          faq: {
            en: 'FAQ',
            ru: 'Частые вопросы'
          },
          toCreateAProject: {
            en: 'To create a project on Grossfort you do not need special skills. If you have a question, most likely you will find the answer below.',
            ru: 'Для создания проекта на Grossfort вам не нужны специальные навыки. Если у вас есть вопрос, скорее всего, вы найдете ответ ниже.'
          },

        },
      }
    },
    methods: {
      async handleShowMoreClick() {
        const newItems = [];

        for (let i = 0; i < 4; i++) {
          const newItem = getRandomItem(this.items);

          newItems.push(newItem);
        }

        this.items.push(...newItems);

        await this.$nextTick();

        scrollByNewItems('.component-faq', '.faq-item', 5);
      }
    },
    computed: {
      ...mapState({
        $lang: (state) => state.$lang,
      }),
    }
  };
</script>

<style scoped lang="scss">
  .view-home-component-faq {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    // padding: 120px 355px 75px 355px;
    padding: 120px 24.6% 75px;

    @media only screen and (max-width: 1200px) {
      padding: 120px 18.6% 75px;
    }
    @media only screen and (max-width: 767px){
      padding: 60px 20px;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    text-align: center;
    color: #4D4D4D;
  }

  .line {
    width: 60px;
    height: 2px;
    background: #50CAB6;
    margin-top: 28px;
    @media only screen and (max-width: 767px){
      margin-top: 20px;
      max-width: 88px;
      width: 100%;
    }
  }

  .item {
    text-align: center;
    color: #666666;
    margin-top: 30px;
    max-width: 500px;

    @media only screen and (max-width: 767px){
      margin-top: 20px;
      color: #4D4D4D;
    }
  }

  .show-more {
    margin-top: 35px;
    @media only screen and (max-width: 767px){
      margin-top: 20px;
      font-size: 9px;
      line-height: 18px;

    }
  }

  .content {
    margin-top: 52px;
    @media only screen and (max-width: 767px){
      margin-top: 42px;
    }
  }

  .content-show {
    margin-top: 0;
  }
  .content-show.active {
    margin-top: 100px;
  }
</style>
